import { makeStyles } from '@material-ui/core/styles';
import styled from '@emotion/styled';


export const HeaderClassStyle = makeStyles((theme) => ({
    header: {
        position: 'relative'
    },
    content: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        marginTop: '0.5rem',
        textAlign: 'left'
    },
    coverWrapper: {
        position: 'relative',
        top: 0,
        left: 0,
        height: 135,
        width: '100%',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: '#CAD7CA'
    },
    headerCartBtnLink: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
    },
    shopBranding: {
        marginBottom: '1rem',
        display: 'flex'
    },
    avatar: {
        marginTop: '-20px',
        width: 90,
        minWidth: 90,
        height: 90,
        borderRadius: '0.5rem',
        overflow: 'hidden',
        //boxShadow: '0px 0px 2px rgba(52,58,67,0.10),0px 5px 10px rgba(52,58,67,0.08),0px 15px 35px rgba(52,58,67,0.08)',
        backgroundColor: '#ffffff'
    },
    logo: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        position: 'relative',
        lineHeight: 0
    },
    titleContainer: {
        marginLeft: '0.75rem'
    },
    shopTitle: {
        position: 'relative',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        lineHeight: '1.75rem !important'
    },
    verifiedBadge: {
        position: 'absolute',
        display: 'inline',
        marginLeft: '.25rem',
        lineHeight: 0
    },
    verifiedBadgeIcon: {
        verticalAlign: 'middle'
    },
    whatsappBadge: {
        display: 'inline-block',
        marginTop: 2,
        padding: '0.25rem 0',
        borderRadius: '1rem',
        lineHeight: 0,
        background: '#F2F2F2'
    },
    whatsappBadgeIcon: {
        verticalAlign: 'middle',
        fontSize: '1rem !important',
        color: '#48AC98'
    },
    whatsappBadgeCaption: {
        verticalAlign: 'middle',
        fontSize: '0.75rem',
        lineHeight: '0.75rem',
        color: '#53627C'
    }
}));

export const AppCover = styled.div`
    width: 100%;
    min-height: 120px;
    background: url( ${(props) => props.image });
    background-color: #CDCDCD;
    background-size: cover;
`;

export const AppCoverEmpty = styled.div`
    width: 100%;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #CDCDCD;
`