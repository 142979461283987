import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link as RouterLink } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';

import EditIcon from "../../assets/icons/edit.svg";

//MIXPANEL
import { Mixpanel } from '../../constants.js/mixpanel';

import {
    errorToast,
    successToast,
    routes
} from '../../constants.js/misc';

import { getStoreService, createStoreImagesService, updateStoreService, publisStoreService } from '../../services/store';

import StorePreview from '../../components/ManageStore/StorePreview/StorePreview';
import StoreInfo from '../../components/ManageStore/StoreInfo/StoreInfo';
import { ButtonContainer, Intro } from './ManageStorePage.styles';

export const ManageStorePage = ({ storeId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch
    } = useForm({
        mode: 'onChange'
    });

    const storeName = watch('name');
    const storeDescription = watch('description');
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [gettingInfo, setGettingInfo] = useState(true);
    const [storeData, setStoreData] = useState({});
    const [profilePicture, setProfilePicture] = useState(false);
    const [bannerPicture, setBannerPicture] = useState(false);
    const [saving, setSaving] = useState(false);
    const [publish, setPublish] = useState(false);
    const [savingProfilePicture, setSavingProfilePicture] = useState(false);
    const [savingBannerPicture, setSavingBannerPicture] = useState(false);
    const [verifyUrlError, setVerifyUrlError] = useState(false)
    const isValid = Object.keys(errors)?.length === 0;

    const handleProfilePictureChange = (imageFile) => {
        const newPicture = URL.createObjectURL(imageFile);
        setProfilePicture(newPicture);
        setSavingProfilePicture(true);
        createStoreImagesService(storeId, [imageFile])
            .then(res => {
                Mixpanel.track('Configurar perfil', {
                    "Agregar foto de perfil": "Imagen"
                });
                setStoreData({
                    ...res.data,
                    profile_picture: newPicture,
                    banner_picture: bannerPicture
                });
                enqueueSnackbar(t('ManageStore.profileImageSuccess'), successToast);
                setSavingProfilePicture(false);
            })
            .catch(() => {
                enqueueSnackbar(t('ManageStore.profileImageError'), errorToast);
                setSavingProfilePicture(false);
            });
    }

    const handleBannerPictureChange = (imageFile) => {
        const newPicture = URL.createObjectURL(imageFile);
        setBannerPicture(newPicture);
        setSavingBannerPicture(true);
        createStoreImagesService(storeId, [imageFile], true)
            .then(res => {
                Mixpanel.track('Configurar perfil', {
                    "Agregar foto de banner": "Imagen"
                });
                setStoreData({
                    ...res.data,
                    banner_picture: newPicture,
                    profile_picture: profilePicture,
                });
                enqueueSnackbar(t('ManageStore.bannerImageSuccess'), successToast);
                setSavingBannerPicture(false);
            })
            .catch(() => {
                enqueueSnackbar(t('ManageStore.bannerImageError'), errorToast);
                setSavingBannerPicture(false);
            });
    }

    useEffect(() => {
        getStoreService(storeId)
            .then(res => {
                setStoreData({
                    ...res.data,
                    profile_picture: res.data.profile_picture ? process.env.REACT_APP_IMAGES_API_URL + res.data.profile_picture : '',
                    banner_picture: res.data.banner_picture ? process.env.REACT_APP_IMAGES_API_URL + res.data.banner_picture : '',
                });
                setProfilePicture(res.data.profile_picture ? process.env.REACT_APP_IMAGES_API_URL + res.data.profile_picture : '');
                setBannerPicture(res.data.banner_picture ? process.env.REACT_APP_IMAGES_API_URL + res.data.banner_picture : '');
                setGettingInfo(false);
                setValue('name', res.data.name);
                setValue('url', res.data.url);
                setValue('description', res.data.description);
            })
            .catch(err => {
                console.log(err);
                setGettingInfo(false);
            })
    }, []);

    /**
     * Saves the store data
     * @param {Object} data product data
     */
    const onSubmit = (data) => {
        setSaving(true);
        updateStoreService(storeId, data, true)
            .then(res => {
                enqueueSnackbar(t('ManageStore.updateSuccess'), successToast);
                setSaving(false);
            })
            .catch(err => {
                console.log(err);
                enqueueSnackbar(t('ManageStore.updateError'), errorToast);
                setSaving(false);
            });
    };

    // const onPublish = () => {
    //     setPublish(true);
    //     publisStoreService(storeId)
    //         .then(res => {
    //             enqueueSnackbar(t('ManageStore.updateSuccess'), successToast);
    //             setPublish(false);
    //         })
    //         .catch(err => {
    //             enqueueSnackbar(t('ManageStore.updateError'), errorToast);
    //             setPublish(false);
    //         });
    // };

    const onImageDeletion = banner => {
        setStoreData({
            ...storeData,
            profile_picture: banner ? storeData.profile_picture : '',
            banner_picture: banner ? '' : storeData.banner_picture,
        });
        if (banner) {
            setSavingBannerPicture(false);
            setBannerPicture('');
        } else {
            setProfilePicture('');
            setSavingProfilePicture(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: isTabletOrMobile ? 100 : 0 }}>
            <Grid container>
                {isTabletOrMobile &&
                    <Intro item container xs={12}>
                        <img src={EditIcon} />
                        <Typography variant="caption">
                            {t('Menu.myApps')}
                        </Typography>
                    </Intro>
                }
                <Grid item container xs={12} style={{ padding: isTabletOrMobile ? 20 : '0 0 20px' }}>
                    <Grid item xs={12}>
                        <Typography
                            variant={isTabletOrMobile ? 'h5' : 'h4'}
                            style={{ marginBottom: '10px' }}>
                            {t('ManageStore.title')}
                        </Typography>
                        <Link
                            component={RouterLink}
                            to={routes.homeUrl}>
                            ‹ {t('ManageStorePage.goHome')}
                        </Link>
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={7}>
                    <StoreInfo
                        savingProfilePicture={savingProfilePicture}
                        savingBannerPicture={savingBannerPicture}
                        onImageDeletion={onImageDeletion}
                        onProfilePictureChange={handleProfilePictureChange}
                        onBannerPictureChange={handleBannerPictureChange}
                        gettingInfo={gettingInfo}
                        storeData={storeData}
                        control={control}
                        setValue={setValue}
                        errors={errors} 
                        verifyUrlError={verifyUrlError}
                        setVerifyUrlError={setVerifyUrlError}
                        actualCharsName={watch('name')}
                        actualCharsDescription={watch('description')}
                    />
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                        {isTabletOrMobile ?
                            <ButtonContainer>
                                <Button
                                    style={{ width: '100%' }}
                                    type="submit"
                                    disabled={!isValid || saving}
                                    variant="contained"
                                    color="primary">
                                    {saving ?
                                        <CircularProgress
                                            size={30}
                                            color="secondary" /> :
                                        <span>{t('ManageStore.saveBtn')}</span>
                                    }
                                </Button>
                            </ButtonContainer> :
                            <Button
                                type="submit"
                                disabled={ !isValid || saving || verifyUrlError }
                                variant="contained"
                                color="primary">
                                {saving ?
                                    <CircularProgress
                                        size={30}
                                        color="secondary" /> :
                                    <span>{t('ManageStore.saveBtn')}</span>
                                }
                            </Button>
                        }
                    </Grid>
                </Grid>
                {!isTabletOrMobile &&
                    <Grid item xs={12} lg={5} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Typography
                            variant="h6"
                            style={{ marginBottom: '10px', textAlign: 'center' }}>
                            Vista previa
                        </Typography>
                        <StorePreview
                            coverImage={bannerPicture}
                            storeImage={profilePicture}
                            storeName={storeName}
                            storeDescription={storeDescription} />
                    </Grid>
                }
            </Grid>
        </form>
    );
}

const mapStateToProps = ({ auth, products }) => ({
    storeId: auth.storeId,
    productToEdit: products.productToEdit
});

// const mapDispatchToProps = dispatch => bindActionCreators({ setProductToEdit }, dispatch);

export default connect(
    mapStateToProps,
    // mapDispatchToProps
)(ManageStorePage);