import React, {useState} from 'react'

import QRCode from "qrcode"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import { successToast } from '../../constants.js/misc';

//MIXPANEL
import { Mixpanel } from '../../constants.js/mixpanel';

import { ContentLink, BtnCopy, UrlLink, Content } from '../PreviewLinkStore/PreviewLinkStore.styles'



const QRCodeProduct = ({ storeData, productData, handleShare, isTabletOrMobile }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const [image, setImage] = useState('')

    // Production URL
    const urlForCode = `https://${storeData.domain ? storeData.domain : (`${storeData.url}.ecometri.shop`)}/${storeData.default_user_store.mobile_phone_number}/${productData.slug}`
    
    // //Dev URL
    // const urlForCode = `https://${storeData.url}.l10s.co/${storeData.default_user_store ? storeData.default_user_store.mobile_phone_number : 0}/${productData.slug}`
    
    const copyLink = (url) => {
        document.addEventListener('copy', (e) => {
            e.clipboardData.setData('text/plain', (url));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        enqueueSnackbar(t('UserStores.copied'), successToast);
        Mixpanel.track('Copiar link de tienda', {
            "Copiar": url,
         });
    };

    const generateQrCode = async () => {
        try {
            const res = await QRCode.toDataURL(urlForCode, { errorCorrectionLevel: 'H' })
                setImage(res)
        } catch (error) {
            console.log(error, 'error')
        }
    }   
    
    generateQrCode()
   
    return(
        <Dialog 
            open={handleShare}
            onClose={handleShare}
        >
            <DialogContent style={{ margin: '0 0 6.4%' }}>
                 <DialogTitle>
                    {t('products.QRCodeTitle')}
                </DialogTitle>
                <IconButton  
                    style={{
                        position: 'absolute',
                        right: 1,
                        top: 1,
                    }} 
                    onClick={handleShare}
                >
                    <CloseIcon />
                </IconButton>
                <div style={{ textAlign:'center' }}>
                    <Content>
                        <ContentLink>
                            <UrlLink style={{ paddingBottom: '2.5%' }}>{urlForCode}</UrlLink>
                            <BtnCopy>
                                <Button
                                    onClick={() => copyLink(urlForCode)}
                                    color="primary"
                                    variant="contained"
                                    >
                                    {t('PreviewLink.copy')}
                                </Button>
                            </BtnCopy>
                        </ContentLink>
                    </Content>
                    <div>
                        <img src={image} alt="qrCode" />
                    </div>
                    <div>
                        <a href={image} download  style={{ textDecoration: 'none' }}>
                            { isTabletOrMobile ? 
                                <Button
                                    style={{ width: '40%' }}
                                    color="primary"
                                    variant="contained">
                                    {t('products.QRCodeButtonMobile')}
                                </Button> :
                                <Button
                                    style={{ width: '55%' }}
                                    color="primary"
                                    variant="contained">
                                    {t('products.QRCodeButton')}
                                </Button>
                            }
                            
                        </a>    
                    </div>
                </div>        
            </DialogContent>
        </Dialog>   
    )
}
const mapStateToProps = ({ common }) => ({
    storeData: common.storeData
});
export default connect(
    mapStateToProps
)(QRCodeProduct)
