
import styled from '@emotion/styled';

export const Content = styled.div`
    button{
        margin: 0 auto;
        display: block;
    }
`;

export const ContentLink = styled.div`
    background: #F5F5FA;
    box-shadow: 0px 0px 1px rgba(52, 58, 67, 0.75);
    border-radius: 7px;
    margin: 22px;
    align-items: center;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
`;

export const UrlLink = styled.div`
    padding: 6px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    width: 80%;
`;

export const BtnCopy = styled.div`
    display: inline-block;
    float: right;
`;




export const Strike = styled.div`
         display: block;
         text-align: center;
         overflow: hidden;
         white-space: nowrap;
         padding: 30px;
     >span {
         position: relative;
         display: inline-block;
         font-weight: bold;
         color: #53627C;
     }
     >span:before,
     >span:after {
         content: "";
         position: absolute;
         top: 50%;
         width: 9999px;
         height: 1px;
         background: #979797;
     }
     >span:before {
         right: 100%;
         margin-right: 15px;
     }
     >span:after {
         left: 100%;
         margin-left: 15px;
     }
`;
