import React from 'react'
import CodeQr from '../../components/QRCode/CodeQR'
import { useTranslation } from 'react-i18next'
import Link from '@material-ui/core/Link';
import { Card, CardContent } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';

import { Link as RouterLink } from 'react-router-dom'
import { routes } from '../../constants.js/misc'

const QrPage = () => {
    const { t } = useTranslation()

    return (
        <>
            <div style={{
                margin: '-3% 0 2% 7%',
            }}>
                <h1>{t('Config.qrCode')}</h1>
                <Link
                    component={RouterLink}
                    to={routes.config}>
                    ‹ {t('Config.title')}
                </Link>
            </div>
            <Card style={{ width: '86%', margin:'0 auto' }}>
                <CardContent style={{ textAlign:'center' }}>
                    <div>
                        <h1>{t('Config.qrCodeTitle')}</h1> 
                    </div>
                    <div >
                        <CodeQr />
                    </div>
                    <div style={{ padding:'1% 0 0 1%' }}>
                    <Typography variant="body1" gutterBottom>
                        {t('config.qrCode.lines')}
                        <Link component={RouterLink} to={routes.userStores}>{t('config.qrCode.click')}</Link>
                    </Typography>
                    </div>
                </CardContent>
                
            </Card> 
        </>
    )
}
export default QrPage