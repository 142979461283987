// ReactJS
import React, { useEffect, memo } from 'react';

const FrillWidget = memo(() => {
    useEffect(() => {
        let widget;

        const config = {
            key: process.env.REACT_APP_WIDGET_KEY,
            callbacks: {
                onReady: (frillWidget) => {
                    widget = frillWidget;
                },
            },
        };

        if ('Frill' in window) {
            widget = window.Frill.widget(config);
        } else {
            window.Frill_Config = window.Frill_Config || [];
            window.Frill_Config.push(config);
        }

        return () => {
            if (widget) {
                widget.destroy();
            }

            if (window.Frill_Config) {
                window.Frill_Config = window.Frill_Config.filter((c) => c !== config);
            }
        };
    }, []);

    return (
        <></>
    );
});

export default FrillWidget;