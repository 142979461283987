// ReactJS
import React from "react";

// MUI Components
import { Card, Grid, OutlinedInput, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '1rem'
    },
});

const TitleForm = ({ errors, register, title }) => {
    const classes = useStyles();

    return (
        <Grid item container xs={12} md={10} xl={8} className={classes.header}>
            <Card elevation={1} style={{ padding: 25, width: '100%' }}>
                <Typography
                    variant="body1"
                    style={{ color: '#53627C', fontSize: 24, marginBottom: '1.5rem' }}
                >
                    Título del formulario
                </Typography>
                <Typography
                    variant="body1"
                    style={{ color: '#0F2930', fontSize: 14 }}
                >
                    Título
                </Typography>
                <OutlinedInput defaultValue={title} {...register('title')} fullWidth />
                {errors?.title && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.title?.message}</Typography>}
            </Card>
        </Grid>
    );
}

export default TitleForm;