import styled from '@emotion/styled';
import { colors } from '../../../constants.js/colors';
import { makeStyles } from '@material-ui/core/styles';

export const FileUpload = styled.div`
    width: 100%;
    margin: 12px;

    .MuiDropzoneArea-icon {
        display: none;
    }

    .MuiDropzoneArea-root {
        min-height: auto;
    }

    .MuiDropzoneArea-root {
        &:focus {
            border: dashed ${colors.brandColor} 3px;
            outline: none;
        }
    }

    .MuiDropzonePreviewList-removeButton {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .MuiDropzonePreviewList-imageContainer {
        padding: 5px !important;
    }

    .MuiDropzonePreviewList-root {
        width: 100%;
        margin: 0;
        padding: 5px;
    }

    .MuiDropzoneArea-text {
        margin-top: 16px;
        margin-bottom: 16px;
    }
`;

export const CurrentImageImage = styled.img`
    width: auto;
    height: 150px;
    border-radius: 12px;
    padding: 5px;
`;

export const ImageIconUpload = styled.img`
    padding: 5px;
    margin-left: 15px;
    cursor: pointer;
    @media screen and (max-width: 600px) {
       position: relative;
      ${props => (props.asExitsFiles ? '' : 'top: -24px')};
    }
`;

export const ImageUpload = styled.img`
    height: 150px;
    margin-bottom: 18px;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      margin-bottom: 0;
    }
`;


export const DnDWrapper = styled.div`
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;




export const DeleteMask = styled.div`
    position: absolute;
    opacity: 0;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: ${(props) => props.wide ? 'auto' : '150px'};
    border-radius: 8px;
    background-color: #f0f0f3eb;
    transition: 0.3s;
`;

export const ImageContainer = styled.div`
    position: relative;
    &:hover {
        .delete-mask {
            opacity: 1;
            pointer-events: all;
        }
    }
    width: ${(props) => props.wide ? '100%' : 'initial'};
    img {
        width: ${(props) => props.wide ? '100%' : 'auto'};
        height: ${(props) => props.wide ? props.thumb ? '210px' : 'auto' : '150px'};
        ${props => props.thumb && `object-fit: cover`}
        ;
    }

    
`;

export const ResponsiveStyles = makeStyles((theme) => ({
    mobile: {
        width: '23rem',
        overflowX: 'auto',
        whiteSpace: 'nowrap'
    },
    full: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    inBlock:{
        display: 'inline-block'
    }

}));