import React from 'react';
import { sortableElement, sortableHandle } from 'react-sortable-hoc';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import GragIcon from '../../../../assets/icons/drag.svg';
import { NameProduct, Name, ImagePreviewProduct } from './SortableItem.styles';
import { IconDragCategories } from '../../../Collection/Collection.styles';

const DragHandle = sortableHandle(() => <ListItemIcon style={{ minWidth: 30 }}><IconDragCategories src={GragIcon} /></ListItemIcon>);

const SortableItem = sortableElement(({ value, iconAction, id, i, handleItemClick, dataImage }) => (
    <ListItem ContainerComponent="div">
        <DragHandle />
        <span style={{ padding: 15 }}>{i + 1}. </span>
        {dataImage &&
            <ImagePreviewProduct key={i} src={process.env.REACT_APP_IMAGES_API_URL + 'fit-in/600x600/' + dataImage}>
            </ImagePreviewProduct>
        }
        {!iconAction && <NameProduct>{value}</NameProduct>}
        {iconAction && <Name>{value}</Name>}

        <ListItemSecondaryAction onClick={() => handleItemClick(i, id)}>
            <IconButton
                edge="end" >
                {iconAction && <img src={iconAction}></img>}
                {!iconAction && <CloseIcon />}
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>

));

export default SortableItem;