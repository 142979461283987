import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useDropzone } from 'react-dropzone';


import {
    ContentUpload, TextUpload,
    SelectedImg, PreviewImg
} from './UploadImageUI.styles';

import UploadImage from '../../assets/icons/preimage.svg';
import BtnUpload from '../../assets/icons/btnimage.svg';


const UploadImageUI = ({ fileUpload, handleChange, height, deleteImage }) => {
    const { t } = useTranslation();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/jpeg, image/png, image/tiff, image/webp, image/svg+xml',
        onDrop: handleChange
    });

    return (
        <>
            {fileUpload ? (
                <PreviewImg height={height}>
                    <SelectedImg src={URL.createObjectURL(fileUpload)} />
                </PreviewImg>
            ) : (
                <ContentUpload {...getRootProps()} height={height}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <>
                            <img src={UploadImage} ></img>
                            <img src={BtnUpload} />
                            <TextUpload>
                                {t('dragTitleImage')}
                            </TextUpload>
                        </>
                    ) : (
                        <>
                            <img src={UploadImage}></img>
                            <img src={BtnUpload} />
                            <TextUpload>
                                {t('dragTitleImage')}
                            </TextUpload>
                        </>
                    )}
                </ContentUpload>
            )
            }
        </>
    );
};



export default connect()(UploadImageUI);