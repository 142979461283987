import { Button, Grid } from "@material-ui/core";
import React from "react";
import { formatNumber } from "../../../../constants.js/misc";

// TODO: el codigo comentado es para usar la funcionalidad de pagos con PAYU

// Función para generar una firma MD5
// function generateMD5Signature(message) {
//   return CryptoJS.MD5(message).toString();
// }

const PaymentButton = ({ setShowWelcomePopup, plan }) => {
  // const [isPayment, setIsPayment] = useState(false);
  // const [firma, setFirma] = useState("");
  // const [refSale, setRefSale] = useState("");
  // const cookies = new Cookies();
  // const userInfo = cookies.get("user") || {};

  // const location = window.location;
  // const BASE_URL = `https://${location.host}`;

  const definePlanValue = () => {
    const planValues = {
      mensual: 49000,
      trimestral: 180000,
      anual: 650000,
    };
    return planValues[plan] || 0;
  };

  const handleClick = async () => {
    // const referenceCode = `${userInfo.storeId}-${Date.now()}`;
    // setRefSale(referenceCode);

    // const message = `${APIKeyPayU}~${merchantIdPayU}~${referenceCode}~${definePlanValue()}~COP`;

    // const generatedFirma = await generateMD5Signature(message);
    // setFirma(generatedFirma);

    // await setIsPayment(true);
    // await setShowWelcomePopup(false);
    let urlPayment = '';
    if (plan === 'mensual') {
      urlPayment = 'https://treli.co/payment-plan/?username=ecometri&product_id=742621&quantity=1&plan_id=0&plan_currency=COP'
    }else if (plan === "trimestral") {
      urlPayment =
        "https://treli.co/payment-plan/?username=ecometri&product_id=742620&quantity=1&plan_id=0&plan_currency=COP";
    } else {
      urlPayment =
        " https://treli.co/payment-plan/?username=ecometri&product_id=742436&quantity=1&plan_id=0&plan_currency=COP";
    } 
    window.open(urlPayment, "_blank");
    await setShowWelcomePopup(false);
  };

  return (
    <div>
      <Grid item xs={12}>
        <Button variant="contained" color="secondary" onClick={handleClick}>
          {`Membresía ${plan}`} <br />
          {formatNumber(definePlanValue(), "COP")} COP
        </Button>
        {/* {isPayment && refSale && firma && (
          <div style={{ display: "none" }}>
            <PayU
              email={userInfo.userEmail}
              extra1={"Pago plan de 10x"}
              firma={firma}
              idAccount={accountIdPayu}
              merchantId={merchantIdPayU}
              newTab={false}
              numberPhone={userInfo.userPhone}
              payDescripcion={"Suscripcion a plan de 10x"}
              valueToPay={definePlanValue()}
              urlConfirmationPayU={"api/v1/notification/payu/active-plan"}
              urlResponse={BASE_URL}
              refSale={refSale}
            />
          </div>
        )} */}
      </Grid>
    </div>
  );
};


export default PaymentButton;
