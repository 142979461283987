// ReactJS
import React, { useState } from "react";

// MUI Components
import {
    Button,
    Card,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    makeStyles,
    Typography,
} from "@material-ui/core";

import { useMediaQuery } from "react-responsive";

// Image [PNG]
import iconError from '../../../assets/img/errorIcon.png';

import DesktopTableDomain from "./DesktopTableDomain";
import MobileTableDomain from "./MobileTableDomain";

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '1rem'
    },
    root: { width: '100%' },
    container: {
        maxHeight: 'calc(100vh - 231px)',
    },
    spacer: {
        "& .MuiTablePagination-spacer": {
            flex: 0
        }
    },
    filterInput: {
        width: '40%',
        "& .MuiOutlinedInput-input": {
            background: '#fff'
        }
    }
});

const DomainTable = ({ storeData, setNameDomain, setStep, setChange }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <Grid container xs={12} md={10} xl={8} className={classes.header}>
            <Card elevation={1} style={{ width: '100%', marginBottom: "80px" }}>
                <Card elevation={0} style={{ margin: '27px 27px 20px 27px' }}>
                    <Typography
                        variant="body1"
                        style={{ color: '#53627C', fontSize: 24, marginBottom: 8 }}
                    >
                        Dominio principal
                    </Typography>
                </Card>
                <Divider />
                {isTabletOrMobile ? <MobileTableDomain storeData={storeData} setIsOpen={setIsOpen} /> : <DesktopTableDomain storeData={storeData} setIsOpen={setIsOpen} />}
            </Card>

            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(!isOpen)}
            >
                <DialogContent style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', padding: "30px 30px" }}>
                    <img src={iconError} alt="" style={{ marginRight: 16, width: 64, height: 64 }} />
                    <Typography variant="body1" style={{ width: isTabletOrMobile ? 'auto' : '100%' }}>
                        Al cambiar tu dominio se desconectará de tu tienda y tus clientes perderán el acceso a ella a través de este dominio.
                    </Typography>
                </DialogContent>
                <Divider />
                <DialogTitle id="alert-dialog-title">
                    <Grid item style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ marginRight: 16 }}
                            className="btnLink"
                            color="primary"
                            variant="contained"
                        >
                            Mantener dominio
                        </Button>
                        <Button
                            onClick={() => { setNameDomain(storeData?.domain); setIsOpen(false); setStep(0); setChange(true) }}
                            style={{ borderColor: "#CB7C7A", color: "#CB7C7A" }}
                            color="danger"
                            variant="outlined"
                        >
                            Cambiar dominio
                        </Button>
                    </Grid>
                </DialogTitle>
            </Dialog>
        </Grid>
    );
}

export default DomainTable;
