// ReactJS
import React from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";

// Icons
import ConfigIcon from "../../../assets/icons/config.svg";

// MUI Components
import { Grid, Typography } from "@material-ui/core";

// Components: ConfigProvider
import NavLink from "./NavLink";
import FormProviderPayments from "./FormProvider";
import { Intro } from "../../ManageCollection/ManageCollection.styles";

const ConfigProvider = ({ storeId, storeData }) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const location = useLocation();
// console.log(location)
    return (
        <Grid container spacing={isTabletOrMobile ? 0 : 3} style={{ justifyContent: 'center' }}>
            {isTabletOrMobile &&
                <Intro item container xs={12}>
                    <img src={ConfigIcon} alt="" />
                    <Typography variant="caption">
                        Añadir nuevo provedor para pagos
                    </Typography>
                </Intro>
            }
            <NavLink isTabletOrMobile={isTabletOrMobile} method={location?.state?.method} />
            <FormProviderPayments
                methodName={location?.state?.methodName}
                methodId={location?.state?.methodId}
                methodAccountId={location?.state?.methodAccountId}
                methodApikey={location?.state?.methodApikey}
                methodLabelAccountId={location?.state?.methodLabelAccountId}
                methodLabelApikey={location?.state?.methodLabelApikey}
                methodTestMode={location?.state?.methodTestMode}
                integrity={location?.state?.integrity}
                nameShop={storeData?.url}
                path={location?.pathname}
                storeId={storeId}
                eventWompiKey={location?.state?.eventWompiKey}
            />
        </Grid>
    );
}

export default connect(({ auth, common }) => ({
    storeId: auth.storeId,
    storeData: common.storeData
}))(ConfigProvider);
