import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import ClientOrderTable from '../ClientOrderTable/ClientOrderTable';
import { DivContainerTable } from '../../../constants.js/containers';

const ClientOrders = ({ orders }) => {
    const { t } = useTranslation();

    return (
        <Card elevation={2}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('ClientOrdersTable.detailsTitle')} style={{ paddingBottom: '0' }} />
            <CardContent style={{ padding: 0 }}>
                <DivContainerTable style={{ maxHeight: 440 }}>
                    <ClientOrderTable orderDetails={orders} />
                </DivContainerTable>
            </CardContent>
        </Card>
    );
};


export default connect()(ClientOrders);