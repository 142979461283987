// ReactJS
import React from "react";

// MUI Components
import {
    Card,
    Grid,
    makeStyles,
    Typography,
} from "@material-ui/core";

// Components
import Loader from '../../Loader';

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '1rem'
    },
});

const LoaderConecction = () => {
    const classes = useStyles();

    return (
        <Grid container xs={12} md={10} xl={8} className={classes.header}>
            <Card elevation={1} style={{ width: '100%' }}>
                <Card elevation={0} style={{ margin: 27, backgroundColor: '#EFF3F7', padding: 22, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Loader />
                    <Typography
                        variant="body1"
                        style={{ color: '#0F2930', fontSize: 18, margin: 10 }}
                    >
                        Verificando la conexión del dominio...
                    </Typography>
                </Card>
            </Card>
        </Grid>
    );
}

export default LoaderConecction;