import styled from "@emotion/styled"

export const ThankUMain = styled.main`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
export const ImageContainer = styled.section`
    width: 270px;
`
export const TextContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
export const Title = styled.h1`
    margin: 10px 0 0;
    font-size: 35px;
    text-transform: uppercase;
`
export const SubTitle = styled.p`
    font-size: 17px;
    text-align: center;
`