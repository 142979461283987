import { axiosInstance } from './instance';

const getCollectionTreeService = (storeId) => {
    return axiosInstance.get(`stores/${storeId}/collections?limit=999`);
};

const getCollectionsService = (storeId) => {
    return axiosInstance.get(`/stores/${storeId}/collections?limit=999`);
};

const createCollectionsService = (storeId, value) => {
    return axiosInstance.post(`/stores/${storeId}/collections`, value);
};

const updateCollectionsService = (storeId, value) => {
    return axiosInstance.put(`/stores/${storeId}/collections/${value.id}`, value);
};

const deleteCollectionsService = (storeId, id, value) => {
    return axiosInstance.delete(`/stores/${storeId}/collections/${id}`, value);
};

const updateCollectionsServiceImage = (storeId, idColle, value) => {
    return axiosInstance.put(`/stores/${storeId}/collections/${idColle}/images`, value);
};

const deleteCollectionsServiceImage = (storeId, idColle, value) => {
    return axiosInstance.delete(`/stores/${storeId}/collections/${idColle}/images`, value);
};

const orderCollections = (storeId, value) => {
    return axiosInstance.put(`/stores/${storeId}/collections/order`, value);
};

const orderProductsCollections = (storeId, idColle, value) => {
    return axiosInstance.put(`/stores/${storeId}/collections/${idColle}/products/order`, value);
};


const createSubCollection = (storeId, idColle, value) => {
    return axiosInstance.put(`/stores/${storeId}/collections/${idColle}/${value}`);
};



const deleteSubCollection = (storeId, idColle, value) => {
    return axiosInstance.delete(`/stores/${storeId}/collections/${idColle}/${value}`);
};



const filterNameCollectionsService = (storeId, value) => {
    return axiosInstance.get(`/stores/${storeId}/collections?name.contains=${value}`);
};


const filterProductsCollectionsService = (storeId, value) => {
    return axiosInstance.get(`/stores/${storeId}/products?name.contains=${value}`);
};

const collectionsxIdService = (storeId, value) => {
    return axiosInstance.get(`/stores/${storeId}/collections/${value}`);
};




export {
    getCollectionTreeService,
    getCollectionsService,
    createCollectionsService,
    updateCollectionsService,
    filterProductsCollectionsService,
    filterNameCollectionsService,
    collectionsxIdService,
    updateCollectionsServiceImage,
    deleteCollectionsServiceImage,
    deleteCollectionsService,
    orderCollections,
    orderProductsCollections,
    createSubCollection,
    deleteSubCollection
}