// react dependencies
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

// material ui dependencies
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';

// Internal dependencies
import BillingDetailsForm from '../BillingDetailsForm/BillingDetailsForm';

const BilligDetailsModal = ({ open, handleClose, isNoClose = true, storeId, billingData, handleSuccess }) => {

    const { t } = useTranslation();
    const {
        control,
        formState,
        handleSubmit,
        errors,
        watch,
        setValue
    } = useForm({ mode: 'all' });

    const [saving, setSaving] = useState(false);
    const [submit, setSubmit] = useState(false);
    return (
        <Dialog open={open} disableBackdropClick={isNoClose} onClose={handleClose}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {t('BilligDetailsModal.title')}
            </DialogTitle>
            <DialogContent dividers>
                <BillingDetailsForm
                    billingData={billingData}
                    handleSuccess={handleSuccess}
                    setSubmit={setSubmit}
                    formObject={{
                        control,
                        formState,
                        handleSubmit,
                        setValue,
                        errors,
                        watch
                    }}
                    setSaving={setSaving}
                    submit={submit} />
            </DialogContent>
            <DialogActions>
                {!isNoClose &&
                    <Button onClick={handleClose} variant="contained">
                        {t('BilligDetailsModal.cancel')}
                    </Button>
                }
                <Button disabled={!formState.isValid || saving} onClick={() => setSubmit(true)} autoFocus color="primary" variant="contained">
                    {t('BilligDetailsModal.cta')}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(BilligDetailsModal);
