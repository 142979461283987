
import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, FormHelperText, Grid, makeStyles, OutlinedInput, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import CardItemActionFooter from '../../CardItemActionFooter/CardItemActionFooter';
import { useMediaQuery } from 'react-responsive';
import { ButtonContainer } from '../../ManageCollection/ManageCollection.styles';
import { useForm } from 'react-hook-form';
import { availableDomain } from '../../../services/domain';
import { useSnackbar } from 'notistack';
import { errorToast } from '../../../constants.js/misc';

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '1rem'
    },
});

const fieldsSchema = yup.object({
    nameDomain: yup.string().required('Este campo es obligatorio'),
});

const UnconfiguredDomain = ({ storeId, nameDomain, setNameDomain, setLoading, setStep }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'all',
        resolver: yupResolver(fieldsSchema)
    });

    const onSubmit = data => {
        setNameDomain(data.nameDomain)
        setLoading(true);
        availableDomain(storeId, data.nameDomain)
            .then((info) => {
                /* const { data } = info; */
                setLoading(false);
                setStep(1);
            })
            .catch((error) => {
                enqueueSnackbar(t('Config.domainSelector.error'), errorToast)
                setLoading(false);
            });
    };

    return (
        <Grid container xs={12} md={10} xl={8} className={classes.header}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <Card elevation={1} style={{ width: '100%', marginBottom: 32 }}>
                    <Card elevation={0} style={{ padding: 27 }}>
                        <Typography
                            variant="body1"
                            style={{ color: '#0F2930', fontSize: 16 }}
                        >
                            Mejora la experiencia de tus clientes en tu tienda 10X conectando tu dominio.
                        </Typography>
                    </Card>
                </Card>

                <Card elevation={1} style={{ width: '100%', marginBottom: '80px' }}>
                    <CardItemActionFooter
                        title={t('Config.domainInputTitle')}
                        paddingProp={'11px 11px 0 11px'}
                        children={
                            <>
                                <Typography
                                    variant="body1"
                                    style={{ color: '#0F2930', fontSize: 16, marginBottom: 3 }}
                                >
                                    Escribe el nombre de tu dominio
                                </Typography>
                                <OutlinedInput
                                    fullWidth
                                    defaultValue={nameDomain && nameDomain}
                                    error={errors?.nameDomain ? true : false}
                                    {...register('nameDomain')}
                                    placeholder={t('Config.domainPlaceholder')}
                                />
                                <FormHelperText error={errors?.nameDomain ? true : false}>{errors?.nameDomain ? t('Config.domainInput.nameError') : errors?.nameDomain?.message}</FormHelperText>
                            </>
                        }
                    // url={'https://help.shopify.com/es/manual/domains/add-a-domain/connecting-domains?utm_campaign=domains&utm_content=oneState&utm_medium=textlink&utm_source=shopifyadmin'}
                    // actionTitle={t('Config.actionConfigDomain')}
                    />
                </Card>

                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                    {!isTabletOrMobile &&
                        <Button
                            type="submit"
                            /* disabled={saving || savingCurrency} */
                            color="primary"
                            variant="contained">
                            {t('SaveChangesBtn')}
                        </Button>
                    }

                    {isTabletOrMobile &&
                        <ButtonContainer
                            item
                            xs={12}
                            lg={4}>
                            <Button
                                style={{ width: '100%' }}
                                color="primary"
                                variant="contained"
                                /*  disabled={saving || savingCurrency} */
                                type="submit">
                                {t('SaveChangesBtn')}
                            </Button>
                        </ButtonContainer>
                    }
                </Grid>
            </form>
        </Grid>
    );
}

export default UnconfiguredDomain;