

import styled from '@emotion/styled';

export const Content = styled.div`
  padding: 40px 110px;
  flex: 1;
  ${props => props.isActive && `opacity: 0.5;pointer-events: 'none'; `}
`;


export const ContentSubTitle = styled.div`
  width: 75%;
  flex: 1;
  >p{
    margin-top: 10px;
  }
  margin-bottom: 2rem;
`;

