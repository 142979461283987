// ReactJS
import React from "react";

// MUI Components
import { Avatar, FormControl, FormHelperText, FormLabel, OutlinedInput, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { BtnRemoveImage, DnDWrapper, PreviewImg, RemoveImg, RemoveImgWrapper } from "../../ManageCollection/ImageCollection/ImageCollection.styles";
import UploadImage from '../../../assets/img/upload-image.svg';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// Constants

const ManualPayForm = ({ errors, register, methodDescription, colleImg, removeImg, isDragActive, getRootProps, getInputProps }) => {
    const { t } = useTranslation();

    return (
        <>
            <FormControl
                variant="outlined"
                margin="normal"
                fullWidth
            >
                <FormLabel
                    variant="body1"
                    htmlFor="title"
                >
                    Título
                </FormLabel>
                <OutlinedInput
                    id="title"
                    defaultValue={methodDescription && JSON.parse(methodDescription)?.title}
                    fullWidth
                    placeholder="Esto controla el título que el usuario verá al terminar la compra"
                    error={errors?.title ? true : false}
                    {...register('title')}
                />
                <FormHelperText error={errors?.title ? true : false}>{errors?.title ? ''/* t('Config.domainInput.nameError') */ : errors?.title?.message}</FormHelperText>
            </FormControl>
            <FormControl
                variant="outlined"
                margin="normal"
                fullWidth>
                <FormLabel
                    variant="body1"
                    htmlFor="description"
                >
                    Descripción
                </FormLabel>
                <OutlinedInput
                    id="description"
                    placeholder="Descripción del método de pago que el cliente verá en el pago"
                    defaultValue={methodDescription && JSON.parse(methodDescription)?.description}
                    fullWidth
                    error={errors?.description ? true : false}
                    {...register('description')}
                />
                <FormHelperText error={errors?.description ? true : false}>{errors?.description ? ''/* t('Config.domainInput.nameError') */ : errors?.description?.message}</FormHelperText>
            </FormControl>
            <FormControl
                variant="outlined"
                margin="normal"
                fullWidth>
                <FormLabel
                    variant="body1"
                    htmlFor="instructions"
                >
                    Instrucciones
                </FormLabel>
                <OutlinedInput
                    id="instructions"
                    placeholder="Se añadirán a la página de agradecimiento y correos electrónicos"
                    defaultValue={methodDescription && JSON.parse(methodDescription)?.instructions}
                    fullWidth
                    minRows={4}
                    multiline={true}
                    error={errors?.instructions ? true : false}
                    {...register('instructions')}
                />
                <FormHelperText error={errors?.instructions ? true : false}>{errors?.instructions ? ''/* t('Config.domainInput.nameError') */ : errors?.instructions?.message}</FormHelperText>
            </FormControl>

            <Typography
                variant="body1"
                style={{ color: '#0F2930', fontSize: 16, marginTop: 32, marginBottom: 16 }}
            >
                {t(`Config.addPaymentProvider.qr`)}
            </Typography>

            {colleImg && colleImg.preview ? (
                <PreviewImg>
                    <Avatar
                        style={{
                            margin: '0px auto',
                            alignSelf: 'center',
                            justifyContent: "center",
                            display: "flex",
                            width: 138,
                            height: 138,
                            marginTop: 25,
                            objectFit: 'cover'
                        }}
                        src={colleImg.preview}
                        {...register('qrImage')}
                    />
                    <RemoveImg>
                        <RemoveImgWrapper onClick={removeImg}>
                            <DeleteForeverIcon onClick={removeImg} color="#333" size="35px" />
                        </RemoveImgWrapper>
                    </RemoveImg>
                    {colleImg && <BtnRemoveImage onClick={colleImg.id ? console.log('eliminar') : removeImg}>Eliminar</BtnRemoveImage>}
                </PreviewImg>
            ) : (
                <>
                    <DnDWrapper {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <>
                                <p>Sueltalo aqui...</p>
                            </>
                        ) : (
                            <>
                                <img style={{ height: 200 }} src={UploadImage} alt=""></img>
                            </>
                        )}
                    </DnDWrapper>
                </>
            )}
        </>
    );
}

export default ManualPayForm;