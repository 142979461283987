import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import QRCode from "qrcode"

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'


const CodeQr = ({ storeData }) => {
    const { t } = useTranslation()
   	
    const [image, setImage] = useState('')

    const urlForCode = `https://${storeData.domain ? storeData.domain : (`${storeData.url}.ecometri.shop`)}/${storeData.default_user_store ? storeData.default_user_store.mobile_phone_number : 0}`

    const generateQrCode = async () => {
        try {
            const res = await QRCode.toDataURL(urlForCode, { errorCorrectionLevel: 'H' })
                setImage(res)
        } catch (error) {
            console.log(error, 'error')
        }
    }   
    
    generateQrCode()
    
    return(
        <>
            <Grid style={{ width: '86%', margin:'0 auto' }}>
                <div style={{ textAlign:'center' }}>
                    <img src={image} alt="qrCode" style={{
                        width: '40%',
                        height: '40%',
                    }} />
                </div>
                <div style={{ width: '83%', margin:'0 auto 2% ' }}>
                <a href={image} download  style={{ textDecoration: 'none' }}>
                    <Button
                        style={{ width: '65%' }}
                        color="primary"
                        variant="contained">
                        {t('config.qrCode.download')}
                    </Button>
                </a>
                </div>   
            </Grid>
        </>
    )
}
const mapStateToProps = ({ common }) => ({
    storeData: common.storeData
});
export default connect(
    mapStateToProps
)(CodeQr)
