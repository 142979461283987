import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { Box, Button, CardHeader, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import { statusOrders } from '../../../constants.js/orders';

// Assets
import card from '../../../assets/icons/card.svg';
import WPIcon from "../../../assets/icons/white-wp.svg";
import calendar from '../../../assets/icons/calendar2.svg';
import { formatNumber } from '../../../constants.js/misc';

const orderStatus = [
    {
        statusId: 1,
        statusName: "Pendiente",
    },
    {
        statusId: 2,
        statusName: "En espera",
    },
    {
        statusId: 3,
        statusName: "Cancelación/Fallido",
    },
    {
        statusId: 4,
        statusName: "Pagado",
    },
    {
        statusId: 5,
        statusName: "Devolución",
    }
]

// styles
const useStyles = makeStyles({
    div: {
        background: '#F8F9FB',
        borderRadius: '8px',
        padding: '32px 8px 18px 8px',
        marginBottom: '3px',
        textAlign: 'center',
    },
    div2: {
        border: '1px solid #C9CED6',
        borderRadius: '8px'
    },
    info: {
        background: '#EFF3EF',
        padding: '25px',
        display: 'flex',
        alignItems: 'center'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    bill: {
        border: '1px solid #C9CED6',
        padding: '50px',
        marginTop: '15px'
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '30px',
        marginTop: '200px',
        borderTop: '1px solid #C9CED6'
    }
});

const OrderPaymentInfo = ({ info, clientName, clientPhone, orderDetails, store, idOrder, orderShippingCost }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const paymentDate = new Date(info?.transaction_date);

    const totalWithShipping = () => {
        return orderShippingCost
            ? (isNaN(orderShippingCost)
                ? ((isNaN(store?.delivery_cost_details) || !store?.delivery_cost_enabled) ? 0 : parseInt(store?.delivery_cost_details))
                : orderShippingCost
            )
            : (isNaN(store?.delivery_cost_details) || !store?.delivery_cost_enabled) ? 0 : parseInt(store?.delivery_cost_details);
    }

    const products = orderDetails.map(item => {
        let product = '';
        if (item?.order_item_option?.length) {
            product = item?.order_item_option.reduce((acc, current) => {
                if (current?.product_option_value) {
                    const currentProduct = current?.product_option_value?.name
                    return acc + `${currentProduct}, `;
                }
                return ''
            }, '');
        }
        return `- ${item?.quantity} *${item?.product?.name} ${item?.product?.sku}* ${product ? '- ' + product : ''}/  ${item?.product?.price > 0 ? `_${formatNumber((item?.product?.discount_price || item?.product?.price), store?.currency)}_` : ''} \n`
    });

    const message = `
Hola, ${clientName},
Este es tu pedido en ${store?.name}:
========================\n
${products.map(product => product.trim()).join('\n')}
========================\n
${orderDetails.reduce((prev, current) => current?.price + prev, 0) > 0 ?
            `*TOTAL:* _${formatNumber(orderDetails.reduce((prev, current) => prev + (current?.product?.discount_price || current?.product?.price) * current?.quantity, 0) + totalWithShipping(), store?.currency)}_` :
            '\n'}
            *Envío:* ${orderShippingCost !== (0 || null) ? orderShippingCost : store?.delivery_cost_details}
${store?.shipping_time_enabled ? `*Tiempo de entrega:* ${store?.shipping_details || ``}` : ``}
========================\n
Tus datos:\n
 *Nombre:* ${clientName}\n *Celular:* +${clientPhone}\n
========================\n
Realiza el pago a través del siguiente enlace: https://${store?.url}.ecometri.shop/checkout/${store?.id}/${idOrder}/payment-link
========================\n
`;
    /* *TOTAL2:* _${formatNumber(orderDetails.reduce((acc, detail) => acc + (detail.price * detail.quantity), 0), store?.currency)} COP */

    const handleClick = () => {
        const messageUrl = `https://wa.me/${clientPhone}?text=`
        window.open(`${messageUrl}${encodeURIComponent(message).replace(/%20/g, '+')}`)
    }

    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('OrderDetail.paymentInfoTitle')} />
            <CardContent style={{ padding: '0 16px 13px 16px' }}>
                {info
                    ? <Box className={classes.div2}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            style={{ padding: '22px 28px' }}>
                            <Grid item xs={2} style={{ padding: '0 10px 0 0' }}>
                                <img src={info?.payment_channel?.icon} alt={`Icon ${info?.payment_channel?.name}`} width='80px' />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>{t('OrderDetail.paymentInfo.name')} {info?.payment_channel?.name}</Typography>
                                <Typography variant="body1" >
                                    <span>
                                        <img src={calendar} alt="calendar" style={{ width: '14px', marginRight: '7px' }} />
                                    </span>
                                    {paymentDate.toLocaleString()}
                                </Typography>
                                <Typography variant="body1">{t('OrderDetail.paymentInfo.reference')} {info?.platform_reference}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    : <Box className={classes.div}>
                        <img src={card} alt="card" style={{ width: '23px' }} />
                        <Typography variant="body1">{t('OrderDetail.paymentInfo.notFound')}</Typography>
                        <Button
                            startIcon={<img src={WPIcon} alt='WPIcon' />}
                            color="primary"
                            variant="contained"
                            onClick={handleClick}
                            style={{
                                marginTop: 7,
                                fontSize: 16
                            }}>
                            Enviar enlace de pago
                        </Button>
                    </Box>
                }
            </CardContent>
        </Card >
    );
};
export default connect()(OrderPaymentInfo);