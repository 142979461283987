// ReactJS
import React, { useCallback } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from "react-redux";

// external dependencies
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';

// MUI Components
import { Grid, makeStyles } from "@material-ui/core";

// Services
import { putDynamicForm, createDynamicForm } from "../../../services/form";

// Components
import ActionButtons from "./ActionButtons";
import TitleForm from "./TitleForm";
import TypeInput from "./TypeInput";
import TypeSection from "./TypeSection";

const useStyles = makeStyles({
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1rem',
        width: '100%',
    },
    form: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    wrapperInput: {
        alignItems: 'center',
        backgroundColor: '#F8F9FB',
        border: '1px dashed #48AC98',
        borderRadius: 8,
        color: '#48AC98',
        cursor: 'pointer',
        display: 'flex',
        fontSize: 16,
        fontWeight: '700',
        height: 90,
        placeContent: 'center',
    },
});

const fieldsSchema = yup.object({
    title: yup.string().required('Se requiere el titulo del formulario'),
    dynamicInputs: yup.array().of(yup.object({
        order: yup.number(),
        type: yup.string().required('Se requiere un tipo'),
        title: yup.string().required('Se requiere el titulo del campo'),
        numeric: yup.boolean(),
        mandatory: yup.boolean(),
        text: yup.string(),
        options: yup.array().of(yup.string()).min(1, 'Se requiere un campo').nullable(),
    })),
});

const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const FormContent = ({ data, storeId }) => {
    const { fields: fieldsData, title } = data
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const { register, control, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            title: title,
            dynamicInputs: fieldsData?.map((field, index) => ({
                order: index,
                type: field?.type,
                title: field?.title,
                fields_id: field?.id,
                numeric: field?.numeric,
                mandatory: field?.mandatory,
                text: field?.text,
                options: field?.options,
            }))
        },
        resolver: yupResolver(fieldsSchema)
    });

    const { fields, append, remove, replace, update } = useFieldArray({
        control,
        name: 'dynamicInputs',
    });

    // Functions
    const handleAppend = useCallback(({ isSection, index }) => {
        isSection
            ? append({
                id: 0,
                form_id: 1,
                type: "label",
                title: "",
                text: "",
                mandatory: false,
                numeric: false,
                options: null,
                order: index,
            })
            : append({
                id: 0,
                form_id: 1,
                type: "",
                title: "",
                text: "",
                mandatory: false,
                numeric: false,
                options: [''],
                order: index,
            });
    }, [append])

    const handleRemove = useCallback(({ index }) => {
        remove(index);
    }, [remove])

    const handleUpdate = useCallback(({ data, index, isRemove, text }) => {
        if (isRemove) {
            const removeArray = data.options.filter(option => option !== text);
            update(index, {
                id: 0,
                form_id: data.form_id,
                type: data.type,
                title: data.title,
                text: data.text,
                mandatory: data.mandatory,
                numeric: data.numeric,
                options: removeArray,
                order: index,
            });
        } else {
            update(index, {
                id: 0,
                form_id: data.form_id,
                type: data.type,
                title: data.title,
                text: data.text,
                mandatory: data.mandatory,
                numeric: data.numeric,
                options: data.options === null ? [""] : [...data.options, ""],
                order: index,
            });
        }
    }, [update]);

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (
            source.index === destination.index &&
            source.droppableId === destination.droppableId
        ) {
            return;
        }

        replace(reorder(fields, source.index, destination.index));
    }

    // Submit
    const onSubmit = data => {
        const formData = {
            title: data?.title,
            fields: data?.dynamicInputs?.map(field => ({
                id: field?.fields_id,
                type: field?.type,
                title: field?.title,
                numeric: field?.numeric,
                mandatory: field?.mandatory,
                text: field?.text,
                options: field?.options,
                order: field?.order,
            }))
        }

        if (formData.fields.some(field => (field.type === "radio" || field.type === "select" || field.type === "checkbox") && field.options.some(option => option === ""))) {
            enqueueSnackbar('Se requiere al menos una opcion', { variant: 'error' });
            return;
        } else {
            fieldsData
                ? putDynamicForm(storeId, formData)
                    .then(() => {
                        enqueueSnackbar('Tu formulario se ha guardado exitosamente', { variant: 'success' });
                    })
                    .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
                : createDynamicForm(storeId, formData)
                    .then(() => {
                        enqueueSnackbar('Tu formulario se ha guardado exitosamente', { variant: 'success' });
                    })
                    .catch(error => enqueueSnackbar('Es necesario añadir al menos un campo personalizado', { variant: 'error' }));
        }

    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} >
                <TitleForm errors={errors} register={register} title={title} />
                <Droppable droppableId="fields">
                    {(droppableProvided) => (
                        <div
                            {...droppableProvided.droppableProps}
                            className={classes.form}
                            ref={droppableProvided.innerRef}
                        >
                            {fields?.map((field, index) => (
                                <Draggable key={field.id} draggableId={field.id} index={index}>
                                    {(draggableProvided) => (
                                        <Grid
                                            {...draggableProvided.draggableProps}
                                            {...draggableProvided.dragHandleProps}
                                            ref={draggableProvided.innerRef}
                                            item
                                            container
                                            className={classes.header}
                                            xs={12} md={10} xl={8}
                                        >
                                            {field.type === 'label'
                                                ?
                                                <TypeSection
                                                    field={field}
                                                    handleRemove={handleRemove}
                                                    index={index}
                                                    register={register}
                                                />
                                                :
                                                <TypeInput
                                                    errors={errors}
                                                    field={field}
                                                    handleRemove={handleRemove}
                                                    index={index}
                                                    register={register}
                                                    handleUpdate={handleUpdate}
                                                    watch={watch}
                                                />

                                            }
                                        </Grid>
                                    )}
                                </Draggable>
                            ))}
                            {droppableProvided.placeholder}
                        </div>
                    )}
                </Droppable>
                <ActionButtons handleAppend={handleAppend} fieldIndex={fields?.length + 1} />
            </form>
        </DragDropContext>
    );
}

export default connect(({ auth }) => ({
    storeId: auth.storeId
}))(FormContent);