import { axiosInstance } from './instance';

/**
 * Gets the information of a store
 * @param {number} storeId store id
 */
const getStoreService = (storeId) => {
    return axiosInstance.get(`/stores/${storeId}`);
}

const getStoreLimits = (storeId) => {
    return axiosInstance.get(`/stores/${storeId}/limits`);
}

const publisStoreService = (storeId) => {
    return axiosInstance.put(`/stores/${storeId}/publish`);
}

const publisIdTrackingMarketing = (storeId, value) => {
    return axiosInstance.put(`/stores/${storeId}/marketing`, value);
}

const editLegalStore = (storeId, value) => {
    return axiosInstance.put(`/stores/${storeId}/legal`, value);
}

const editShippingStore = (storeId, value) => {
    return axiosInstance.put(`/stores/${storeId}/shipping`, value);
}

const updateCurrencyService = (storeId, currency) => {
    return axiosInstance.put(`/stores/${storeId}/currency`, { currency });
}

/**
 * Update store url
 * @param {number} storeId store id
 * @param {string} storeUrl updated store url
 */
const editStoreUrl = (storeId, storeUrl) => {
    return axiosInstance.get(`/stores/${storeId}/verify-url/${storeUrl}`)
}

/**
 * Updates a store
 * @param {number} storeId store id
 * @param {Object} data updated store data
 * @param {boolean} patch use the patch method
 */
const updateStoreService = (storeId, data, patch = false) => {
    if (patch) {
        return axiosInstance.patch(`/stores/${storeId}`, data);
    }
    return axiosInstance.put(`/stores/${storeId}`, data);
}

/**
 * Updates images for a store
 * @param {number} storeId store id
 * @param {Array} files array of files to upload
 * @param {boolean} banner Update the banner picture instead of the profile picture
 */
const createStoreImagesService = (storeId, files, banner = false) => {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    if (banner) {
        return axiosInstance.put(`/stores/${storeId}/banner`, formData);
    }
    return axiosInstance.put(`/stores/${storeId}/profile`, formData);
}

/**
 * Updates images for a store
 * @param {number} storeId store id
 * @param {boolean} banner Delete the banner picture instead of the profile picture
 */
const deleteStoreImagesService = (storeId, banner) => {
    if (banner) {
        return axiosInstance.delete(`/stores/${storeId}/banner`);
    }
    return axiosInstance.delete(`/stores/${storeId}/profile`);
}

/**
 * Gets the user stores list
 * @param {number} storeId store id
 * @param {number} limit how many items to show per page
 * @param {number} page page to show
 */
const getUserStoresService = (storeId, limit = 10, page = 0, filter = '') => {
    return axiosInstance.get(`/stores/${storeId}/user-stores?limit=${limit}&page=${page + 1}&sort=created_at&mobile_phone_number.contains=${filter}`);
};

/**
 * Gets the information of a user store
 * @param {number} storeId store id
 * @param {number} userStoreId user store id
 */
const getUserStoreService = (storeId, userStoreId) => {
    return axiosInstance.get(`/stores/${storeId}/user-stores/${userStoreId}`);
}

/**
 * Creates a user store
 * @param {number} storeId store id
 * @param {Object} data user store data
 */
const createUserStoreService = (storeId, data) => {
    return axiosInstance.post(`/stores/${storeId}/user-stores`, data);
}

/**
 * Updates a user store
 * @param {number} storeId store id
 * @param {Object} data user store data
 */
const updateUserStoreService = (storeId, data, userStoreId) => {
    return axiosInstance.put(`/stores/${storeId}/user-stores/${userStoreId}`, data);
}

/**
 * Gets the user stores list filtered
 * @param {number} storeId store id
 * @param {string} term search term
 */
const autocompleteUserStoresService = (storeId, term = '') => {
    return axiosInstance.get(`/stores/${storeId}/user-stores?name.contains=${term}`);
}

/**
 * Sets the main user store
 * @param {number} storeId 
 * @param {number} userStoreId 
 */
const setMainUserStoreService = (storeId, userStoreId, data) => {
    return axiosInstance.put(`/stores/${storeId}/user-stores/${userStoreId}/default`, data);
}

/**
 * Deletes a user store
 * @param {number} storeId store id
 * @param {number} userStoreId user store id
 */
const deleteUserStoreService = (storeId, userStoreId) => {
    return axiosInstance.delete(`/stores/${storeId}/user-stores/${userStoreId}`);
}

/**
 * Get all valid ctas
 */
const getCtasService = () => {
    return axiosInstance.get(`/parameters/CALL_TO_ACTION_BUTTON`);
}

/**
 * Saves the cta 
 * @param {number} storeId store id
 * @param {object} data 
 */
const saveCtaService = (storeId, data) => {
    return axiosInstance.put(`/stores/${storeId}/button`, data);
}

export {
    getStoreService,
    updateStoreService,
    createStoreImagesService,
    deleteStoreImagesService,
    getUserStoresService,
    getUserStoreService,
    createUserStoreService,
    updateUserStoreService,
    autocompleteUserStoresService,
    setMainUserStoreService,
    deleteUserStoreService,
    publisStoreService,
    publisIdTrackingMarketing,
    editLegalStore,
    editShippingStore,
    updateCurrencyService,
    getStoreLimits,
    getCtasService,
    saveCtaService,
    editStoreUrl
}