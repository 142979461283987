import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';


import Button from '@material-ui/core/Button';
import { Card, CardContent } from '@material-ui/core';

import {
    Price, 
    DetailsPrice, 
    Options, 
    IconCheck, 
    CenterAlign, 
    Op
} from './Plan.styles';
import CheckIcon from '../../../../assets/icons/check.svg';
import NoCheckIcon from '../../../../assets/icons/nocheck.svg';
import TagBestSeller from '../Tag/TagBestSeller';


export const Plan = ({ title, subtitle, price, detailPrice, btnTitle, options, action, id }) => {
    return (
        <Card> 
            { (id === 11) && 
            // { (id === 6 || id === 7) && 
                <TagBestSeller /> 
            }
            <CardContent>
                <CenterAlign>
                    <Typography variant="h4" component="h1">
                        {title}
                    </Typography>
                    <Typography variant="body2">
                        {subtitle}
                    </Typography>
                    <Price>{ id === 3 && <Op>$250.000 </Op> || id === 12 && <Op>$100,00 </Op> || id === 11 && <Op>$540,00 </Op> } {price}</Price>
                    {/* <Price>{ id === 1 && <Op>$250.000 </Op> || id === 5 && <Op>$100,00 </Op> || id === 7 && <Op>$540,00 </Op> || id === 6 && <Op>$1'200.000 </Op> } {price}</Price> */}
                    <DetailsPrice>{detailPrice}</DetailsPrice>

                    <Button
                        onClick={action}
                        color="primary"
                        variant="contained">
                        {btnTitle}
                    </Button>
                </CenterAlign>
                <ul style={{ padding: '0px 15px 0px' }}>
                    {options.map((data, index) => {
                        return (
                            <Options key={index}>
                                <IconCheck src={data.included ? CheckIcon : NoCheckIcon}></IconCheck>
                                {data.item}
                            </Options>
                        )
                    })}
                </ul>

            </CardContent>
        </Card>
    )
}

export default Plan;
