import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import arrayMove from 'array-move';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { AddNewCollection } from './CollectionsPage.styles';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';


import ListCollections from '../../components/ListCollections/ListCollections';
import ViewDataEmpty from '../../components/ViewDataEmpty/ViewDataEmpty';
import ImageDefaultCategorie from '../../assets/img/default/onboarding_categories.jpg';


/** SERVICES */
import { getCollectionsService, orderCollections } from '../../services/collections';

const Collections = ({ storeId }) => {
    const { t } = useTranslation();
    const [collectionData, setCollectionData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getCollectionsService(storeId)
            .then(({ data }) => {
                setLoading(false);
                setCollectionData(data.sort((a, b) => Number(b.default) - Number(a.default)));
            })
            .catch(err => {
                setLoading(false);
                console.log('Error collectionPage', err);
                setCollectionData([]);
            });
    }, [storeId]);


    const onSortEnd = ({ oldIndex, newIndex }) => {
        setCollectionData(arrayMove(collectionData, oldIndex, newIndex).sort((a, b) => Number(b.default) - Number(a.default)));
        orderCollections(storeId, arrayMove(collectionData, oldIndex, newIndex).map(x => x.id))
            .then(({ data }) => { })
            .catch(err => {
                console.log('Error collectionPage', err);
            });
    };

    return (
        <>
            {loading && <LinearProgress />}
            {!loading && collectionData.length === 0 ?
                <>
                    <ViewDataEmpty title="Agrupar tus productos en categorías"
                        subtitle="Facilita que tus clientes puedan encuentrar tus productos organizandolos por categorías y subcategorias."
                        btnTitle={t('Collection.addNew')}
                        action={'/create-collection'}
                        image={ImageDefaultCategorie}>
                    </ViewDataEmpty>

                </>
                :
                <>
                    {!loading && collectionData.length >= 0 &&
                        <Grid item container xs={12}>
                            <Grid item xs={8}>
                                <Typography
                                    style={{ marginBottom: '10px' }}>
                                    {t('Collection.subTitle')}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <AddNewCollection>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        component={Link}
                                        to={'/create-collection'}
                                        startIcon={<AddCircleIcon />}>
                                        {t('Collection.addNew')}
                                    </Button>
                                </AddNewCollection>
                            </Grid>
                        </Grid>
                    }
                    <ListCollections
                        axis={'xy'}
                        useDragHandle
                        items={collectionData}
                        onSortEnd={onSortEnd}
                        setItems={setCollectionData}
                    >
                    </ListCollections>
                </>
            }

        </>
    );
};


const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(Collections);
