import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';

import {
    ActionItem, SubmitButtonContainer
} from '../SignUpForm/SignUpForm.style';
import { errorToast } from '../../constants.js/misc';

import { passWordRegex } from '../../constants.js/misc';

/*SERVICES */
import {
    changePasswordService
} from '../../services/auth';

const RecoveryPasswordForm = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [loadingChange, setloadingChange] = useState(false);

    const { handleSubmit, control, formState, errors, getValues } = useForm({
        mode: "onChange"
    });


    useEffect(() => {
        if (!window.location.search.split('token=')[1]) {
            history.push('/');
        }
    }, []);

    const repeatVal = passwordRepeat =>
        passwordRepeat === getValues()?.password || "Passwords do not match";


    const onSubmit = (data) => {
        data.token = window.location.search.split('token=')[1];
        setloadingChange(true);
        changePasswordService(data)
            .then(({ data }) => {
                setloadingChange(false);
                history.push('/');
                enqueueSnackbar(t('RecoveryPassWord.passwordSuccess'), {
                    variant: 'success',
                    autoHideDuration: 2500
                });
            }).catch(err => {
                setloadingChange(false);
                enqueueSnackbar(t('RecoveryPassWord.passwordErrorApi'), errorToast);
            });
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth
                >
                    <FormLabel error={errors?.password ? true : false}>{t('RecoveryPassWord.resetnewPassword')}</FormLabel>
                    <Controller
                        render={({ field }) => (
                            <OutlinedInput
                                {...field}
                                defaultValue=""
                                type="password"
                                placeholder={t('LogIn.passwordPlaceholder')}
                                error={errors?.password ? true : false}
                            />
                        )}
                        defaultValue=""
                        control={control}
                        name="password"
                        rules={{ required: true, pattern: passWordRegex }}
                    />
                    <FormHelperText error={errors?.password ? true : false}>{errors?.password ? t('LogIn.passwordErrorMinLength') : ''}</FormHelperText>
                </FormControl>
                <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth
                >
                    <FormLabel error={errors?.confirm_password ? true : false}>{t('RecoveryPassWord.resetConfirmnewPassword')}</FormLabel>
                    <Controller
                        render={({ field }) => (
                            <OutlinedInput
                                {...field}
                                defaultValue=""
                                error={errors?.confirm_password ? true : false}
                                placeholder={t('LogIn.passwordPlaceholder')}
                                type="password"
                            />
                        )}
                        control={control}
                        defaultValue=""
                        name="confirm_password"
                        rules={{ required: true, validate: repeatVal }}
                    />
                    <FormHelperText error={errors?.confirm_password ? true : false}>{errors?.confirm_password ? t('RecoveryPassWord.passwordError') : ''}</FormHelperText>
                </FormControl>
                <SubmitButtonContainer>
                    <Button
                        color="primary"
                        disabled={!formState.isValid || loadingChange}
                        type="submit"
                        size="large"
                        variant="contained"
                        fullWidth>
                        {t('RecoveryPassWord.btnReset')}
                    </Button>
                </SubmitButtonContainer>
            </form>
            <ActionItem>
                <Typography variant="body1" gutterBottom>
                    {t('RecoveryPassWord.linkBack')}
                    <Link component={RouterLink} to="/">{t('LogIn.titlePassword')}</Link>
                </Typography>
            </ActionItem>
        </>
    );
};

export default RecoveryPasswordForm;
