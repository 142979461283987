import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { routes, formatNumber } from '../../../constants.js/misc';
import { ProductImageOrder } from './OrderProductsTable.styles';
import { Box, makeStyles, Typography } from '@material-ui/core';

// Assets
import error from '../../../assets/icons/error.svg';

// styles
const useStyles = makeStyles({
    div: {
        background: '#F8F9FB',
        borderRadius: '8px',
        padding: '32px 8px 18px 8px',
        marginBottom: '3px',
        textAlign: 'center',
    },
});

const OrderProductsTable = ({ orderDetails, storeData }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <Table stickyHeader={true}>
            <TableHead /* style={{ height: '35px!important' }} */>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{t('OrdersTablePage.products.headers.product')}</TableCell>
                    <TableCell>{t('OrdersTablePage.headers.variants')}</TableCell>
                    {!isTabletOrMobile && <TableCell>{t('OrdersTablePage.products.headers.sku')}</TableCell>}
                    <TableCell>{t('OrdersTablePage.products.headers.amount')}</TableCell>
                    <TableCell>{t('OrdersTablePage.products.headers.price')}</TableCell>
                    <TableCell>{t('OrdersTablePage.products.headers.total')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {/* Validación dado el caso el producto ya no exista */}

                {orderDetails?.map(orderDetail =>
                    <>
                        {orderDetail?.product?.id ? (
                            <TableRow key={orderDetail?.id}>
                                <TableCell>
                                    <ProductImageOrder
                                        style={{ maxWidth: '50px' }}
                                        src={orderDetail?.product?.product_images[0]?.picture_url ? `${process.env.REACT_APP_IMAGES_API_URL}fit-in/600x600/${orderDetail?.product?.product_images[0]?.picture_url}` : `https://ui-avatars.com/api/?name=${orderDetail?.product?.name}&size=80&background=CAD7CA&color=fff`}
                                        alt={orderDetail?.product?.product_images[0]?.name}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Link
                                        component={RouterLink}
                                        to={routes.updateProduct + '/' + orderDetail.product.id}
                                        color="primary">
                                        {orderDetail.product.name}
                                    </Link>
                                </TableCell>
                                {/* <TableCell>{orderDetail.product.product_variants} {orderDetail.product.product_options} </TableCell> */}
                                <TableCell>{orderDetail?.order_item_option?.length === 0
                                    ? <>No hay variantes</>
                                    : orderDetail?.order_item_option?.map(option =>
                                        option.product_option_value?.value + ', '
                                    )}
                                </TableCell>
                                {!isTabletOrMobile && <TableCell>{orderDetail.product.sku}</TableCell>}
                                <TableCell>{orderDetail?.quantity}</TableCell>
                                <TableCell>{formatNumber(orderDetail?.price, storeData?.currency)}</TableCell>
                                <TableCell>{formatNumber((orderDetail?.price * orderDetail?.quantity), storeData?.currency)}</TableCell>
                            </TableRow>
                        ) : (
                            <TableRow key={orderDetail?.id}>
                                <TableCell colSpan={7}>
                                    <Box className={classes.div}>
                                        <img src={error} alt="error" style={{ width: '23px' }} />
                                        <Typography variant="body1"><strong>{t('OrdersTablePage.products.notFoundTitle')}</strong></Typography>
                                        <Typography variant="body1">{t('OrdersTablePage.products.notFound')}</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )
                        }
                    </>
                )}
            </TableBody>
        </Table>
    );
};

const mapStateToProps = ({ common }) => ({
    storeData: common.storeData
});

export default connect(
    mapStateToProps
)(OrderProductsTable);