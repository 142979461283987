import { axiosInstance } from './instance';

/**
 * Gets the client filters
 * @param {number} storeId store id
 */
const getClientFilters = (storeId) => {
    return axiosInstance.get(`/stores/${storeId}/customers-counters`);
};

/**
 * Gets the client filters
 * @param {number} storeId store id
 */
const getClientList = (
    storeId,
    limit = 10,
    page = 0,
    search = '',
    order = '',
    value = '',
    rate = '',
    frecuency = '',
) => {
    return axiosInstance.get(
        `/stores/${storeId}/customers?limit=${limit}&page=${page + 1}&sort=created_at${search !== '' ? `&search=${search}` : ''}${order !== '' ? `&orders.between=${order}` : ''}${value !== '' ? `&total.between=${value}` : ''}${rate !== '' ? `&customer_status_id.in=${rate}` : ''}${frecuency !== '' ? `&customer_frequency.in=${frecuency}` : ''}`
    );
};

/**
 * Gets one client
 * @param {number} storeId store id
 * @param {number} customerId customer id
 */
const getClientService = (storeId, customerId) => {
    return axiosInstance.get(`/stores/${storeId}/customers/${customerId}`);
};

/**
* Update client data
* @param {number} storeId store id
* @param {number} customerId customer id
* @param {object} data new customer data
*/
const updateClientInfo = (storeId, customerId, data) => {
    return axiosInstance.put(`/stores/${storeId}/customers/${customerId}`, data);
};

/**
* Update notes of client
* @param {number} storeId store id
* @param {number} customerId customer id
* @param {object} data notes customer data
*/
const updateNotesClientInfo = (storeId, customerId, data) => {
    return axiosInstance.put(`/stores/${storeId}/customers/${customerId}`, data);
};

export {
    getClientFilters,
    getClientList,
    getClientService,
    updateClientInfo,
    updateNotesClientInfo
}