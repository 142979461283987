// react dependencies
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

// material ui dependencies
import { Link as RouterLink } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

//Components
import CardItemConfig from '../../components/CardItemConfig/CardItemConfig';

import { Intro } from '../../components/ManageCollection/ManageCollection.styles';

//Images
import ConfigIcon from "../../assets/icons/config.svg";
import ImageDefaultBilling from '../../assets/icons/billing.svg';
import ImageDefaultDomain from '../../assets/icons/domain.svg';
import ImageDefaultForm from '../../assets/icons/form.svg';
import ImageDefaultPayment from '../../assets/icons/payment.svg';
import ImageDefaultQr from '../../assets/icons/qrcode.svg';
import ImageDefaultGeneral from '../../assets/icons/general.svg';
import ImageDefaultRunway from '../../assets/icons/legal.svg';
import ImageDefaultShipping from '../../assets/icons/shipping.svg';


// internal dependencies
import { routes } from '../../constants.js/misc';

const ConfigPage = ({ storeData }) => {
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (

        <Grid container spacing={isTabletOrMobile ? 0 : 3}>
            {isTabletOrMobile &&
                <Intro item container xs={12}>
                    <img src={ConfigIcon} alt="" />
                    <Typography variant="caption">
                        {t('Config.title')}
                    </Typography>

                </Intro>
            }
            <Grid item xs={12} >
                <Grid item xs={6}>
                    {!isTabletOrMobile && <Typography
                        variant={isTabletOrMobile ? 'h5' : 'h4'}
                        style={{ marginBottom: '10px' }}>
                        {t('Config.title')}
                    </Typography>
                    }
                    {isTabletOrMobile && <Grid item xs={12} style={{ margin: 17 }}>
                        <Link
                            component={RouterLink}
                            to={routes.homeUrl}
                        >
                            {t('MobileFooter.10x')}
                        </Link>
                    </Grid>}
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
                <Card elevation={0}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} lg={4}>
                                <CardItemConfig
                                    title={t('Config.general')}
                                    subtitle={t('Config.generalDescription')}
                                    action={routes.general}
                                    image={ImageDefaultGeneral}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <CardItemConfig
                                    title={t('Billing.title')}
                                    subtitle={t('Config.billingDescription')}
                                    action={routes.billing}
                                    image={ImageDefaultBilling}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <CardItemConfig
                                    title={t('Config.shipping')}
                                    subtitle={t('Config.shippingDescription')}
                                    action={routes.shipping}
                                    image={ImageDefaultShipping}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <CardItemConfig
                                    title={t('Config.legal')}
                                    subtitle={t('Config.legalDesctiption')}
                                    action={routes.legal}
                                    image={ImageDefaultRunway}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <CardItemConfig
                                    title={t('Config.form')}
                                    subtitle={t('Config.formDescription')}
                                    action={routes.form}
                                    image={ImageDefaultForm}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <CardItemConfig
                                    title={t('Config.qrCode')}
                                    subtitle={t('Config.qrCodeDescription')}
                                    action={routes.qrCode}
                                    image={ImageDefaultQr}
                                >
                                </CardItemConfig>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <CardItemConfig
                                    title={t('Config.domain')}
                                    subtitle={t('Config.domainDescription')}
                                    action={routes.domain}
                                    image={ImageDefaultDomain}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <CardItemConfig
                                    title={t('Config.paymentProviders')}
                                    subtitle={t('Config.paymentProvidersDescription')}
                                    action={routes.paymentProviders}
                                    image={ImageDefaultPayment}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = ({ auth, common }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeData: common.storeData
});

export default connect(
    mapStateToProps
)(ConfigPage);
