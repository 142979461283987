import React from 'react'

import Widget from './Widget/Widget'
import WhatsAppButton from './WhatsAppButton/WhatsAppButton'
import { WidgetContextProvider } from './WidgetContext/WidgetContext'
import styles from './WhatsAppWidget.module.css'

export default function WhatsAppWidget({
  textReplyTime = 'Typically replies within a day',
  message = `Hello! 👋🏼 \n\nWhat can we do for you?`,
  companyName = 'Support',
  sendButton = 'Send',
  placeholder = 'Type a message',
  phoneNumber
}) {
  return (
    <div className={styles.root}>
      <WidgetContextProvider>
        <Widget
          textReplyTime={textReplyTime}
          companyName={companyName}
          phoneNumber={phoneNumber}
          sendButton={sendButton}
          message={message}
          placeholder={placeholder}
        />
        <WhatsAppButton />
      </WidgetContextProvider>
    </div>
  )
}
