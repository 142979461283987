import { axiosInstance } from './instance';

/*
[Obtener lista de canales de pagos]
Este servicio muestra la lista de los canales de pagos disponibles por 10X 
y que la tienda no haya configurado. Además muestra aquellas tienda que estén eliminadas (soft delete) 
*/
const getPaymentChannels = (storeId) => {
    return axiosInstance.get(`/stores/${storeId}/payment-channels`);
};

/*
[Obtener lista de canales de pagos configurados]
Este servicio muestra la lista de los canales de pagos que una tienda ha configurado y que no estén eliminadas.
*/
const getPaymentChannelsList = (storeId) => {
    return axiosInstance.get(`/stores/${storeId}/payment-channels-store`);
};

/*
[Verificar si una configuración existe]
Este servicio verifica si la configuración de una tienda existe o no,
se puede usar para aquellas configuraciones que fueron eliminadas y así tomar la decisión de cargar la vista de crear nuevo o actualizar.
*/
const getPaymentChannelVerify = (storeId, channelId) => {
    return axiosInstance.get(`/stores/${storeId}/payment-channels-store/${channelId}/exist`);
};

/*
[Obtener datos de configuración]
Este servicio obtiene los datos de un canal de pago configurado por una tienda
*/
const getPaymentChannelConfig = (storeId, channelId) => {
    return axiosInstance.get(`/stores/${storeId}/payment-channels-store/${channelId}`);
};

/*
[Guardar configuración de canal de pago]
Este servicio guarda la configuración de un canal de pago
*/
const setPaymentChannelConfig = (storeId, channelId, value) => {
    return axiosInstance.post(`/stores/${storeId}/payment-channels-store/${channelId}`, value);
};

/*
[Actualizar configuración de canal de pago]
Este servicio actualiza los datos de la configuración de canal de pago.
Este mismo servicio puede ser usado para activar el medio de pago o para activar el modo de pruebas solo enviando esos campos.
*/
const updatePaymentChannelConfig = (storeId, channelId, value) => {
    return axiosInstance.patch(`/stores/${storeId}/payment-channels-store/${channelId}`, value);
};

/*
[Eliminar configuración de canal de pago]
Este servicio elimina un canal de pago configurado por una tienda. La eliminación realizada es SOFT DELETE
*/
const deletePaymentChannelConfig = (storeId, channelId) => {
    return axiosInstance.delete(`/stores/${storeId}/payment-channels-store/${channelId}`);
};

export {
    getPaymentChannels,
    getPaymentChannelsList,
    getPaymentChannelVerify,
    getPaymentChannelConfig,
    setPaymentChannelConfig,
    updatePaymentChannelConfig,
    deletePaymentChannelConfig
}