import styled from '@emotion/styled';


export const ContainerApp = styled.div`
    padding: 50px;
`;



export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 18px;
  text-align: center;
`;

export const ContainerCode = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
    text-align: center;  
`;



export const CodeGenerate = styled.h1`
    padding: 0;
    margin: 0;
    text-align: center;
    margin-left: 15px;
`;




