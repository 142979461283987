import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

/*SERVICES */
// import { filterProductsCollectionsService } from '../../../services/collections';
import { getProducts } from '../../../services/products.js';

const AutoCompleteProduct = ({ storeId, listDataProduct, setListDataProduct }) => {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('')
    const [products, setProducts] = useState([])
    // const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    // const setFilterValue = (value) => {
    //     setLoading(true);
    //     if (value) {
    //         filterProductsCollectionsService(storeId, value)
    //             .then(({ data }) => {
    //                 setOptions(data);
    //                 setLoading(false);
    //             });
    //     } else {
    //         setLoading(false);
    //     }
    // }

    const addChip = (value) => {
        const productInCat = listDataProduct.find( pro => pro.name === value.name)
        if (productInCat === undefined) {
            setListDataProduct([...listDataProduct, value]);
        }
    }

    useEffect(() => {
        inputValue?.length >= 3 && getProducts(storeId, inputValue)
            .then(res => {
                setProducts(res?.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [storeId, inputValue])

    return (
        <Autocomplete
            id="asynchronous-product"
            style={{ width: '100%' }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, value) => addChip(value)}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={products}
            loading={loading}
            // onInputChange={(event, newInputValue) => {
            //     setFilterValue(newInputValue);
            // }}

            
            renderInput={(params) => (
                <FormControl>
                    <FormLabel>Ingresa al menos 3 caracteres para buscar un producto</FormLabel>
                    <TextField
                        variant="outlined"
                        {...params}
                        onChange={(e) => setInputValue(e?.target?.value)}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} style={{ position: 'absolute', right: '11px' }}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                </FormControl>
            )}
        />
    );
};


const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});


export default connect(
    mapStateToProps
)(AutoCompleteProduct);
