// react dependencies
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';


// external dependencies
import { useSnackbar } from 'notistack';

// material ui dependencies
import { FormHelperText, OutlinedInput, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Autocomplete from '@material-ui/lab/Autocomplete';



//MIXPANEL
import { Mixpanel } from '../../../constants.js/mixpanel';

import { setCountries } from '../../../modules/common';

// Internal dependencies
import { commaSeparatedEmailsRegex, errorToast } from '../../../constants.js/misc';
import { setBillingInfoService, getStoreCountries } from '../../../services/billing';
import { getCitiesService, getDocumentTypesService } from '../../../services/misc';

const BilligDetailsForm = ({
    storeId,
    billingData,
    handleSuccess,
    formObject,
    setSaving,
    submit,
    setSubmit,
    countries,
    selectedCountryBilling,
    changeCountry
}) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [cities, setCities] = useState([]);
    const [countryChange, setCountryChange] = useState();
    const [documentTypes, setDocumentTypes] = useState([]);
    const formRef = useRef(null);

    const countryToFlag = (isoCode) => {
        return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode
    }

    const {
        control,
        handleSubmit,
        errors,
        setValue
    } = formObject;

    const getCities = () => {
        getCitiesService()
            .then(res => setCities(res.data))
            .catch(err => enqueueSnackbar(t('BilligDetailsModal.citiesError'), errorToast))
    }

    const getDocumenTypes = () => {
        getDocumentTypesService()
            .then(res => setDocumentTypes(res.data))
            .catch(err => enqueueSnackbar(t('BilligDetailsModal.documentTypesError'), errorToast))
    }

    const normalizeData = data => ({
        ...data,
        country: countryChange,
        document_type_id: +data.document_type_id,
        city_id: data.city_id ? +data.city_id : null,
        country_code: countryChange ? countryChange?.code : (selectedCountryBilling ? selectedCountryBilling?.code : (billingData?.country_code ? billingData?.country_code : data?.country?.code)),
    });

    const onSubmit = data => {
        setSaving(true);
        setBillingInfoService(storeId, normalizeData(data), billingData)
            .then(res => {
                setSubmit(false);
                setSaving(false);
                handleSuccess();
                Mixpanel.track('Actualizar datos de facturación', {
                    "Nombres": data.contact_name,
                    "Apellidos": data.contact_last_name,
                    "Tipo de documento": data.document_type_id,
                    "No documento": data.dni,
                    "Codigo postal": data.zip_code,
                    "Fecha del registro inicial": new Date(),
                    "Fecha del cambio": new Date(),
                    "Correo": data.emails
                });
            })
            .catch(err => {
                setSubmit(false);
                setSaving(false);
                enqueueSnackbar(t('BilligDetailsModal.submitError'), errorToast);
            });
    }

    useEffect(() => {
        getCities();
        getDocumenTypes();
    }, []);

    useEffect(() => {
        (countryChange ? (countryChange?.code !== 'CO' && setValue('city_id', '')) : ((selectedCountryBilling?.code !== 'CO' && billingData?.country_code !== 'CO') && setValue('city_id', '')) && setValue('city_id', ''));
    }, [countryChange?.code, selectedCountryBilling?.code, billingData?.country_code]);

    useEffect(() => {
        if (countries.length === 0) {
            getStoreCountries()
                .then(({ data }) => {
                    setCountries(data);
                })
                .catch(err => { })
        }
    }, [countries]);

    useEffect(() => {
        if (submit) {
            handleSubmit(onSubmit)();
        }
    }, [submit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <Grid container spacing={isTabletOrMobile ? 2 : 3}>
                <Grid item xs={12} lg={6} >
                    <FormControl>
                        <FormLabel error={errors?.contact_name ? true : false}>{t('BilligDetailsModal.name')}*</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.contact_name ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="contact_name"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={billingData.contact_name ? billingData.contact_name : null}
                        />
                        <FormHelperText error={errors?.contact_name ? true : false}>{errors?.contact_name ? t('BilligDetailsModal.nameError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl>
                        <FormLabel error={errors?.contact_last_name ? true : false}>{t('BilligDetailsModal.lastName')}*</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.contact_last_name ? true : false}
                                    margin="normal"
                                />
                            )}
                            control={control}
                            rules={{ required: true }}
                            name="contact_last_name"
                            defaultValue={billingData.contact_last_name ? billingData.contact_last_name : null}
                        />
                        <FormHelperText error={errors?.contact_last_name ? true : false}>{errors?.contact_last_name ? t('BilligDetailsModal.lastNameError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl>
                        <FormLabel error={errors?.document_type_id ? true : false}>{t('BilligDetailsModal.idType')}*</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    native
                                    variant="outlined"
                                    fullWidth
                                    error={errors?.document_type_id ? true : false}
                                    margin="normal"
                                >
                                    <option value=''>...</option>
                                    {documentTypes.map(type => <option value={type.id}>{type.name}</option>)}
                                </Select>
                            )}
                            control={control}
                            rules={{ required: true }}
                            name="document_type_id"
                            defaultValue={billingData.document_type_id ? billingData.document_type_id : null}
                        >
                        </Controller>
                        <FormHelperText error={errors?.document_type_id ? true : false}>{errors?.document_type_id ? t('BilligDetailsModal.idTypeError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl>
                        <FormLabel error={errors?.dni ? true : false}>{t('BilligDetailsModal.id')}*</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.dni ? true : false}
                                    margin="normal"
                                />
                            )}
                            control={control}
                            rules={{ required: true }}
                            name="dni"
                            defaultValue={billingData.dni ? billingData.dni : null}
                        />
                        <FormHelperText error={errors?.dni ? true : false}>{errors?.dni ? t('BilligDetailsModal.idError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} lg={12}>
                    <FormControl>
                        <FormLabel error={errors?.country ? true : false}>{t('CountrySelector.title')}* {selectedCountryBilling && <Link href="#" onClick={(e) => changeCountry(e)}>Cambiar</Link>}</FormLabel>
                        {selectedCountryBilling &&
                            <OutlinedInput
                                fullWidth
                                defaultValue={selectedCountryBilling.name ? selectedCountryBilling.name : null}
                                margin="normal"
                                disabled
                            />}
                        {!selectedCountryBilling &&
                            <Controller
                                name="country"
                                control={control}
                                rules={{ required: true }}
                                defaultValue={billingData.country ? billingData.country : null}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        defaultValue={billingData.country ? billingData.country : null}
                                        value={countryChange?.name}
                                        error={errors?.country ? true : false}
                                        options={countries}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, v) => { setCountryChange(v); setValue('country', v?.name); }}
                                        getOptionSelected={(o, v) => o.name === v.name}
                                        renderOption={option => (
                                            <>
                                                <span style={{ marginRight: '1rem' }}>
                                                    {countryToFlag(option.code)}&nbsp;&nbsp;{option.name}
                                                </span>
                                            </>
                                        )}
                                        id="combo-box-demo"
                                        style={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                )}
                            />
                        }
                    </FormControl>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <FormControl>
                        <FormLabel error={errors?.city_id ? true : false}>{t('BilligDetailsModal.city')}*</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    native
                                    fullWidth
                                    variant="outlined"
                                    error={errors?.city_id ? true : false}
                                    margin="normal"
                                    disabled={(countryChange ? (countryChange?.code !== 'CO' || false) : (selectedCountryBilling?.code === 'CO' ? false : (billingData.country !== 'CO' || false)))}
                                >
                                    <option value=''>...</option>
                                    {cities.map(city => <option value={city.id}>{city.name}</option>)}
                                </Select>
                            )}
                            control={control}
                            rules={{ required: (countryChange?.code === 'CO' ? true : false) }}
                            name="city_id"
                            defaultValue={(countryChange ? (countryChange?.code === 'CO' ? billingData.city_id : null) : (billingData.country_code === 'CO' ? billingData.city_id : null))}
                        >
                        </Controller>
                        <FormHelperText error={errors?.city_id ? true : false}>{errors?.city_id ? t('BilligDetailsModal.cityError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl>
                        <FormLabel error={errors?.zip_code ? true : false}>{t('BilligDetailsModal.postalCode')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.zip_code ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="zip_code"
                            control={control}
                            rules={{ required: false }}
                            defaultValue={billingData.zip_code ? billingData.zip_code : null}
                        />
                        <FormHelperText error={errors?.zip_code ? true : false}>{errors?.zip_code ? t('BilligDetailsModal.postalCodeError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl>
                        <FormLabel error={errors?.address ? true : false}>{t('BilligDetailsModal.address')}*</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.address ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="address"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={billingData.address ? billingData.address : null}
                        />
                        <FormHelperText error={errors?.address ? true : false}>{errors?.address ? t('BilligDetailsModal.addressError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl>
                        <FormLabel error={errors?.emails ? true : false}>{t('BilligDetailsModal.emails')}*</FormLabel>
                        <Controller
                            variant="outlined"
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.emails ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="emails"
                            control={control}
                            rules={{ required: true, pattern: commaSeparatedEmailsRegex }}
                            defaultValue={billingData.emails ? billingData.emails : null}
                        />
                        <FormHelperText error={errors?.emails ? true : false}>{errors?.emails ? t('BilligDetailsModal.emailsError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox color="primary" name="checkedC" />} label={t('BilligDetailsModal.terms')} />
                </Grid> */}
            </Grid>
        </form >
    );
};

const mapStateToProps = ({ auth, common }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId,
    countries: common.countries
});

export default connect(
    mapStateToProps
)(BilligDetailsForm);
