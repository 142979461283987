import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

//Services
import { getUsers } from '../../services/users';
import { getStoreService } from '../../services/store';

// material ui dependencies
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';


// internal dependencies
import { routes } from '../../constants.js/misc';

// styles
const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    }, avatarName: {
        color: '#266678',
        float: 'left',
        background: '#F5D6BA',
        fontSize: 18,
        lineHeight: 23,
        marginRight: 10,
        textTransform: 'uppercase'
    }
});

export const UsersRolesPage = ({ storeId, storeData, userEmail }) => {
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const classes = useStyles();
    const [infoUserStore, setInfoUserStore] = useState('');
    const [rows, setRows] = useState([]);
    const [gettingData, setGettingData] = useState(false);

    const columns = [
        { id: 'name', label: t('UserRole.members.row.user'), minWidth: 170 },
        { id: 'role', label: t('UserRole.members.row.role'), minWidth: 100 },
        { id: 'action', label: '', minWidth: 100 },
    ];

    useEffect(() => {
        setGettingData(true);
        getUsers().then(({ data }) => {
            setGettingData(false);
            setRows(data);
        });
    }, []);

    /*useEffect(() => {
        //
        if (!infoUserStore) {
            getStoreService(storeId)
                .then(({ data }) => {
                    setInfoUserStore(data);
                })
                .catch(err => { });
        }

    }, [infoUserStore, storeId]);*/

    return (
        <Grid container spacing={isTabletOrMobile ? 0 : 3} style={{ justifyContent: 'center' }}>
            <Grid
                item
                container
                xs={12} md={10} xl={8}
                className={classes.container}
                style={{
                    justifyContent: 'flex-start',
                    padding: isTabletOrMobile ? 20 : '0 0 20px'
                }}
            >
                <Grid item xs={6}>
                    <Typography
                        variant={isTabletOrMobile ? 'h5' : 'h4'}
                        style={{ marginBottom: '10px' }}
                    >
                        {t('UserRole.title')}
                    </Typography>
                    <Link
                        component={RouterLink}
                        to={routes.config}
                    >
                        {t('Config.title')}
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Card container spacing={3} style={{ width: '100%', marginTop: 10 }}>
                        <CardHeader
                            titleTypographyProps={{ variant: 'h6' }}
                            title={t('UserRole.manageAccountTitle')} />
                        <CardContent>
                            <Grid item xs={12} md={2} >
                                <Typography
                                    variant={'h6'}
                                    style={{ marginBottom: '10px', display: 'flex' }}>
                                    <Avatar className={classes.avatarName}>{userEmail ? userEmail.slice(0, 2) : ''}</Avatar>
                                    {userEmail}
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} >
                    <Card container spacing={3} style={{ width: '100%', marginTop: 10 }}>
                        <Grid item container xs={12}>
                            <Grid item xs={6} style={{ padding: 10 }}>
                                <CardHeader
                                    titleTypographyProps={{ variant: 'h6' }}
                                    subheader={t('UserRole.members.description')}
                                    title={t('UserRole.members')} />
                            </Grid>
                            <Grid item container xs={6} style={{ justifyContent: 'flex-end', display: 'flex', padding: 30 }}>
                                <Button
                                    component={RouterLink}
                                    to={routes.createUserRole}
                                    variant="contained">
                                    {t('UserRole.members.addNew')}
                                </Button>
                            </Grid>
                        </Grid>
                        <CardContent>


                            <div style={{ minHeight: '4px' }}>
                                {gettingData && <LinearProgress />}
                            </div>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id + index}>
                                                    <TableCell>
                                                        {row.email}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.role.name || 'Administrador'}
                                                    </TableCell>

                                                    <TableCell>
                                                        <Button
                                                            variant="contained">
                                                            {t('UserRole.members.update')}
                                                        </Button>
                                                    </TableCell>


                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        </Grid>
    )
};

const mapStateToProps = ({ auth, common }) => ({
    storeId: auth.storeId,
    userEmail: auth.userEmail,
    storeData: common.storeData
});

export default connect(
    mapStateToProps
)(UsersRolesPage);
