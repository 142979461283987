// ReactJS
import React from "react";

// Icons
import dragIcon from '../../../../assets/icons/dragIcon.svg';

// MUI Components
import { Card, Grid, OutlinedInput, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        margin: 0
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '20px 0 0 0'
    }
});

const TypeSection = ({ field, handleRemove, index, register }) => {
    const classes = useStyles();

    return (
        <Card elevation={1} style={{ padding: 25, width: '100%' }}>
            <Grid item className={classes.header}>
                <Typography
                    variant="body1"
                    style={{ color: '#53627C', fontSize: 24 }}
                >
                    <img src={dragIcon} alt="" style={{ marginRight: 4 }} />
                    Título y descripción
                </Typography>
                <Typography
                    onClick={() => handleRemove({ index: index })}
                    variant="body1"
                    style={{ color: '#CB7C7A', fontSize: 16, cursor: 'pointer' }}
                >
                    Eliminar título y descripción
                </Typography>
            </Grid>
            <Grid item className={classes.title}>
                <Typography
                    variant="body1"
                    style={{ color: '#0F2930', fontSize: 14 }}
                >
                    Título
                </Typography>
                <OutlinedInput defaultValue={field.title} {...register(`dynamicInputs.${index}.title`)} fullWidth />
            </Grid>
            <Grid item className={classes.title}>
                <Typography
                    variant="body1"
                    style={{ color: '#0F2930', fontSize: 14 }}
                >
                    Descripción (Opcional)
                </Typography>
                <OutlinedInput defaultValue={field.text} {...register(`dynamicInputs.${index}.text`)} fullWidth rows={6} multiline />
            </Grid>
        </Card>
    );
}

export default TypeSection;