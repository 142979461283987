// ReactJS
import React from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { formatDateLarge } from "../../../constants.js/misc";

const DesktopTableDomain = ({ storeData, setIsOpen }) => {
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Nombre de dominio</TableCell>
                        <TableCell align="center">Estado</TableCell>
                        <TableCell align="center">Fecha en que se agregó</TableCell>
                        {/* <TableCell align="center">Proveedor</TableCell> */}
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="center">
                            <Typography
                                variant="h5"
                                style={{ color: '#0F2930', fontSize: 16 }}
                            >
                                {storeData?.domain}
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography
                                variant="h5"
                                style={{
                                    alignItems: 'center',
                                    backgroundColor: '#48AC98',
                                    borderRadius: 12,
                                    color: '#FFFFFF',
                                    display: 'flex',
                                    fontSize: 16,
                                    height: 26,
                                    justifyContent: 'center',
                                    margin: 'auto',
                                    width: 86,
                                }}
                            >
                                Conectado
                            </Typography>
                        </TableCell>
                        <TableCell align="center">{formatDateLarge(storeData?.custom_domain_created_at)}</TableCell>
                        {/* <TableCell align="center">GoDaddy.com, LLC</TableCell> */}
                        <TableCell align="center">
                            <Button
                                style={{
                                    backgroundColor: '#EEEEEE',
                                    color: '#53627C',
                                    height: 33,
                                    idth: 94,
                                    fontSize: 16
                                }}
                                type="button"
                                onClick={() => setIsOpen(true)}
                                variant="contained"
                            >
                                Cambiar
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default DesktopTableDomain;