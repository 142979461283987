import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { CardHeader, Box } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';

const CollectionAvailability = ({ control, watch, collectionData, setValue }) => {
    const { t } = useTranslation();
    const active = watch('active');
    let { id } = useParams();
    const [isCheck, setIsCheck] = useState(false)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    useEffect(() => {
        if (Object.keys(collectionData).length > 0) {
            setValue('active', collectionData.public ? true : false);
        }
        setIsCheck(Boolean(collectionData.public))
    }, [collectionData, setValue]);

    return (
        <Card elevation={2} style={{ marginBottom: isTabletOrMobile ? 38 : 21 }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('Collection.availabilityTitle')} />
            <CardContent>
                <Grid
                    container
                    spacing={3}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center">
                        <Controller
                            render={({ field: { value, ref, ...field } }) => (
                                <Switch
                                    {...field}
                                    inputRef={ref}
                                    checked={!!value}
                                    disableRipple
                                    onClick={() => setIsCheck(!isCheck)}
                                />
                            )}
                            control={control}
                            defaultValue={true}
                            name="active"
                        />

                        {id
                            ? <Typography variant="body2">
                                {isCheck ? t('Collection.isAvailability') : t('Collection.noAvailability')}
                            </Typography>
                            : <Typography variant="body2">
                                {(!isCheck) ? t('Collection.isAvailability') : t('Collection.noAvailability')}
                            </Typography>
                        }
                    </Box>

                </Grid>

            </CardContent>
        </Card >
    );
};
export default connect()(CollectionAvailability);