import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReplayIcon from '@material-ui/icons/Replay';
import Typography from '@material-ui/core/Typography';
import FilterIcon from '../../assets/icons/FilterIcon.svg';
import IconOrder from '../../assets/icons/IconOrder.svg';
/** SERVICES */
import { getCollectionsService } from '../../services/collections';
import { KEY_SELECTED_CATEGORY } from '../../constants.js/keys';


const useStyles = makeStyles({
    btn: {
        "& .MuiButton-label": {
            fontSize: 14
        },
        "& .MuiButton-contained": {
            background: '#fff'
        }
    }
});



const FilterProductsSelect = ({
    storeId,
    selectedCategorie,
    setSelectedCategorie,
    selectedStatus,
    setSelectedStatus,
    setSelectedOrder,
    selectedOrder
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openCategorie, setOpenCategorie] = useState(false);
    const [openOrder, setOpenOrder] = useState(false);
    const [collectionData, setCollectionData] = useState([]);
    const [categorySel, setCategorySel] = useState(JSON.parse(localStorage.getItem(KEY_SELECTED_CATEGORY)));

    const anchorRef = useRef(null);
    const anchorRefCategorie = useRef(null);
    const anchorRefOrder = useRef(null);

    const options = ['Inactivo', 'Activo'];


    const handleClick = () => {
        setOpen(open ? false : true);
    };

    const handleClickCategorie = () => {
        setOpenCategorie(openCategorie ? false : true);
    };

    const handleClickOrder = () => {
        setOpenOrder(openOrder ? false : true);
    };



    useEffect(() => {
        getCollectionsService(storeId)
            .then(({ data }) => {
                setCollectionData(data.sort((a, b) => Number(b.default) - Number(a.default)));
            })
            .catch(err => {
                setCollectionData([]);
            });
    }, [storeId]);

    useEffect(() => {
        if (categorySel) {
           setSelectedCategorie(categorySel) 
        }
     }, [categorySel, selectedCategorie, setSelectedCategorie]);


    const handleClickClearFilter = () => {
        setSelectedStatus(null);
        setSelectedCategorie('');
    };

    const handleMenuItemClickStatus = (index) => {
        setSelectedStatus(index);
        setOpen(false);
    };

    const handleMenuItemClickCategorie = (option) => {
        setSelectedCategorie(option);
        setCategorySel(option)
        setOpenCategorie(false);
        localStorage.setItem(KEY_SELECTED_CATEGORY, JSON.stringify(option))
    };


    const handleMenuItemClickSortName = (index) => {
        setSelectedOrder(index === 0 ? 'asc' : 'desc');
        setOpenOrder(false);
    };

    /**CLOSED POPPVER */
    const handleCloseCategorie = (event) => {
        if (anchorRefCategorie.current && anchorRefCategorie.current.contains(event.target)) {
            return;
        }
        setOpenCategorie(false);
    };

    const handleCloseOrder = (event) => {
        if (anchorRefOrder.current && anchorRefOrder.current.contains(event.target)) {
            return;
        }
        setOpenOrder(false);
    };

    const handleCloseStatus = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    
    return (
        <Grid container>
            <Grid item container xs={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography
                    variant="body2"
                    style={{ margin: 8 }}
                >
                    <img style={{ marginRight: 5 }} src={FilterIcon} alt="" />
                    {t('Product.filter.by')}
                </Typography>
                <ButtonGroup className={classes.btn} variant="contained" disableElevation={true}>
                    <Button
                        onClick={handleClickCategorie}
                        endIcon={<ExpandMoreIcon />}
                        ref={anchorRefCategorie}
                        aria-controls={openCategorie ? 'split-button-categorie' : undefined}
                        aria-expanded={openCategorie ? 'true' : undefined}
                    >
                        {selectedCategorie ? selectedCategorie.name : t('Product.filter.categorie')}
                    </Button>
                    <Button
                        onClick={handleClick}
                        endIcon={<ExpandMoreIcon />}
                        ref={anchorRef}
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu" >
                        {selectedStatus !== null ? options[selectedStatus] : t('Product.filter.visibility')}
                    </Button>

                    <Button
                        onClick={handleClickClearFilter}
                        disabled={selectedStatus !== null || selectedCategorie ? false : true}
                        startIcon={<ReplayIcon />}>{t('Product.filter.empty')}</Button>
                </ButtonGroup>


                {/**POPPER CATEGORIES */}
                <Popper 
                    style={{ zIndex: 9999, margin: '40px 0' }} 
                    open={openCategorie} 
                    anchorEl={anchorRefCategorie.current} 
                    role={undefined} 
                    transition 
                    disablePortal 
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseCategorie}>
                                    <MenuList id="split-button-categorie">
                                        {collectionData.map((option, index) => (
                                            <MenuItem
                                                key={option.id}
                                                onClick={(event) => handleMenuItemClickCategorie(option)}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

                {/**POPPER ESTADOS */}
                <Popper style={{ zIndex: 99 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseStatus}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                selected={index === selectedStatus}
                                                onClick={(event) => handleMenuItemClickStatus(index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>


            </Grid>
            <Grid item container xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography
                    variant="body2"
                    style={{ margin: 8 }}>
                    <img style={{ marginRight: 5 }} src={IconOrder} />
                    {t('Product.filter.order')}
                </Typography>
                <ButtonGroup className={classes.btn} variant="contained" ref={anchorRefOrder} disableElevation={true}>
                    <Button
                        onClick={handleClickOrder}
                        endIcon={<ExpandMoreIcon />}>{selectedOrder === 0 || selectedOrder === 'asc' ? t('Product.filter.orderAz') : t('Product.filter.orderDz')}</Button>
                </ButtonGroup>


                {/**POPPER ORDER */}
                <Popper style={{ zIndex: 99 }} open={openOrder} anchorEl={anchorRefOrder.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseOrder}>
                                    <MenuList id="split-button-categorie">
                                        {['Ordenar ascendente', 'Ordenar descendente'].map((option, index) => (
                                            <MenuItem
                                                key={option.id}
                                                onClick={(event) => handleMenuItemClickSortName(index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

            </Grid>
        </Grid>
    );
}

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});


export default connect(
    mapStateToProps
)(FilterProductsSelect);