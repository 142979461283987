import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import ConfigIcon from "../../assets/icons/config.svg";
import shareIcon from "../../assets/icons/share.svg";
import openInNewIcon from "../../assets/icons/open-in-new.svg";
import logoutIcon from "../../assets/icons/logout.svg";
import editIcon from '../../assets/icons/edit.svg'
import categoriesIcon from '../../assets/icons/collections.svg'
import { makeStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { routes } from '../../constants.js/misc';

const useStyles = makeStyles({
    listItem: {
        borderBottom: 'solid #ECECF2 1px'
    }
});

const MobileDrawer = ({ open, onClose, storeData, logOut, dataStore }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    
    const storeUrl = `https://${dataStore.domain ? dataStore.domain : (`${dataStore.url}.ecometri.shop`)}/${dataStore?.default_user_store ? dataStore?.default_user_store?.mobile_phone_number : 0}`
    
    const shareStore = () => {
        const shareData = {
            title: storeData.name,
            text: storeData.description.replace( /(<([^>]+)>)/ig, ''),
            url: storeUrl
        }
        if (navigator.canShare) {
            navigator.share(shareData)
                .then(() => console.log('Share was successful.'))
                .catch((error) => console.log('Sharing failed', error));
          } else {
            console.log(`Your system doesn't support sharing`);
          }
    }
    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={onClose}
            PaperProps={{ style : { borderRadius: '16px 16px 0px 0px' } }}>
            <div
                role="presentation"
                onClick={onClose}
                onKeyDown={onClose}>
                <List style={{ paddingBottom: 0 }}>
                    <ListItem
                        className={classes.listItem}
                        component={Link}
                        href={storeUrl}
                        color="inherit"
                        target="_blank"
                        button
                        key={t('MobileDrawer.store')}>
                        <ListItemIcon>
                            <img src={openInNewIcon} alt={t('MobileDrawer.store')} />
                        </ListItemIcon>
                        <ListItemText primary={t('MobileDrawer.store')} />
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        onClick={shareStore}
                        button
                        key={t('MobileDrawer.share')}>
                        <ListItemIcon>
                            <img src={shareIcon} alt={t('MobileDrawer.share')} />
                        </ListItemIcon>
                        <ListItemText primary={t('MobileDrawer.share')} />
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        component={RouterLink}
                        to={routes.config}
                        button
                        key={t('MobileDrawer.config')}>
                        <ListItemIcon>
                            <img src={ConfigIcon} alt={t('MobileDrawer.config')} />
                        </ListItemIcon>
                        <ListItemText primary={t('MobileDrawer.config')} />
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        component={RouterLink}
                        to={routes.store}
                        button
                        key={t('MobileDrawer.edit')}>
                        <ListItemIcon>
                            <img src={editIcon} alt={t('MobileDrawer.edit')} />
                        </ListItemIcon>
                        <ListItemText primary={t('MobileDrawer.edit')} />
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        component={RouterLink}
                        to={routes.mobileManageCategories}
                        button
                        key={t('MobileDrawer.categories')}>
                        <ListItemIcon>
                            <img src={categoriesIcon} alt={t('MobileDrawer.categories')} />
                        </ListItemIcon>
                        <ListItemText primary={t('MobileDrawer.categories')} />
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        style={{ color: '#CB7C7A' }}
                        onClick={logOut}
                        button
                        key={t('MobileDrawer.logout')}>
                        <ListItemIcon>
                            <img src={logoutIcon} alt={t('MobileDrawer.logout')} />
                        </ListItemIcon>
                        <ListItemText primary={t('MobileDrawer.logout')} />
                    </ListItem>
                </List>
            </div>
        </Drawer>
    );
};

const mapStateToProps = ({ common }) => ({
    storeData: common.storeData
});

export default connect(
    mapStateToProps
)(MobileDrawer);
