// react dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { bindActionCreators } from 'redux';
import uniq from 'lodash/uniq';
import { useMediaQuery } from 'react-responsive';


// material ui dependencies
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';

// internal dependencies
import { routes, successToast, errorToast, currencyToString } from '../../constants.js/misc';

// internal components
import CardItemActionFooter from '../../components/CardItemActionFooter/CardItemActionFooter';
import WPIcon from "../../assets/icons/white-wp.svg";
import errorIcon from "../../assets/icons/error.svg";

import {
    publisIdTrackingMarketing,
    getStoreService,
    updateCurrencyService,
    getCtasService,
    saveCtaService
} from '../../services/store';

import { setStoreData } from '../../modules/common';
import { Intro, ButtonContainer } from '../../components/ManageCollection/ManageCollection.styles';
import ConfigIcon from "../../assets/icons/config.svg";

const GeneralPage = ({ storeId, countries, storeData, setStoreData }) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [saving, setSaving] = useState(false);
    const [validCtas, setValidCtas] = useState([]);
    const [savingCta, setSavingCta] = useState(false);
    const [savingCurrency, setSavingCurrency] = useState(false);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const { handleSubmit, control, errors, setValue, watch } = useForm({
        mode: "onChange"
    });

    const currency = watch('currency')
    const cta = watch('cta')
    useEffect(() => {
        if (storeData.id) {
            if (currency && currency !== storeData.currency) {
                setSavingCurrency(true);
                updateCurrencyService(storeId, currency)
                    .then(res => {
                        setSavingCurrency(false);
                        setStoreData({
                            ...storeData,
                            currency: currency
                        });
                    })
                    .catch(res => {
                        setSavingCurrency(false);
                        enqueueSnackbar(t('GeneralPage.currencyError'), errorToast);
                    });
            }
        }
    }, [currency]);

    useEffect(() => {
        getStoreService(storeId)
            .then(({ data }) => {
                setValue('google_analitycs_tracking_id', data.google_analitycs_tracking_id);
                setValue('facebook_pixel_code', data.facebook_pixel_code);
            })
            .catch(err => { });

        // Get call to actions
        // getCtasService()
        //     .then(({ data }) => setValidCtas(data))
        //     .catch(err => enqueueSnackbar(t('GeneralPage.ctasError'), errorToast));
    }, []);

    useEffect(() => {
        if (storeData && storeData.currency) setValue('currency', storeData.currency);
        if (storeData && storeData.call_to_action_button) setValue('cta', storeData.call_to_action_button)
    }, [storeData])

    // useEffect(() => {
    //     // save cta in bd
    //     if (cta && cta !== storeData.call_to_action_button) {
    //         saveCtaService(storeId, { call_to_action_button: cta })
    //             .then(res => enqueueSnackbar(t('GeneralPage.ctaConfigSuccess'), successToast))
    //             .catch(res => enqueueSnackbar(t('GeneralPage.ctaConfigError'), errorToast))
    //     }
    // }, [cta])
    const savingCtaChanges = () => {
        if (cta && cta !== storeData.call_to_action_button) {
            saveCtaService(storeId, { call_to_action_button: cta.trim() })
            .then(res =>  enqueueSnackbar(t('GeneralPage.ctaConfigSuccess'), successToast))
            .catch(res => enqueueSnackbar(t('GeneralPage.ctaConfigError'), errorToast))
        }   
    }
    const onSubmit = (data) => {
        setSaving(true);
        publisIdTrackingMarketing(storeId, data)
            .then(({ data }) => {
                setSaving(false);
                enqueueSnackbar(t('GeneralPage.successMessage'), successToast);
            })
            .catch(error => {
                setSaving(false);
                enqueueSnackbar(t('GeneralPage.errorMessage'), errorToast);
            });
    };
    
    return (
        <Grid
            container
            spacing={isTabletOrMobile ? 0 : 3}
            style={{ paddingBottom: isTabletOrMobile ? 120 : 24, padding: 'auto 100px' }}>

            {isTabletOrMobile &&
                <Intro item container xs={12}>
                    <img src={ConfigIcon} alt="ConfigIcon" />
                    <Typography variant="caption">
                        {t('Config.title')}
                    </Typography>
                </Intro>
            }

            <Grid item container xs={12} style={{ padding: isTabletOrMobile ? 20 : '0 0 20px' }}>
                <Grid item xs={6}>
                    <Typography
                        variant={isTabletOrMobile ? 'h5' : 'h4'}
                        style={{ marginBottom: '10px' }}>
                        {t('Config.general')}
                    </Typography>
                    <Link
                        component={RouterLink}
                        to={routes.config}>
                        ‹ {t('Config.title')}
                    </Link>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>

                <Grid item xs={12} style={{ marginBottom: 18 }}>
                    <CardItemActionFooter
                        title={t('GeneralPage.currencyTitle')}
                        children={
                            <FormControl
                                variant="outlined"
                                margin="normal"
                                fullWidth>
                                <FormLabel error={errors?.facebook_pixel_code ? true : false}>{t('GeneralPage.currencyLabel')}</FormLabel>
                                <Controller
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            native
                                            fullWidth
                                            variant="outlined"
                                            disabled={savingCurrency || !storeData.id}
                                            inputProps={{ style: { textTransform: 'capitalize' } }}
                                            defaultValue={storeData.currency}
                                        >
                                            {uniq(countries.map(country => country.currency)).map(currency =>
                                                <option key={currency} value={currency}>{currencyToString(currency)} - {currency}</option>
                                            )}
                                        </Select>
                                    )}
                                    control={control}
                                    name="currency">

                                </Controller>
                            </FormControl>
                        }
                    />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 18 }}>
                    <CardItemActionFooter
                        title={t('GeneralPage.ctaConfigTitle')}
                        children={
                            <Grid container spacing={2}>
                                <Grid item lg={5} xs={12}>
                                    
                            <FormControl
                                /* disabled={true} */
                                variant="outlined"
                                margin="normal"

                            >
                               <Controller
                                    render={({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            inputProps={{ maxLength: 30 }}
                                            placeholder={t('GeneralPage.ctaConfigPlacaholder')}
                                            fullWidth
                                            margin="normal"
                                            defaultValue=""
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                    name="cta"
                                />
                            </FormControl>
                                </Grid>
                                <Grid item lg={5} xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth>
                                        {/* <FormLabel></FormLabel> */}
                                        <Button
                                            disabled={ cta?.length === 0 || cta === storeData.call_to_action_button}
                                            color="primary"
                                            variant="contained"
                                            onClick={savingCtaChanges}
                                            style={{
                                                maxWidth: 250,
                                                minHeight: 43
                                            }}>
                                            {t('GeneralPage.ctaConfigSaving')}
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        }
                description={t('GeneralPage.ctaConfigDescription')}
                actionTitle={' '}
                        />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 18 }}>
                    <CardItemActionFooter
                        title={t('GeneralPage.titleGoogle')}
                        children={
                            <FormControl
                                /* disabled={true} */
                                variant="outlined"
                                margin="normal"

                            >
                                <FormLabel error={errors?.google_analitycs_tracking_id ? true : false}>{t('GeneralPage.labelTrackingGoogle')}</FormLabel>
                                <Controller
                                    render={({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            placeholder={t('GeneralPage.placeholderTrackingGoogle')}
                                            fullWidth
                                            error={errors?.contact_name ? true : false}
                                            margin="normal"
                                            defaultValue=""
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                    name="google_analitycs_tracking_id"
                                />
                            </FormControl>
                        }
                        url={'https://support.google.com/analytics/answer/1008080?hl=es#GAID'}
                        description={t('GeneralPage.descriptionTrackingGoogle')}
                        actionTitle={t('GeneralPage.actionTrackingGoogle')}
                    />
                </Grid>



                <Grid item xs={12} style={{ marginBottom: 18 }} >
                    <CardItemActionFooter
                        title={t('GeneralPage.titleFacebook')}
                        children={
                            <FormControl
                                /* disabled={true} */
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            >
                                <FormLabel error={errors?.facebook_pixel_code ? true : false}>{t('GeneralPage.labelTrackingFacebook')}</FormLabel>
                                <Controller
                                    render={({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            fullWidth
                                            error={errors?.contact_name ? true : false}
                                            margin="normal"
                                            defaultValue=""
                                            placeholder={t('GeneralPage.placeholderTrackingFacebook')}
                                        />
                                    )}
                                    control={control}
                                    name="facebook_pixel_code"
                                />
                            </FormControl>

                        }
                        url={'https://www.facebook.com/business/help/952192354843755?id=1205376682832142'}
                        description={t('GeneralPage.descriptionTrackingFacebook')}
                        actionTitle={t('GeneralPage.actionTrackingFacebook')}

                    />
                </Grid>

                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                    {!isTabletOrMobile &&
                        <Button
                            type="submit"
                            disabled={saving || savingCurrency}
                            color="primary"
                            variant="contained">
                            {t('SaveChangesBtn')}
                        </Button>
                    }

                    {isTabletOrMobile &&
                        <ButtonContainer
                            item
                            xs={12}
                            lg={4}>
                            <Button
                                style={{ width: '100%' }}
                                color="primary"
                                variant="contained"
                                disabled={saving || savingCurrency}
                                type="submit">
                                {t('SaveChangesBtn')}
                            </Button>
                        </ButtonContainer>
                    }
                </Grid>
            </form>
        </Grid>
    );
};

const mapStateToProps = ({ auth, common }) => ({
    storeId: auth.storeId,
    countries: common.countries,
    storeData: common.storeData
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setStoreData
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneralPage);
