import styled from "@emotion/styled";
import { Grid } from "@material-ui/core";

export const InsightContainer = styled(Grid)`
  display: flex;
  gap: 15px;
  justify-content: center;
  .icon-insight {
    font-size: 30px;
    color: rgb(42, 112, 133);
  }
  .container-texts {
    p {
      margin: 0;
      font-weight: 600;
      color: rgb(42, 112, 133);
    }
  }
  @media screen and (max-width: 414px) {
    justify-content: flex-start;
  }
`;