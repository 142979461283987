import styled from '@emotion/styled';

import phoneBg from '../../../assets/img/phone.png';

export const AppBg = styled.div`
    background: url(${phoneBg}) no-repeat;
    background-size: cover;
    width: 400px;
    height: 400px;
    overflow: hidden;
    padding: 302px 29px 0 29px;
`;

export const AppCover = styled.div`
    width: 100%;
    min-height: 100px;
    margin-top: -188px;
    background: url( ${(props) => props.image });
    background-color: #CDCDCD;
    background-size: cover;
`;

export const StoreImage = styled.div`
    background: white;
    width: 55px;
    height: 55px;
    border-radius: 8px;
    background: url( ${(props) => props.image });
    background-color: white;
    background-size: cover;
`;

export const Whatsapp = styled.div`
    width: 111px;
    height: 20px;
    border-radius: 16px;
    font-size: 11px;
    color: #48AC98;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    img {
        margin-left: 6px;
        height: 12px;
    }
`;

export const AppContent = styled.div`
    background: #F5F5FA;
    padding: 0 12px;
    position: relative;
    top: -36px;
`;

export const WhiteContainer = styled.div`
    top: -45px;
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    position: relative;
    padding: 0 12px;
`;

export const Collections = styled.img`
    width: 100%;
    position: absolute;
    left: 2px;
    bottom: -82px;
    opacity: 0.5;
`;

export const StoreTitle = styled.div`
    display: flex;
    align-items: center;
    margin-top: 2px;
    justify-content: space-between;

    img {
        height: 21px;
        margin-top: 6px;
    }
`;
