import { axiosInstance } from './instance';

/**
 * Gets users
 */
const getUsers = () => {
    return axiosInstance.get(`/users`);
}

const getRoles = () => {
    return axiosInstance.get(`/roles`);
}


const saveUserRole = (value) => {
    return axiosInstance.post(`/users-role`, value);
}


const updateUserRole = (value) => {
    return axiosInstance.put(`/users-role${value.id}`, value);
}


export {
    getUsers,
    getRoles,
    saveUserRole,
    updateUserRole
}