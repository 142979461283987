
import styled from '@emotion/styled';

export const FooterAction = styled.div`
    background: #F8F9FB;
    border-radius: 0px 0px 8px 8px;
    padding: 8px 15px;
    p {
        color: #53627C;
    }
`;
