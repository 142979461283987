import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import { routes } from '../../../constants.js/misc';

//ICONS
import HomeIcon from '../../../assets/icons/home.svg';
import AddProductIcon from '../../../assets/icons/add-product.svg';
import OrdersIcon from '../../../assets/icons/orders.svg';



//Styles Components
const footerStyles = makeStyles({
    footer: {
        position: 'fixed',
        width: '100%',
        bottom: 0,
        height: 58,
        background: 'white'
    },
    container: {
        margin: '0 auto',
        marginTop: 5
    },
    icon: {
        display: 'block',
        margin: '0 auto',
        width: '100%',
        height: 21
    },
    text: {
        margin: '0 auto',
        marginTop: '5px',
        color: '#0f2930',
        "&:hover": {
            textDecoration: 'none !important'
        }
    },
    iconAdd: {
        height: '78px',
        marginTop: '-22px'
    }
})

export const FooterBar = () => {
    const classes = footerStyles();
    const { t } = useTranslation();

    return (
        <footer className={classes.footer}>
            <Grid item container xs={12}>
                <Grid item container xs={4} className={classes.container}>
                    <Link
                        className={classes.text}
                        component={RouterLink}
                        to={routes.homeUrl}>
                        <img src={HomeIcon} className={classes.icon}></img>
                        <Typography variant="body2" >
                            {t('MobileFooter.10x')}
                        </Typography>
                    </Link>
                </Grid>
                <Grid item container xs={4} className={classes.container}>
                    <Link
                        component={RouterLink}
                        to={routes.createProduct}>
                        <img src={AddProductIcon} className={classes.iconAdd}></img>
                    </Link>
                </Grid>
                <Grid item container xs={4} className={classes.container}>
                    <Link
                        className={classes.text}
                        component={RouterLink}
                        to={routes.ordersPage}>
                        <img src={OrdersIcon} className={classes.icon}></img>
                        <Typography variant="body2" >
                            {t('OrdersTitle')}
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </footer >

    );
}

export default connect()(FooterBar);