import React from 'react';
import Typography from '@material-ui/core/Typography';

import {
    AuthFormContainer,
    AuthFormWrapper,
    AuthFormMainContent,
    AuthFormSubtitle,
    AuthFormHeader,
} from './AuthForm.style';

import logo from '../../assets/img/10x_logo.svg';
import { Container, Grid, Link } from '@material-ui/core';
import AuthFooter from '../AuthFooter/AuthFooter';
import { FLAGS_LISTS } from '../../utils/app';

export const AuthForm = ({ children, img, title, subtitle, isLogin }) => (
  <>
    <AuthFormWrapper>
      {/* <AuthFormAuxContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}></Grid>
                    <Grid item xs={12} sm={6}>
                        <AuthFormAside style={{ backgroundimg: `url(${img}` }}>
                        </AuthFormAside>
                    </Grid>
                </Grid>
            </AuthFormAuxContent> */}
      <AuthFormMainContent>
        <Container mb={4}>
          <Grid container justify="center">
            <Grid item xs={12} sm={8}>
              <AuthFormHeader>
                {/* <Link href="https://get10x.co" color="inherit">
                  <img style={{ height: "48px" }} src={logo} alt="logo"></img>
                </Link>
                <div className="container-flags">
                  {FLAGS_LISTS.map((item, index) => {
                    return (
                      <img
                        key={index}
                        src={item.flag}
                        width={30}
                        height={30}
                        alt="flag country"
                      />
                    );
                  })}
                </div> */}
              </AuthFormHeader>
              <AuthFormContainer>
                <Typography
                  variant="h1"
                  align="center"
                  style={{ color: "#2A7085", fontWeight: "500" }}
                >
                  {title}
                </Typography>
                <AuthFormSubtitle variant="body1" align="center">
                  {subtitle}
                </AuthFormSubtitle>
                <div>{children}</div>
              </AuthFormContainer>
            </Grid>
          </Grid>
        </Container>
        <AuthFooter />
      </AuthFormMainContent>
    </AuthFormWrapper>
  </>
);