import styled from '@emotion/styled';

export const ContainerActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
export const ContainerSearch = styled.div`
    width: 59%;
`;
export const ContainerSort = styled.div`
    width: 35%;
`;
export const ContainerImg = styled.div`
    display: flex;
    align-items: center;
    margin: 40px 0 16px;
    flex-direction: column;
`;
export const ImgLoading = styled.img`
    height: 64px;
    margin: 0 0 10px;
`;