export const DOCUMENTS_TYPES = {
    "CC": {
        name: "Cédula",
        description: "Cédula de ciudadanía"
    },
    "TI": {
        name: "Tarjeta",
        description: "Tarjeta de identidad"
    },
    "Pasaporte": {
        name: "Pasaporte",
        description: "Pasaporte"
    }
}