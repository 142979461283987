// ReactJS
import React from "react";

// MUI Components
import { Checkbox, FormControlLabel, FormHelperText, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// Constants

const WhatsAppPayForm = ({ errors, register, isChecked }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography
                variant="body1"
                style={{ color: '#0F2930', fontSize: 16, marginTop: 32, marginBottom: 16 }}
            >
                {t(`Config.addPaymentProvider.whatsApp`)}
            </Typography>

            <FormControlLabel
                control={<Checkbox color="primary" defaultChecked={isChecked} />}
                label={t('Config.addPaymentProvider.whatsApp.Desc')}
                {...register('showMethod')}
            />

            <FormHelperText error={errors?.showMethod ? true : false}>{errors?.showMethod ? ''/* t('Config.domainInput.nameError') */ : errors?.showMethod?.message}</FormHelperText>
        </>
    );
}

export default WhatsAppPayForm;