import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';

import {
    ActionItem
} from '../SignUpForm/SignUpForm.style';

import { logInCode } from '../../modules/auth';


//MIXPANEL
import { Mixpanel } from '../../constants.js/mixpanel';

/**
 * Log in form component
 * @param {function} logIn log in function from the store
 * @param {boolean} isLoggingIn log in loader variable from the store
 * @param {function} handleLogin function to execute on login success
 */
const SignInCodeForm = ({ logInCode, isLoggingIn, onLogin }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, control, formState, errors } = useForm({
        mode: "onChange"
    });

    const handleLoginError = () => enqueueSnackbar(t('Code.error'), {
        variant: 'error',
        autoHideDuration: 2500
    });

    const onSubmit = (data) => {
        Mixpanel.track('Ingresar código de inicia sesión ', {
            "digitar codigo": data.code
        });
        logInCode(data.code, onLogin, handleLoginError)
    };

    return (
        <Card elevation={0}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    >
                        <FormLabel error={errors?.code ? true : false}>{t('Code.codeTitle')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    defaultValue=""
                                    error={errors.code ? true : false}
                                    placeholder={t('Code.codePlaceholder')}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            name="code"
                            rules={{ required: true }}
                        />
                        <FormHelperText error={errors?.code ? true : false}>{errors?.code ? t('Code.codeError') : ''}</FormHelperText>
                    </FormControl>
                </CardContent>
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    <Button
                        color="primary"
                        disabled={!formState.isValid || isLoggingIn}
                        type="submit"
                        variant="contained">
                        {t('LogIn')}
                    </Button>
                </CardActions>
            </form>
            <ActionItem>
                <CardActions style={{ padding: 0 }}>
                    <Typography variant="body1" gutterBottom>
                        {t('Code.credencialsTitle')}
                        <Link component={RouterLink} to="/">{t('Code.LoginEmailPw')}</Link>
                    </Typography>
                </CardActions>
            </ActionItem>
        </Card>
    );
};

const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn
});

const mapDispatchToProps = dispatch => bindActionCreators({ logInCode }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignInCodeForm);
