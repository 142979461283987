// ReactJS
import React from "react";

// MUI Components
import {
    Button,
    Card,
    Divider,
    Grid,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";

// Image [PNG]
import iconError from '../../../assets/img/errorIcon.png';

// Icon [SVG]
import iconCopy from '../../../assets/icons/copy.svg';
import { saveDomain, verifyDomain } from "../../../services/domain";
import { copyText, successToast } from "../../../constants.js/misc";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { getStoreService } from "../../../services/store";

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '1rem'
    },
});

const DomainContent = ({ storeId, nameDomain, setLoading, setStep, errorState, setStoreData }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const onHandleCopy = () => {
        copyText(errorState?.required_ip);
        enqueueSnackbar(t('Config.copyIP'), successToast);
    }

    const onHandleClick = () => {
        setLoading(true);
        verifyDomain(storeId, nameDomain)
            .then((info) => {
                saveDomain(storeId, { "domain": nameDomain })
                    .then((info) => {
                        setLoading(false);
                        /* Actualizar store data */
                        getStoreService(storeId)
                            .then(({ data }) => {
                                // Save store data in redux
                                setStoreData(data);
                            })
                            .catch((err) => {
                                /* console.log(err) */
                            });
                        setStep(3);
                    })
                    .catch((error) => {
                        /* console.log(error); */
                        setLoading(false);
                    });
            })
            .catch((error) => {
                setStep(2)
                /* console.log(error); */
                setLoading(false);
            });
    };

    return (
        <Grid container xs={12} md={10} xl={8} className={classes.header}>
            <Card elevation={1} style={{ width: '100%', marginBottom: '80px' }}>
                <Card elevation={0} style={{ margin: 27 }}>
                    <Typography
                        variant="body1"
                        style={{ color: '#53627C', fontSize: 24, marginBottom: 8 }}
                    >
                        Conexión de tu dominio
                    </Typography>
                    <Typography
                        variant="h5"
                        style={{ color: '#0F2930', fontSize: 18, marginBottom: 8 }}
                    >
                        El registro A de {nameDomain} no está configurado correctamente
                    </Typography>
                    <Typography
                        variant="body1"
                        style={{ color: '#0F2930', fontSize: 16, marginBottom: 8 }}
                    >
                        Revisa que los valores se hayan ingresado correctamente y haz click de nuevo en “Verificar conexión”.
                    </Typography>
                </Card>
                <Divider />
                <Card elevation={0} style={{ margin: 27 }}>
                    <Typography
                        variant="h5"
                        style={{ color: '#0F2930', fontSize: 18 }}
                    >
                        Registro A (@)
                    </Typography>
                    <Paper elevation={0} style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
                        <img src={iconError} alt="Error" style={{ width: 42, height: 42 }} />
                        <Paper elevation={0} style={{ marginLeft: 8 }}>
                            <Typography
                                variant="body1"
                                style={{ color: '#0F2930', fontSize: 16 }}
                            >
                                Dirección IP actual: <span style={{ fontWeight: 700, color: '#D49494' }}>{errorState?.incoming_ip}</span>
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{ color: '#0F2930', fontSize: 16, display: 'flex', flexDirection: 'row' }}
                            >
                                Valor obligatrorio:
                                <span style={{ fontWeight: 700, color: '#0F2930', marginLeft: 6 }}>{errorState?.required_ip}</span>
                                <span
                                    style={{ alignItems: 'center', display: 'flex', color: '#48AC98', marginLeft: 6, cursor: 'pointer' }}
                                    onClick={onHandleCopy}
                                >
                                    <img src={iconCopy} alt="Copy" style={{ width: 16, height: 16 }} />
                                    Copiar
                                </span>
                            </Typography>
                        </Paper>
                    </Paper>
                </Card>
                <Divider />
                <Card elevation={0} style={{ margin: 27, backgroundColor: '#FCF3EA', padding: 22 }}>
                    <Typography
                        variant="h5"
                        style={{ color: '#0F2930', fontSize: 18, marginBottom: 8 }}
                    >
                        ¡Importante!
                    </Typography>
                    <Typography
                        variant="body1"
                        style={{ color: '#0F2930', fontSize: 16 }}
                    >
                        Algunos proveedores de dominios tardan entre 10 minutos hasta 48 horas en actualizar la configuración.
                        Si ya agregaste el registro A intenta verificar el dominio más tarde o usa otro dominio.
                    </Typography>
                    <Button
                        style={{ backgroundColor: '#FFFFFF', color: '#53627C', marginTop: 16 }}
                        variant="contained"
                        type="button"
                        onClick={() => setStep(0)}
                    >
                        Usar otro dominio
                    </Button>
                </Card>
                <Card
                    elevation={0}
                    style={{
                        padding: '16px 27px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: '#F8F9FB',
                        borderRadius: '0px 0px 8px 8px',
                    }}
                >
                    <Typography
                        variant="body1"
                        style={{ color: '#0F2930', fontSize: 16 }}
                    >
                        Verifica la conexión para asegurarte de que tu dominio se haya configurado correctamente.
                    </Typography>
                    <Button
                        className="btnLink"
                        color="primary"
                        variant="contained"
                        type="submit"
                        onClick={onHandleClick}
                    >
                        Verificar conexión
                    </Button>
                </Card>
            </Card>
        </Grid >
    );
}

export default DomainContent;
