// ReactJS
import React from "react";

// Icons
import iconCheckBox from '../../../../../assets/icons/iconCheckBox.svg';
import iconDelete from '../../../../../assets/icons/iconDelete.svg';
import iconRadioButton from '../../../../../assets/icons/iconRadioButton.svg';

// MUI Components
import {
    Grid,
    TextField,
    makeStyles,
} from "@material-ui/core";
import { sortedIndexOf } from "lodash";

const useStyles = makeStyles({
    wrapperSelects: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: '20px 0 0 0',
    }
});

const DynamicInput = ({ field, register, watch, index, handleUpdate, inputType }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            {field?.options?.map((option, newIndex) => (
                <React.Fragment key={newIndex}>
                    <Grid item className={classes.wrapperSelects}>
                        {inputType === 'radio' && <img src={iconRadioButton} alt="" style={{ marginRight: 8, width: 24, height: 24 }} />}
                        {inputType === 'checkbox' && <img src={iconCheckBox} alt="" style={{ marginRight: 8, width: 24, height: 24 }} />}
                        {inputType === 'select' &&
                            <p style={{ marginRight: 6 }}>
                                {newIndex + 1}.
                            </p>
                        }
                        <TextField
                            autoFocus
                            {...register(`dynamicInputs.${index}.options.${newIndex}`)}
                            defaultValue={option}
                            placeholder="Añadir opción"
                            id="standard-basic"
                            variant="standard"
                            style={{ width: '43%' }}
                        />
                        <img
                            src={iconDelete}
                            alt=""
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleUpdate({ data: watch(`dynamicInputs.${index}`), index: index, isRemove: true, text: option })}
                        />
                    </Grid>
                </React.Fragment>
            ))}
            <Grid item className={classes.wrapperSelects}>
                {inputType === 'radio' && <img src={iconRadioButton} alt="" style={{ marginRight: 8, width: 24, height: 24 }} />}
                {inputType === 'checkbox' && <img src={iconCheckBox} alt="" style={{ marginRight: 8, width: 24, height: 24 }} />}
                {inputType === 'select' &&
                    <p style={{ marginRight: 6 }}>
                        {field?.options === null ? 1 : field?.options?.length + 1.}
                    </p>
                }
                <TextField
                    onClick={() => handleUpdate({ data: watch(`dynamicInputs.${index}`), index: index, isRemove: false })}
                    placeholder="Añadir opción"
                    id="standard-basic"
                    variant="standard"
                    style={{ width: '43%' }}
                />
            </Grid>
        </React.Fragment>
    );
}

export default DynamicInput;