// ReactJS
import React from "react";

// MUI Components
import {
    Button,
    Card,
    Divider,
    Grid,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";

// Image [PNG]
import iconError from '../../../assets/img/errorIcon.png';

// Icon [SVG]
import iconPencil from '../../../assets/icons/pencil.svg';
import { useTranslation } from "react-i18next";
import { saveDomain, verifyDomain } from "../../../services/domain";
import { getStoreService } from "../../../services/store";

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '1rem'
    },
});

const ConfiguredDomain = ({ storeId, nameDomain, setLoading, setStep, setErrorState, setStoreData }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const onHandleClick = () => {
        setLoading(true);
        verifyDomain(storeId, nameDomain)
            .then((info) => {
                saveDomain(storeId, { "domain": nameDomain })
                    .then((info) => {
                        setLoading(false);
                        /* Actualizar store data */
                        getStoreService(storeId)
                            .then(({ data }) => {
                                // Save store data in redux
                                setStoreData(data);
                            })
                            .catch((err) => {
                                /* console.log(err) */
                            });
                        setStep(3)
                    })
                    .catch((error) => {
                        /* console.log(error); */
                        setLoading(false);
                    });
            })
            .catch((error) => {
                setStep(2)
                /* console.log(error); */
                setErrorState(error?.response?.data)
                setLoading(false);
            });
    }

    const instructions = () => window.open('https://drive.google.com/file/d/1lYKQCzoaU_I2vr6qNWNumWhOXMB0T435/view')

    return (
        <Grid container xs={12} md={10} xl={8} className={classes.header}>

            <Card elevation={1} style={{ width: '100%', marginBottom: 32 }}>
                <Card elevation={0} style={{ margin: 27 }}>
                    <Typography
                        variant="body1"
                        style={{ color: '#53627C', fontSize: 24, marginBottom: 8 }}
                    >
                        {t('Config.domainInputTitle')}
                    </Typography>
                    <Typography
                        variant="body1"
                        style={{ fontWeight: 700, color: '#0F2930', fontSize: 18, display: 'flex', flexDirection: 'row' }}
                    >
                        {nameDomain}
                        <span style={{ alignItems: 'center', display: 'flex', color: '#48AC98', marginLeft: 6, cursor: 'pointer' }} onClick={() => setStep(0)}>
                            <img src={iconPencil} alt="Pencil" style={{ width: 16, height: 16 }} />
                            editar
                        </span>
                    </Typography>
                </Card>
            </Card>

            <Card elevation={1} style={{ width: '100%', marginBottom: '80px' }}>
                <Card elevation={0} style={{ margin: 27 }}>
                    <Typography
                        variant="body1"
                        style={{ color: '#53627C', fontSize: 24, marginBottom: 8 }}
                    >
                        Conexión de tu dominio
                    </Typography>
                    <Typography
                        variant="body1"
                        style={{ color: '#0F2930', fontSize: 16, marginBottom: 8 }}
                    >
                        Para conectar tu dominio, sigue las instrucciones para cambiar la configuración de tu cuenta de proveedor y haz clic en “Verificar conexión”.
                    </Typography>
                    <Button
                        variant="contained"
                        type="button"
                        onClick={instructions}
                    >
                        Seguir las instrucciones de configuración
                    </Button>
                </Card>
                <Card
                    elevation={0}
                    style={{
                        padding: '16px 27px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: '#F8F9FB',
                        borderRadius: '0px 0px 8px 8px',
                    }}
                >
                    <Typography
                        variant="body1"
                        style={{ color: '#0F2930', fontSize: 16 }}
                    >
                        Verifica la conexión para asegurarte de que tu dominio se haya configurado correctamente.
                    </Typography>
                    <Button
                        className="btnLink"
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={onHandleClick}
                    >
                        Verificar conexión
                    </Button>
                </Card>
            </Card>
        </Grid>
    );
}

export default ConfiguredDomain;