import React, { useEffect} from "react"
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/img/10x_logo.svg'
import { 
    ImageContainer,
    SubTitle,
    TextContainer,
    ThankUMain,
    Title,
} from "./styles.thankUPage"

import { Button } from "@material-ui/core"
 
import ReactPixel from 'react-facebook-pixel'

const ThankUPage = () => {
    const { t } = useTranslation();
    const history = useHistory()

    const goToInit = () => {
        history.push('/')
        localStorage.removeItem( 'campaing' )
    }

    useEffect( ()=> {
        ReactPixel.track('Purchase', {
            content_name: 'Compra completa',
        })
    }, [] )
    return(
        <ThankUMain>
            <ImageContainer>
                <img src={logo} alt="10x logo" style={{ width: '100%', height: '100%' }}/>
            </ImageContainer>
            <TextContainer>
                <Title>{t('thankU.title')}</Title>
                <SubTitle>{t('thankU.subTitle')}</SubTitle>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={goToInit}
                >
                    Ir al inicio
                </Button>
            </TextContainer>
        </ThankUMain>
    )
}

export default ThankUPage