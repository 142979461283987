import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ChipInput from 'material-ui-chip-input';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { CardHeader } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from '@material-ui/core/Tooltip'

import { withStyles } from "@material-ui/core/styles"

import { ButtonOffSet, InfoContainer, OffSetContainer, PopUpButtons, PopUpContent, SaveOptContainer, Variants } from './ProductVariants.styles';
import { cartesian, errorToast } from '../../../constants.js/misc';

import CurrencyField from '../../CurrencyField/CurrencyField';

import iconError from '../../../assets/img/errorIcon.png';
import { IconInfo } from '../../../assets/icons/FuncInfoIcon'
import { IconDelete } from '../../../assets/icons/funcDeleteIcons'

const TextOnlyTooltip = withStyles({
    tooltip: {
        fontSize: 15
    }
})(Tooltip)

const ProductVariants = ({
    isTabletOrMobile,
    productData,
    getValues,
    options,
    setOptions,
    variants,
    setVariants,
    hasVariants,
    setHasVariants
}) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [optionsChanged, setOptionsChanged] = useState(false);
    const [moreVariants, setMoreVariants] = useState(false);
    const [currentPage, setCurrentPage] = useState(0)
    const [page, setPage] = useState(1)
    const [searchValue, setSearchValue] = useState('')
    const [totalPages, setTotalPages] = useState(0)
     const [optCopy, setOptCopy] = useState([])
    const [addMoreVariantsBtn, setAddMoreVariantsBtn] = useState(false)
    const [changeOpt, setChangeOpt] = useState(false)
    const [open, setOpen] = useState(false)
    const [indexToDelete, setIndexToDelete] = useState(null)

    const appliedFilter =  variants.filter( v => v.sku.includes( searchValue.toLocaleUpperCase()))
    const paginationQuantity =  20

    const filteredVariants = () => {
        if (searchValue.length === 0) {
            variants.slice(currentPage, currentPage + paginationQuantity)
        }
        return appliedFilter.slice(currentPage, currentPage + paginationQuantity)
    } 

    const serchedVariants = (e) => {
        setCurrentPage(0)
        setPage(1)
        setSearchValue(e.target.value)
    }

    const nextPage = () => {
        setCurrentPage(currentPage + paginationQuantity)
        setPage(page + 1)
    }
    const prevPage = () => {
        setCurrentPage(currentPage - paginationQuantity)
        setPage(page - 1)
    }
    
   const handleMoreVariants = ({ target }) => setMoreVariants(target.checked)

    const handleCheckboxChange = ({ target }) => setHasVariants(target.checked);

    const addNewOption = () => {
        setOptions([...options, {
            name: '', options: []
        }])
        setOptCopy([...options, {
            name: '', options: []
        }])
        setOptionsChanged(true)
        setAddMoreVariantsBtn(false)
    }

    const deleteOption = (index) => {
        setOptions(options.filter((item, optionIndex) => optionIndex !== index));
        setOptionsChanged(true);
    }

    const handleOptionNameChange = ({ target }) => {
    //     const namesArr = optCopy.map( n => n.name )
    //     const validateName = namesArr.some( n => n.toLowerCase() === target.value.toLowerCase() )
    //     if(!validateName) {
    //         setOptCopy(optCopy.map((option, index) => ({
    //             ...option,
    //             name: index === +target.dataset.index ? target.value : option.name
    //         })));
    //         setOptionsChanged(false);
    //         setChangeOpt(true)
    //     }
        setOptCopy(optCopy.map((option, index) => ({
                ...option,
                name: index === +target.dataset.index ? target.value : option.name
            })));
            setOptionsChanged(false);
            setChangeOpt(true)
     }
    const deleteOptionItem = (chip, deleteIndex, optionIndex) => {
        const newValue = optCopy[optionIndex].options.filter((item, itemIndex) => itemIndex !== deleteIndex);
        setOptCopy(optCopy.map((option, index) => ({
            ...option,
            options: index === optionIndex ? newValue : option.options,
        })));
        setOptions(optCopy.map((option, index) => ({
            ...option,
            options: index === optionIndex ? newValue : option.options,
        })));
        setOptionsChanged(true);
        return newValue;
    }

    const addOptionItem = (optionIndex, chip) => {
        const hasChip = optCopy[optionIndex].options.filter(opt => opt.toLowerCase() === chip.toLowerCase());
        if (hasChip.length) return false;

        if (chip) {
            const newValue = [...optCopy[optionIndex].options, chip];
            setOptCopy(optCopy.map((option, index) => ({
                ...option,
                options: index === optionIndex ? newValue : option.options,
            })));
            setOptionsChanged(true);
            setChangeOpt(true)
        }
        return true;
    }
    const saveOptions = () => {
        const namesArr = optCopy.map( n => n.name )
        const arraySet = new Set(namesArr)

        if (arraySet.size < namesArr.length) {
            enqueueSnackbar(t('Product.variantsNameError'), errorToast)
            setAddMoreVariantsBtn(false)
        }else{
            setOptions(optCopy)
            setChangeOpt(false)
            setAddMoreVariantsBtn(true)
        }
    }
    /**
     * Creates the variants array
     */
    const setCurrentVariants = () => {
        if (options.length) {
            const currentOptions = options.reduce((acc, current, currentIndex) => {
                const currentOption = current.options
                return currentOption.length ? [...acc, current.options] : [...acc]
            }, []);
            if (currentOptions.length) {
                setVariants([...cartesian(...currentOptions)].map(iterableVariants => {
                    const variantKey = iterableVariants.reduce((acc, current) => `${acc}${current} `, '').trim();
                    const existingVariant = variants.find(variant => variant.key === variantKey);
                    return existingVariant ? existingVariant : {
                        key: variantKey,
                        options: iterableVariants,
                        price: productData.price || 0,
                        quantity: null,
                        discount_price: productData.discount_price || 0,
                        sku: `${productData.sku ? productData.sku + '-' : getValues("sku") ? getValues("sku").toUpperCase() + '-' : ''}${variantKey.replace(/ /g, '').toUpperCase()}`
                    }
                }));
            } else {
                setVariants([]);
            }
        } else {
            setVariants([]);
        }
    }

    const setVariantData = (field, key, value) => {

        setVariants(variants.map(variant => ({
            ...variant,
            price: variant.key === key && field === 'price' ? value ? Number(value) : null : variant.price,
            discount_price: variant.key === key && field === 'discount_price' ? value ? Number(value) : null : variant.discount_price,
            quantity: variant.key === key && field === 'quantity' ? value ? Number(value) : null : variant.quantity,
            sku: variant.key === key && field === 'sku' ? value : variant.sku
        })));
        setOptionsChanged(false);
    }

    useEffect(() => {
        // Set initial priduc data
        if (productData.product_options) {
            if (productData.product_options.length) {
                setHasVariants(true);
                setOptions(productData.product_options.map(option => ({
                    name: option.name,
                    options: option.options.map(opt => opt.name) // TODO, fix options array here
                })));
                setAddMoreVariantsBtn(true)
            }
        }
        if (productData.product_variants) {
            if (productData.product_variants.length) {
                setVariants(productData.product_variants.map(variant => ({
                    key: variant.options.reduce((acc, current) => `${acc}${current} `, '').trim(),
                    name: variant.name,
                    options: variant.options,
                    quantity: variant.quantity,
                    price: variant.price,
                    discount_price: variant.discount_price,
                    sku: variant.sku
                })));
            }
        }
    }, [productData]);

    useEffect(() => {
        if (optionsChanged) setCurrentVariants();
    }, [options, optionsChanged]);
    
    useEffect(() => {
        if (options.length) {
            setOptCopy(options)
        } 
        if (options.length === 0) {
            setAddMoreVariantsBtn(true)
        }
        if (options.length >= 3) {
            setMoreVariants(true)
        }
    }, [options])
    
    useEffect( ()=> {
        if(searchValue.length > 0){
            setTotalPages(Math.ceil(appliedFilter.length / paginationQuantity))
        }else{
            setTotalPages(Math.ceil(variants.length / paginationQuantity))
        }
    },[variants, searchValue] )
    
    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('Product.variants')} />
            <CardContent>
                <Grid container spacing={isTabletOrMobile ? 0 : 3}>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={hasVariants} onChange={handleCheckboxChange} name="hasVariants" />}
                            label={t('Product.variants.checkbox')} />
                    </Grid>

                    {hasVariants &&
                        <Variants>
                            <Divider />
                            <Typography style={{ marginTop: '16px' }} variant="h5">
                                {t('Product.variants.options')}
                            </Typography>
                            <Grid spacing={3} container alignItems="flex-end">
                                {options.map((option, index) => (
                                    <React.Fragment key={index}>
                                        <Grid xs={12} sm={6} item >
                                            <FormControl>
                                                <FormLabel>{t('Product.variants.optionName')}</FormLabel>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={changeOpt ? optCopy[index].name : option.name}
                                                    placeholder={index === 0 ? t('Product.variants.optionNameOnePlaceholder') : index === 1 ? t('Product.variants.optionNameTwoPlaceholder') : ''}
                                                    inputProps={{
                                                        'data-index': index
                                                    }}
                                                    onChange={handleOptionNameChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={12} sm={6} item>
                                            <FormControl>
                                                <FormLabel>{t('Product.variants.options')}</FormLabel>
                                                <ChipInput
                                                    value={changeOpt ? optCopy[index].options : option.options}
                                                    placeholder={t('Product.variants.placeHolder')}
                                                    variant="outlined"
                                                    margin="normal"
                                                    onBeforeAdd={chip => addOptionItem(index, chip)}
                                                    onChange={chips => chips[0]}
                                                    newChipKeys={[',', 'Enter', 'Tab']}
                                                    onDelete={(chip, deleteIndex) => deleteOptionItem(chip, deleteIndex, index)}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid xs item>
                                            { options.length > 1 &&
                                                <IconDelete
                                                    onClick={() => {
                                                        setOpen(true) 
                                                        setIndexToDelete(index)
                                                    }}    
                                                    style={{ width: '25px', height: '25px', fill:'#3a8a7a', cursor: 'pointer' }}
                                                />
                                            }
                                        </Grid>
                                    </React.Fragment>
                                ))}
                                <SaveOptContainer>
                                    <Button 
                                        variant="contained"
                                        color="primary" 
                                        onClick={saveOptions}
                                        style={{ margin: '5px 0 16px' }}
                                        disabled={!changeOpt} 
                                        >
                                        {t('Product.variants.optionsAddBtn')}
                                    </Button>
                                    <Dialog open={open}>
                                        <DialogContent >
                                                <PopUpContent>
                                                    <img src={iconError} alt="Error" style={{ width: 42, height: 42 }} />
                                                    <Typography style={{ width: 300}}>{t('Product.variants.optionsPopUp')}</Typography>
                                                </PopUpContent>
                                                <Divider style={{ marginBottom: '14px'}}/> 
                                                <PopUpButtons>
                                                    <Button
                                                        onClick={()=> setOpen(false)}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                       {t('Product.variants.optionsPopUpSave')}
                                                    </Button>
                                                    <Button
                                                        style={{ backgroundColor: '#d32f2f', color: 'white' }}
                                                        variant="contained"
                                                        onClick={() => { 
                                                            deleteOption(indexToDelete) 
                                                            setOpen(false) } }
                                                    >
                                                        {t('Product.variants.optionsPopUpDelete')}
                                                    </Button>
                                                </PopUpButtons>
                                        </DialogContent>                 
                                    </Dialog>
                                    <Divider />           
                                </SaveOptContainer> 
                                                
                                {(options.length < 2 && !moreVariants) &&
                                    <Grid item xs={12}>
                                        <Button
                                            style={{ marginTop: '16px' }}
                                            onClick={addNewOption}
                                            variant="contained"
                                            color="secondary"
                                            disabled={!addMoreVariantsBtn}    
                                        >
                                            {t('Product.variants.newOption')}
                                        </Button>
                                    </Grid>
                                }
                                {moreVariants &&
                                    <Grid item xs={12}>
                                        <Button
                                            style={{ marginTop: '16px' }}
                                            onClick={addNewOption}
                                            variant="contained"
                                            color="secondary"
                                            size='medium'
                                            disabled={!addMoreVariantsBtn}     
                                        >
                                            {t('Product.variants.newOption')}
                                        </Button>
                                    </Grid>
                                } 
                                {(options.length === 2) &&
                                    <Grid item xs={12}>
                                        <h3><b>{t('Product.variants.moreOptionsTitle')}</b></h3>
                                        <FormControlLabel
                                            control={<Checkbox checked={moreVariants} onChange={handleMoreVariants} name="moreVariants" />}
                                            label={t('Product.variants.moreOptionsCheckBox')} />
                                        <Grid>
                                            <FormLabel>{t('Product.variants.moreOptionsDescription')}</FormLabel>
                                        </Grid>
                                    </Grid>
                                }               
                            </Grid>
                            <Divider style={{ marginTop: '20px' }} />
                            {variants.length > 0 && <Grid spacing={3} container alignItems="flex-end">
                                <Grid item xs={12}>
                                    <Typography style={{ marginTop: '16px' }} variant="h6">
                                        {t('Product.variants.title')}
                                    </Typography>
                                    <TextField
                                        style={{ marginTop: '16px' }}
                                        placeholder={t('Product.variantsPlaceholder')}
                                        value={searchValue}
                                        onChange={serchedVariants}
                                        variant="outlined"
                                        margin="normal" 
                                    />
                                </Grid>
                                {filteredVariants().map((variant, index) =>
                                    <React.Fragment key={variant.key}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" style={{ textTransform: 'capitalize', fontWeight: 600 }}>
                                                {variant.key}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <CurrencyField
                                                label={t('Product.variants.price')}
                                                defaultValue={variant.price}
                                                setValue={value => setVariantData('price', variant.key, value)} />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <CurrencyField
                                                label={t('Product.discountPrice')}
                                                defaultValue={variant.discount_price}
                                                setValue={value => setVariantData('discount_price', variant.key, value)} />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('Product.variants.sku')}</FormLabel>
                                                <TextField
                                                    value={variant.sku}
                                                    onChange={({ target }) => setVariantData('sku', variant.key, target.value)}
                                                    variant="outlined"
                                                    margin="normal" />
                                            </FormControl>
                                        </Grid>
                                        <TextOnlyTooltip
                                            arrow
                                            title={t('Product.variants.quantityTooltip')}
                                            placement="right-end"
                                        >
                                            <Grid item xs={6} sm={3} style={{ padding: '12px 0 12px 12px' }}>
                                                <FormControl>
                                                    <FormLabel>{t('Product.variants.quantity')}</FormLabel>
                                                    <InfoContainer>
                                                        <TextField
                                                            value={variant.quantity}
                                                            onChange={({ target }) => setVariantData('quantity', variant.key, target.value)}
                                                            variant="outlined"
                                                            margin="normal"
                                                            type='number'
                                                            inputProps={{
                                                                min: 0
                                                            }}
                                                            defaultValue={null}
                                                        />
                                                        <IconInfo style={{ width: '30px', fill: '#3a8a7a' }}/>
                                                    </InfoContainer>
                                                </FormControl>
                                            </Grid>
                                        </TextOnlyTooltip>
                                    </ React.Fragment>
                                )}
                                <OffSetContainer>
                                    <ButtonOffSet 
                                        variant="outlined"
                                        color="primary"
                                        disabled={page === 1}
                                        onClick={prevPage}
                                    >
                                        {t('Product.variantsButtonPrev')}
                                    </ButtonOffSet>
                                    <Typography style={{ fontWeight:'600' }}>{t('Product.variantsPage')} {page} {t('Product.variantsPageOf')} {totalPages}</Typography>                              
                                    <ButtonOffSet
                                        variant="outlined"
                                        color="primary"
                                        disabled={page === totalPages} 
                                        onClick={nextPage}
                                    >
                                        {t('Product.variantsButtonNext')}
                                    </ButtonOffSet>
                                </OffSetContainer>
                            </Grid>}
                        </Variants>
                    }

                </Grid>

            </CardContent>
        </Card>
    );
};
export default connect()(ProductVariants);