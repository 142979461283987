// ReactJS
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

// Services
import { getDynamicForm } from "../../services/form";

// Components
import DynamicForm from "../../components/DynamicForm";
import Loader from "../../components/Loader";

const FormPage = ({ storeId }) => {
    const [data, setData] = useState({});
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        getDynamicForm(storeId)
            .then((info) => {
                const { data } = info;

                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setData({});
                setLoading(false);
            });
    }, [storeId]);

    return (
        !isLoading ? <DynamicForm data={data} /> : <Loader />
    );
}

export default connect(({ auth }) => ({
    storeId: auth.storeId
}))(FormPage);
