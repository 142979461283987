// react dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

// external dependencies
import { useSnackbar } from 'notistack';

// material ui dependencies
import { Card, CardContent, CardHeader, Box, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

// Internal components
import BillingDetailsModal from '../BillingDetailsModal/BillingDetailsModal';
import { getBillingInfoService } from '../../../services/billing';
import { successToast } from '../../../constants.js/misc';

// styles
const useStyles = makeStyles({
    noData: {
        backgroundColor: '#F8F9FB',
        padding: '47px 0',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '16px',
        borderRadius: '8px',
        margin: '0 16px 16px 16px',
    },
    noDataText: {
        texAlign: 'center',
        marginTop: '10px'
    },
    cardInfo: {
        display: 'flex',
        alignItems: 'center'
    },
    body: {
        border: 'solid #e0e0e0 1px'
    },
    updateButton: {
        display: 'flex',
        border: 'none'
    }
});

const BilligDetails = ({ storeId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

    const [dialogOpen, setDialogOpen] = useState(false);
    const [billingData, setBillingData] = useState(false);
    const [gettingData, setGettingData] = useState(true);

    const handleDialogClose = () => setDialogOpen(false);

    const openModal = () => setDialogOpen(true);

    const getBillingData = () => {
        getBillingInfoService(storeId)
            .then(res => {
                setBillingData(res.data);
                setGettingData(false);
            })
            .catch(err => setGettingData(false));
    }

    const handleDataUpdate = () => {
        handleDialogClose();
        enqueueSnackbar(t('BilligDetailsModal.success'), successToast);
        getBillingData();
    }

    useEffect(getBillingData, []);

    return (
        <>
        <Card container spacing={3} style={{ width: '100%' }}>
            <CardHeader
                titleTypographyProps={{ variant: 'h6' }}
                title={t('BilligDetails.title')} />
            {gettingData && <LinearProgress />}
            {!gettingData &&
                <CardContent style={{ padding: '16px 0 0 0' }}>
                    {billingData
                        ?
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableBody className={classes.body}>
                                    <TableRow style={{ display: isTabletOrMobile ? 'grid' : 'table-row' }}>
                                        <TableCell>
                                            <Typography variant="body2">{t('BilligDetails.data.name')}: <b>{billingData.contact_name}</b></Typography>
                                            <Typography variant="body2">{t('BilligDetails.data.lastName')}: <b>{billingData.contact_last_name}</b></Typography>
                                            <Typography variant="body2">{t('BilligDetails.data.idType')}: <b>{billingData.document_type.name}</b></Typography>
                                            <Typography variant="body2">{t('BilligDetails.data.idNumber')}: <b>{billingData.dni}</b></Typography>
                                            <Typography variant="body2">{t('BilligDetails.data.state')}: <b>{billingData.city.state}</b></Typography>
                                            <Typography variant="body2">{t('BilligDetails.data.city')}: <b>{billingData.city.name}</b></Typography>
                                            <Typography variant="body2">{t('BilligDetails.data.email')}: <b>{billingData.emails}</b></Typography>
                                        </TableCell>
                                        <TableCell align="right" className={classes.updateButton}>
                                            <Button style={{ width: '100%' }} onClick={openModal} variant="contained">{t('BilligDetails.edit')}</Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <Box
                            className={classes.noData}
                            container
                            spacing={2}
                            justifyContent="center"
                            display="flex">
                            <Typography className={classes.noDataText}>
                                {t('BilligDetails.noData')}
                            </Typography>
                            <Link onClick={openModal}>
                                {t('BilligDetails.addData')}
                            </Link>
                        </Box>
                    }
                </CardContent>
            }
        </Card>
        <BillingDetailsModal
            billingData={billingData}
            open={dialogOpen}
            isNoClose={false}
            handleClose={handleDialogClose}
            handleSuccess={handleDataUpdate} />
        </>
    );
};

const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(BilligDetails);
