import styled from '@emotion/styled';

export const ContentUpload = styled.div`
    background: #F8F9FB;
    outline: 0;
    height: 300px;
    border: 2px dashed #98A1B1;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0 auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`;


export const CardAction = styled.div`
    background: #F8F9FB;
    border-radius: 0px 0px 8px 8px;
    padding: 15px;
`;
