import styled from '@emotion/styled';


export const DnDWrapper = styled.div`
  width: 100%;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
`;

export const BtnRemoveImage = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #CB7C7A;
  margin-top: 13px;
  cursor: pointer;
  display: block;
`;


export const PreviewImg = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 3rem;
`;

export const SelectedImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover + div {
    display: flex;
  }
`;



export const RemoveImg = styled.div`
  position: absolute;
  width: 100%;
  height: 180px;
  background: rgba(0, 0, 0, 0.5);

  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  border-radius: 5px;
  display: none;
  transition: all 0.3s ease;
  :hover {
    display: flex;
  }
`;

export const RemoveImgWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
