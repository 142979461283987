export const statusOrders = {
    1: "Pendiente",
    2: "En espera",
    3: "Cancelación/Fallido",
    4: "Pagado",
    5: "Devolución",
}

export const statusShipping = {
    7: "Preparado",
    6: "No preparado",
    8: "Parcialmente preparado",
    9: "Programado",
    10: "Despachado",
}

export const parseDateToEdit = (date, withoutT) => {
    const creationDate = new Date(date);

    const yearToEdit = creationDate.getFullYear();
    const monthToEdit = String(creationDate.getMonth() + 1).padStart(2, '0');
    const dayToEdit = String(creationDate.getDate()).padStart(2, '0');
    const joinedDate = [yearToEdit, monthToEdit, dayToEdit].join('-');

    let hourToEdit = creationDate.getHours();
    let minutesToEdit = creationDate.getMinutes();
    let secondsToEdit = creationDate.getSeconds();

    hourToEdit = ("0" + hourToEdit).slice(-2);
    minutesToEdit = ("0" + minutesToEdit).slice(-2);
    secondsToEdit = ("0" + secondsToEdit).slice(-2);

    const joinedTime = [hourToEdit, minutesToEdit, secondsToEdit].join(':');

    return (withoutT ? `${joinedDate} ${joinedTime}` : `${joinedDate}T${joinedTime}`)
}

// transform base64 to blob
export function b64toBlob(base64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(base64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
}