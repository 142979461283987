import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Chip, Typography } from '@material-ui/core'

//internal depedencies
import { formatNumber } from '../../../constants.js/misc';
import { ProductClassStyle } from './ProductMobile.styles';

import ProductDefault from '../../../assets/img/10x-product.jpg';


const ProductMobile = ({ url, alt, thumbnail, productName, productPrice, productDiscountPrice, currency }) => {
    const productThumbnail = thumbnail ? `${process.env.REACT_APP_IMAGES_API_URL}${thumbnail}` : ProductDefault;

    const ProductItemStyles = ProductClassStyle();

    return (
        <Link
            component={RouterLink}
            to={url}
            color="primary"
            className={ProductItemStyles.item}
        >
            <div className={ProductItemStyles.card}>
                <figure className={ProductItemStyles.image}>
                    <img
                        className={ProductItemStyles.imgProduct}
                        src={`${thumbnail ? productThumbnail + '?w=175&auto=format&f=pjpg' : productThumbnail}`}
                        alt={alt}
                    />
                    {productDiscountPrice ? (
                        <Chip
                            className={ProductItemStyles.discountChip}
                            label="¡PROMO!"
                        />
                    ) : null}
                </figure>
                <Typography
                    className={ProductItemStyles.caption}
                    display="block"
                    variant="overline"
                >
                    {productName}
                </Typography>
                {productPrice > 0 &&
                    <div className={ProductItemStyles.priceContainer}>
                        {productDiscountPrice ? (
                            <Typography
                                className={ProductItemStyles.discountPrice}
                                variant="caption"
                            >
                                {formatNumber(productDiscountPrice, currency)}
                            </Typography>
                        ) : null}
                        <Typography
                            className={productDiscountPrice ? ProductItemStyles.withDiscount: ProductItemStyles.price}
                            variant="caption"
                        >
                            {formatNumber(productPrice, currency)}
                        </Typography>
                    </div>
                }
            </div>
        </Link>
    )
}

export default ProductMobile;
