// ReactJS
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

// Components
import SubDomains from "../../components/SubDomains";

// Modules
import { setStoreData } from '../../modules/common';

// Services
import { getStoreService } from "../../services/store";

const DomainPage = ({ storeId, storeData, setStoreData }) => {
    const [data, setData] = useState({});

    useEffect(() => {
        setData(storeId);
        getStoreService(storeId)
            .then(({ data }) => {
                // Save store data in redux
                setStoreData(data);
            })
            .catch(err =>
                console.log(err)
            );
    }, [storeId]);

    return (
        <SubDomains storeId={data} storeData={storeData} setStoreData={setStoreData} />
    );
}

const mapStateToProps = ({ auth, common }) => ({
    storeId: auth.storeId,
    storeData: common.storeData
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setStoreData
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DomainPage);
