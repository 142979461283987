// react dependencies
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'


// external dependencies
// import { useSnackbar } from 'notistack'
// import ReactGA from "react-ga4"
import ReactPixel from 'react-facebook-pixel'

// material dependencies
import LinearProgress from '@material-ui/core/LinearProgress'

// Internal dependencies
import { AppContainer, AuthContainer, CleanContainer } from "./containers";
import { logOut, logInDirectly } from './modules/auth'
import { routes } from './constants.js/misc'
import { setCountries } from './modules/common'
import { statusService } from './services/auth'

const App = ({ isUserAuthenticated, logInDirectly, logOut, setCountries, countries, storeData, cleanContainer }) => {
  const history = useHistory()
  
  // const { enqueueSnackbar } = useSnackbar()

  history.listen(() => {
    if (process.env.NODE_ENV === 'production') {
      // ReactGA.initialize("G-2XDZE0SLMV")
      // ReactGA.send({ hitType: "pageview", page:(window.location.pathname + window.location.search) })
  
      ReactPixel.init('678045126245695')
      ReactPixel.pageView(window.location.pathname + window.location.search)
    }
  })

  // state
  const [loading, setLoading] = useState(true)
  // const [gettingCountries, setGettingCountries] = useState(false)

  // Sirve para renderizar una pagina que esta logueada y no tiene sidebar ni header
  // const validateRenderContainer = () => {
  //   return cleanContainer ? CleanContainer : AppContainer;
  // }

  // vars
  const isMobile = window.screen.width <= 414 ? true : false
  const ComponentToRender = isUserAuthenticated ? AppContainer : AuthContainer;

  // Effects
  useEffect(() => {
    statusService()
      .then(() => {
        logInDirectly()
        setLoading(false)
      })
      .catch(() => {
        logOut()
        setLoading(false)
        const redirectToHomeRoutes = [routes.signUpUrl, routes.signUpUserInfoUrl, routes.signUpVerifyUrl, routes.recoveryPassword, routes.resetPassword]
        if (!isMobile) {
          redirectToHomeRoutes.push(routes.code)
        }
        if (redirectToHomeRoutes.indexOf(history.location.pathname) === -1) {
          if (!window.location.search.split('source=')[1]) {
            history.push(routes.loginUrl)
          }
        }
      })
  }, [logInDirectly, logOut, history])
  
  useEffect(() => {
    if (isUserAuthenticated) {
      const redirectToHomeRoutes = [routes.signUpUrl, routes.signUpUserInfoUrl, routes.signUpVerifyUrl, routes.recoveryPassword, routes.resetPassword]
      const redirectToHomeInactivesRoutes = [routes.homeUrl, routes.config]
      if (Object.keys(storeData).length > 0 && !storeData.active && redirectToHomeInactivesRoutes.indexOf(history.location.pathname) === -1) {
        history.push(routes.homeUrl)
      }
      if (redirectToHomeRoutes.indexOf(history.location.pathname) !== -1) {
        history.push(routes.homeUrl)
      }
    }
    setTimeout(() => setLoading(false), 600)
  }, [isUserAuthenticated, storeData, history])

  // useEffect(() => {
  //   if (countries.length === 0) {
  //     setGettingCountries(true)
  //     getStoreCountries()
  //       .then(({ data }) => {
  //         setCountries(data)
  //         setGettingCountries(false)
  //       })
  //       .catch(err => {
  //         enqueueSnackbar(t('CountrySelector.error'), errorToast)
  //         setGettingCountries(false)
  //       })
  //   }
  // }, [countries])

  return (<div style={{ height: '100%' }}>
    {loading
      ? <LinearProgress />
      : <ComponentToRender />
    }
  </div>)
}

const mapStateToProps = ({ auth, common }) => ({
  isUserAuthenticated: auth.isUserAuthenticated,
  countries: common.countries,
  storeData: common.storeData,
  cleanContainer: common.cleanContainer
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logOut,
  logInDirectly,
  setCountries
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

