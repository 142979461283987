import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import ws from '../../../assets/img/ws.jpg';
import circles from '../../../assets/img/circles.png';
import phone from '../../../assets/img/phone.png';
import checkIcon from '../../../assets/img/check.png';
import shareIcon from '../../../assets/img/share.png';

import { 
    AppBg,
    AppCover,
    StoreImage,
    Whatsapp,
    WhiteContainer,
    AppContent,
    Collections,
    StoreTitle
} from './StorePreview.styles';

const StorePreview = ({coverImage, storeImage, storeName, storeDescription}) => {
    
    return (
        <AppBg>
            <AppCover image={coverImage}></AppCover>
            <WhiteContainer>
                <StoreImage image={storeImage} />
                <Whatsapp>
                    <img src={ws} alt={storeName}></img>
                    <Typography variant="body-2">Tienda Whatsapp</Typography>
                </Whatsapp>
            </WhiteContainer>
            <AppContent>
                <StoreTitle>
                    <div style={{display: 'flex'}}>
                        <Typography variant="h6" style={{ marginRight: '3px'}}>
                            {storeName}
                        </Typography>    
                        <img style={{height: '16px', marginTop: '9px'}} src={checkIcon} alt={storeName} />
                    </div>
                    <img style={{height: '16px'}} src={shareIcon} alt={storeName} />
                </StoreTitle>
                <Typography variant="body-2" style={{ fontSize: '12px' }}>
                    {storeDescription}
                </Typography>
                <br /><br />
                <Collections src={circles} alt={storeName}/>

            </AppContent>
        </AppBg>
    );
};

export default connect()(StorePreview);
