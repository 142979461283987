// ReactJS
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

// Components
import { Intro } from '../../components/ManageCollection/ManageCollection.styles';

// Icons
import ConfigIcon from "../../assets/icons/config.svg";

// MUI Components
import { Grid, Typography } from "@material-ui/core";

// Components: SubDomains
import NavLink from "./NavLink";
import DomainContent from "./DomainContent";
import LoaderConecction from "./LoaderConecction";
import DomainTable from "./DomainTable";
import UnconfiguredDomains from "./UnconfiguredDomain";
import ConfiguredDomains from "./ConfiguredDomain";
import { verifyDomain } from "../../services/domain";

const SubDomains = ({ storeId, storeData, setStoreData }) => {
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [errorState, setErrorState] = useState();
    const [step, setStep] = useState(0);
    const [change, setChange] = useState(false);
    const [nameDomain, setNameDomain] = useState();
    const [isLoading, setLoading] = useState(false);

    return (
        <Grid container spacing={isTabletOrMobile ? 0 : 3} style={{ justifyContent: 'center' }}>
            {isTabletOrMobile &&
                <Intro item container xs={12}>
                    <img src={ConfigIcon} alt="" />
                    <Typography variant="caption">
                        {t('Config.formRoute')}
                    </Typography>
                </Intro>
            }
            <NavLink isTabletOrMobile={isTabletOrMobile} />

            {isLoading
                ? <LoaderConecction />
                : ((storeData?.domain || step === 3) && !change && <DomainTable storeData={storeData} setNameDomain={setNameDomain} setStep={setStep} setChange={setChange}/>)
                || (step === 0 && <UnconfiguredDomains storeId={storeId} nameDomain={nameDomain} setNameDomain={setNameDomain} setLoading={setLoading} setStep={setStep} />)
                || (step === 1 && <ConfiguredDomains storeId={storeId} nameDomain={nameDomain} setLoading={setLoading} setStep={setStep} setErrorState={setErrorState} setStoreData={setStoreData} />)
                || (step === 2 && <DomainContent storeId={storeId} nameDomain={nameDomain} setLoading={setLoading} setStep={setStep} errorState={errorState} setStoreData={setStoreData} />)
            }
        </Grid>
    );
}

export default SubDomains;
