import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { getUserStoresService } from '../../services/store';
import { errorToast, successToast } from '../../constants.js/misc';

import warningIcon from '../../assets/img/warning.png';
import { downgradePlanService } from '../../services/billing';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  header: {
      textAlign: 'center',
      marginBottom: 14
  }
}));

const DowngradeModal = ({
    open,
    onClose,
    storeId,
    prevLines,
    maxLines,
    prevPlanName,
    newPlanName,
    onSuccess
}) => {

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const classes = useStyles();

    const [gettingData, setGettingData] = useState(false);
    const [userStores, setUserStores] = useState([]);
    const [downgrading, setDowngrading] = useState(false);
    const error = userStores.filter(userStore => userStore.checked).length > maxLines;
    const intro = t('DowngradeModal.intro')
                    .replace('__PREV_PLAN_NAME__', prevPlanName)
                    .replace('__PREV_PLAN_LINES__', prevLines)
                    .replace('__NEW_PLAN_NAME__', newPlanName)
                    .replace('__NEW_PLAN_LINES__', maxLines);

    const handleCheckboxChange = (id, checked) => {
        setUserStores(userStores.map(userStore => ({
            ...userStore,
            checked: userStore.id === id ? checked : userStore.checked
        })))
    }


    const handleDowngrade = () => {
        setDowngrading(true);
        downgradePlanService(storeId, userStores
            .filter(userStore => userStore.checked === false)
            .map(userStore => userStore.id.toString())
        )
        .then(res => {
            onSuccess();
            onClose();
            setDowngrading(false);
            enqueueSnackbar(t('DowngradeModal.downgradeSuccess'), successToast)
        })
        .catch(err => {
            enqueueSnackbar(t('DowngradeModal.downgradeError'), errorToast)
            setDowngrading(false);
        });
    }
    
    useEffect(() => {
        // get user stores
        setGettingData(true);
        getUserStoresService(storeId, 1000)
            .then(res => {
                setUserStores(res.data.map(userStore => ({
                    ...userStore,
                    checked: false
                })));
                setGettingData(false);
            })
            .catch(err => {
                setGettingData(false);
                enqueueSnackbar(t('UserStores.tableError'), errorToast)
            });
    }, [])
    
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                <div className={classes.header}>
                    <img src={warningIcon} alt="warning"/>
                    <Typography variant="h5">{t('DowngradeModal.title')}</Typography>
                </div>
                <Typography variant="body2">{intro}</Typography><br/>
                <Typography variant="body2">{t('DowngradeModal.label')}</Typography>
                <div>
                    <br/>
                    <FormControl style={{ margin: 0 }} error={error} component="fieldset" className={classes.formControl}>
                        <FormGroup>
                        {userStores.map(userStore => 
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={userStore.checked} onChange={({target}) => handleCheckboxChange(userStore.id, target.checked)} name={userStore.mobile_phone_number} />}
                                label={userStore.mobile_phone_number}
                            />
                        )}
                        </FormGroup>
                    </FormControl>
                    </div>

            </DialogContent>
            <DialogActions>
                <Button disabled={error} onClick={handleDowngrade} color="primary" variant="contained">
                    {downgrading ?
                        <CircularProgress
                            size={30}
                            color="secondary" /> :
                            t('DowngradeModal.confirm')
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(DowngradeModal);