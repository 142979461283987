import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import ChipInput from 'material-ui-chip-input'
import { Controller } from 'react-hook-form';
import { CardHeader } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const ProductTags = ({ control, getValues, setValue, productData }) => {
    const { t } = useTranslation();
    const deleteTag = (chip, index) => {
        const values = getValues();
        setValue('tags', values.tags.filter((item, tagIndex) => tagIndex !== index));
    }

    const addTag = (chip) => {
        const values = getValues();
        const newValue = [...values.tags, chip];
        setValue('tags', newValue);
    }

    useEffect(() => {
        if (productData.tags) {
            const splittedTags = productData.tags.split(',');
            setValue('tags', splittedTags.map(tag => tag.trim()));
        }
    }, [productData]);

    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('Product.tags')} />
            <CardContent>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel>{t('Product.tags.input')}</FormLabel>
                            <Controller
                                render={({ field }) => (
                                    <ChipInput
                                        {...field}
                                        variant="outlined"
                                        margin="normal"
                                        onAdd={chip => addTag(chip)}
                                        onChange={chips => chips[0]}
                                        onDelete={(chip, index) => deleteTag(chip, index)}
                                    />
                                )}
                                control={control}
                                name="tags"
                            />
                        </FormControl>
                    </Grid>

                </Grid>

            </CardContent>
        </Card>
    );
};
export default connect()(ProductTags);