import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { bindActionCreators } from 'redux';

import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { DialogActions, CircularProgress } from '@material-ui/core';

//Assets
import BillingDetailsForm from '../BillingDetailsForm/BillingDetailsForm';
import { errorToast, successToast, routes, formatNumber } from '../../../constants.js/misc';


//MIXPANEL
import { Mixpanel } from '../../../constants.js/mixpanel';
import ReactPixel from 'react-facebook-pixel'

import { getStorePlans, getStoreCountries, getStorePlansPaymentTypes, getBillingInfoService, createPreferenceService } from '../../../services/billing';
import Plan from './Plan/Plan';
import CountrySelectorAutocomplete from '../../CountrySelectorAutocomplete/CountrySelectorAutocomplete';
import { setCountries } from '../../../modules/common';

const useStyles = makeStyles(() => ({
    paper: { minWidth: "70%" },
    info: {
        background: '#266678',
        color: '#FFFFFF',
        padding: '25px',
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 8
    },
}));

const WizardSelectPlan = ({
    countries,
    onClose,
    open,
    currentPlan,
    setCountries,
    storeId,
    disableCloseBtn,
    disableBackdropClick,
    disableEscapeKeyDown,
    logOut
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [plans, setPlans] = useState([]);
    const [planSelect, setPlanSelect] = useState('');
    // const [optionsFrequency, setOptionsFrequency] = useState([]);
    const [step, setStep] = useState('select-plan');
    const [title, setTitle] = useState(t('SelectPlan.title'));
    // const [gettingFrequencies, setGettingFrequencies] = useState(false);
    const [billingData, setBillingData] = useState(false);
    // const [gettingData, setGettingData] = useState(true);
    const [saving, setSaving] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [totalAmount, setTotalAmount] = useState(false);
    // const [frequency, setFrequency] = useState(false);
    // const [frequencyInfo, setFrequencyInfo] = useState(false);

    const [frequencyId, setFrequencyId] = useState(false);
    const [planId, setPlanId] = useState({});
    const [gettingPlans, setGettingPlans] = useState(false);
    // state for campaing EC-MX
    const [campaing, setCampaing] = useState(false)

    const [selectedCountryBilling, setSelectedCountryBilling] = useState({
        code: 'CO',
        created_at: '',
        currency: 'COP',
        name: 'Colombia',
        updated_at: '',
    });

    const {
        control,
        formState,
        handleSubmit,
        register,
        watch,
        errors,
        setValue
    } = useForm({ mode: 'all' });

    const contactSales = () => {
        const sirenaNumber = '573003154556'
        const message = 'Hola, me gustaría obtener más información sobre los beneficios del plan anual.'
        const campaingMessage = 'Hola, me gustaría obtener más información sobre el descuento del plan anual.'
        if( campaing ){
            window.open(`https://wa.me/${sirenaNumber}?text=${encodeURIComponent(campaingMessage)}`)
            ReactPixel.track('Contact')
        }else{
            window.open(`https://wa.me/${sirenaNumber}?text=${encodeURIComponent(message)}`)
        }
    }
    
    useEffect(() => {
        if (countries.length === 0) {
            getStoreCountries()
                .then(({ data }) => {
                    setCountries(data);
                })
                .catch(err => { })
        }
    }, [countries]);

    useEffect(() => {
        setGettingPlans(true);
        const selCountry = selectedCountryBilling ? selectedCountryBilling.code : '';
        getStorePlans(storeId, selCountry)
            .then(({ data }) => {
                setGettingPlans(false);
                setPlans(data);
            })
            .catch(err => {
                setGettingPlans(false);
                enqueueSnackbar(t('WizarsSelectPlan.plansError'), errorToast)
            });
    }, [selectedCountryBilling])
    
    const paymentsOptionsFrecuency = value => {
        // setGettingFrequencies(true);
        const selCountry = selectedCountryBilling ? selectedCountryBilling.code : '';
        getStorePlansPaymentTypes(storeId, selCountry, value.id)
            .then(({ data }) => {
                setPlanId(value.id);
                // setFrequencyInfo(data.find(x => x.name === 'Pago Anual') || data.find(x => x.name === 'Annual payment'));
                // setFrequency(data.find(x => x.name === 'Pago Anual').id || data.find(x => x.name === 'Annual payment').id);
                setFrequencyId(data.find(x => x.name === 'Pago Anual').id || data.find(x => x.name === 'Annual payment').id);
                // setGettingFrequencies(false);
                // setOptionsFrequency(data);
            })
            .catch(err => {
                // setGettingFrequencies(false);
            })
    };

    const setStepValue = (value, item) => {
        setStep(value);
        switch (value) {
            case 'billing-info':
                if (item) {
                    setPlanSelect(item);
                    paymentsOptionsFrecuency(item);
                    setTotalAmount(item.base_price);
                }
                if ( campaing ){
                    ReactPixel.track('InitiateCheckout', {
                        content_category: 'Iniciando formulario',
                    })
                }
                setTitle(t('BilligDetails.edit'))
                break;

            default:
                setTitle(t('SelectPlan.title'));
                break;
        };
    };

    const getBillingData = () => {
        getBillingInfoService(storeId)
            .then(res => {
                setBillingData(res.data);
                if (res.data.country) {
                    setSelectedCountryBilling(res.data.country)
                }
                // setGettingData(false);
            })
            // .catch(err => setGettingData(false));
    }

    const handleCountryChange = newValue => {
        setSelectedCountryBilling(newValue);
    }

    const changeCountry = (e) => {
        e.preventDefault();
        setStep('select-plan');
    }

    const handleDialogClose = () => {
        onClose();
        setTimeout(() => setStep('select-plan'), 300);
    }

    const handleBillingDataSuccess = () => {
        setSubmit(false);
        enqueueSnackbar('Datos guardados, ya puedes pagar', successToast);

        Mixpanel.track('Cambio plan', {
            "Nombre del plan actual": currentPlan.plan_name,
            "Costo del plan actual": currentPlan.amount_date,
            "Frecuencia del plan actual": currentPlan.frequency ? currentPlan.frequency : 1,
            "Usuario": storeId,
            "Nombre del plan nuevo": planSelect.plan_name,
            "Costo del plan nuevo": totalAmount,
            // "Frecuencia del plan nuevo": frequencyInfo.name
        });
        if ( campaing ){
            ReactPixel.track('CompleteRegistration', {
                content_name: 'Terminando formulario',
            })
        }
        // Create mercadopago preference
        createPreferenceService({
            notification_url: process.env.REACT_APP_API_URL + '/payments/webhooks/mercadopago',
            external_reference: btoa(JSON.stringify({
                store_id: storeId,
                plan_id: planId,
                plan_payment_type: frequencyId,
                amount: totalAmount,
                type: 'PLAN'
            })),
            items: [
                {
                    id: 1,
                    title: 'Plan Ecommerce',
                    unit_price: totalAmount,
                    quantity: 1,
                    currency_id: selectedCountryBilling.code === 'CO' ? 'COP' : 'USD',
                }
            ],
            marketplace: '10X',
            additional_info: "Plan Ecommerce",
            back_urls: {
                success: window.location.origin + `${routes.thankU}`,
                failure: window.location.origin + `${routes.payments}/${planId}/${frequencyId}`,
                pending: window.location.origin + `${routes.payments}/${planId}/${frequencyId}`
            },
            auto_return: "all",
        })
            .then(res => window.location.href = res.data.init_point)
            .catch(err => enqueueSnackbar('Imposible crear la preferencia', errorToast))
    }

    useEffect( ()=> {
        getBillingData()
        setCampaing(localStorage.getItem('campaing'))
    },  [])

    //Open form billing info if frecuency is monthly
    useEffect( ()=> {
        const getFrecuencyLoc = localStorage.getItem('selectedPlan')
        const pL = plans.find( pl => pl.id === 3 || pl.id === 12 )
        if (getFrecuencyLoc === 'monthly' && pL) {
            setStepValue('billing-info', pL)
        }
    },  [plans]) 
    return (
        <Dialog
            onClose={handleDialogClose}
            open={open}
            maxWidth="md"
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={disableBackdropClick || false}
            disableEscapeKeyDown={disableEscapeKeyDown || false}>
            <DialogTitle id="alert-dialog-title">
                {title} 
                {onClose && step === 'select-plan' && !disableCloseBtn ? (
                    <IconButton aria-label="close" style={{
                        position: 'absolute',
                        right: 1,
                        top: 1,
                    }} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {step === 'select-plan' && (
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={12}>
                                <div style={{ maxWidth: '16rem', margin: '0 auto' }}>
                                    <CountrySelectorAutocomplete
                                        name="country"
                                        countries={countries}
                                        handleCountryChange={handleCountryChange}
                                        defaultCountryValue={selectedCountryBilling}
                                        label={t('WizarsSelectPlan.country')}
                                    />
                                </div>
                            </Grid>
                            {gettingPlans && <CircularProgress style={{ margin: '0 auto' }} />}
                            {!gettingPlans && selectedCountryBilling && plans.map(item => {
                                return (
                                    <Grid key={item.id} item xs={12} lg={6} style={{ display: `${ ((item.id === 3 || item.id === 12 ) && campaing === 'true') || ((item.id === 13 || item.id === 10) &&(campaing === 'false' || campaing == null)) || (item.id === 2 || item.id === 11) ? 'block' : 'none' }` }}>
                                    {/* <Grid item xs={12} lg={6} style={{ display: `${ ((item.id === 1  || item.id === 5 ) && campaing === 'true') || ((item.id === 8 || item.id === 9) && (campaing === 'false' || campaing == null)) || (item.id === 6 || item.id === 7) ? 'block' : 'none' }` }}>  */}
                                        <Plan
                                            title={item.name}
                                            subtitle={item.sub_title}
                                            price={formatNumber(item.base_price, item.currrency === 'COP' ? 'COP' : 'USD') + `${item.id === 2 || item.id === 11 ? '/año' : '/mes'}`}
                                            detailPrice={item.description}
                                            btnTitle={ item.id === 3  || item.id === 12 || item.id === 13 || item.id === 10 ? 'Comprar plan' : 'Contactanos' }
                                            action={ item.id === 3  || item.id === 12 || item.id === 13 || item.id === 10 ? () => setStepValue('billing-info', item) : contactSales}
                                            //dev plans
                                            // btnTitle={ item.id === 1  || item.id === 5 || item.id === 8 || item.id === 9 ? 'Comprar plan' : 'Contactanos' }
                                            // action={ item.id === 1  || item.id === 5 || item.id === 8 || item.id === 9 ? () => setStepValue('billing-info', item) : contactSales}
                                            options={item.plan_features}
                                            id={item.id}
                                        />
                                    </Grid>
                                ) } ) }
                            <Grid item xs={12} style={{ textAlign: 'center', width: '40%' }}>
                                <Button
                                    onClick={logOut}
                                    color="primary"
                                    variant="contained"
                                >
                                    Cerrar sesión 
                                </Button>
                            </Grid>
                        </Grid>
                    )}

                    {step === 'billing-info' &&
                        <>
                            <div style={{ paddingBottom: 20 }}>
                                <Link onClick={() => setStepValue('select-plan')}
                                >
                                    {t('SelectPlan.titleBack')}
                                </Link>
                            </div>

                            <BillingDetailsForm
                                billingData={billingData}
                                handleSuccess={handleBillingDataSuccess}
                                setSubmit={setSubmit}
                                formObject={{
                                    control,
                                    formState,
                                    handleSubmit,
                                    setValue,
                                    register,
                                    watch,
                                    errors
                                }}
                                setSaving={setSaving}
                                submit={submit}
                                handleCountryChange={handleCountryChange}
                                selectedCountryBilling={selectedCountryBilling}
                                changeCountry={changeCountry} />
                        </>
                    }
                </DialogContentText>
            </DialogContent>
            {
                step === 'billing-info' && 
                <DialogActions>
                    <Button color="primary" variant="contained" disabled={!formState.isValid || saving} onClick={() => setSubmit(true)}>Confirmar y pagar</Button>
                </DialogActions>
            }

             {/* {
                step === 'select-frequency' && isTabletOrMobile && <DialogActions>
                    <Button onClick={()=> setStepValue('billing-info')} disabled={!frequency} fullWidth color="primary" variant="contained">{t('Bill.pay')}</Button>
                </DialogActions>
            } */}
        </Dialog >
    );
}

const mapStateToProps = ({ auth, common }) => ({
    storeId: auth.storeId,
    userId: auth.userId,
    countries: common.countries
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setCountries
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WizardSelectPlan);