import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { Container, ImageView, Content, ContentSubTitle } from './ViewDataEmpty.styles';
import Button from '@material-ui/core/Button';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';


export const ViewDataEmpty = ({ isMobileProduct = false, image, title, subtitle, action, btnTitle }) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
      <Container isMobileProduct={isMobileProduct}>
        <Content>
          {isMobileProduct && btnTitle && (
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={action}
              startIcon={<AddCircleIcon />}
            >
              {btnTitle}
            </Button>
          )}
          <ImageView src={image} alt={title} />
          <Typography variant={isTabletOrMobile ? "h6" : "h4"} component="h1">
            {title}
          </Typography>
          <ContentSubTitle>
            <Typography variant="body2" component="h2">
              {subtitle}
            </Typography>
          </ContentSubTitle>
          {!isMobileProduct && btnTitle && (
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={action}
              startIcon={<AddCircleIcon />}
            >
              {btnTitle}
            </Button>
          )}
        </Content>
      </Container>
    );
}

export default ViewDataEmpty;
