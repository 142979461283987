import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Check from '../../../assets/icons/check.png';


// styles
const useStyles = makeStyles({
    info: {
        background: '#EFF3EF',
        padding: '35px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20
    },
});

export const SuccessMessage = ({ description }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box className={classes.info}>
            <img src={Check} alt="" style={{ marginRight: '10px' }} />
            <Typography variant="body1">
                {description}
            </Typography>
        </Box>
    );
}

export default SuccessMessage;