import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useInput = initialValue => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);
  const onChange = e => setValue(e.target.value);
  const clearValue = () => setValue('');
  const onError = () => (value === '' ? '*' : '');
  const onErrorMsg = () => (value === '' ? t('required filed') : '');
  return {
    value,
    onChange,
    clearValue,
    onError,
    onErrorMsg,
  };
};

export default useInput;
