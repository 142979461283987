import { axiosInstance } from './instance';

const getDynamicForm = (storeId) => {
    return axiosInstance.get(`/stores/${storeId}/dynamic-form`);
}

const putDynamicForm = (storeId, value) => {
    return axiosInstance.put(`/stores/${storeId}/dynamic-form`, value);
}

const createDynamicForm = (storeId, value) => {
    return axiosInstance.post(`/stores/${storeId}/dynamic-form`, value);
}

export {
    getDynamicForm,
    putDynamicForm,
    createDynamicForm
};