import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import arrayMove from 'array-move';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


//Internal dependencies
import { routes } from '../../constants.js/misc';


import { Container, Intro, ButtonContainer } from '../../components/ManageCollection/ManageCollection.styles';
import SortableList from '../../components/ManageCollection/ProductsCollection/SortabletListProducts/SortabletListProducts';

import CollectionIcon from '../../assets/icons/collections.svg';
import EditIcon from '../../assets/icons/edit-collection.svg';


//Services
import { getCollectionsService, orderCollections } from '../../services/collections';



const ActionCategorieMobile = ({ storeId }) => {
    const { t } = useTranslation();
    const [collectionData, setCollectionData] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useHistory();


    useEffect(() => {
        setLoading(true);
        getCollectionsService(storeId)
            .then(({ data }) => {
                setLoading(false);
                setCollectionData(data.sort((a, b) => Number(b.default) - Number(a.default)));
            })
            .catch(err => {
                setLoading(false);
                console.log('Error collectionPage', err);
                setCollectionData([]);
            });
    }, [storeId]);


    const onSortEnd = ({ oldIndex, newIndex }) => {
        setCollectionData(arrayMove(collectionData, oldIndex, newIndex).sort((a, b) => Number(b.default) - Number(a.default)));
        orderCollections(storeId, arrayMove(collectionData, oldIndex, newIndex).map(x => x.id))
            .then(({ data }) => { })
            .catch(err => {
                console.log('Error collectionPage', err);
            });
    };

    const handleAction = (index, value) => {
        history.push(`/edit-collection/${value}`);
    };


    return (
        <Container container spacing={0}>
            <Intro item container xs={12}>
                <img src={CollectionIcon} />
                <Typography variant="caption">
                    {t('CollectionsTitle')}
                </Typography>
            </Intro>

            <Grid item container xs={12} style={{ padding: 20 }}>
                <Grid item xs={12}>
                    <Typography
                        variant={'h5'}
                        style={{ marginBottom: '10px' }}>
                        {t('MobileCategories.manage')}
                    </Typography>
                    <Link
                        component={RouterLink}
                        to={routes.homeUrl}>
                        ‹ {t('ManageCollection.goHome')}
                    </Link>
                </Grid>

                <Grid item xs={12}>

                    {loading && <LinearProgress />}

                    {!loading &&
                        <Card elevation={0} style={{ margin: '20px 0px 120px' }}>
                            <CardContent>
                                {collectionData.length > 0 &&
                                    <SortableList
                                        pressDelay={100}
                                        useDragHandle
                                        iconAction={EditIcon}
                                        items={collectionData}
                                        handleClick={handleAction}
                                        onSortEnd={onSortEnd}></SortableList>
                                }
                            </CardContent>
                        </Card >
                    }
                </Grid>


            </Grid>

            <ButtonContainer
                item
                xs={12}>
                <Button
                    style={{ width: '100%' }}
                    component={RouterLink}
                    to={'/create-collection'}
                    color="primary"
                    variant="contained"
                    type="submit">
                    {t('Collection.createCollection')}
                </Button>
            </ButtonContainer>

        </Container>
    )
};


const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});


export default connect(
    mapStateToProps
)(ActionCategorieMobile);



