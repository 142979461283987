

import styled from '@emotion/styled';


export const SubmitButtonContainer = styled.div`
    gap: 15px;
    display: flex;
    flex-direction: column;
    width: max-content;
    margin-top: 20px;
`;


export const RecoveryLinkPassword = styled.div`
    padding: 10px 0px;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
`;


export const ActionItem = styled.div`
  margin: 35px 0 45px 0;
  @media screen and (max-width: 414px) {
    position: absolute;
    margin-top: 45px;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
`;
