import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Link from '@material-ui/core/Link';
import { useDropzone } from 'react-dropzone';


import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardHeader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import { ContentUpload, CardAction } from './ManageImportProducts.styles';
import ListError from './ListError/ListError';
import SuccessMessage from './SuccessMessage/SuccessMessage';

import UploadImage from '../../assets/icons/uploadnube.svg';
import BtnUpload from '../../assets/icons/btnupload.svg';


const ManageImportProducts = ({ fileUpload, setFileUpload, listErrors, setListErrors, successMessage }) => {
    const { t } = useTranslation();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: '.xlsx, xls',
        onDrop: acceptedFiles => {
            setFileUpload(acceptedFiles[0]);
        }
    });

    const resetValues = () => {
        setListErrors('');
        setFileUpload('');
    }

    const support = () => {
        window.open(`https://wa.me/573225920209?text=Hola,%20quiero%20saber%20como%20importar%20productos%20desde%20un%20archivo%20excel%20a%20mi%20tienda`);
    }  

    return (
        <Card elevation={0}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('ImportProducts.uploadTitle')} />
            <CardContent>
                <ContentUpload {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <>
                            <img src={UploadImage} alt="" />
                            <img src={BtnUpload} alt="" />
                        </>
                    ) : (
                        <>
                            <img src={UploadImage} alt="" />
                            <img src={BtnUpload} alt="" />
                            <Typography variant="p">
                                {fileUpload ? fileUpload.name : t('ImportProducts.dragTitle')}
                            </Typography>
                            {fileUpload &&
                                <Button
                                    size="small"
                                    onClick={() => resetValues()}
                                    startIcon={<CloseIcon color="error" />}>
                                    {t('Product.deleteConfirm')}
                                </Button>
                            }
                        </>
                    )}

                </ContentUpload>
                {
                    listErrors?.length > 0 && <ListError
                        title={t('ImportProducts.errorFileTitle')}
                        subTitle={t('ImportProducts.errorFileDescription')}
                        items={listErrors}></ListError>
                }
                {
                    successMessage && <SuccessMessage description={successMessage}></SuccessMessage>
                }
                <Typography variant="body1" gutterBottom>
                    {t('ImportProducts.donwloadAction')}
                    <Link href="https://s3.amazonaws.com/wa10x.v2/formato_carga_10x.xlsx
                    " target="_blank">{t('ImportProducts.donwloadActionLink')}</Link>
                    {t('ImportProducts.donwloadActionComplement')}
                </Typography>
            </CardContent>

            <CardAction>
                <Typography variant="body1" gutterBottom>
                    {t('ImportProducts.helpAction')}
                </Typography>
            </CardAction>
        </Card>
    );
};



export default connect()(ManageImportProducts);