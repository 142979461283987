import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthForm } from '../../components/AuthForm/AuthForm';
import signInImg from '../../assets/img/account_login.jpg';
import SignInCodeForm from '../../components/SignInCodeForm/SignInCodeForm';


//MIXPANEL
import { Mixpanel } from '../../constants.js/mixpanel';

const AuthCodePage = () => {
    const { t } = useTranslation();
    const handleLogin = () => console.log('Login succesfull');


    useEffect(() => {
        Mixpanel.track('Visitar página para ingresar código', {
            "ViewEnter": 1
        });
    }, []);

    return (
        <AuthForm
            isLogin={true}
            image={signInImg}
            title={t('Code.title')}
            subtitle={t('Code.subtitle')}>
            <SignInCodeForm onLogin={handleLogin}></SignInCodeForm>
        </AuthForm>
    );
}
export default AuthCodePage;