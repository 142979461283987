import { b64toBlob } from "../constants.js/orders";
import { axiosInstance } from "./instance";

/**
 * Upload File Masive  product
 * @param {number} year 
 * @param {number} month
 * @param {number} day
 */
export const reportUsersDownload = (year, month, day) => {
  return axiosInstance
    .get(`/stores/new-users-report?year=${year}&month=${month}&day=${day}`)
    .then((response) => {
      const base64Content = response.data.base64Content;

      const blob = b64toBlob(base64Content);

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;

      const fileName = "reporte.xlsx";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error("Error al descargar el archivo:", error);
    });
};