import { axiosInstance } from './instance';

const getCitiesService = () => {
    return axiosInstance.get(`/cities`);
};

const getDocumentTypesService = () => {
    return axiosInstance.get(`/document-types`);
};

export {
    getCitiesService,
    getDocumentTypesService
}