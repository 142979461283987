import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { CardHeader, FormHelperText, LinearProgress, OutlinedInput } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

import { CurrentImage } from '../../../components/ManageProduct/ProductImages/ProductImages';
import UploadImageUI from '../../UploadImageUI/UploadImageUI';


import { ChangeUrlContainer, FileUpload } from './StoreInfo.styles';
import { deleteStoreImagesService, editStoreUrl } from '../../../services/store';

import {
    errorToast,
    successToast
} from '../../../constants.js/misc';



const StoreInfo = ({
    storeId,
    control,
    errors,
    actualCharsName,
    actualCharsDescription,
    gettingInfo,
    storeData,
    onProfilePictureChange,
    onBannerPictureChange,
    onImageDeletion,
    savingProfilePicture,
    savingBannerPicture,
    setValue,
    verifyUrlError,
    setVerifyUrlError
}) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const [storeUrl, setStoreUrl] = useState(null)
    const [profileImageSelected, setProfileImageSelected] = useState(false)
    const [bannerImageSelected, setBannerImageSelected] = useState(false)
    const [deletingProfileImage, setDeletingProfileImage] = useState(false)
    const [deletingBannerImage, setDeletingBannerImage] = useState(false)

    const maxLengthName = 50
    const maxLengthDescription = 250

    const totalCharsName = maxLengthName - actualCharsName?.length
    const totalCharsDescription = maxLengthDescription - actualCharsDescription?.length

    const deleteImage = (banner) => {
        if (banner) {
            setBannerImageSelected(false);
        } else {
            setProfileImageSelected(false);
        }
    }

    const handleProfilePictureChange = files => {
        setProfileImageSelected(files[0]);
        onProfilePictureChange(files[0]);
    }

    const handleBannerPictureChange = files => {
        setBannerImageSelected(files[0]);
        onBannerPictureChange(files[0]);
    }

    const deleteImageFromServer = (banner) => {
        if (banner) {
            setDeletingBannerImage(true);
        } else {
            setDeletingProfileImage(true);
        }
        const setSelected = () => {
            if (banner) {
                setBannerImageSelected(false);
                setDeletingBannerImage(false);
            } else {
                setProfileImageSelected(false);
                setDeletingProfileImage(false);
            }
        }
        deleteStoreImagesService(storeId, banner)
            .then(() => {
                onImageDeletion(banner);
                setSelected();
                enqueueSnackbar(t('ManageStore.ImageDeletionSuccess'), successToast);
            })
            .catch(() => {
                setSelected();
                enqueueSnackbar(t('ManageStore.ImageDeletionError'), errorToast);
            })
    }

    const handleChangeUrlInput = (e) => {
        const actValue = e.target.value
        const filValue = actValue.replace(/[^a-zA-Z0-9-]/g, '')
        setStoreUrl(filValue.trim())
        if(!verifyUrlError) {
            if(filValue !== storeData.url) setVerifyUrlError(true)
         }else{
            if(filValue === storeData.url) setVerifyUrlError(false)
        }
    }

    const changeUrl = () => {
        editStoreUrl(storeId, storeUrl)
        .then( res => {
            if(res.data.valid) {
                setValue('url', storeUrl)
                enqueueSnackbar(t('ManageStore.ChangeUrlSuccess'), successToast)
                if(verifyUrlError) setVerifyUrlError(false) 
            }else{
                enqueueSnackbar(t('ManageStore.ChangeUrlSaveError'), errorToast)
                setVerifyUrlError(true)
            }
        })
        .catch( err => console.log(err) )
    }

    useEffect( () => {
        if(storeData) {
            setStoreUrl(storeData.url)
        }
    }, [storeData] )

    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            {gettingInfo && <LinearProgress />}
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('UserStore.title')} />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel error={errors?.name ? true : false}>{t('UserStore.nameStore')}*</FormLabel>
                            <Controller
                                render={({ field }) => (
                                    <OutlinedInput
                                        {...field}
                                        disabled={gettingInfo}
                                        defaultValue=""
                                        margin="normal"
                                        error={errors.name ? true : false} />
                                )}
                                control={control}
                                name="name"
                                rules={{ required: true, maxLength: { value: maxLengthName } }}
                            />
                            <FormHelperText error={errors.name ? true : false}>{errors.name ? t('UserStore.errorStoreName') : ''}</FormHelperText>
                            { actualCharsName?.length >= 0 &&
                                <Typography 
                                    error={errors.description ? true : false} 
                                    style={{ 
                                        margin: `${errors.name ? 0 : '10px 0 0'}`, 
                                        color: `${errors.name ? '#cb7c7a' : ''}` 
                                    }} 
                                >
                                    {t('Product.availableCharacters')} {totalCharsName}
                                </Typography>
                            } 
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel error={errors.description ? true : false}>{t('UserStore.descriptionStore')}</FormLabel>
                            <Controller
                                render={({ field }) => (
                                    <OutlinedInput
                                        {...field}
                                        disabled={gettingInfo}
                                        defaultValue=""
                                        multiline rows={5}
                                        rowsMax={4}
                                        margin="normal"
                                        error={errors.description ? true : false}
                                    />
                                )}
                                control={control}
                                name="description"
                                rules={{ maxLength: { value: maxLengthDescription } }}
                            />
                            <FormHelperText error={errors.description ? true : false}>{errors.description ? t('UserStore.errorStoreDescription') : ''}</FormHelperText>
                            { actualCharsDescription?.length >= 0  &&
                                <Typography 
                                    error={errors.description ? true : false} 
                                    style={{ 
                                        margin: `${errors.description ? 0 : '10px 0 0'}`, 
                                        color: `${errors.description ? '#cb7c7a' : ''}` 
                                    }} 
                                >
                                    {t('Product.availableCharacters')} {totalCharsDescription}
                                </Typography>
                            }
                        </FormControl>
                    </Grid>

                    <ChangeUrlContainer>
                        <FormLabel error={verifyUrlError ? true : false}>{(t('ManageStore.ChangeUrlTitle'))}</FormLabel>
                        <Controller
                            render={({ field }) => (
                            <OutlinedInput
                                { ...field }
                                disabled={gettingInfo}
                                value={storeUrl}
                                onChange={handleChangeUrlInput}
                                margin="normal"
                            />
                            )}
                            control={control}
                            name="url"
                        />
                        {storeData?.url && 
                            <b>{`https://${storeUrl}.ecometri.shop`}</b>
                        }
                        <div>
                            <Button
                                disabled={storeUrl === storeData?.url}
                                variant="contained"
                                color="secondary"
                                onClick={changeUrl}
                            >
                                {(t('ManageStore.ChangeUrlSaveBtn'))}
                            </Button>
                        </div>
                    </ChangeUrlContainer>

                    <Grid item xs={12} md={4}>
                        <FileUpload className={profileImageSelected.length ? 'has-image' : ''}>
                            <FormLabel>Foto de perfil/Logo</FormLabel>
                            {storeData.profile_picture &&
                                <CurrentImage
                                    wide={true}
                                    thumb={true}
                                    isDeleting={deletingProfileImage}
                                    style={{ width: '100%' }}
                                    handleClick={() => deleteImageFromServer()}
                                    image={storeData.profile_picture} />}
                            {savingProfilePicture && <LinearProgress />}
                            {!storeData.profile_picture &&
                                <UploadImageUI
                                    height={"200px"}
                                    deleteImage={deleteImage}
                                    fileUpload={profileImageSelected}
                                    handleChange={handleProfilePictureChange} />
                            }
                            <Typography variant="caption">Dimensiones sugeridas 512x512px</Typography>
                        </FileUpload>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <FileUpload className={bannerImageSelected.length ? 'has-image' : ''}>
                            <FormLabel>Foto de portada/Banner</FormLabel>
                            {storeData.banner_picture &&
                                <CurrentImage
                                    wide={true}
                                    thumb={true}
                                    isDeleting={deletingBannerImage}
                                    style={{ width: '100%', height: '210px' }}
                                    handleClick={() => deleteImageFromServer(true)}
                                    image={storeData.banner_picture} />}
                            {savingBannerPicture && <LinearProgress />}
                            {!storeData.banner_picture &&
                                <UploadImageUI
                                    height={"200px"}
                                    deleteImage={deleteImage}
                                    fileUpload={bannerImageSelected}
                                    handleChange={handleBannerPictureChange} />
                            }
                            <Typography variant="caption">Dimensiones sugeridas 1440x550px</Typography>
                        </FileUpload>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    );
};

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId,
});

export default connect(
    mapStateToProps
)(StoreInfo);