// react dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// material ui dependencies
import { Card, CardContent, CardHeader, Box, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// Internal dependencies
import { getStoreInvoicesPendingService } from '../../../services/billing';
import { routes, formatNumber, formatDate } from '../../../constants.js/misc';

const useStyles = makeStyles({
    noBills: {
        backgroundColor: '#F8F9FB',
        padding: '47px 0',
        fontSize: '16px',
        borderRadius: '8px',
        margin: '0 16px 16px 16px'
    },
    noBorder: {
        border: 'none'
    }
});

const PendingBills = ({ storeId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [hasBills, setHasBills] = useState(false);
    const [billingPendingStore, setBillingPendingStore] = useState([]);
    const [gettingData, setGettingData] = useState(true);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

    useEffect(() => {
        getStoreInvoicesPendingService(storeId)
            .then(({ data }) => {
                setGettingData(false);
                setHasBills(data.length > 0 ? true : false);
                setBillingPendingStore(data);
            })
            .catch(err => setGettingData(false))
    }, []);

    return (
        <Card container spacing={3} style={{ width: '100%' }}>
            <CardHeader
                titleTypographyProps={{ variant: 'h6' }}
                title={t('PendingBills.title')} />
            {gettingData && <LinearProgress />}
            {!gettingData &&     
                <CardContent style={{ padding: '16px 0 0 0' }}>
                    {hasBills
                        ?
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {billingPendingStore.map(item =>
                                        <TableRow style={{ display: isTabletOrMobile ? 'grid' : 'table-row' }}>
                                            <TableCell className={classes.noBorder}>
                                                <Typography variant="subtitle2"><strong>{formatDate(item.due_date)}</strong></Typography>
                                                <Typography variant="caption">Fecha de corte</Typography>
                                            </TableCell>
                                            <TableCell className={classes.noBorder}>
                                                <Typography variant="subtitle2"><strong>{formatNumber(item.amount)}</strong></Typography>
                                                <Typography variant="caption">Importe a pagar</Typography>
                                            </TableCell>
                                            <TableCell className={classes.noBorder}>
                                                <Typography variant="subtitle2"><strong>{formatDate(item.end_date)}</strong></Typography>
                                                <Typography variant="caption">Fecha limite de pago</Typography>
                                            </TableCell>
                                            <TableCell className={classes.noBorder}>
                                                <Typography variant="subtitle2"><strong>{t('BillState' + item.invoice_status_id)}</strong></Typography>
                                                <Typography variant="caption">Estado</Typography>
                                            </TableCell>
                                            <TableCell className={classes.noBorder}>
                                                <Button 
                                                    style={{ width: '100%' }} 
                                                    component={RouterLink} 
                                                    to={`${routes.bill}/${item.id}`} 
                                                    variant="contained">{t('PendingBills.viewBill')}</Button>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <Box
                            className={classes.noBills}
                            container
                            spacing={2}
                            justifyContent="center"
                            display="flex">
                            {t('PendingBills.noBills')}
                        </Box>
                    }
                </CardContent>
            }

        </Card>
    );
};

const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(PendingBills);
