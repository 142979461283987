import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import styled from '@emotion/styled';
import EditClientDataModal from '../EditClientDataModal/EditClientDataModal';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { successToast } from '../../../constants.js/misc';

const Footer = styled.div`
    background: #F8F9FB;
    border-radius: 0px 0px 8px 8px;
    padding: 13px 15px;
    p {
        color: #53627C;
    }
`;

const ClientNotes = ({ clientId, notes }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogClose = () => setDialogOpen(false);
    const handleDataUpdate = () => {
        enqueueSnackbar(t('OrderDetail.submitSuccess'), successToast)
        setTimeout(() => window.location.reload(), 1000)
        handleDialogClose();
    }

    const openModal = () => setDialogOpen(true);

    return (
        <>
            <Card elevation={2} style={{ marginBottom: '21px' }}>
                <Grid container
                    alignItems="center"
                    justifyContent="center">
                    <Grid item xs={10}>
                        <CardHeader
                            titleTypographyProps={{ variant: 'h6' }}
                            /* avatar={<img src={clientIcon} alt='clientIcon' />} */
                            title={t('ClientDetail.notes.title')}
                            style={{ padding: '17px 20px 13px 20px' }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Link
                            style={{ fontSize: '16px', cursor: 'pointer' }}
                            target="_blank"
                            onClick={openModal}
                        >
                            {t('ClientDetail.details.edit')}
                        </Link>
                    </Grid>
                </Grid>
                <CardContent style={{ padding: '0 16px 10px 16px' }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ padding: '13px 15px', backgroundColor: '#FCF3EA', borderRadius: '8px' }}>
                            <Typography gutterBottom variant="body1">
                                {notes ? notes : t('ClientDetail.notes.message')}
                            </Typography>
                        </div>
                    </Grid>
                </CardContent>
                <Footer>
                    <Typography variant="body1" gutterBottom>
                        {t('ClientDetail.notes.notice')}
                    </Typography>
                </Footer>
            </Card>
            <EditClientDataModal
                dataToEdit={{ clientId, notes }}
                //setData={}
                open={dialogOpen}
                isNoClose={false}
                handleClose={handleDialogClose}
                handleSuccess={handleDataUpdate}
                type={2}
            />
        </>
    );
};
export default connect()(ClientNotes);