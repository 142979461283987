import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import {
    FooterAction
} from './CardItemActionFooter.styles';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    link: {
        fontWeight: '400',
        fontSize: '16px',
        color: '#48AC98',
        textDecoration: 'underline'
    },
});

export const CardItemActionFooter = ({ title, subTitle, children, description, actionTitle, url, paddingProp }) => {
    const classes = useStyles();

    return (
        <Card container spacing={3} style={{ width: '100%' }}>
            <div style={{ padding: (paddingProp || 'auto') }}>
                <CardHeader
                    titleTypographyProps={{ variant: 'h6' }}
                    title={title}
                    style={{ marginBottom: '31px 31px 15px 31px' }}
                    subheader={subTitle} />
                <CardContent style={{ padding: '0 16px 16px 16px' }}>
                    {children}
                </CardContent>
            </div>
            {actionTitle && <FooterAction>
                <Typography variant="p" gutterBottom>
                    {description}
                    <Link target="_blank" className={classes.link} href={url}>{actionTitle}</Link>
                </Typography>
            </FooterAction>}
        </Card >
    );
}

export default CardItemActionFooter;