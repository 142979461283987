import axios from "axios";

export const sendWelcomeMessage = async (userPhoneNumber, userName, API_URL, API_KEY) => {
    try {
        const response = await axios.post(API_URL, {
            to: `+${userPhoneNumber}`,
            from: '10x',
            type: 'document',
            content: {
                text: `Hola ${userName} ¡Bienvenido a 10x! 

            Felicitaciones🥳 Acabaste de crear tu tienda 

            Cuéntame ¿has podido montar tu primer producto?

            1.⁠ ⁠¡Claro!
            2.⁠ ⁠Nada… 
            3.⁠ ⁠Necesito ayuda para montarlo.`},
        }, { headers: {
            'Authorization': `Bearer ${API_KEY}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }});
        console.log("Mensaje enviado:", response.data);
    } catch (error) {
        console.error("Error al enviar el mensaje:", error);
    }
};

export const setData = (actionName, payload) => {
    return dispatch => {
        dispatch({ 
            type: actionName, 
            payload: payload
        });
    }
}