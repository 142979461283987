// react dependencies
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

// material ui dependencies
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';

// Internal dependencies
import ClientForm from './ClientForm';
import ShippingInfoForm from './ShippingInfoForm';
import { useSnackbar } from 'notistack';
import { infoToast } from '../../../constants.js/misc';


const EditOrderDataModal = ({
    open,
    handleClose,
    isNoClose = true,
    storeId,
    dataToEdit,
    handleSuccess,
    type,
    setData
}) => {

    const { t } = useTranslation();
    const {
        control,
        formState,
        handleSubmit,
        errors,
        watch,
        setValue,
        register
    } = useForm({ mode: 'all' });

    const { enqueueSnackbar } = useSnackbar();
    const [saving, setSaving] = useState(false);
    const [submit, setSubmit] = useState(false);

    const handleClick = () => {
        setSubmit(true);
        enqueueSnackbar(t('OrderDetail.info'), infoToast)
        handleClose();
    }

    return (
        <Dialog open={open} disableBackdropClick={isNoClose} onClose={handleClose}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {t('OrderDetail.clientModal.title')}
            </DialogTitle>
            <DialogContent dividers>
                {type === 1 &&
                    <ClientForm
                        dataToEdit={dataToEdit}
                        setData={setData}
                        handleSuccess={handleSuccess}
                        setSubmit={setSubmit}
                        formObject={{
                            control,
                            formState,
                            handleSubmit,
                            setValue,
                            errors,
                            watch,
                            register
                        }}
                        setSaving={setSaving}
                        submit={submit}
                    />
                }
                {type === 2 &&
                    <ShippingInfoForm
                        dataToEdit={dataToEdit}
                        setData={setData}
                        handleSuccess={handleSuccess}
                        setSubmit={setSubmit}
                        formObject={{
                            control,
                            formState,
                            handleSubmit,
                            setValue,
                            errors,
                            watch
                        }}
                        setSaving={setSaving}
                        submit={submit}
                    />
                }
            </DialogContent>
            <DialogActions>
                {!isNoClose &&
                    <Button onClick={handleClose} variant="contained">
                        {t('BilligDetailsModal.cancel')}
                    </Button>
                }
                <Button disabled={!formState.isValid || saving} onClick={handleClick} autoFocus color="primary" variant="contained">
                    {t('BilligDetailsModal.cta')}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(EditOrderDataModal);
