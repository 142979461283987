import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from 'react-responsive';

import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import {
    SelectedImg,
    DnDWrapper, RemoveImgWrapper, BtnRemoveImage, RemoveImg, PreviewImg
} from './ImageCollection.styles';
import UploadImage from '../../../assets/img/upload-image.svg';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

/*const useStyles = makeStyles({
    avatarImage: {
        "&:hover + div": {
            display: 'flex'
        }
    },
});*/

const ImageCollection = ({
    setColleImg,
    colleImg,
    setSelectedImg,
    removeImgFunction
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
    //const classes = useStyles();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/jpeg, image/png, image/tiff, image/webp, image/svg+xml',
        //maxFileSize: 3000000,
        onDrop: acceptedFiles => {
            /*  if (acceptedFiles[0].size > 3000000) {
                  return enqueueSnackbar(acceptedFiles[0].name + ' ' + t('Product.imageTooBig'), errorToast)
              }*/
            setColleImg({
                preview: URL.createObjectURL(acceptedFiles[0]),
            });
            setSelectedImg(acceptedFiles);
        },
    });
    const removeImg = () => setColleImg({});

    return (
        <Card elevation={0} style={{
            marginBottom: isTabletOrMobile ? 38 : 15,
            marginTop: isTabletOrMobile ? 38 : 0
        }}>
            <CardContent>
                <Typography variant="h6" >
                    {t('Collection.uploadImage')}
                </Typography>
                {colleImg && colleImg.preview ? (
                    <PreviewImg>
                        <Avatar style={{
                            margin: '0px auto',
                            alignSelf: 'center',
                            justifyContent: "center",
                            display: "flex",
                            width: 138,
                            height: 138,
                            marginTop: 25,
                            objectFit: 'cover'
                        }} src={colleImg.preview} />
                        {/** <SelectedImg src={colleImg.preview} />*/}
                        <RemoveImg>
                            <RemoveImgWrapper onClick={removeImg}>
                                <DeleteForeverIcon onClick={removeImg} color="#333" size="35px" />
                            </RemoveImgWrapper>
                        </RemoveImg>
                        {colleImg ? (
                            <BtnRemoveImage onClick={colleImg.id ? removeImgFunction : removeImg}>Eliminar</BtnRemoveImage>
                        ) : (<> </>)}
                        
                    </PreviewImg>
                ) : (
                    <>
                        <DnDWrapper {...getRootProps()}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <>
                                    <p>Sueltalo aqui...</p>
                                </>
                            ) : (
                                <>
                                    <img style={{ height: 200 }} src={UploadImage} alt=""></img>
                                </>
                            )}
                        </DnDWrapper>
                        <Typography variant='h6' style={{ fontSize: 13 }} >Dimensiones sugeridas 256x256px</Typography>
                    </>
                )}
                
            </CardContent>
        </Card>
    );
};
export default connect()(ImageCollection);