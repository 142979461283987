import { axiosInstance, serverInstance } from './instance';

/**
 * gets the user store info
 * @param {number} storeId store id
 */
const getBillingInfoService = (storeId) => {
    return axiosInstance.get(`/stores/${storeId}/billing/information`);
}

/**
 * Sets the user store info
 * @param {number} storeId store id
 * @param {Object} newData new user data
 * @param {bollean} previousData user previous data
 */
const setBillingInfoService = (storeId, newData, previousData) => {
    if(previousData)
        return axiosInstance.put(`/stores/${storeId}/billing/information`, newData);
    return axiosInstance.post(`/stores/${storeId}/billing/information`, newData);
}

/**
 * Gets the information of a store
 * @param {number} storeId store id
 */
const getStoreUsageService = (storeId, codeCountry) => {
    return axiosInstance.get(`/stores/${storeId}/billing/${codeCountry}/usage`);
}

const getStorePlans = (storeId, codeCountry) => {
    return axiosInstance.get(`/stores/${storeId}/billing/${codeCountry}/plans`);
}

const getStoreCountries = () => {
    return axiosInstance.get(`/countries`);
}

const getStorePlansPaymentTypes = (storeId, codeCountry, planSelect) => {
    return axiosInstance.get(`/stores/${storeId}/billing/${codeCountry}/plans/${planSelect}/payment-types`);
}

const getStoreInvoicesPendingService = (storeId) => {
    return axiosInstance.get(`/stores/${storeId}/billing/invoices?invoice_status_id.equals=1`);
}

const getStoreInvoicesService = (storeId) => {
    return axiosInstance.get(`/stores/${storeId}/billing/invoices`);
}

const getDetailInvoice = (storeId, idInvoice) => {
    return axiosInstance.get(`/stores/${storeId}/billing/invoices/${idInvoice}`);
}

const savePlanPaymentService = (storeId, data) => {
    return axiosInstance.post(`/stores/${storeId}/billing/plans/payments`, data);
}

const saveBillPaymentService = (storeId, billId, data) => {
    return axiosInstance.post(`/stores/${storeId}/billing/invoices/${billId}/payments`, data);
}

const createPreferenceService = (data) => {
    return serverInstance.post('/mp-preference', data);
}

const downgradePlanService = (storeId, data) => {
    return axiosInstance.put(`/stores/${storeId}/disable-user-stores`, data);
}

export {
    createPreferenceService,
    getBillingInfoService,
    getDetailInvoice,
    getStoreCountries,
    getStoreInvoicesPendingService,
    getStoreInvoicesService,
    getStorePlans,
    getStorePlansPaymentTypes,
    getStoreUsageService,
    saveBillPaymentService,
    savePlanPaymentService,
    setBillingInfoService,
    downgradePlanService
}