import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { formatNumber } from '../../constants.js/misc';
import { OutlinedInput } from '@material-ui/core';

/**
 * Renders the paginated products table
 * @param {number} storeId store id from state
 */
const CurrencyField = ({ defaultValue, storeData, label, name, setValue = false, onChange = false, inputProps, ...rest }) => {
    const [internalValue, setInternalValue] = useState('');
    const [formattedValue, setFormattedValue] = useState('');
    const [numberValue, setNumberValue] = useState('');
    const [initialValue, setInitialValue] = useState(false);

    const handleChange = ({ target }) => {
        const emptyValue = target.value === '';
        const newValue = parseFloat(target.value);

        setFormattedValue(emptyValue ? '' : formatNumber(newValue, storeData.currency));
        setNumberValue(emptyValue ? '' : parseFloat(newValue));
        setInternalValue(emptyValue ? '' : target.value);
        if (setValue) setValue(emptyValue ? '' : parseFloat(newValue));
        return emptyValue ? '' : parseFloat(newValue);
    }

    const handleKeyDown = (e) => {
        if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].indexOf(e.key) === -1) {
            return e.preventDefault();
        }
    }

    const handleBlur = () => setInternalValue(formattedValue);
    const handleFocus = () => setInternalValue(numberValue);

    useEffect(() => {
        if (!isNaN(defaultValue) && initialValue === false) {
            const emptyValue = defaultValue === '';
            const value = defaultValue === null ? 0 : defaultValue;
            setInitialValue(emptyValue ? '' : value);
            setFormattedValue(emptyValue ? '' : formatNumber(value, storeData.currency));
            setInternalValue(emptyValue ? '' : formatNumber(value, storeData.currency));
            setNumberValue(emptyValue ? '' : value);
        }
    }, [defaultValue])

    useEffect(() => {
        if (storeData.currency && numberValue)
            setFormattedValue(formatNumber(numberValue, storeData.currency));
    }, [storeData])

    return (
        <FormControl>
            {label && <FormLabel>{label}</FormLabel>}
            <OutlinedInput
                {...rest}
                inputProps={inputProps}
                value={internalValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                onFocus={handleFocus}
                margin="normal" />
        </FormControl>
    )

}

const mapStateToProps = ({ common }) => ({
    storeData: common.storeData
});

export default connect(
    mapStateToProps
)(CurrencyField);