// ReactJS
import React from "react";

// Icons
import dragIcon from '../../../../assets/icons/dragIcon.svg';
import iconIsCheckBox from '../../../../assets/icons/iconIsCheckBox.svg';
import iconIsRadioButton from '../../../../assets/icons/iconIsRadioButton.svg';
import iconParagraph from '../../../../assets/icons/iconParagraph.svg';
import iconShortQuestion from '../../../../assets/icons/iconShortQuestion.svg';
import iconSomeQuestions from '../../../../assets/icons/iconSomeQuestions.svg';
import iconNumber from '../../../../assets/icons/iconNumber.svg';
import iconEmail from '../../../../assets/icons/iconEmail.svg';
import iconCalendar from '../../../../assets/icons/iconCalendar.svg';
import iconClock from '../../../../assets/icons/iconClock.svg';
import iconRangeDate from '../../../../assets/icons/iconRangeDate.svg';


// MUI Components
import {
    Card,
    Grid,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    TextField,
    Typography,
    makeStyles,
} from "@material-ui/core";

// Components
import DynamicInput from "./DynamicInput";

const useStyles = makeStyles({
    header: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 0,
        width: '100%',
    },
    btnMandatory: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        margin: '20px 0 0 0',
        width: '100%',
    },
    wrapperQuestion: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: '20px 0 0 0',
    },
    wrapperInputs: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '20px 0 0 0',
    },
    wrapperSelects: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: '20px 0 0 0',
    },
    textQuestion: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '20px 20px 0 0'
    }
});

const TypeInput = ({ field, handleRemove, index, register, watch, handleUpdate, errors }) => {
    const classes = useStyles();

    return (
        <Card elevation={1} style={{ padding: 25, width: '100%' }}>
            <Grid item className={classes.header}>
                <Typography
                    variant="body1"
                    style={{ color: '#53627C', fontSize: 24 }}
                >
                    <img src={dragIcon} alt="" style={{ marginRight: 4 }} />
                    Pregunta #{index + 1}
                </Typography>
                <Typography
                    onClick={() => handleRemove({ index: index })}
                    variant="body1"
                    style={{ color: '#CB7C7A', fontSize: 16, cursor: 'pointer' }}
                >
                    Eliminar pregunta
                </Typography>
            </Grid>
            <Grid item className={classes.wrapperQuestion}>
                <Grid item className={classes.textQuestion}>
                    <Typography
                        variant="body1"
                        style={{ color: '#0F2930', fontSize: 14 }}
                    >
                        Pregunta
                    </Typography>
                    <OutlinedInput defaultValue={field?.title} {...register(`dynamicInputs.${index}.title`)} fullWidth />
                    {errors?.dynamicInputs && <Typography style={{ color: '#CB7C7A', fontSize: 12 }}>{errors.dynamicInputs[index]?.title?.message}</Typography>}
                </Grid>
                <Grid item className={classes.textQuestion}>
                    <Typography
                        variant="body1"
                        style={{ color: '#0F2930', fontSize: 14 }}
                    >
                        Tipo de pregunta
                    </Typography>
                    <Select
                        defaultValue={field?.type}
                        {...register(`dynamicInputs.${index}.type`)}
                        disableUnderline
                    >
                        <MenuItem value="input">
                            <img src={iconShortQuestion} alt="" style={{ marginRight: 8 }} />
                            Respuesta corta
                        </MenuItem>
                        <MenuItem value="textarea">
                            <img src={iconParagraph} alt="" style={{ marginRight: 8 }} />
                            Parrafo
                        </MenuItem>
                        <MenuItem value="number">
                            <img src={iconNumber} alt="" style={{ marginRight: 8 }} />
                            Campo numérico
                        </MenuItem>
                        <MenuItem value="email">
                            <img src={iconEmail} alt="" style={{ marginRight: 8 }} />
                            Email
                        </MenuItem>
                        <MenuItem value="radio">
                            <img src={iconSomeQuestions} alt="" style={{ marginRight: 8 }} />
                            Varias opciones
                        </MenuItem>
                        <MenuItem value="checkbox">
                            <img src={iconIsCheckBox} alt="" style={{ marginRight: 8 }} />
                            Casillas
                        </MenuItem>
                        <MenuItem value="select">
                            <img src={iconIsRadioButton} alt="" style={{ marginRight: 8 }} />
                            Desplegable
                        </MenuItem>
                        <MenuItem value="date">
                            <img src={iconCalendar} alt="" style={{ marginRight: 8 }} />
                            Fecha
                        </MenuItem>
                        <MenuItem value="time">
                            <img src={iconClock} alt="" style={{ marginRight: 8 }} />
                            Hora
                        </MenuItem>
                        <MenuItem value="range">
                            <img src={iconRangeDate} alt="" style={{ marginRight: 8 }} />
                            Rango de fechas
                        </MenuItem>
                    </Select>
                    {errors?.dynamicInputs && <Typography style={{ color: '#CB7C7A', fontSize: 12 }}>{errors.dynamicInputs[index]?.type?.message}</Typography>}
                </Grid>
            </Grid>
            {watch(`dynamicInputs.${index}.type`) !== "" &&
                <Grid item className={classes.wrapperInputs}>
                    {watch(`dynamicInputs.${index}.type`) === "input" &&
                        <TextField
                            {...register(`dynamicInputs.${index}.text`)}
                            id="standard-basic"
                            label="Texto de ejemplo de respuesta corta"
                            variant="standard"
                            style={{ width: '50%' }}
                        />
                    }
                    {watch(`dynamicInputs.${index}.type`) === "email" &&
                        <TextField
                            {...register(`dynamicInputs.${index}.text`)}
                            type="email"
                            id="standard-basic"
                            label="Texto de ejemplo de correo"
                            variant="standard"
                            style={{ width: '50%' }}
                        />
                    }
                    {watch(`dynamicInputs.${index}.type`) === "number" &&
                        <TextField
                            {...register(`dynamicInputs.${index}.text`)}
                            id="standard-basic"
                            label="Texto de ejemplo"
                            variant="standard"
                            style={{ width: '50%' }}
                        />
                    }
                    {watch(`dynamicInputs.${index}.type`) === "textarea" &&
                        <TextField
                            {...register(`dynamicInputs.${index}.text`)}
                            id="standard-basic"
                            label="Texto de ejemplo de respuesta párrafo"
                            variant="standard"
                            style={{ width: '50%' }}
                        />
                    }
                    {watch(`dynamicInputs.${index}.type`) === "radio" &&
                        <React.Fragment>
                            <DynamicInput
                                field={field}
                                register={register}
                                watch={watch}
                                index={index}
                                handleUpdate={handleUpdate}
                                inputType="radio"
                            />
                            {errors?.dynamicInputs && <Typography style={{ color: '#CB7C7A', fontSize: 12, marginLeft: 30 }}>{errors.dynamicInputs[index]?.options?.message}</Typography>}
                        </React.Fragment>
                    }
                    {watch(`dynamicInputs.${index}.type`) === "checkbox" &&
                        <React.Fragment>
                            <DynamicInput
                                field={field}
                                register={register}
                                watch={watch}
                                index={index}
                                handleUpdate={handleUpdate}
                                inputType="checkbox"
                            />
                            {errors?.dynamicInputs && <Typography style={{ color: '#CB7C7A', fontSize: 12, marginLeft: 30 }}>{errors.dynamicInputs[index]?.options?.message}</Typography>}
                        </React.Fragment>
                    }
                    {watch(`dynamicInputs.${index}.type`) === "select" &&
                        <React.Fragment>
                            <DynamicInput
                                field={field}
                                register={register}
                                watch={watch}
                                index={index}
                                handleUpdate={handleUpdate}
                                inputType="select"
                            />
                            {errors?.dynamicInputs && <Typography style={{ color: '#CB7C7A', fontSize: 12, marginLeft: 30 }}>{errors.dynamicInputs[index]?.options?.message}</Typography>}
                        </React.Fragment>
                    }
                </Grid>
            }
            <Grid item className={classes.btnMandatory}>
                <Switch defaultChecked={field?.mandatory} {...register(`dynamicInputs.${index}.mandatory`)} />
                <Typography
                    variant="body1"
                    style={{ color: '#1C1D21', fontSize: 14 }}
                >
                    Obligatrio
                </Typography>
            </Grid>
        </Card >
    );
}

export default TypeInput;
