import styled from '@emotion/styled';

export const NameProduct = styled.p`
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 18px;
    color: #48AC98;
    mix-blend-mode: normal;
    opacity: 0.9;
    margin: 8px;
`;


export const Name = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #0F2930;
    line-height: 18px;
    mix-blend-mode: normal;
    margin: 8px;
`;


export const ImagePreviewProduct = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 8px;
`;



