import { Button, Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setShowWelcomePopup } from "../../modules/common";

const DiscountPaymentButton = ({ setShowWelcomePopup }) => {

  const handleClick = async () => {
    const urlPayment =
      "https://treli.co/payment-plan/?username=ecometri&product_id=742450&quantity=1&plan_id=0&plan_currency=COP";
    window.open(urlPayment, "_blank");
    await setShowWelcomePopup(false);
  };

  return (
    <div>
      <Grid item xs={12}>
        <Button variant="contained" color="secondary" onClick={handleClick}>
          Pago online
        </Button>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setShowWelcomePopup }, dispatch);

export default connect(null, mapDispatchToProps)(DiscountPaymentButton)
