import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link as RouterLink } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

/**COMPONENTS */
import ManageImportProducts from '../../components/ManageImportProducts/ManageImportProducts';

import {
    uploadBulkProducts
} from '../../services/products';
import { routes } from '../../constants.js/misc';
import { setProductsFile, setProcessId } from '../../modules/products';

const ImportProductsPage = ({ storeId, setProductsFile, setProcessId }) => {
    const { t } = useTranslation();
    const [fileUpload, setFileUpload] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loadingLoadFile, setLoadingLoadFile] = useState(false);
    const [validFile, setValidFile] = useState(false);

    const [listErrors, setListErrors] = useState([]);

    const uploadFileXlsx = () => {
        if (Object.keys(fileUpload).length > 0) {
            setLoadingLoadFile(true);
            const fileData = new FormData();
            fileData.append('file', fileUpload);
            uploadBulkProducts(storeId, fileData)
                .then(({ data }) => {
                    setProcessId(data.id);
                    setProductsFile(data.items);
                    setValidFile(true);
                    setLoadingLoadFile(false);
                    setSuccessMessage(
                        t('ImportProducts.successMessage')
                        + data.items.length + ' ' +
                        t('ImportProducts.successMessagComple')
                        + data.items.length + ' ' +
                        t('ImportProducts.successMessagCompleImport') + '   ' +
                        t('ImportProducts.successMessagCompleImportPreview'))
                })
                .catch(error => {
                    setProcessId(null);
                    setProductsFile([]);
                    setValidFile(false);
                    setLoadingLoadFile(false);
                    setListErrors(error.response.data.errors);
                });
        }
    }

    useEffect(uploadFileXlsx, [fileUpload]);

    return (
        <Grid item container xs={12} spacing={3}>
            <Grid item xs={6}>
                <Typography
                    variant="h4"
                    style={{ marginBottom: '10px' }}>
                    {t('ImportProducts.Title')}
                </Typography>
                <Link
                    component={RouterLink}
                    to={routes.productsTable}>
                    ‹ {t('ProductsTitle')}
                </Link>
            </Grid>
            <Grid item xs={12}>
                <ManageImportProducts
                    successMessage={successMessage}
                    listErrors={listErrors}
                    setListErrors={setListErrors}
                    fileUpload={fileUpload}
                    setFileUpload={setFileUpload}>
                </ManageImportProducts>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                <Button
                    component={RouterLink}
                    to={routes.massiveUploadTable}
                    color="primary"
                    variant="contained"
                    disabled={!fileUpload || loadingLoadFile || !validFile}
                >
                    {loadingLoadFile ?
                        <CircularProgress
                            size={30}
                            color="secondary" /> :
                        t('ImportProducts.previewProducts')
                    }
                </Button>
            </Grid>

        </Grid>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setProductsFile,
    setProcessId
}, dispatch);

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportProductsPage);
