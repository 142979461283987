import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { Button, CardHeader, MenuItem, Select, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { setOrderStatus } from '../../../services/orders';
import { useSnackbar } from 'notistack';
import { statusOrders } from '../../../constants.js/orders';

const orderStatus = [
    {
        statusId: 1,
        statusName: "Pendiente",
    },
    {
        statusId: 2,
        statusName: "En espera",
    },
    {
        statusId: 3,
        statusName: "Cancelación/Fallido",
    },
    {
        statusId: 4,
        statusName: "Pagado",
    },
    {
        statusId: 5,
        statusName: "Devolución",
    }
]

const OrderStatus = ({ status, storeId, orderId, editMode, generalData, setGeneralData }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [changeState, setChangeState] = useState(false)

    const handleClick = () => {
        setChangeState(!changeState)
    }

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            newOrderState: status,
        }
    });

    // Submit
    const onSubmit = data => {
        setOrderStatus(storeId, orderId, data?.newOrderState, 1)
            .then(res => {
                enqueueSnackbar('Se actualizó correctamente', { variant: 'success' });
                window.location.reload();
            })
            .catch(err => {
                enqueueSnackbar('No se pudo actualizar', { variant: 'error' });
            })
    }

    const saveInfo = () => {
        setGeneralData({ ...generalData, OrderState: watch('newOrderState') })
    }

    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('OrderDetail.statusTitle')} />
            <CardContent style={{ padding: '0 16px 13px 16px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center">
                        <Grid item xs={6}>
                            {!changeState && !editMode
                                ? <Typography variant="body1" style={{ fontWeight: 700, fontSize: '20px' }}>
                                    {statusOrders[status]}
                                </Typography>
                                : <Select
                                    defaultValue={status}
                                    {...register('newOrderState')}
                                    style={{ width: '60%' }}
                                    onClick={editMode && saveInfo}
                                    disableUnderline
                                >
                                    {orderStatus.map(status =>
                                        <MenuItem value={status.statusId}>
                                            {status.statusName}
                                        </MenuItem>
                                    )}
                                </Select>
                            }
                        </Grid>
                        {!editMode && <Grid item xs={6}>
                            {!changeState
                                ? <Button
                                    fullWidth
                                    type='submit'
                                    onClick={handleClick}
                                    variant="outlined"
                                    color="primary">
                                    {t('OrderDetail.changeStatusBtn')}
                                </Button>
                                : <Button
                                    fullWidth
                                    type='button'
                                    variant="contained"
                                    onClick={handleClick}
                                    color="primary">
                                    {t('OrderDetail.shippingStatusBtnConfirm')}{statusOrders[watch('newOrderState')]}
                                </Button>
                            }
                        </Grid>}
                    </Grid>
                </form>
            </CardContent>
        </Card >
    );
};
export default connect()(OrderStatus);