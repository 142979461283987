import React from "react";
import { Grid, Typography, Link, Divider } from "@material-ui/core";
import { Facebook, LinkedIn, Instagram } from "@material-ui/icons";
import logo from "../../assets/img/10x_logo_white.svg";

const AuthFooter = () => {
  return (
    <div style={{ marginTop: "80px" }}>
      <Grid
        container
        spacing={3}
        justify="space-between"
        style={{ backgroundColor: "#3D9D89", color: "#fff", padding: "30px 0" }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="body2">
            ⓒ 2024 Cometric S.A.S. Todos los derechos reservados.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} container justify="flex-end">
          <Link
            href="https://get10x.co/terminos-y-condiciones/"
            color="inherit"
            style={{ marginRight: "20px" }}
          >
            Términos y Condiciones
          </Link>
          <Link
            href="https://get10x.co/politica-de-privacidad/"
            color="inherit"
          >
            Política de Privacidad
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ margin: "20px 0", backgroundColor: "#ffffff" }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <Link href="https://get10x.co" color="inherit">
            <img
              src={logo}
              alt="Logo"
              style={{ height: "50px", marginRight: "20px" }}
            />
          </Link> */}
        </Grid>
        <Grid item xs={12} sm={6} container justify="flex-end" alignItems="center">
          {/* <Link
            href="https://www.facebook.com/Get10x-102337771542496"
            color="inherit"
          >
            <Facebook style={{ fontSize: "30px", marginRight: "10px" }} />
          </Link>
          <Link href="https://www.linkedin.com/company/get10x/" color="inherit">
            <LinkedIn style={{ fontSize: "30px", marginRight: "10px" }} />
          </Link>
          <Link href="https://www.instagram.com/get10x/" color="inherit">
            <Instagram style={{ fontSize: "30px" }} />
          </Link> */}
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </div>
  );
};

export default AuthFooter;
