import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProductImages from '../ManageProduct/ProductImages/ProductImages';
import { successToast, errorToast } from '../../constants.js/misc';
import { createBulkProductImagesService } from '../../services/products';

const MassiveImageUploadModal = ({
    open,
    onClose,
    onSuccess,
    cancelText,
    confirmText,
    productData,
    storeId,
    processId,
    handleImageDeletion
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [files, setFiles] = useState([]);
    const [saving, setSaving] = useState(false);

    /**
     * Function to call after all images are saved
     */
    const handleFilesLoaded = () => {
        enqueueSnackbar(t('Product.createSuccess'), successToast);
    }

    /**
     * Function to call if the images upload fails
     */
    const handleFilesLoadedError = () => {
        enqueueSnackbar(t('Product.imagesError'), errorToast);
    }

    const handleClose = () => {
        onClose();
        setFiles([]);
    }

    const handleOnConfirm = () => {
        setSaving(true);
        createBulkProductImagesService(storeId, processId, productData.sku, files)
            .then(res => {
                onSuccess(res.data.map(image => ({
                    ...image,
                    picture_url: image.s3_file_name
                })));
                handleClose();
                setSaving(false);
                enqueueSnackbar(t('Product.imagesSuccess'), successToast);
            })
            .catch(err => {
                handleClose();
                setSaving(false);
                enqueueSnackbar(t('Product.imagesError'), errorToast);
            });
    }

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent style={{ padding: 0 }}>
                <ProductImages 
                    handleImageDeletion={handleImageDeletion}
                    elevation={0}
                    productData={productData}
                    onFilesLoaded={handleFilesLoaded}
                    onFilesError={handleFilesLoadedError}
                    files={files} setFiles={setFiles} />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    variant="contained"
                    disabled={saving}>
                    {cancelText}
                </Button>
                <Button
                    onClick={handleOnConfirm}
                    color="primary"
                    variant="contained"
                    disabled={!files.length || saving}>
                    {saving ?
                        <CircularProgress
                            size={30}
                            color="secondary" /> :
                            confirmText
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = ({ auth, products }) => ({
    storeId: auth.storeId,
    processId: products.processId
});

export default connect(
    mapStateToProps
)(MassiveImageUploadModal);