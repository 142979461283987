import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CodeQr from '../QRCode/CodeQR'
import { Content, Strike, ContentLink, BtnCopy, UrlLink } from './PreviewLinkStore.styles';

import { successToast } from '../../constants.js/misc';

//MIXPANEL
import { Mixpanel } from '../../constants.js/mixpanel';
import e from 'cors';
import { getStoreService } from '../../services/store';

const PreviewLinkDialog = ({
    open,
    dataStore,
    storeId,
    onClose,
    title,
    message,
}) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const [domain, setDomain] = useState(null)
    const [storeUrl, setStoreUrl] = useState(null)

    useEffect(() => {
        if (open) {
            Mixpanel.track('link de la tienda', {
                "modo previsualizar": "vista de link",
                "nombre de tienda": dataStore.name
            });
        }
        getStoreService(storeId)
        .then(({ data }) => {
            setStoreUrl(data.url) 
        })
            
        if(dataStore) {
            if(dataStore.domain){
                setDomain(dataStore.domain)
            }
        }
    }, [dataStore, open]);

    const copyLink = (url) => {
        document.addEventListener('copy', (e) => {
            e.clipboardData.setData('text/plain', (url));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        enqueueSnackbar(t('UserStores.copied'), successToast);
        Mixpanel.track('Copiar link de tienda', {
            "Copiar": url,
            "nombre de tienda": dataStore.name
        });
    };
    const openLink = (url) => {
        Mixpanel.track('Ir a tienda', {
            "ir a tienda": url,
            "nombre de tienda": dataStore.name
        });
    };

    const urlView = `https://${domain ? domain : (`${storeUrl}.ecometri.shop`)}/${dataStore?.default_user_store ? dataStore?.default_user_store?.mobile_phone_number : 0}`;
    
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {title}
                {onClose ? (
                    <IconButton aria-label="close" style={{
                        position: 'absolute',
                        right: 1,
                        top: 1,
                    }} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    {message}
                    {dataStore &&
                        <Content>
                            <ContentLink>
                                <UrlLink>{urlView}</UrlLink>
                                <BtnCopy>
                                    <Button
                                        onClick={() => copyLink(urlView)}
                                        color="primary"
                                        variant="contained">
                                        {t('PreviewLink.copy')}
                                    </Button>
                                </BtnCopy>
                            </ContentLink>
                            <div style={{ textAlign:'center', margin:'1.5% 0'}}>
                                {t('previewLink.goToStore')}
                                <Link href={urlView} target="_blank">
                                    <Button
                                        onClick={() => openLink(urlView)}
                                        color="primary"
                                        variant="contained">
                                        {t('PreviewLink.iAm') + ' ' + (`${domain ? domain : storeUrl}`)}
                                    </Button>
                                </Link>
                            </div>
                            <div style={{ textAlign:'center', margin:'4.5% 0 0 '}}>
                            <p style={{ color:'#979797'}}>
                                _____________________________________________________________________
                            </p>
                            </div>
                            <div style={{ textAlign:'center', margin:'5% 0 4%'}}>
                                {t('previewLink.qrTitle')}
                                  <CodeQr /> 
                            </div>       
                            
                        </Content>
                    }
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default connect()(PreviewLinkDialog);