import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { CardHeader, Typography } from '@material-ui/core';
import { formatNumber } from '../../../constants.js/misc';

/* const orderStatus = [
    {
        statusId: 1,
        statusName: "Pendiente",
    },
    {
        statusId: 2,
        statusName: "En espera",
    },
    {
        statusId: 3,
        statusName: "Cancelación/Fallido",
    },
    {
        statusId: 4,
        statusName: "Pagado",
    },
    {
        statusId: 5,
        statusName: "Devolución",
    }
] */

const ClientStatus = ({ storeData, quantity, total }) => {
    const { t } = useTranslation();

    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('ClientDetail.statusTitle')} />
            <CardContent style={{ padding: '0 16px 13px 16px' }}>
                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center">
                    <Grid item xs={4}>
                        <Typography variant="body1" style={{ fontWeight: 700, fontSize: '20px' }}>
                            {quantity}
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {t('ClientDetail.ordersQuantity')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" style={{ fontWeight: 700, fontSize: '20px' }}>
                            {formatNumber(total)} {storeData?.currency}
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {t('ClientDetail.accumulatedExpense')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" style={{ fontWeight: 700, fontSize: '20px' }}>
                            {/* TO DO: Valor quemado */}
                            {formatNumber(total/quantity)} {storeData?.currency}
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {t('ClientDetail.averageOrderValue')}
                        </Typography>
                    </Grid>
                </Grid>
                {/* </form> */}
            </CardContent>
        </Card >
    );
};

const mapStateToProps = ({ common }) => ({
    storeData: common.storeData
});

export default connect(
    mapStateToProps
)(ClientStatus);