// ReactJS
import React from "react";
import { connect } from 'react-redux';

// Services
import { } from "../../services/paymentProviders";

// Components
import PaymentProviders from "../../components/PaymentProviders";

const PaymentProvidersPage = ({ storeId }) => {
    return (
        <PaymentProviders storeId={storeId} />
    );
}

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId,
});

export default connect(mapStateToProps)(PaymentProvidersPage);
