// ReactJS
import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

// Services
import {
    setPaymentChannelConfig,
    updatePaymentChannelConfig,
    deletePaymentChannelConfig,
    getPaymentChannelConfig,
    getPaymentChannelVerify,
} from '../../../services/paymentProviders';

// Icon [SVG]
import visibility from '../../../assets/icons/visibility.svg'
import visibilityOff from '../../../assets/icons/visibilityOff.svg'


// Other dependencies
import { useSnackbar } from 'notistack';

// MUI Components
import { Button, Card, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, makeStyles, OutlinedInput, Typography } from "@material-ui/core";

// Components
import { ButtonContainer } from "../../ManageCollection/ManageCollection.styles";
import Loader from "../../Loader";
import { useDropzone } from "react-dropzone";
import ManualPayForm from "./ManualPayForm";
import WhatsAppPayForm from "./WhatsAppPayForm";
import WompiPayForm from "./WompiPayForm";
import { successToast } from "../../../constants.js/misc";

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '1rem'
    },
});

const FormProviderPayments = ({
    methodAccountId,
    methodApikey,
    methodLabelAccountId,
    methodLabelApikey,
    methodId = '7',
    methodName,
    methodDescription,
    methodTestMode,
    nameShop,
    path,
    storeId,
    integrity,
    eventWompiKey,
}) => {
    const isUpdate = path.split('/')[3] === 'update' ? true : false;
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [isDelete, setDelete] = useState(false)
    const [config, setConfig] = useState()
    const [verify, setVerify] = useState()
    const [password, setPassword] = useState(false)

    const [colleImg, setColleImg] = useState({});
    const [selectedImg, setSelectedImg] = useState([]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/jpeg, image/png, image/tiff, image/webp, image/svg+xml',
        onDrop: acceptedFiles => {
            fileToBlob(acceptedFiles[0])
                .then(blob => {
                    setColleImg({
                        preview: blob,
                    })
                })
                .catch(err => {
                    console.log(err)
                })
            setSelectedImg(acceptedFiles);
        },
    });
    const removeImg = () => setColleImg({});

    const showPassword = () => {
        setPassword( prevState => !prevState )
    }

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'all',
    });

    const fileToBlob = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const UrlToHelp = {
        'PayU' : 'https://get10x.notion.site/PayU-584009db744542b1af801e1f8671a911',
        'Wompi': 'https://get10x.notion.site/Wompi-83634c92fe9a479d92af3cf1581f7fe3',
        'Mercado Pago': 'https://get10x.notion.site/Mercado-Pago-582d8c595e814cb4ac8fa7012677756d',
        'Paypal': 'https://get10x.notion.site/PayPal-1bcb73a46e7f480b8a6a9d76db4f21ef'
    }

    const urlEventPaypal = `https://app.ecometri.store/api/v1/notification/paypal/${storeId}`
    const copyLink = () => {
        navigator.clipboard.writeText(urlEventPaypal)
        .then(() => {
            enqueueSnackbar('URL copiada', successToast)
        })
        .catch(err => {
            console.error('Error al copiar al portapapeles:', err)
        })
    }

    const selectUrl = UrlToHelp[methodName]

    const handleClick = () => {
       window.open(selectUrl) 
    }

    const onSubmit = data => {
        const formData = {
            client_id: data.accountId,
            client_secret: data.apiKey,
            extra_parameters: JSON.stringify({
                title: data.title,
                description: data.description,
                qrImage: colleImg.preview,
                instructions: data.instructions,
            }),
            test_mode: data.testMode,
            active: data?.showMethod,
            integrity: data?.integrity,
            event_wompi_key:data?.eventWompiKey,
        }

        if (isUpdate && isDelete) {
            deletePaymentChannelConfig(storeId, methodId)
                .then(res => {
                    enqueueSnackbar('Se a eliminado correctamente', { variant: 'success' });
                    history.push('/config/paymentProviders')
                })
                .catch(err => {
                    enqueueSnackbar('No se pudo eliminar', { variant: 'error' });
                });
        } else if (isUpdate && !isDelete) {
            updatePaymentChannelConfig(storeId, methodId, formData)
                .then(res => {
                    enqueueSnackbar('Se a actualizado correctamente', { variant: 'success' });
                    history.push('/config/paymentProviders')
                })
                .catch(err => {
                    enqueueSnackbar('No se pudo actualizar', { variant: 'error' });
                });
        } else if (!isUpdate && !isDelete) {
            if (Object.keys(config).length !== 0) {
                updatePaymentChannelConfig(storeId, methodId, formData)
                    .then(res => {
                        enqueueSnackbar('Se a agregado correctamente', { variant: 'success' });
                        history.push('/config/paymentProviders')
                    })
                    .catch(err => {
                        enqueueSnackbar('No se pudo crear', { variant: 'error' });
                    })
            } else {
                setPaymentChannelConfig(storeId, methodId, formData)
                    .then(res => {
                        enqueueSnackbar('Se a agregado correctamente', { variant: 'success' });
                        history.push('/config/paymentProviders')
                    })
                    .catch(err => {
                        enqueueSnackbar('No se pudo crear', { variant: 'error' });
                    })
            }
        }
    };

    useEffect(() => {
        getPaymentChannelConfig(storeId, methodId)
            .then(res => {
                setConfig(res.data)
            })
            .catch(err => {
                setConfig({})
                console.log(err);
            });
    }, [methodId, storeId]);

    useEffect(() => {
        getPaymentChannelVerify(storeId, methodId)
            .then(res => {
                setVerify(res.data)
            })
            .catch(err => {
                console.log(err);
            });
    }, [methodId, storeId]);

    return (
        config
            ?
            <Grid container xs={12} md={10} xl={8} className={classes.header}>
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                    <Card elevation={1} style={{ width: '100%', padding: '25px 27px', borderRadius: '8px 8px 0px 0px' }}>
                        <Typography
                            variant="body1"
                            style={{ color: '#53627C', fontSize: 24, marginBottom: '1.5rem' }}
                        >
                            {methodName?.toLowerCase() === 'whatsapp' ? t('Config.addPaymentProvider.Info.TitleWhatsApp') : t('Config.addPaymentProvider.Info.Title')}
                        </Typography>
                        {methodName?.toLowerCase() !== 'pago manual'
                            ? methodName?.toLowerCase() === 'whatsapp'
                                ? <WhatsAppPayForm errors={errors} register={register} isChecked={config?.active} />
                                : methodName?.toLowerCase() === 'wompi'
                                    ? <WompiPayForm 
                                        errors={errors} 
                                        register={register} 
                                        methodLabelAccountId={methodLabelAccountId} 
                                        methodAccountId={methodAccountId} 
                                        config={config} 
                                        methodLabelApikey={methodLabelApikey} 
                                        methodApikey={methodApikey} 
                                        integrity={integrity}
                                        eventWompiKey={eventWompiKey}
                                        storeId={storeId}
                                    />
                                    : <>
                                        <FormControl
                                            type={password ? "text" : "password"}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth>
                                            <FormLabel
                                                variant="body1"
                                                htmlFor="accountId"
                                            >
                                                {/* TODO: Back no envía nombre correctamente cuando es Wompi */}
                                                {methodLabelAccountId}
                                            </FormLabel>
                                            <OutlinedInput
                                                id="accountId"
                                                defaultValue={methodAccountId ? config?.client_id : ''}
                                                fullWidth
                                                error={errors?.accountId ? true : false}
                                                {...register('accountId')}
                                            />
                                            <FormHelperText error={errors?.accountId ? true : false}>{errors?.accountId ? ''/* t('Config.domainInput.nameError') */ : errors?.accountId?.message}</FormHelperText>
                                        </FormControl>

                                        <FormControl
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth>
                                            <FormLabel
                                                variant="body1"
                                                htmlFor="apiKey"
                                            >
                                                {methodLabelApikey}
                                            </FormLabel>
                                            <div style={{ display: 'flex', alignItems: 'center', margin:''}}>
                                            <OutlinedInput
                                                type={password ? "text" : "password"}
                                                id="apiKey"
                                                defaultValue={methodApikey ? config?.client_secret : ''}
                                                fullWidth
                                                error={errors?.apiKey ? true : false}
                                                {...register('apiKey')}
                                            />
                                            <img 
                                                style={{ width:'25px', height: '25px', position: 'absolute', right: '15px', cursor: 'pointer'}}
                                                src={password ? visibilityOff : visibility } 
                                                onClick={showPassword}    
                                            />
                                            </div>
                                            
                                            <FormHelperText error={errors?.apiKey ? true : false}>{errors?.apiKey ? ''/* t('Config.domainInput.nameError') */ : errors?.apiKey?.message}</FormHelperText>
                                        </FormControl>
                                        {methodName?.toLowerCase() === 'paypal' &&
                                            <>
                                                <FormLabel variant="body1">URL de notificación de eventos</FormLabel>
                                                <h4>{urlEventPaypal}</h4>
                                                <p>Copia esta URL de notificación de eventos y pégala en la sección de configuración de tu pasarela de pago. Así, recibirás actualizaciones importantes sobre tus transacciones en tiempo real.</p>
                                                <Button 
                                                    onClick={copyLink}
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    copiar url
                                                </Button>
                                            </>
                                        }

                                        {methodName?.toLowerCase() === 'payu' &&
                                            <FormControl
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth>
                                                <FormLabel
                                                    variant="body1"
                                                    htmlFor="instructions"
                                                >
                                                    MerchantId
                                                </FormLabel>
                                                <OutlinedInput
                                                    id="instructions"
                                                    defaultValue={config?.extra_parameters ? (JSON?.parse(config?.extra_parameters)?.instructions) : ''}
                                                    fullWidth
                                                    error={errors?.instructions ? true : false}
                                                    {...register('instructions')}
                                                />
                                                <FormHelperText error={errors?.instructions ? true : false}>{errors?.instructions ? ''/* t('Config.domainInput.nameError') */ : errors?.instructions?.message}</FormHelperText>
                                            </FormControl>}
                                    </>
                            : <ManualPayForm errors={errors} register={register} methodDescription={methodDescription || config?.extra_parameters} colleImg={colleImg} removeImg={removeImg} isDragActive={isDragActive} getRootProps={getRootProps} getInputProps={getInputProps} />
                        }
                    </Card>

                    <Card
                        elevation={1}
                        style={{
                            marginBottom: '32px',
                            padding: '16px 27px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: '#F8F9FB',
                            borderRadius: '0px 0px 8px 8px',
                        }}
                    >
                        <Typography
                            variant="body1"
                            style={{ color: '#53627C', fontSize: 16, display: 'flex', alignItems: 'center' }}
                        >
                            ¿Necesitas ayuda para conectar tu pasarela de pagos?
                            <span style={{ color: '#48AC98', cursor: 'pointer', textDecoration: 'underline' }} onClick={handleClick}>&nbsp;Más información</span>
                        </Typography>
                    </Card>

                    {methodName.toLowerCase() !== 'whatsapp' && methodName.toLowerCase() !== 'payu' && methodName.toLowerCase() !== 'pago manual'
                        && <Card elevation={1} style={{ width: '100%', marginBottom: '32px', padding: '25px 27px' }}>
                            <Typography
                                variant="body1"
                                style={{ color: '#53627C', fontSize: 24, marginBottom: '1.5rem' }}
                            >
                                {t('Config.addPaymentProvider.testMode.Title')}
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{ color: '#0F2930', fontSize: 16, marginBottom: 25 }}
                            >
                                {t(`Config.addPaymentProvider.testMode.Desc.${methodName}`)}
                            </Typography>

                            <FormControlLabel control={<Checkbox color="primary" defaultChecked={methodTestMode || config?.test_mode} />} label={t('Config.addPaymentProvider.testMode.Checkbox')} {...register('testMode')} />
                        </Card>
                    }

                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        {!isTabletOrMobile &&
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: isUpdate ? 'space-between' : 'flex-end' }}>
                                {isUpdate && methodName.toLowerCase() !== 'whatsapp' &&
                                    <Button
                                        type="submit"
                                        /* disabled={saving || savingCurrency} */
                                        style={{ backgroundColor: '#CB7C7A', color: '#FFFFFF' }}
                                        onClick={() => setDelete(true)}
                                        variant="contained"
                                    >
                                        Desactiva {methodName}
                                    </Button>
                                }
                                <Button
                                    type="submit"
                                    /* disabled={saving || savingCurrency} */
                                    color="primary"
                                    onClick={() => setDelete(false)}
                                    variant="contained"
                                >
                                    {t('SaveChangesBtn')}
                                </Button>
                            </Grid>
                        }

                        {isTabletOrMobile &&
                            <ButtonContainer item xs={12} lg={4}>
                                {isUpdate && methodName.toLowerCase() !== 'whatsapp' &&
                                    <Button
                                        type="submit"
                                        /* disabled={saving || savingCurrency} */
                                        style={{ backgroundColor: '#CB7C7A', color: '#FFFFFF', width: '100%', marginBottom: '1rem' }}
                                        onClick={() => setDelete(true)}
                                        variant="contained"
                                    >
                                        Desactiva {methodName}
                                    </Button>
                                }
                                <Button
                                    style={{ width: '100%' }}
                                    color="primary"
                                    variant="contained"
                                    /*  disabled={saving || savingCurrency} */
                                    onClick={() => setDelete(false)}
                                    type="submit"
                                >
                                    {t('SaveChangesBtn')}
                                </Button>
                            </ButtonContainer>
                        }
                    </Grid>
                </form>
            </Grid >
            :
            <Loader />
    );
}

export default FormProviderPayments;