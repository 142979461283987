import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'

export const Variants = styled.div`
    width: 100%;
    padding: 16px;
`; 
export const OffSetContainer = styled.div`
    width: 100%;
    padding: 10px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`; 
export const ButtonOffSet = styled(Button)`
    color: #48AC98;
    font-weight: 600;
`
export const SaveOptContainer = styled.div`
    width: 100%;
    position: ;
    right: 0;
    text-align: right;
`;
export const VariantsInfoContainer = styled.div`
    width: 100%;
    text-align: right;
`;
export const ButtonDeleteDesk = styled(Button)`
    background-color: #d32f2f;
    color: white;
    margin-bottom: 5px;
    height: 40px;
    @media (max-width: 700px){
        display: none;
    }
`
export const ButtonDeleteMobile = styled(Button)`
    display: none;
    @media (max-width: 699px){
        display: block;
        background-color: #d32f2f;
        color: white;
        margin-bottom: 5px;
        height: 40px;
    }
`
export const PopUpContent = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 5px 5px 15px;
`
export const PopUpButtons = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
`
export const InfoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`