import { Cookies } from 'react-cookie';
import { loginService, signUpService, logoutService, loginCodeService } from '../services/auth';
import { KEY_SELECTED_CATEGORY } from '../constants.js/keys';

export const LOGIN_STATE_TOGGLE = 'auth/LOGIN_STATE_TOGGLE';
export const LOGIN = 'auth/LOGIN';
export const SIGN_UP_STATE_TOGGLE = 'auth/SIGN_UP_STATE_TOGGLE';
export const SIGN_UP = 'auth/SIGN_UP';
export const LOGOUT = 'auth/LOGOUT';
export const SAVE_SIGNUP = 'auth/SAVE_SIGNUP'

const cookies = new Cookies();
const currentUser = cookies.get('user') || {};

const initialState = {
  isUserAuthenticated: false,
  isLoggingIn: false,
  isSigningUp: false,
  userEmail: currentUser.userEmail,
  userType: currentUser.userType,
  userId: currentUser.userId,
  storeId: currentUser.storeId,
  signUpData: {
    email: '',
    password: '',
    store: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_STATE_TOGGLE:
      return {
        ...state,
        ...action.payload,
        isLoggingIn: !state.isLoggingIn,
      }

    case LOGIN:
      return {
        ...state,
        ...action.payload,
        isUserAuthenticated: true,
        isLoggingIn: false,
      }

    case SIGN_UP_STATE_TOGGLE:
      return {
        ...state,
        isSigningUp: !state.isSigningUp,
        ...action.payload,
      }

    case LOGOUT:
      return {
        ...state,
        ...action.payload,
        isUserAuthenticated: false,
        userEmail: null,
        userType: null,
        userId: null,
        storeId: null
      }

    case SAVE_SIGNUP:
      return {
        ...state,
        signUpData: action.payload,
      }

    default:
      return state
  }
}

/**
 * Calls the loginService and executes the given callback
 * @param {string} email user email
 * @param {string} pass user password
 * @param {function} onLogin callback to run after login success
 * @param {function} onError callback to run after login error
 */
export const logIn = (email, pass, onLogin, onError) => {
  return dispatch => {
    dispatch({ type: LOGIN_STATE_TOGGLE });

    loginService(email, pass)
      .then(({ data }) => {
        const user = {
          userEmail: data.user.email,
          userType: data.user.user_type.id,
          userId: data.user.id,
          userPhone: data.user.mobile_number,
          storeId: data.user.default_store_id,
        };
        cookies.set('user', user, { path: '/' });
        onLogin();
        dispatch({
          type: LOGIN,
          payload: user
        });
        dispatch({ type: SIGN_UP_STATE_TOGGLE, isSigningUp: false });
      })
      .catch(err => {
        onError(err);
        dispatch({ type: LOGIN_STATE_TOGGLE });
      });
  }
}


export const logInCode = (code, onLogin, onError) => {
  return dispatch => {
    dispatch({ type: LOGIN_STATE_TOGGLE });

    loginCodeService(code)
      .then(({ data }) => {
        const user = {
          userEmail: data.user.email,
          userType: data.user.user_type.id,
          userId: data.user.id,
          storeId: data.user.default_store_id,
        };
        cookies.set('user', user, { path: '/' });
        onLogin();
        dispatch({
          type: LOGIN,
          payload: user
        });
        dispatch({ type: SIGN_UP_STATE_TOGGLE, isSigningUp: false });
      })
      .catch(err => {
        console.log(err)
        onError(err);
        dispatch({ type: LOGIN_STATE_TOGGLE });
      });
  }
}

/**
 * Logs the use in withou the api call
 */
export const logInDirectly = () => dispatch => {
  dispatch({ type: LOGIN });
}

/**
 * Creates a new user and then logs the new user in
 * @param {Object} signUpData sign up forms data
 */
export const signUp = (
  signUpData,
  onSignUp,
  onError,
  handleRedirectionWelcome,
  handleRegisterOk
) => {
  return (dispatch) => {
    dispatch({ type: SIGN_UP_STATE_TOGGLE });

    signUpService(signUpData)
      .then((res) => {
        if (res.data.error_code === 202) {
          onError("SignUp.error202");
          dispatch({ type: SIGN_UP_STATE_TOGGLE });
        }
        if (res.status === 200) {
          onSignUp(
            signUpData.email,
            signUpData.password,
            () => false,
            () => false
          );
          handleRegisterOk();
          handleRedirectionWelcome();
        }
      })
      .catch((err) => {
        onError(err);
        dispatch({ type: SIGN_UP_STATE_TOGGLE });
      });
  };
};

/**
 * Deletes the token cookie and logs out the user
 */
export const logOut = () => dispatch => {
  cookies.remove('user', { path: '/' });
  localStorage.removeItem(KEY_SELECTED_CATEGORY);
  logoutService().then(() => dispatch({
    type: LOGOUT
  }));
}

/**
 * Saves the signUpForm state in the store
 * @param {Object} payload form data
 */
export const saveSignUpData = (payload) => dispatch => {
  dispatch({
    type: SAVE_SIGNUP,
    payload
  });
}

