import styled from '@emotion/styled';

export const Container = styled.div`
 border-radius: 8px;
 display: flex;
 justify-content: center;
 align-items: center;
 ${props => (props.isMobileProduct ? 'height: 50vh' : 'height: 100vh')};

  @media (min-width: 600px) {
    height: 80vh;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(52, 58, 67, 0.08), 0px 1px 2px rgba(52, 58, 67, 0.08), 0px 0px 2px rgba(52, 58, 67, 0.1);
  }
`;

export const Content = styled.div`
  overflow-y: auto;
  text-align: center;
  flex: 1;
  flex-direction: column;
  max-width: 260px;

  img {
    width: 96px;
    height: 96px;
    border-radius: 8px;
  }

  @media (min-width: 600px) {
    max-width: 100%;

    img {
      width: auto;
      height: auto;
      border-radius: 0px;
    }
  }
`;


export const ContentSubTitle = styled.div`
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
  @media (min-width: 600px) {
    max-width: 500px;
  }
`;



export const ImageView = styled.img`

`;


