import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import collections from './collections';
import orders from './orders';
import products from './products';
import userStores from './user-stores';
import common from './common';

export default (history) => combineReducers({
    router: connectRouter(history),
    auth,
    collections,
    orders,
    products,
    userStores,
    common
});