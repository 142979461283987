import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthForm } from '../../components/AuthForm/AuthForm';
import DefultImg from '../../assets/img/account_reset_v2.jpg';
import RecoveryPasswordForm from '../../components/RecoveryPasswordForm/RecoveryPasswordForm';




const RecoveryPasswordPage = () => {
    const { t } = useTranslation();

    return (
        <AuthForm
            isLogin={true}
            image={DefultImg}
            title={t('RecoveryPassWord.title')}
            subtitle={t('RecoveryPassWord.description')}>
            <RecoveryPasswordForm></RecoveryPasswordForm>
        </AuthForm>
    );
}
export default RecoveryPasswordPage;