import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { CardHeader } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import AutoComplateProduct from '../AutoCompleteProduct/AutoCompleteProduct'
import SortableList from './SortabletListProducts/SortabletListProducts'
import IconOrder from '../../../assets/icons/IconOrder.svg'
import logo from '../../../assets/img/10x_logo.svg';
import { ContainerActions, ContainerSearch, ContainerSort, ContainerImg, ImgLoading } from './productoscollection.styles'

const ProductsCollection = ({ onSortEnd, listDataProduct, setListDataProduct, collectionData }) => {
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [products, setProducts] = useState([])
    const [sortSelect, setSortSelect] = useState(null)
    const [loading, setLoading] = useState(false)

    const sortDataSelect = [
        {
            id: 1,
            name: 'A - Z'
        },
        {
            id: 2,
            name: 'Z - A'
        },
        {
            id: 3,
            name: 'Mayor precio - Menor precio'
        },
        {
            id: 4,
            name: 'Menor precio - Mayor precio'
        },
        {
            id: 5,
            name: 'Más reciente - Más antiguo'
        },
        {
            id: 6,
            name: 'Más antiguo - Más reciente'
        },
    ]

    const handleDelete = (index) => {
        if (collectionData && !collectionData.default) {
            const items = listDataProduct
            items.splice(index, 1)
            setListDataProduct([...items])
        }
    };
    const handleSelectChange = ({ target }) => {
        setSortSelect(target.value)
        setProducts([])
        setLoading(true)
    }

    useEffect( ()=> {
        if(listDataProduct.length > 0){
            setProducts(listDataProduct)
        }
    }, [listDataProduct] )

    useEffect( () => {
        switch (sortSelect) {
            case 1:
                setTimeout(() => {
                    setProducts(listDataProduct.sort( (a, b) => a.name.localeCompare(b.name) ))
                    setLoading(false)
                }, 1000)
              break
            case 2:
                setTimeout(() => {
                    setProducts(listDataProduct.sort( (a, b) => b.name.localeCompare(a.name) ))
                    setLoading(false)
                }, 1000)
            break
            case 3: 
            setTimeout(() => {
                setProducts(listDataProduct.sort( (a, b) => (b.price - a.price) ) )
                setLoading(false)
            }, 1000)
            break
            case 4:
                setTimeout(() => {
                    setProducts(listDataProduct.sort( (a, b) => (a.price - b.price) ) )
                    setLoading(false)
                }, 1000)
            break
            case 5:
                setTimeout(() => {
                    setProducts(listDataProduct.sort( (a, b) => (new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) ) )
                    setLoading(false)
                }, 1000)
            break
            case 6:
                setTimeout(() => {
                    setProducts(listDataProduct.sort( (a, b) => (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()) ) )
                    setLoading(false)
                }, 1000)
            break
            default:
              
          }
}, [sortSelect] )
   

    return (
        <Card style={{ marginTop: isTabletOrMobile ? 38 : 15 }} elevation={0}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('ProductsTitle')} />
            <div style={{ paddingLeft: 15 }}>
                <Typography
                    component="span"
                    variant="body1"
                    color="textPrimary">
                    {t('Collection.productDescriptionAdd')}
                </Typography>
            </div>
            <CardContent>
                <ContainerActions>
                    <ContainerSearch>
                        <AutoComplateProduct listDataProduct={listDataProduct} setListDataProduct={setListDataProduct}></AutoComplateProduct>
                    </ContainerSearch>
                    <ContainerSort>
                    <FormLabel
                        style={{ margin: '8px' }}>
                        <img style={{ marginRight: 5 }} src={IconOrder} />
                        {t('Product.filter.order')}
                    </FormLabel>
                    <FormControl variant="outlined"  style={{ paddingTop: '5px' }}>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={sortSelect}
                            onChange={handleSelectChange}
                            label="SortSelect"
                            placeholder='Selecciona'
                            disabled={ products.length <= 1 }
                            >
                            {   sortDataSelect.map( product => (
                                    <MenuItem key={product.id} value={product.id}>{product.name}</MenuItem>
                                ))
                            }
                           
                        </Select>
                    </FormControl>
                    </ContainerSort>
                </ContainerActions>
                { loading ? 
                    <ContainerImg>
                        <ImgLoading src={logo} alt="logo" />
                        <Typography>Ordenando tus productos...</Typography>
                    </ContainerImg>
                    :
                    <SortableList 
                        pressDelay={100} 
                        useDragHandle 
                        items={products} 
                        handleClick={handleDelete}
                        onSortEnd={onSortEnd} 
                        setLoading={setLoading} 
                        sortSelect={sortSelect}>

                    </SortableList>
                }
            </CardContent>
        </Card >
    );
};

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});


export default connect(
    mapStateToProps
)(ProductsCollection);