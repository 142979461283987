import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useMediaQuery } from 'react-responsive';


import { Link as RouterLink, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import ProductInfo from '../../components/ManageProduct/ProductInfo/ProductInfo';
import ProductImages from '../../components/ManageProduct/ProductImages/ProductImages';
import ProductPrice from '../../components/ManageProduct/ProductPrice/ProductPrice';
import ProductVariants from '../../components/ManageProduct/ProductVariants/ProductVariants';
import ProductAvailability from '../../components/ManageProduct/ProductAvailability/ProductAvailability';
import ProductTags from '../../components/ManageProduct/ProductTags/ProductTags';
import ProductCollections from '../../components/ManageProduct/ProductCollections/ProductCollections';
import DeleteProductDialog from '../../components/ManageProduct/DeleteProductDialog/DeleteProductDialog';
import DataSheetProduct from '../../components/ManageProduct/DataSheetProduct/DataSheetProduct';
import ProductInfoAdditional from '../../components/ManageProduct/ProductInfoAdditional/ProductInfoAdditional';
import QRCodeProduct from '../../components/QRCode/QRCodeProduct'

import { ButtonContainer, Intro } from '../../components/ManageCollection/ManageCollection.styles';

import ProductsIcon from "../../assets/icons/products.svg";
//MIXPANEL
import { Mixpanel } from '../../constants.js/mixpanel';

import {
    routes,
    errorToast,
    successToast
} from '../../constants.js/misc';

import {
    createProductService,
    getProductService,
    updateProductService,
    deleteProductService,
    saveAttachment,
    removeAttachmentProduct,
    createProductServiceAdditionalInfo,
    updateProductServiceAdditionalInfo,
    getAdditionalInfo,
    deleteProductServiceAdditionalInfo
} from '../../services/products';

import { setProductToEdit } from '../../modules/products';

import { Container } from './ManageProductPage.styles';
import { updateProductInventory } from '../../services/inventory';

const ManageProductPage = ({ storeId, setProductToEdit, productToEdit }) => {
    const { t } = useTranslation();
    const history = useHistory();
    let { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [gettingProduct, setGettingProduct] = useState(false);
    const [loading, setLoading] = useState(false);
    const [productData, setProductData] = useState(false);
    const [productId, setProductId] = useState(false);
    const [files, setFiles] = useState([]);
    const [share, setShare] = useState(false);
    const [productDataAdditional, setProductDataAdditional] = useState([
        {
            title: '',
            description: ''
        }
    ]);

    const [filesDataSheet, setFilesDataSheet] = useState('');

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [options, setOptions] = useState([{
        name: '',
        options: []
    }]);
    const [variants, setVariants] = useState([]);
    const [hasVariants, setHasVariants] = useState(false);
    

    const {
        handleSubmit,
        control,
        formState,
        formState: { errors },
        setValue,
        getValues,
        watch
    } = useForm({
        mode: 'onChange'
    });

    const isValid = Object.keys(errors)?.length === 0;
    const MAX_CHARACTERS = 2011

    const handleShare = () => {
        if (!share) {
            setShare(true)
        } else {
            setShare(false)
        }
    }

    const normalizeData = (data, optionsData) => {
        return {
            name: data.name,
            sku: data.sku,
            description: data.description.substring(0, MAX_CHARACTERS),
            price: +data.price,
            discount_price: +data.discountPrice,
            active: data.active ? 1 : 0,
            tags: data.tags ? data.tags.reduce((prev, current) => `${prev}${prev.length ? ',' : ''} ${current}`, '') : '',
            collections_id: data.collections,
            quantity_available: data.quantity_available !== null && data.quantity_available !== '' && optionsData.variants.length === 0 ? Number(data.quantity_available) : null,
            product_options: optionsData.options ? optionsData.options.map((option, index) => ({
                name: option.name,
                order: index,
                options: option.options.map(optionOption => ({
                    name: optionOption,
                    value: optionOption
                }))
            })) : null,
            product_variants: optionsData.variants.map((variant, index) => ({
                discount_price: variant.discount_price !== null && variant.discount_price !== '' ? variant.discount_price : null,
                options: variant.options,
                price: variant.price,
                quantity: variant.quantity,
                sku: variant.sku,
            }))
        }
    }
    
    const onSubmit = (data) => {
        const optionsData = getOptionsData();

        const functionToCall = id ? updateProductService : createProductService;

        if (!optionsData) {
            enqueueSnackbar(t('Product.checkVariants'), errorToast);
            return;
        }

        setLoading(true);

        functionToCall(storeId, normalizeData(data, optionsData), id)
            .then(async res => {
                let promisesInfoAdditional = [];

                if (!id) {
                    Mixpanel.track('Agregar producto', {
                        "Nombre": normalizeData(data, optionsData).name,
                        "descripcion": normalizeData(data, optionsData).description,
                        "precios": normalizeData(data, optionsData).price,
                        "variaciones": normalizeData(data, optionsData).product_variants,
                        "categoria": normalizeData(data, optionsData).collections_id,
                        "Subir imagen": files.length > 0 ? "foto" : '',
                        "Cantidad de fotos": files.length
                    });

                    if (Object.keys(filesDataSheet).length > 0) {
                        const imgData = new FormData();
                        imgData.append('files', filesDataSheet);
                        saveAttachment(storeId, res.data.id, imgData)
                            .then(res => { })
                            .catch(err => { });
                    }
                }

                if (productDataAdditional.length > 0) {
                    productDataAdditional.forEach((op) => {
                        promisesInfoAdditional.push(
                            op.id ? updateProductServiceAdditionalInfo(storeId, id ? id : res.data.id, op) : createProductServiceAdditionalInfo(storeId, id ? id : res.data.id, op)
                        )
                    });
                    await Promise.all(promisesInfoAdditional);
                }

                //updateProductInventory(storeId, res.data.id, normalizeData(data, optionsData))

                setProductId(res.data.id);
            })
            .catch(err => {
                enqueueSnackbar(id ? t('Product.updateError') : t('Product.createError'), errorToast);
                setLoading(false);
            });
    };

    const handleFilesLoaded = () => {
        setLoading(false);
        enqueueSnackbar(id ? t('Product.updateSuccess') : t('Product.createSuccess'), successToast);
        history.push(isTabletOrMobile ? routes.homeUrl : routes.productsTable);
    }

    const handleFilesLoadedError = () => {
        enqueueSnackbar(id ? t('Product.updateSuccess') : t('Product.createSuccess'), successToast);
        enqueueSnackbar(t('Product.imagesError'), errorToast);
        history.push(isTabletOrMobile ? routes.homeUrl : routes.productsTable);
    }

    const deleteProduct = () => {
        if (id && productData) {
            setCancelDialogOpen(false);
            setLoading(true);
            deleteProductService(storeId, id)
                .then(() => {
                    setLoading(false);
                    enqueueSnackbar(t('Product.deleteSuccess'), successToast);
                    history.push(routes.productsTable);
                })
                .catch(() => {
                    setLoading(false);
                    enqueueSnackbar(t('Product.deleteError'), errorToast)
                })
        }
    }

    const handleImageDeletion = (imageId) => {
        setProductData({
            ...productData,
            product_images: productData.product_images.filter(img => img.id !== imageId)
        });
    }

    const getOptionsData = () => {
        if (!hasVariants) return { options: [], variants: [] };

        const validOptions = options.filter(option => option.name && option.options.length).length === options.length;
        // used if the sku and price are required for the variants
        // const validvariants = variants.filter(variant => variant.price && variant.sku).length === variants.length;
        if (validOptions) {
            return {
                options: hasVariants ? options : [],
                variants: hasVariants ? variants.map(variant => ({ ...variant, price: +variant.price })) : []
            }
        }
        return false;
    }

    const deleteAdditionalInfo = (index, data) => {
        const items = productDataAdditional;
        items.splice(index, 1);
        setProductDataAdditional([...items]);
        if (data.id) {
            deleteProductServiceAdditionalInfo(storeId, data.product_id, data).then(res => { });
        }
    }

    const deleteTagFile = () => {
        if (id) {
            removeAttachmentProduct(storeId, id, filesDataSheet.id)
                .then(res => {
                    setFilesDataSheet('');
                    enqueueSnackbar(`Archivo eliminado exitosamente`, {
                        variant: 'success',
                        autoHideDuration: 2500
                    });
                })
                .catch(err => {
                    enqueueSnackbar(`Problemas al eliminar el archivo`, {
                        variant: 'error',
                        autoHideDuration: 2500
                    });
                });
        } else {
            setFilesDataSheet('');
        }
    }

    const handleFileDataSheetChange = files => {
        setFilesDataSheet(files[0]);
        if (id) {
            const imgData = new FormData();
            imgData.append('files', files[0]);
            saveAttachment(storeId, id, imgData)
                .then(res => {
                    enqueueSnackbar(`Archivo subido exitosamente`, {
                        variant: 'success',
                        autoHideDuration: 2500
                    });
                })
                .catch(err => {
                    enqueueSnackbar(`Problemas al subir el archivo`, {
                        variant: 'error',
                        autoHideDuration: 2500
                    });
                });
        }
    }

    useEffect(() => {
        if (id) {
            getAdditionalInfo(storeId, id)
                .then(({ data }) => {
                    if (data.length > 0) {
                        setProductDataAdditional(data);
                    }
                });
        }
    }, [id, storeId])

    useEffect(() => {
        if (id) {
            setGettingProduct(true);
            getProductService(storeId, id)
                .then(res => {
                    setGettingProduct(false);
                    setFilesDataSheet(res.data.product_attachments.length > 0 ? res.data.product_attachments[0] : '')
                    setProductData(res.data);
                })
                .catch(err => {
                    setGettingProduct(false);
                    enqueueSnackbar(t('Product.dataError'), errorToast);
                    history.push(routes.productsTable);
                });
        }
    }, [enqueueSnackbar, history, id, storeId, t])

    useEffect(() => {
        setProductToEdit(null)
    }, [setProductToEdit]);
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Container container spacing={isTabletOrMobile ? 0 : 3}>
                {isTabletOrMobile &&
                    <Intro item container xs={12}>
                        <img src={ProductsIcon} alt="" />
                        <Typography variant="caption">
                            {t('ProductsTitle')}
                        </Typography>
                    </Intro>
                }
                <Grid item container xs={12} style={{ padding: isTabletOrMobile ? 20 : '' }}>
                    <Grid item xs={6} >
                        <Typography
                            variant={isTabletOrMobile ? 'h5' : 'h4'}
                            style={{ marginBottom: '10px' }}>
                            {id ? t('Product.updateTitle') : t('Product.createTitle')}
                        </Typography>
                        <Link
                            component={RouterLink}
                            to={isTabletOrMobile ? routes.homeUrl : routes.productsTable}
                        >
                            {isTabletOrMobile ? t('MobileFooter.10x') : t('Product.back')}
                        </Link>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div>
                            {(id && productData) &&
                                <Grid>
                                    <Button
                                        onClick={() => handleShare()}
                                        style={{ marginRight: '10px' }}
                                        variant="contained"
                                        color="initial"
                                        size="small"
                                    >
                                        {t('product.shareTitle')}
                                    </Button>
                                    <Button
                                        disabled={loading}
                                        color="inherit"
                                        size="small"
                                        onClick={() => setCancelDialogOpen(true)}>
                                        {t('Product.deleteTitle')}
                                    </Button>
                                </Grid>

                            }

                            {!id && !isTabletOrMobile &&
                                <Button
                                    disabled={loading}
                                    component={RouterLink}
                                    to={routes.productsTable}>
                                    {t('Product.cancelButton')}
                                </Button>
                            }
                        </div>
                    </Grid>
                </Grid>
                {gettingProduct && <Grid item xs={12}><LinearProgress /></Grid>}
                {!gettingProduct && <Grid item xs={12} lg={8}>
                    {share &&
                        <QRCodeProduct
                            isTabletOrMobile={isTabletOrMobile}
                            productData={productData}
                            handleShare={handleShare}
                        />
                    }
                    {isTabletOrMobile &&
                        <ProductImages
                            isTabletOrMobile={isTabletOrMobile}
                            handleImageDeletion={handleImageDeletion}
                            productData={productData}
                            productId={productId}
                            onFilesLoaded={handleFilesLoaded}
                            onFilesError={handleFilesLoadedError}
                            files={files}
                            setFiles={setFiles} />}
                    <ProductInfo
                        isTabletOrMobile={isTabletOrMobile}
                        productData={productData}
                        control={control}
                        setValue={setValue}
                        maxChars={MAX_CHARACTERS}
                        prodDescription={watch('description')}
                        errors={errors} />
                    {!isTabletOrMobile &&
                        <ProductImages
                            isTabletOrMobile={isTabletOrMobile}
                            handleImageDeletion={handleImageDeletion}
                            productData={productData}
                            productId={productId}
                            onFilesLoaded={handleFilesLoaded}
                            onFilesError={handleFilesLoadedError}
                            files={files}
                            setFiles={setFiles} />}
                    <ProductVariants
                        isTabletOrMobile={isTabletOrMobile}
                        hasVariants={hasVariants}
                        setHasVariants={setHasVariants}
                        options={options}
                        setOptions={setOptions}
                        variants={variants}
                        setVariants={setVariants}
                        getValues={getValues}
                        productData={productData} />

                    <ProductInfoAdditional
                        onDeleteAdditional={deleteAdditionalInfo}
                        isTabletOrMobile={isTabletOrMobile}
                        setProductDataAdditional={setProductDataAdditional}
                        optionsInfoAdditional={productDataAdditional}
                    />

                </Grid>}
                {!gettingProduct && <Grid item xs={12} lg={4}>
                    <ProductPrice
                        isTabletOrMobile={isTabletOrMobile}
                        watch={watch}
                        productData={productData}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                    />
                    <ProductAvailability
                        isTabletOrMobile={isTabletOrMobile}
                        productData={productData}
                        hasVariants={hasVariants}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                    />
                    <ProductCollections
                        isTabletOrMobile={isTabletOrMobile}
                        productData={productData}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                    />
                    <DataSheetProduct
                        isTabletOrMobile={isTabletOrMobile}
                        fileUpload={filesDataSheet}
                        deleteTagFile={deleteTagFile}
                        handleChangeDataSheet={handleFileDataSheetChange}
                    />

                    {isTabletOrMobile &&
                        <ButtonContainer
                            item
                            xs={12}
                            lg={4}>
                            <Button
                                style={{ width: '100%' }}
                                color="primary"
                                variant="contained"
                                disabled={(id ? !isValid : !formState.isValid) || loading}
                                type="submit">
                                {id ? t('Product.updateButton') : t('Product.createButton')}
                            </Button>
                        </ButtonContainer>
                    }
                    {!isTabletOrMobile &&
                        <Button
                            type="submit"
                            disabled={(id ? !isValid : !formState.isValid) || loading || gettingProduct}
                            variant="contained"
                            color="primary">
                            {loading ?
                                <CircularProgress
                                    size={30}
                                    color="secondary" /> :
                                id ? t('Product.updateButton') : t('Product.createButton')
                            }
                        </Button>
                    }
                </Grid>}
            </Container>
            {(id && productData) &&
                <DeleteProductDialog
                    open={cancelDialogOpen}
                    onConfirm={deleteProduct}
                    onClose={() => setCancelDialogOpen(false)}
                    title={t('Product.deleteTitle')}
                    message={t('Product.deleteMessage')}
                    cancelText={t('Product.deleteCancel')}
                    confirmText={t('Product.deleteConfirm')}
                />
            }
        </form>
    );
};

const mapStateToProps = ({ auth, products }) => ({
    storeId: auth.storeId,
    productToEdit: products.productToEdit
});

const mapDispatchToProps = dispatch => bindActionCreators({ setProductToEdit }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageProductPage);