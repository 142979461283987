import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@material-ui/core';
import React from 'react'
import { CenteredContent } from './WelcomePage.styles';
import rocketIcon from '../../assets/icons/rocketIcon.svg'
import DiscountPaymentButton from '../../components/DiscountPaymentButton/DiscountPaymentButton';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setShowWelcomePopup } from '../../modules/common';


const WelcomePage = ({ setShowWelcomePopup }) => {

  const handleClick = () => {
    setShowWelcomePopup(false);
  };

  const handleGoWhatsapp = () => {
    const messageNumber = "573003154556";
    const message =
      "Hola! Quiero más información sobre 10X y sus planes para implementarlo en mi negocio.";
    window.open(
      `https://wa.me/${messageNumber}?text=${encodeURIComponent(message)}`
    );
  };
  return (
    <Box
      style={{
        backgroundColor: "#F7F8FE",
        padding: "10px 0",
      }}
    >
      <Container maxWidth="md">
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          onClick={handleClick}
        >
          <i
            className="ri-close-line"
            style={{ fontSize: "25px", fontWeight: 600, cursor: "pointer" }}
          />
        </Grid>
        <CenteredContent>
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            {/* Título centrado */}
            <Typography
              variant="h2"
              gutterBottom
              align="center"
              color="secondary"
            >
              Hola, ¡Bienvenido a 10x!
            </Typography>
            {/* Descripción */}
            <Typography variant="body1" gutterBottom align="center">
              El primer sistema Ecommerce para recibir pedidos <br /> por
              WhatsApp o por donde quieras.
            </Typography>
          </div>
        </CenteredContent>
        <CenteredContent>
          {/* Contenedor central */}
          <Card style={{ width: "95%" }}>
            <CardContent
              container
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
                alignItems: "center",
                paddingTop: "24px",
              }}
            >
              {/* Imagen
              <Grid item xs={12}>
                <img
                  src={rocketIcon}
                  alt="Imagen"
                  style={{ maxWidth: "30px", height: "auto" }}
                />
              </Grid> */}
              {/* Botón */}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <i
                    class="ri-bank-card-2-line"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <DiscountPaymentButton />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "12px",
                  }}
                >
                  <i class="ri-whatsapp-line" style={{ fontSize: "20px" }}></i>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleGoWhatsapp}
                  >
                    Contactar agente
                  </Button>
                </div>
              </Box>

              {/* Enlace */}
              <Grid item xs={12}>
                <Button
                  underline="always"
                  color="secondary"
                  onClick={handleClick}
                >
                  <u>No, despues</u>
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </CenteredContent>
        <Grid style={{ height: "25px" }}></Grid>
      </Container>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setShowWelcomePopup }, dispatch);

export default connect(null, mapDispatchToProps)(WelcomePage)