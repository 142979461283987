import styled from "@emotion/styled";
import { Box } from "@material-ui/core";

export const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
`;

export const ButtonBox = styled(Box)`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;