import styled from '@emotion/styled';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import UploadLogo from '../../../assets/img/upload-image.svg';

export const FileUpload = styled.div`
    .MuiDropzoneArea-icon {
        display: none;
    }

    .MuiDropzoneArea-root {
       /**
        min-height: 160px !important;
        background-image: url(${UploadLogo});
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center top; */
        border: none;
        border-color: initial;
        margin-top: 4px;
    }
    .MuiDropzoneArea-root::after{
        width: 100%;
        content: " ";
        background-image: url(${UploadLogo});
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: -22px;
        height: 100%;
   }
   
   
    *{
        outline: none;
    }


    .MuiDropzonePreviewList-imageContainer {
        width: 100% !important;
        max-width: 100% !important;
        flex-basis: 100% !important;
        align-items: center;
        display: flex;
    }

    .MuiDropzonePreviewList-removeButton {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 5;
    }

    .MuiDropzonePreviewList-image {
        width: 100%;
        height: auto;
    }

    &.has-image {
        .MuiDropzoneArea-text {
            display: none !important;
        }
    }
`
export const ChangeUrlContainer = styled.span`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 13px;

    div {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    b {
        margin: 10px 0;
    }
`
export const ButtonContainer = styled(Grid)`
  padding: 10px;
  position: fixed;
  width: 100%;
  bottom: 72px;
`