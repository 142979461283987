import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';


// material ui dependencies
import {
    Card, CardContent,
    CardHeader, FormHelperText,
    OutlinedInput,
    Select
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';


//Services
import { getRoles } from '../../services/users';


// internal dependencies
import { routes } from '../../constants.js/misc';


// styles
const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    }, avatarName: {
        color: '#266678',
        float: 'left',
        background: '#F5D6BA',
        fontSize: 18,
        lineHeight: 23,
        marginRight: 10,
        textTransform: 'uppercase'
    }
});

export const ManageUserRole = ({ storeId }) => {
    const { t } = useTranslation();

    const [roles, setRoles] = useState([]);
    const [generalConfigModule, setGeneralConfigModule] = useState([]);
    const [configModule, setConfigModule] = useState([]);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const {
        handleSubmit,
        control,
        formState,
        errors,
        setValue,
        getValues
    } = useForm({
        mode: 'onChange'
    });


    const classes = useStyles();


    useEffect(() => {
        if (roles.length === 0) {
            getRoles().then(({ data }) => {
                setGeneralConfigModule(data[0].permission_role.filter(x => x.permission.module.id === 1));
                setConfigModule(data[0].permission_role.filter(x => x.permission.module.id === 2));
                setRoles(data);
            });
        }
    }, [roles]);

    const handleChange = () => {

    }

    const handleCheckboxChange = () => {

    }



    return (

        <Grid container spacing={isTabletOrMobile ? 0 : 3} style={{ justifyContent: 'center', marginTop: 10 }}>
            <Grid item container xs={12} md={10} xl={8} className={classes.container} style={{
                justifyContent: 'flex-start',
                padding: isTabletOrMobile ? 20 : '0 0 20px'
            }}>
                <Grid item xs={6}>
                    <Typography
                        variant={isTabletOrMobile ? 'h5' : 'h4'}
                        style={{ marginBottom: '10px' }}>
                        {t('UserRole.members.newUserTitle')}
                    </Typography>
                    <Link
                        component={RouterLink}
                        to={routes.usersRoles}>
                        ‹ {t('UserRole.backTitle')}
                    </Link>
                </Grid>


                <Grid item xs={12}>
                    <Card container spacing={3} style={{ width: '100%', marginTop: 10 }}>
                        <CardHeader
                            titleTypographyProps={{ variant: 'h6' }}
                            title={t('UserRole.members.infoTitle')} />
                        <CardContent>

                            <Grid container spacing={3}>

                                <Grid item xs={12} lg={6}>
                                    <FormControl>
                                        <FormLabel error={errors?.name ? true : false}>{t('UserRole.members.name')}*</FormLabel>
                                        <Controller
                                            render={({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    defaultValue=""
                                                    error={errors.name ? true : false}
                                                    margin="normal"
                                                />
                                            )}
                                            control={control}
                                            defaultValue=""
                                            name="name"
                                            rules={{ required: true }}
                                        />
                                        <FormHelperText error={errors?.name ? true : false}>{errors?.name ? t('ManageUserStore.errors?.name') : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <FormControl>
                                        <FormLabel error={errors?.lastname ? true : false}>{t('UserRole.members.lastname')}*</FormLabel>
                                        <Controller
                                            render={({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    defaultValue=""
                                                    error={errors.lastname ? true : false}
                                                    margin="normal"
                                                />
                                            )}
                                            control={control}
                                            defaultValue=""
                                            name="lastname"
                                            rules={{ required: true }}
                                        />
                                    </FormControl>
                                </Grid>


                                <Grid item xs={12} lg={6}>
                                    <FormControl>
                                        <FormLabel error={errors?.email ? true : false}>{t('UserRole.members.email')}*</FormLabel>
                                        <Controller
                                            render={({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    defaultValue=""
                                                    error={errors.email ? true : false}
                                                />
                                            )}
                                            control={control}
                                            margin="normal"
                                            name="email"
                                            rules={{ required: true }}
                                        />
                                    </FormControl>
                                </Grid>


                                <Grid item xs={12} lg={6}>
                                    <FormControl>
                                        <FormLabel error={errors?.role ? true : false}>{t('UserRole.members.role')}*</FormLabel>
                                        <Controller
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    native
                                                    variant="outlined"
                                                    fullWidth
                                                    error={errors.role ? true : false}
                                                    margin="normal"
                                                >
                                                    <option value=''>...</option>
                                                    {roles.map(type => <option value={type.id}>{type.name}</option>)}
                                                </Select>
                                            )}
                                            control={control}
                                            rules={{ required: true }}
                                            name="role" >
                                        </Controller>
                                    </FormControl>
                                </Grid>


                            </Grid>



                        </CardContent>
                    </Card>
                </Grid>


                <Grid item xs={12}>
                    <Card container spacing={3} style={{ width: '100%', marginTop: 10 }}>
                        <CardHeader
                            titleTypographyProps={{ variant: 'h6' }}
                            title={t('UserRole.members.permits')} />
                        <CardContent>

                            <FormControlLabel
                                control={<Checkbox color="primary" checked={false} onChange={handleChange} name="checkAll" />}
                                label={t('UserRole.members.permitsAll')} />

                            <Grid container style={{ marginTop: 10 }}>

                                <Grid item xs={6}>
                                    <Typography variant={'h6'}>
                                        {t('UserRole.members.permits.general')}
                                    </Typography>
                                    <FormGroup>
                                        {generalConfigModule.map(option =>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={option.permission.checked} onChange={({ target }) => handleCheckboxChange(option, target.checked)} name={option.permission.name} />}
                                                label={option.permission.name}
                                            />
                                        )}
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={'h6'}>
                                        {t('UserRole.members.permits.config')}
                                    </Typography>
                                    <FormGroup>
                                        {configModule.map(option =>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={option.permission.checked} onChange={({ target }) => handleCheckboxChange(option, target.checked)} name={option.permission.name} />}
                                                label={option.permission.name}
                                            />
                                        )}
                                    </FormGroup>
                                </Grid>

                            </Grid>

                        </CardContent>
                    </Card>


                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        <Button
                            color="primary"
                            variant="contained"
                        >
                            {t('UserRole.members.permits.sendInvitation')}
                        </Button>
                    </Grid>

                </Grid>



            </Grid>
        </Grid >
    )


};


const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId,
});

export default connect(
    mapStateToProps
)(ManageUserRole);