// React dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

// External dependencies
import { useSnackbar } from 'notistack';

// Material dependecies
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

// Internal dependencies
import EditIcon from "../../assets/icons/edit.svg";
import UploadPhoto from "../../assets/img/massive-photos.png";
import successIcon from "../../assets/img/check-1.png";
import { routes, errorToast } from '../../constants.js/misc';
import { saveBulkProducts } from '../../services/products';
import useDebounce from '../../utils/debounce';
import AutoCompleteSubCollection from '../../components/ManageCollection/AutoCompleteSubCollection/AutoCompleteSubCollection';
import CurrencyField from '../../components/CurrencyField/CurrencyField';
import MassiveImageUploadModal from '../../components/MassiveImageUploadModal/MassiveImageUploadModal';

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: '1rem'
    },
    success: {
        width: '100%',
        marginTop: '1rem',
        padding: 40
    },
    successContainer: {
        minHeight: 180,
        background: '#EFF3EF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    container: {
        maxHeight: 'calc(100vh - 231px)',
    },
    spacer: {
        '& .MuiTablePagination-spacer': {
            flex: 0
        }
    },
    tableHeader: {
        display: 'flex',
        padding: '16px',
        flexDirection: 'column'
    },
    smallField: {
        fontSize: '14px',
        border: 'none',
        boxShadow: 'none'
    },
    image: {
        width: 80,
        height: 80,
        objectFit: 'cover',
        cursor: 'pointer'
    },
    placeholder: {
        width: 80,
        height: 'auto',
        cursor: 'pointer'
    },
    editBtn: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 250,
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    }

});

export const MassiveUploadTable = ({ storeId, userId, productsFile, processId }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    const columns = [
        { id: 'pictures', label: t('MassiveUploadTable.headers.pictures'), width: 'auto' },
        { id: 'name', label: t('MassiveUploadTable.headers.name'), width: 130 },
        { id: 'sku', label: t('MassiveUploadTable.headers.sku'), width: 110 },
        { id: 'price', label: t('MassiveUploadTable.headers.price'), width: 110 },
        { id: 'discountPrice', label: t('MassiveUploadTable.headers.discountPrice'), width: 110 },
        { id: 'description', label: t('MassiveUploadTable.headers.description'), width: 'auto' },
        { id: 'categories', label: t('MassiveUploadTable.headers.categories'), width: 'auto' },
        { id: 'variants', label: t('MassiveUploadTable.headers.variants'), width: 190 },
    ];

    // State variables
    const [gettingData, setGettingData] = useState(false);
    const [page, setPage] = useState(0);
    const [saving, setSaving] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [term, setTerm] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [saved, setSaved] = useState(false);
    const [currentRow, setCurrentRow] = useState({
        product_images: []
    });
    const [currentRowIndex, setCurrentRowIndex] = useState({});
    const [rows, setRows] = useState(productsFile.map((row, index) => ({
        ...row,
        product_images: [],
        globalIndex: index,
        showVariants: false,
        collectionsArray: row.collections.split(',')
    })));

    const debouncedTerm = useDebounce(term, 600);

    // Functions

    /**
     * Sets the current page
     * @param {Event} event native event, not used
     * @param {number} newPage page to show
     */
    const handleChangePage = (event, newPage) => setPage(newPage);

    /**
     * Sets the rows per page variable and goes back
     * to the first page
     * @param {Event} event  native click event
     */
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(+event.target.value);
    };

    const toggleVariantsTable = (rowIndex) => setRows(rows.map((row, index) => ({
        ...row,
        showVariants: index === rowIndex ? !row.showVariants : row.showVariants
    })));

    const changeRowData = (rowIndex, field, value) => {
        return setRows(rows.map((row, index) => ({
            ...row,
            [field]: index === rowIndex ? value : row[field]
        })))
    };

    const deleteCollection = (rowIndex, collection) => setRows(rows.map((row, index) => ({
        ...row,
        collectionsArray: index === rowIndex ? row.collectionsArray.filter(col => col !== collection) : row.collectionsArray
    })));

    const addCollection = (rowIndex, collection) => {
        if (!collection) return;

        setRows(rows.map((row, index) => ({
            ...row,
            collectionsArray: index === rowIndex ? [...row.collectionsArray, collection.name] : row.collectionsArray
        })));
    };

    const startSaving = () => {
        setSaving(true);
        saveBulkProducts(
            storeId,
            rows.map(row => ({
                ...row,
                collections: row.collectionsArray.reduce((prev, current) => `${prev + current},`, '')
            })),
            processId
        )
            .then(res => {
                setSaved(true);
                setSaving(false);
            })
            .catch(err => {
                setSaving(false);
                enqueueSnackbar(t('MassiveUploadTable.saveError'), errorToast)
            });
    }

    const openModal = (row) => {
        setCurrentRow(row);
        setCurrentRowIndex(row.globalIndex);
        setModalOpen(true);
    }

    const handleImageDeletion = (id) => {
        changeRowData(currentRowIndex, 'product_images', rows[currentRowIndex].product_images.filter(image => image.id !== id));
    }

    const closeModal = () => setModalOpen(false);

    useEffect(() => {
        if (productsFile.length === 0) {
            enqueueSnackbar(t('MassiveUploadTable.needsFile'), errorToast);
            history.push(routes.importProducts);
        }
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        style={{ marginBottom: '10px' }}>
                        {t('MassiveUploadTable.title')}
                    </Typography>
                    <Link
                        component={RouterLink}
                        to={routes.importProducts}>
                        ‹ {t('MassiveUploadTable.back')}
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    {saved &&
                        <Paper className={classes.success}>
                            <div className={classes.successContainer}>
                                <img src={successIcon} />
                                <Typography variant="body1">
                                    {t('MassiveUploadTable.successMessage')}
                                    <Link
                                        component={RouterLink}
                                        to={routes.productsTable}>
                                        {t('MassiveUploadTable.goToProducts')}
                                    </Link>
                                </Typography>
                            </div>
                        </Paper>
                    }
                    {!saved && <Paper className={classes.root}>
                        <div className={classes.tableHeader}>
                            <Typography
                                variant="h6"
                                style={{ marginBottom: '10px' }}>
                                {t('MassiveUploadTable.tableTitle')}
                            </Typography>
                            <TextField
                                variant="outlined"
                                placeholder={t('MassiveUploadTable.tableTerm')}
                                onChange={({ target }) => setTerm(target.value)} value={term} />
                        </div>
                        <TableContainer className={classes.container}>
                            <div style={{ minHeight: '4px' }}>
                                {gettingData && <LinearProgress />}
                            </div>
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ width: column.width }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .filter(row => row.name.toLowerCase().includes(debouncedTerm) || row.sku.toLowerCase().includes(debouncedTerm))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) =>
                                            <React.Fragment>
                                                <TableRow hover tabIndex={-1} key={index}>
                                                    <TableCell style={{ position: 'relative' }}>
                                                        {row.pictures ?
                                                            <>
                                                                <img onClick={() => openModal(row)} className={classes.image} src={row.pictures[0]} />
                                                                <IconButton onClick={() => openModal(row)} aria-label="edit" className={classes.editBtn}>
                                                                    <img src={EditIcon} />
                                                                </IconButton>
                                                            </> :
                                                            <img src={row.product_images.length ? process.env.REACT_APP_IMAGES_API_URL + row.product_images[0].picture_url : UploadPhoto} className={row.product_images.length ? classes.image : classes.placeholder} onClick={() => openModal(row)} />
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            inputProps={{
                                                                className: classes.smallField
                                                            }}
                                                            onChange={e => changeRowData(row.globalIndex, 'name', e.target.value)}
                                                            color="secondary"
                                                            value={row.name}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            inputProps={{
                                                                className: classes.smallField
                                                            }}
                                                            onChange={e => changeRowData(row.globalIndex, 'sku', e.target.value)}
                                                            color="secondary"
                                                            value={row.sku}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <CurrencyField
                                                            inputProps={{ className: classes.smallField }}
                                                            defaultValue={row.price}
                                                            setValue={value => changeRowData(row.globalIndex, 'price', value)} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <CurrencyField
                                                            inputProps={{ className: classes.smallField }}
                                                            defaultValue={row.discount_price}
                                                            setValue={value => changeRowData(row.globalIndex, 'discount_price', value)} />
                                                    </TableCell>
                                                    <TableCell style={{ maxWidth: '250px' }}>
                                                        <TextField
                                                            variant="outlined"
                                                            inputProps={{
                                                                className: classes.smallField
                                                            }}
                                                            multiline
                                                            onChange={e => changeRowData(row.globalIndex, 'description', e.target.value)}
                                                            color="secondary"
                                                            value={row.description}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={classes.chipContainer}>
                                                            {row.collectionsArray.map(collection =>
                                                                <Chip
                                                                    style={{ marginRight: 4 }}
                                                                    size="small"
                                                                    label={collection}
                                                                    onDelete={() => deleteCollection(index, collection)}
                                                                />
                                                            )}
                                                            <AutoCompleteSubCollection
                                                                className={classes.smallField} setEventAdd={val => addCollection(index, val)} />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.variations &&
                                                            <Link className={classes.smallField} onClick={() => toggleVariantsTable(index)}>
                                                                {!row.variations.length && t('MassiveUploadTable.noVariants')}
                                                                {row.variations.length && row.showVariants ? t('MassiveUploadTable.hide') : t('MassiveUploadTable.toggle')}
                                                                {!row.variations.length && '+'}
                                                            </Link>
                                                        }

                                                    </TableCell>
                                                </TableRow>
                                                {row.variations && row.variations.length && <TableRow>
                                                    <TableCell style={{ padding: 0, background: '#F8F9FB' }} colSpan={8}>
                                                        <Collapse in={row.showVariants} timeout="auto" unmountOnExit>
                                                            <Table size="small" aria-label="variants">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>{t('MassiveUploadTable.childHeaders.variant')}</TableCell>
                                                                        <TableCell>{t('MassiveUploadTable.childHeaders.quantity')}</TableCell>
                                                                        <TableCell>{t('MassiveUploadTable.childHeaders.price')}</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {/* {row.variations.map(variation =>
                                                                        variation.options.split(',').map((option, variationIndex) =>
                                                                            <TableRow key={variation.name + option + variationIndex}>
                                                                                <TableCell>{variation.name}</TableCell>
                                                                                <TableCell>{option}</TableCell>
                                                                            </TableRow>
                                                                        ))} */}
                                                                    {row.product_variants.map(item =>
                                                                        <TableRow key={item.sku + item.price}>
                                                                            <TableCell>{item.sku}</TableCell>
                                                                            <TableCell>{item.quantity !== null ? item.quantity : `${t('MassiveUploadTable.childTableContent.null')}` }</TableCell>
                                                                            <TableCell>{item.price}</TableCell>
                                                                        </TableRow>
                                                                    )

                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>}
                                            </React.Fragment>
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            shape="rounded"
                            variant="outlined"
                            labelRowsPerPage={t('table.rowsPerPageLabel')}
                            labelDisplayedRows={({ from, to, count }) => `${t('table.toShow')} ${to} ${t('table.of')} ${count} ${t('table.products')}`}
                            rowsPerPageOptions={rows.length > 0 ? [10, 25, 50] : []}
                            component="div"
                            className={classes.spacer}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                    }
                </Grid>
                {!saved &&
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        <Button
                            onClick={startSaving}
                            color="primary"
                            variant="contained"
                            disabled={false}>
                            {saving ?
                                <CircularProgress
                                    size={30}
                                    color="secondary" /> :
                                t('MassiveUploadTable.startLoading')
                            }
                        </Button>
                    </Grid>
                }
            </Grid>
            <MassiveImageUploadModal
                handleImageDeletion={handleImageDeletion}
                productData={rows[currentRowIndex]}
                pictures={currentRow.pictures}
                open={modalOpen}
                onClose={closeModal}
                onSuccess={images => changeRowData(currentRowIndex, 'product_images', images)}
                title={t('MassiveUploadTable.modalTitle')}
                cancelText={t('MassiveUploadTable.modalCancel')}
                confirmText={t('MassiveUploadTable.modalConfirm')} />
        </Grid>
    );
}

const mapStateToProps = ({ auth, products }) => ({
    storeId: auth.storeId,
    userId: auth.userId,
    productsFile: products.productsFile,
    processId: products.processId
});

export default connect(
    mapStateToProps
)(MassiveUploadTable);