import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { routes, formatNumber } from '../../../constants.js/misc';
import { Button, FormControl, FormHelperText, Grid, makeStyles, MenuItem, OutlinedInput, Select, TextField, Typography } from '@material-ui/core';
import CurrencyField from '../../CurrencyField/CurrencyField';
import { Autocomplete } from '@material-ui/lab';
import { getProducts, getProductsOrder } from '../../../services/products';

// Assets
import deleteIcon from "../../../assets/icons/deleteIcon.svg";
import { Controller } from 'react-hook-form';
import { DivContainerTable } from '../../../constants.js/containers';
import styled from '@emotion/styled';

const useStyles = makeStyles({
    container: {
        display: 'block',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '0px 16px',
    },
    card: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: 26,
        marginBottom: 30
    },
    title: {
        fontSize: '22px',

    },
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1rem',
        width: '100%',
    },
    button: {
        display: 'none',
    },
    numberFieldIcon: {
        width: '10%',
        color: '#48AC98',
        lineHeight: 0,
    },
    numberFieldCode: {
        width: '15%',
    },
    numberFieldWhatsapp: {
        width: '85%',
        textAlign: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        margin: 0,
    },
    total: {
        padding: '16px',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
    },
});

const Helper = styled(FormControl)`
    &:focus-within {
        p {
            visibility: visible;
            color: #53627C;
            transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        };
        &:hover {
            p {
                font-size: 1rem;
                transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            };
        };
        
    }
`;

const OrderProductsTableToEdit = ({ deliveryCost, storeData, control, register, watch, handleSubmit, setValueShipping, useFieldArray, saveInfo, submitProductsTable }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [otherValue, setOtherValue] = useState('');
    const [subtotal, setSubtotal] = useState(0);
    const [openProduct, setOpenProduct] = useState(false);
    const [productOptions, setProductOptions] = useState([]);

    const { fields, append, remove } = useFieldArray({
        control: control,
        name: 'products',
    });

    const handleAppend = useCallback((data) => {
        data.length === 1
            && append({
                product_id: data[0]?.id,
                name: data[0]?.name,
                optionsProduct: data[0]?.product_options,
                variantsProduct: data[0]?.product_variants,
                sku: data[0]?.sku,
                quantity: 1,
                price: data[0]?.price,
                total: data[0]?.price,
            });

    }, [append])

    const handleRemove = useCallback(({ index }) => {
        remove(index);
    }, [remove])

    const handleSubtotal = useCallback(() => {
        let subtotal = 0;
        for (let i = 0; i < fields.length; i++) {
            subtotal += fields[i]?.price * Number(watch(`products.${i}.quantity`));
        }
        setSubtotal(subtotal)
    })

    useEffect(() => {
        handleSubtotal();
    }, [fields, handleSubtotal]);

    useEffect(() => {
        getProducts(storeData?.id, value?.name)
            .then(res => {
                handleAppend(res?.data)
                setProductOptions(res?.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [storeData, value, handleAppend])

    useEffect(() => {
        otherValue?.length >= 3 && getProducts(storeData?.id, otherValue)
            .then(res => {
                setProductOptions(res?.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [storeData, otherValue, handleAppend])

    const calcDeliveriCost2 = () => {
        return deliveryCost !== (0 || null)
            ? deliveryCost
            : (storeData?.delivery_cost_enabled
                ? isNaN(storeData?.delivery_cost_details)
                    ? 0
                    : parseInt(storeData?.delivery_cost_details)
                : 0)
    }

    const getDefaultVariant = (product, i) => {
        const variant = product?.order_item_option?.map(variant => variant?.product_option_value?.value)
        return Array.isArray(variant) ? variant[i] : variant
    }

    return (
        <form onSubmit={handleSubmit(saveInfo)} onLoad={handleSubmit(saveInfo)} onChange={handleSubmit(saveInfo)} className={classes.container}>
            <Typography style={{ marginTop: 18 }}>
                {t('OrdersTablePage.searchProduct')}
            </Typography>
            <Autocomplete
                defaultValue={value}
                onChange={(e, value) => setValue(value)}
                style={{ width: '100%' }}
                open={openProduct}
                onOpen={() => {
                    setOpenProduct(true);
                }}
                onClose={() => {
                    setOpenProduct(false);
                }}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={productOptions}
                renderInput={(params) => (
                    <Helper>
                        <FormHelperText>
                            **{t('OrdersTablePage.searchProduct.anotation')}
                        </FormHelperText>
                        <TextField
                            {...params}
                            {...register('addProducts')}
                            onChange={(e) => setOtherValue(e?.target?.value)}
                            variant="outlined"
                            placeholder="Buscar producto" />

                    </Helper>
                )}
            />
            <DivContainerTable>
                <Table style={{ backgroundColor: '#F8F9FB', marginTop: 32 }} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('OrdersTablePage.products.headers.product')}</TableCell>
                            <TableCell>{t('OrdersTablePage.headers.variants')}</TableCell>
                            <TableCell>{t('OrdersTablePage.products.headers.sku')}</TableCell>
                            <TableCell>{t('OrdersTablePage.products.headers.amount')}</TableCell>
                            <TableCell>{t('OrdersTablePage.products.headers.price')}</TableCell>
                            <TableCell>{t('OrdersTablePage.products.headers.total')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((product, index) => (
                            <TableRow>
                                <TableCell className={classes.centerTable}>
                                    <Link
                                        component={RouterLink}
                                        to={routes.updateProduct + '/' + product?.product?.id}
                                        color="primary"
                                    >
                                        {product?.product?.name || product?.name}
                                    </Link>
                                </TableCell>

                                <TableCell>
                                    {(product?.order_item_option?.length === 0) || (product?.optionsProduct?.length === 0)
                                        ? <>{t('OrdersTablePage.products.noVariants')}</>
                                        : <Grid container spacing={1}>
                                            {(product?.product?.product_options || product?.optionsProduct)?.map((variant, newIndex) => (
                                                <Grid item xs={6}>
                                                    <Select
                                                        {...register(`products.${index}.variants.${newIndex}`)}
                                                        id='variants'
                                                        onClick={handleSubmit(saveInfo)}
                                                        defaultValue={getDefaultVariant(product, newIndex)}
                                                        input={<OutlinedInput fullWidth />}
                                                    >
                                                        {variant?.options?.map((option, index) => (
                                                            <MenuItem key={index} value={option?.name}>
                                                                {option?.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    }
                                </TableCell>

                                <TableCell>{product?.product?.sku || product?.sku}</TableCell>

                                <TableCell>
                                    <TextField
                                        {...register(`products.${index}.quantity`)}
                                        onClick={handleSubtotal}
                                        onKeyUp={handleSubtotal}
                                        variant="outlined"
                                        defaultValue={1}
                                        InputProps={{ inputProps: { min: 1 } }}
                                        type='number'
                                        style={{ width: 70 }}
                                    />
                                </TableCell>

                                <TableCell>{formatNumber(product?.price)}</TableCell>
                                <TableCell>{formatNumber(product?.price * watch(`products.${index}.quantity`))}</TableCell>
                                <TableCell onClick={handleSubmit(saveInfo)}>
                                    <img src={deleteIcon} alt="delete" onClick={() => handleRemove({ index: index })} style={{ cursor: 'pointer' }} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DivContainerTable>
            <div className={classes.total}>
                <div style={{ marginRight: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography gutterBottom variant="body1">Subtotal:</Typography>
                    <Typography gutterBottom variant="body1">Costos de envio:</Typography>
                    <Typography gutterBottom variant="body1">Total:</Typography>
                </div>
                <div>
                    <Typography gutterBottom variant="body1" style={{ textAlign: 'right' }}>{formatNumber(subtotal)}</Typography>
                    <Typography gutterBottom variant="body1">
                        <Controller
                            render={({ field }) => (
                                <CurrencyField
                                    {...field}
                                    /* {...register('shipping_cost')} */
                                    setValue={val => setValueShipping('shipping_cost', val)}
                                    style={{ width: '120px', textAlignLast: 'right' }}
                                    defaultValue={calcDeliveriCost2()}
                                    /* name="shipping_cost" */
                                    variant="standard"
                                />)}
                            name="shipping_cost"
                            control={control}
                            //onChange={saveInfo}
                            rules={{ required: true }}
                            defaultValue={calcDeliveriCost2()}
                        />
                    </Typography>
                    <Typography gutterBottom variant="body1" style={{ textAlign: 'right' }}>
                        {formatNumber(subtotal + Number(watch('shipping_cost')))}
                    </Typography>
                </div>
            </div>
            <Button
                className={classes.button}
                fullWidth
                ref={submitProductsTable}
                type='submit'
                variant="contained"
                color="primary">
                {t('OrderDetail.saveBtn')}
            </Button>
        </form>
    );
};

const mapStateToProps = ({ common }) => ({
    storeData: common.storeData
});

export default connect(
    mapStateToProps
)(OrderProductsTableToEdit);