import React from "react"
import { 
    ArticleCtn,
    Tag,
    TagContainer 
} from "./styles.TagBestseller"

const TagBestSeller = ()  => {
    return(
        <ArticleCtn>
            <TagContainer>
                <Tag>Más vendido | 50% Off</Tag>
            </TagContainer>
        </ArticleCtn>
    )
}

export default TagBestSeller