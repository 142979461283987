import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { CardHeader, FormHelperText, OutlinedInput, Typography, } from '@material-ui/core';

const ProductInfo = ({ 
    isTabletOrMobile, 
    control, 
    errors, 
    productData, 
    setValue, 
    prodDescription, 
    maxChars
}) => {
    const { t } = useTranslation()
    const tagCh = productData?.description ? maxChars + 4 :  maxChars  //ReactQuill guarda una etiquta de cierre al final </p>
    const totalChars = tagCh - prodDescription?.length

    useEffect(() => {
        setValue('name', productData.name);
        setValue('sku', productData.sku);
        if ( productData.description ) 
            setValue('description', productData?.description)
    }, [productData, setValue]);

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike']
        ],
    }
    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('Product.titleAdd')} />
            <CardContent>
                <Grid container spacing={isTabletOrMobile ? 0 : 3}>

                    <Grid item xs={12} lg={6}>
                        <FormControl>
                            <FormLabel error={errors?.name ? true : false}>{t('Product.nameProduct')}*</FormLabel>
                            <Controller
                                render={({ field }) => (
                                    <OutlinedInput
                                        {...field}
                                        defaultValue=""
                                        error={errors.name ? true : false}
                                        margin="normal"
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                name="name"
                                rules={{ required: true }}
                            />
                            <FormHelperText error={errors?.name ? true : false}>{errors?.name ? t('Product.errors?.name') : ''}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <FormControl>
                            <FormLabel error={errors?.sku ? true : false}>{t('Product.skuProduct')}</FormLabel>
                            <Controller
                                render={({ field }) => (
                                    <OutlinedInput
                                        {...field}
                                        defaultValue=""
                                        error={errors.sku ? true : false}
                                        margin="normal"
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                name="sku"
                            //rules={{ required: true }}
                            />
                            <FormHelperText error={errors?.sku ? true : false}>{errors?.sku ? t('Product.errors?.sku') : ''}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel error={errors.description ? true : false}>{t('Product.descriptionProduct')}</FormLabel>
                            <Controller
                                defaultValue=""
                                render={({ field }) => (
                                    <ReactQuill
                                        {...field}
                                        modules={modules}
                                        theme="snow"
                                        defaultValue=""
                                        margin="normal"
                                    />
                                )}
                                control={control}
                                name="description"
                                rules={{ maxLength: { value: 2014 } }}
                            />
                             <FormHelperText error={errors.description ? true : false}>{errors.description ? t('UserStore.errorStoreDescription') : ''}</FormHelperText>
                        </FormControl>
                        { (prodDescription?.length > 0 ) &&
                            <Typography>{t('Product.availableCharacters')} {totalChars}</Typography>
                        }
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    );
};
export default connect()(ProductInfo);