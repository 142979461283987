import styled from '@emotion/styled';
import Grid from '@material-ui/core/Grid';

export const ButtonContainer = styled(Grid)`
  padding: 10px;
  position: fixed;
  width: 100%;
  bottom: 55px;
  background: white;
`;

export const Intro = styled(Grid)`
  padding: 20px;
  color: #53627C;
  border-bottom: 1px solid #dadadc;
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
    width: 19px;
    height: 19px;
  }
`;