import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { CardHeader, FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CurrencyField from '../../CurrencyField/CurrencyField';


//import { numberRegex } from '../../../constants.js/misc';

const ProductPrice = ({ isTabletOrMobile, control, errors, setValue, productData, watch }) => {
    const { t } = useTranslation();

    const [price, setPrice] = useState();
    const [discountPrice, setDiscountPrice] = useState();

    useEffect(() => {
        setValue('price', productData.price);
        setPrice(productData.price);
        setValue('discountPrice', productData.discount_price);
        setDiscountPrice(productData.discount_price);
    }, [productData, setValue]);

    useEffect(() => setValue('discountPrice', ''), []);

    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('Product.price')} />
            <CardContent>
                <Grid container spacing={isTabletOrMobile ? 0 : 3}>
                    <Grid item xs={12} lg={6}>
                        <FormControl>
                            <FormLabel error={errors?.price ? true : false}>{t('Product.normalPrice')}</FormLabel>

                            <Controller
                                render={({ field }) => (
                                    <CurrencyField
                                        {...field}
                                        defaultValue={price}
                                        name="price"
                                        setValue={val => setValue('price', val)}
                                        variant="standard"
                                    />
                                )}
                                name="price"
                                control={control}
                                defaultValue=""
                            />

                            <FormHelperText error={errors?.price ? true : false}>{errors?.price ? t('Product.errors?.price') : ''}</FormHelperText>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} lg={6}>
                        <FormControl>
                            <FormLabel>{t('Product.discountPrice')}</FormLabel>

                            <Controller
                                render={({ field }) => (
                                    <CurrencyField
                                        {...field}
                                        defaultValue={discountPrice}
                                        name="discountPrice"
                                        setValue={val => setValue('discountPrice', val)}
                                        variant="standard"
                                    />
                                )}
                                name="discountPrice"
                                control={control}
                                defaultValue=""
                            />

                            <FormHelperText error={errors?.discountPrice ? true : false}>{errors?.discountPrice ? t('Product.errors?.discountPrice') : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    );
};
export default connect()(ProductPrice);