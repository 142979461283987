// react dependencies
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';


// external dependencies
import { useSnackbar } from 'notistack';

// material ui dependencies
import { FormHelperText, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';

import { Popper } from '@material-ui/core';

// Internal dependencies
import { commaSeparatedEmailsRegex, errorToast } from '../../../constants.js/misc';
import { getDocumentTypesService } from '../../../services/misc';

const ClientForm = ({
    storeId,
    dataToEdit,
    setData,
    handleSuccess,
    formObject,
    setSaving,
    submit,
    setSubmit,
    selectedCountryBilling,
    changeCountry
}) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [documentTypes, setDocumentTypes] = useState([]);

    const formRef = useRef(null);

    const {
        control,
        handleSubmit,
        errors,
    } = formObject;

    const getDocumenTypes = () => {
        getDocumentTypesService()
            .then(res => setDocumentTypes(res.data))
            .catch(err => enqueueSnackbar(t('BilligDetailsModal.documentTypesError'), errorToast))
    }

    const normalizeData = data => ({
        ...data,
        phone_number: dataToEdit?.phone_number
    });

    const onSubmit = data => {
        /* setSaving(true);
        setBillingInfoService(storeId, normalizeData(data), dataToEdit)
            .then(res => {
                setSubmit(false);
                setSaving(false);
                handleSuccess();
            })
            .catch(err => {
                setSubmit(false);
                setSaving(false);
                enqueueSnackbar(t('BilligDetailsModal.submitError'), errorToast);
            }); */
        //setData(normalizeData(data))
        setData(data)
    }

    useEffect(() => {
        getDocumenTypes();
    }, []);

    useEffect(() => {
        if (submit) {
            handleSubmit(onSubmit)();
        }
    }, [submit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <Grid container spacing={isTabletOrMobile ? 2 : 3}>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel error={errors?.first_name ? true : false}>{t('OrderDetail.clientModal.firstName')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.first_name ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="first_name"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={dataToEdit?.first_name}
                        />
                        <FormHelperText error={errors?.first_name ? true : false}>{errors?.first_name ? t('OrderDetail.clientModal.firstNameError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel error={errors?.last_name ? true : false}>{t('OrderDetail.clientModal.lastName')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.last_name ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="last_name"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={dataToEdit?.last_name}
                        />
                        <FormHelperText error={errors?.last_name ? true : false}>{errors?.last_name ? t('OrderDetail.clientModal.lastNameError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel error={errors?.phone_number ? true : false}>{t('OrderDetail.clientModal.phone')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.phone_number ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="phone_number"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={dataToEdit?.phone_number}
                        />
                        <FormHelperText error={errors?.phone_number ? true : false}>{errors?.phone_number ? t('OrderDetail.clientModal.phone') : ''}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel error={errors?.document_type ? true : false}>{t('OrderDetail.clientModal.idType')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    native
                                    variant="outlined"
                                    fullWidth
                                    error={errors?.document_type ? true : false}
                                    margin="normal"
                                >
                                    <option value="CC">
                                        CC
                                    </option>
                                    <option value="TI">
                                        TI
                                    </option>
                                    <option value="Pasaporte">
                                        Pasaporte
                                    </option>
                                </Select>
                            )}
                            control={control}
                            /* rules={{ required: true }} */
                            name="document_type"
                            defaultValue={dataToEdit.document_type ? dataToEdit.document_type : 'CC'}
                        >
                        </Controller>
                        <FormHelperText error={errors?.document_type ? true : false}>{errors?.document_type ? t('OrderDetail.clientModal.idTypeError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl>
                        <FormLabel error={errors?.dni ? true : false}>{t('OrderDetail.clientModal.dni')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.dni ? true : false}
                                    margin="normal"
                                />
                            )}
                            control={control}
                            /* rules={{ required: true }} */
                            name="dni"
                            defaultValue={dataToEdit.dni ? dataToEdit.dni : null}
                        />
                        <FormHelperText error={errors?.dni ? true : false}>{errors?.dni ? t('OrderDetail.clientModal.dniError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <FormControl>
                        <FormLabel error={errors?.email ? true : false}>{t('OrderDetail.clientModal.email')}</FormLabel>
                        <Controller
                            variant="outlined"
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.email ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="email"
                            control={control}
                            rules={{ required: true, pattern: commaSeparatedEmailsRegex }}
                            defaultValue={dataToEdit.email ? dataToEdit.email : null}
                        />
                        <FormHelperText error={errors?.email ? true : false}>{errors?.email ? t('OrderDetail.clientModal.emailError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>

            </Grid>
        </form >
    );
};

const mapStateToProps = ({ auth, common }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId,
    countries: common.countries
});

export default connect(
    mapStateToProps
)(ClientForm);
