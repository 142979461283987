import React, { useState } from 'react'
import { SortableContainer } from 'react-sortable-hoc';

import Grid from '@material-ui/core/Grid';

import Collection from '../Collection/Collection';

const ListCollections = SortableContainer(({ items, setItems, handleClick }) => {
    return (
        <Grid container spacing={3} >
            {items.map((data, index) => (
                <Collection
                    key={`item-${index}`}
                    i={index}
                    index={index}
                    handleItemClick={handleClick}
                    idCollection={data.id}
                    defaultCheck={data.default}
                    imgCollection={data.picture_url || ''}
                    nameCollection={data.name}
                    productsData={data.products}
                    totalProducts={data.total_products}
                    items={items}
                    setItems={setItems}
                >
                </Collection>
            ))}
        </Grid>
    );
});

export default ListCollections;
