import styled from "@emotion/styled"

export const MainContainer = styled.article `
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 550px;
    padding: 50px;
    gap: 20px;
    position: relative;

    img{
        width: 150px;
        height: 150px;
    }
    h1{
        margin: 0;
        font-size: 28px;
        text-align: center;
    }
    p{
        margin: 0;
        font-size: 16px;
        text-wrap: pretty;
    }
` 
export const CloseContainer = styled.div `
    position: absolute;
    right: 30px;
    top: 30px;
    color: #afa8a8;
    cursor: pointer;
` 
export const AnswerSection = styled.section `
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
` 
export const Answer = styled.div `
    display: flex;
    align-items: center;
    border: ${ props => props.optSelected === props.opt ? '2px solid #266678' : '1px solid #cbcbcb' } ;
    width: 100%;
    border-radius: 8px;
    padding: 5px;
    background-color: ${ props => props.optSelected === props.opt && '#4CBA9733' } ; 
` 
export const AnswerArea = styled.textarea `
    height: 104px;
    border: 1px solid #cbcbcb;
    width: 100%;
    border-radius: 8px;
    resize: none;
    padding: 10px;
    color: #888;
    margin-top: 15px;
` 
export const Links = styled.p `
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: #3a8a7aad;
    }
`