import { axiosInstance } from './instance';

const verifyDomain = (storeId, name) => {
    return axiosInstance.get(`/stores/${storeId}/domain/verify?name=${name}`);
}

const saveDomain = (storeId, value) => {
    return axiosInstance.post(`/stores/${storeId}/domain/confirm`, value);
}

const availableDomain = (storeId, name) => {
    return axiosInstance.get(`/stores/${storeId}/domain/available?name=${name}`, );
}

export {
    verifyDomain,
    saveDomain,
    availableDomain
};