import { b64toBlob } from '../constants.js/orders';
import { axiosInstance } from './instance';
/**
 * Gets the orders list
 * @param {number} storeId store id
 * @param {number} limit how many items to show per page
 * @param {number} page page to show
 */
const getOrdersService = (
    storeId,
    limit = 10,
    page = 0,
    date = '',
    sale = '',
    order = '',
    delivery = '',
    search = '',
    orderMethod = '',
    paymentId = ''
) => {
    return axiosInstance.get(
        `/stores/${storeId}/orders?limit=${limit}&page=${page + 1}&sort=${date !== '' ? `created_at&created_at.between=${date}` : `created_at desc`}${sale !== '' ? `&user_store_id.in=${sale}` : ''}${order !== '' ? `&order_status_id.in=${order}` : ''}${delivery !== '' ? `&shipping_order_status_id.in=${delivery}` : ''}&search=${search}&order_method_id.in=${orderMethod}&payment_channel_id.in=${paymentId}`,
    );
}

/**
 * Gets one order
 * @param {number} storeId store id
 * @param {number} orderId order id
 */
const getOrderService = (storeId, orderId) => {
    return axiosInstance.get(`/stores/${storeId}/orders/${orderId}`)
}



/**
 * Download all orders
 * @param {number} storeId store id
 */
const downloadOrders = (storeId) => {
    axiosInstance.get(`/stores/${storeId}/history`)
    .then(response => {
        const base64Content = response.data.base64Content;

        const blob = b64toBlob(base64Content);

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;

        const fileName = 'pedidos.xlsx';  
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    })
    .catch(error => {
        console.error('Error al descargar el archivo:', error);
    });
};



/**
 * Get responses of dynamic form
 * @param {number} storeId store id
 *  @param {number} orderId order id
 */
const getDynamicFormInfo = (storeId, orderId) => {
    return axiosInstance.get(`stores/${storeId}/orders/${orderId}/dynamic-form-responses`)
};

/**
 * Gets filters
 * @param {number} storeId store id
 */
const getOrderFilters = (storeId) => {
    return axiosInstance.get(`/stores/${storeId}/orders-counters`);
};

/**
* Sets status order
* @param {number} storeId store id
* @param {number} orderId order id
* @param {number} statusId status id
* @param {number} type type status
*/
const setOrderStatus = (storeId, orderId, statusId, type) => {
    return axiosInstance.post(`/stores/${storeId}/orders/${orderId}/status/${statusId}/type/${type}`);
};

/**
* Update order
* @param {number} storeId store id
* @param {number} orderId order id
* @param {object} data new data order
*/
const updateOrder = (storeId, orderId, data) => {
    return axiosInstance.put(`/stores/${storeId}/orders/${orderId}`, data);
};

/**
* Update shipping info order
* @param {number} storeId store id
* @param {number} orderId order id
* @param {object} data new shipping data
*/
const updateShippingInfo = (storeId, orderId, data) => {
    return axiosInstance.put(`/stores/${storeId}/orders/${orderId}/shipping-information`, data);
};

/**
* Update customer data order
* @param {number} storeId store id
* @param {number} orderId order id
* @param {number} customerId customer id
* @param {object} data new customer data
*/
const updateCustomerInfo = (storeId, orderId, customerId, data) => {
    return axiosInstance.put(`/stores/${storeId}/orders/${orderId}/customer/${customerId}`, data);
};

/**
* Update customer data order
* @param {number} storeId store id
* @param {number} orderId order id
* @param {object} data new products data
*/
const updateProductsOrder = (storeId, orderId, data) => {
    return axiosInstance.put(`/stores/${storeId}/orders/${orderId}/products`, data);
};

const createOrder = (storeId, value) => {
    return axiosInstance.post(`/stores/${storeId}/create-manual-order`, value);
}

export {
    getOrdersService,
    getOrderService,
    getOrderFilters,
    setOrderStatus,
    createOrder,
    updateOrder,
    updateShippingInfo,
    updateCustomerInfo,
    updateProductsOrder,
    getDynamicFormInfo,
    downloadOrders
}