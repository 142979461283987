import { Button, Container } from '@material-ui/core'
import React from 'react';
import { useLocation } from "react-router-dom";
import { reportUsersDownload } from '../../services/admin';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DownloadReportPage = () => {
  let query = useQuery();
  let year = query.get("year");
  let month = query.get("month");
  let day = query.get("day");

  const handleClick = () => {
    reportUsersDownload(year, month, day);
  }
  return (
    <Container>
      <Button variant="contained" color="secondary" onClick={handleClick}>
        Descargar reporte
      </Button>
    </Container>
  );
}

export default DownloadReportPage