// react dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useMediaQuery } from 'react-responsive';

// material ui dependencies
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';


// internal dependencies
import { routes, successToast, errorToast } from '../../constants.js/misc';
import ConfigIcon from "../../assets/icons/config.svg";

// internal components
import CardItemActionFooter from '../../components/CardItemActionFooter/CardItemActionFooter';
import { Intro, ButtonContainer } from '../../components/ManageCollection/ManageCollection.styles';

import {
    editLegalStore, getStoreService
} from '../../services/store';


const LegalPage = ({ storeId }) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const { handleSubmit, control, errors, setValue } = useForm({
        mode: "onChange"
    });

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike']
        ]
    }


    useEffect(() => {
        getStoreService(storeId)
            .then(({ data }) => {
                setValue('terms_and_conditions', data.terms_and_conditions);
            })
            .catch(err => { });
    }, []);

    const onSubmit = (data) => {
        setSaving(true);
        editLegalStore(storeId, data)
            .then(({ data }) => {
                setSaving(false);
                enqueueSnackbar(t('Config.legalSuccess'), successToast);
            })
            .catch(error => {
                setSaving(false);
                enqueueSnackbar(t('GeneralPage.errorMessage'), errorToast);
            });
    };

    return (
        <Grid
            container
            spacing={isTabletOrMobile ? 0 : 3}
            style={{ paddingBottom: isTabletOrMobile ? 120 : 24 }}>
            {isTabletOrMobile &&
                <Intro item container xs={12}>
                    <img src={ConfigIcon} />
                    <Typography variant="caption">
                        {t('Config.title')}
                    </Typography>
                </Intro>
            }
            <Grid item container xs={12} style={{ padding: isTabletOrMobile ? 20 : '0 0 20px' }}>
                <Grid item xs={6}>
                    <Typography
                        variant={isTabletOrMobile ? 'h5' : 'h4'}
                        style={{ marginBottom: '10px' }}>
                        {t('Config.legal')}
                    </Typography>
                    <Link
                        component={RouterLink}
                        to={routes.config}>
                        ‹ {t('Config.title')}
                    </Link>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>

                <Grid item xs={12} style={{ marginBottom: 18 }}>
                    <CardItemActionFooter
                        title={t('Config.legal.policyTitle')}
                        subTitle={t('Config.legal.policyDescription')}
                        children={
                            <FormControl
                                variant="outlined"
                                margin="normal"
                            >
                                <Controller
                                    defaultValue=""
                                    render={({ field }) => (
                                        <ReactQuill
                                            {...field}
                                            variant="outlined"
                                            defaultValue=""
                                            modules={modules}
                                            theme="snow"
                                            className="legalq"
                                            margin="normal"
                                        />
                                    )}
                                    control={control}
                                    name="terms_and_conditions"
                                />
                            </FormControl>

                        }
                    />
                </Grid>

                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                    {!isTabletOrMobile &&
                        <Button
                            type="submit"
                            disabled={saving}
                            color="primary"
                            variant="contained">
                            {t('SaveChangesBtn')}
                        </Button>
                    }

                    {isTabletOrMobile &&
                        <ButtonContainer
                            item
                            xs={12}
                            lg={4}>
                            <Button
                                style={{ width: '100%' }}
                                color="primary"
                                variant="contained"
                                disabled={saving}
                                type="submit">
                                {t('SaveChangesBtn')}
                            </Button>
                        </ButtonContainer>
                    }
                </Grid>
            </form>
        </Grid>
    );
};

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(LegalPage);
