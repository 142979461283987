// ReactJS
import React from "react";

// MUI Components
import { Box, CircularProgress } from "@material-ui/core";

const Loader = () => (
    <Box
        style={{ height: '100%' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
    >
        <CircularProgress />
    </Box>
)

export default Loader;