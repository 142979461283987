import styled from "@emotion/styled"

export const ArticleCtn = styled.article`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
export const TagContainer = styled.div`
    margin: 10px 10px 0 0;
    background-color: #48ac98;
    width: fit-content;
    padding: 0 15px;
    border-radius: 30px;
`
export const Tag = styled.p`
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  letter-spacing: -1px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin: 2px;

  ::before,::after {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 50%;
    left: 0px;
    background-color: #fff;
  }

  ::before {
    width: 12px;
    height: 12px;
    background-color: #fff;
  }

  ::after {
    width: 12px;
    height: 12px;
    animation: pulse 1s linear infinite;
  }

  @keyframes pulse {
    from {
      transform: scale(0.9);
      opacity: 1;
    }

    to {
      transform: scale(1.8);
      opacity: 0;
    }
  }
  `