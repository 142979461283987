// react dependencies
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';


// external dependencies
import { useSnackbar } from 'notistack';

// material ui dependencies
import { OutlinedInput } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';

// Internal dependencies
import { errorToast } from '../../../constants.js/misc';
import { updateNotesClientInfo } from '../../../services/clients';

const NotesForm = ({
    storeId,
    dataToEdit,
    handleSuccess,
    formObject,
    setSaving,
    submit,
    setSubmit,
}) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const formRef = useRef(null);
    const {
        control,
        handleSubmit,
        errors,
    } = formObject;

    const onSubmit = data => {
        setSaving(true);
        updateNotesClientInfo(storeId, dataToEdit?.clientId, data)
            .then(res => {
                setSubmit(false);
                setSaving(false);
                handleSuccess();
            })
            .catch(err => {
                console.log(err)
                setSubmit(false);
                setSaving(false);
                enqueueSnackbar(t('ClientDetail.submitError'), errorToast);
            });
    }

    useEffect(() => {
        if (submit) {
            handleSubmit(onSubmit)();
        }
    }, [submit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <Grid container spacing={isTabletOrMobile ? 2 : 3}>
                <Grid item xs={12} lg={12}>
                    <FormControl>
                        <FormLabel error={errors?.notes ? true : false}>{t('ClientDetail.clientModal.notes')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.notes ? true : false}
                                    margin="normal"
                                    multiline
                                    rows={3}
                                    style={{ width: '550px' }}
                                />
                            )}
                            name="notes"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={dataToEdit?.notes || null}
                        />
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12}>
                    <FormControl>
                        <FormLabel error={errors?.phone_number ? true : false}>{t('OrderDetail.clientModal.phone')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.phone_number ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="phone_number"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={dataToEdit?.phone_number}
                        />
                    </FormControl>
                </Grid> */}
            </Grid>
        </form >
    );
};

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(NotesForm);
