// react dependencies
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

// material ui dependencies
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';

// Internal dependencies
import ClientForm from './ClientForm';
import NotesForm from './NotesForm';

const EditClientDataModal = ({
    open,
    handleClose,
    isNoClose = true,
    storeId,
    dataToEdit,
    handleSuccess,
    type,
}) => {

    const { t } = useTranslation();
    const {
        control,
        formState,
        handleSubmit,
        errors,
        watch,
        setValue,
        register
    } = useForm({ mode: 'all' });

    const [saving, setSaving] = useState(false);
    const [submit, setSubmit] = useState(false);

    const handleClick = () => {
        setSubmit(true);
        handleClose();
    }

    return (
        <Dialog open={open} disableBackdropClick={isNoClose} onClose={handleClose}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {type === 1 ? t('ClientDetail.clientModal.ClientTitle') : t('ClientDetail.clientModal.noteTitle')}
            </DialogTitle>
            <DialogContent dividers>
                {type === 1 &&
                    <ClientForm
                        dataToEdit={dataToEdit}
                        handleSuccess={handleSuccess}
                        setSubmit={setSubmit}
                        formObject={{
                            control,
                            formState,
                            handleSubmit,
                            setValue,
                            errors,
                            watch,
                            register
                        }}
                        setSaving={setSaving}
                        submit={submit}
                    />
                }
                {type === 2 &&
                    <NotesForm
                        dataToEdit={dataToEdit}
                        handleSuccess={handleSuccess}
                        setSubmit={setSubmit}
                        formObject={{
                            control,
                            formState,
                            handleSubmit,
                            setValue,
                            errors,
                            watch
                        }}
                        setSaving={setSaving}
                        submit={submit}
                    />
                }
            </DialogContent>
            <DialogActions>
                {!isNoClose &&
                    <Button onClick={handleClose} variant="contained">
                        {t('BilligDetailsModal.cancel')}
                    </Button>
                }
                <Button disabled={!formState.isValid || saving} onClick={handleClick} autoFocus color="primary" variant="contained">
                    {t('ClientDetail.clientModal.save')}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(EditClientDataModal);
