import React, {useState} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

// external dependencies
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { checkNumberService } from '../../../services/auth';
import {
    ActionItem,
    SubmitButtonContainer
} from '../SignUpForm.style';

import { signUp, logIn, saveSignUpData } from '../../../modules/auth';

const fieldsSchema = yup.object({
    verification_code: yup.string().matches(/^[0-9]+$/, 'Ingresa sólo números').min(4, 'El código debe tener al menos 4 digitos').max(4, 'El código no debe tener más de 4 digitos').required('Se requiere el código'),
});

const SignUpCodeActivation = ({ signUp, isSigningUp, signUpData, logIn, saveSignUpData }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [isCheckingNumber, setIsCheckingNumber] = useState(false);

    const { handleSubmit, control, formState: { errors, isValid }, register, watch, setValue } = useForm({
        resolver: yupResolver(fieldsSchema),
        mode: 'all'
    });

    const onSubmit = (data) => {
        setIsCheckingNumber(true);
        checkNumberService({
            'To': `+${signUpData.countryNumber}${signUpData.phone}`,
            'Code': `${data.verification_code}`
        }).then((res) => {
            if (res.data.status === 'approved'){
                saveSignUpData({
                    ...signUpData
                });

                setIsCheckingNumber(false);

                history.push('/sign-up/user-info');
            } else {
                enqueueSnackbar(t('SignUp.verificationServiceNotCode'), {
                    variant: 'error',
                    autoHideDuration: 2500
                });

                setIsCheckingNumber(false);
            }
        }).catch(err => {
            enqueueSnackbar(t('SignUp.verificationServiceError'), {
                variant: 'error',
                autoHideDuration: 2500
            });

            setIsCheckingNumber(false);
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth>
                    <FormLabel error={errors?.verification_code ? true : false}>{t('SignUp.codeActivation')}</FormLabel>
                    <Controller
                        render={({ field }) => (
                            <OutlinedInput
                                {...field}
                                {...register("verification_code")}
                                error={errors?.verification_code ? true : false}
                                type="tel"
                                placeholder={'__  __  __  __'}
                                inputProps={{
                                    minLength: 4,
                                    maxLength: 4
                                }}
                            />
                        )}
                        control={control}
                        name="verification_code"
                        rules={{ required: true }}
                    />
                    <FormHelperText error={errors?.verification_code ? true : false}>{errors?.first_name ? t('SignUp.codeActivationError') : ''}</FormHelperText>
                </FormControl>
                <SubmitButtonContainer>
                    <Button
                        color="primary"
                        type="submit"
                        size="large"
                        disabled={!isValid || isCheckingNumber}
                        variant="contained"
                        fullWidth>
                        {isCheckingNumber ?
                            <CircularProgress
                                size={30}
                                color="secondary" /> :
                            t('SignUp.verificationButton')
                        }
                    </Button>
                </SubmitButtonContainer>
                <ActionItem>
                    <Typography variant="body2" gutterBottom>
                        {t('SignUp.verificationNotReceived')}
                        <Link href="#">{t('SignUp.verificationEdit')}</Link>
                    </Typography>
                </ActionItem>
            </form>
        </>
    );
};

const mapStateToProps = ({ auth }) => ({
    isSigningUp: auth.isSigningUp,
    signUpData: auth.signUpData
});

const mapDispatchToProps = dispatch => bindActionCreators({ signUp, logIn, saveSignUpData }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpCodeActivation);