// ReactJS
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// Material UI
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

// Assets
import ImageDefaultHome from '../../assets/img/default/onboarding_cover.jpg';
import ImageDefaultCustom from '../../assets/img/default/onboarding_customize.jpg';
import ImageDefaultProducts from '../../assets/img/default/onboarding_products.jpg';
import ImageDefaultCategories from '../../assets/img/default/onboarding_categories.jpg';

// Components
import CardItemHome from '../../components/CardItemHome/CardItemHome';
import WizardSelectPlan from '../../components/BillingPage/WizardSelectPlan/WizardSelectPlan'
// import { PlanInfoBar } from '../../components/BillingPage/WizardSelectPlan/PlanInfoBar/PlanInfoBar'; // Barra de información del trial
import FrillWidget from '../../components/Widget/Widget';

// Services
import { getStoreUsageService } from '../../services/billing';
import { getProcess } from '../../services/home';

// Constants
import { routes } from '../../constants.js/misc';

// Styles
import { ContentSubTitle, Content } from './HomePage.styles';

export const HomePage = ({ storeId, storeData }) => {
    const { t } = useTranslation();

    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

    const [infoStore, setInfoStore] = useState('');

    const [progress, setProgress] = useState(0);

    const [statusPlan, setStatusplan] = useState(null)
    const [daysToPay, setDaysToPay] = useState(null)
    const [paymentStatus, setPaymentStatus] = useState(null)

    useEffect(() => {
        if (!infoStore) {
            getStoreUsageService(storeId, 'CO')
                .then(({ data }) => {
                    setInfoStore(data);
                })
                .catch(err => { })
        }
    }, [storeData, infoStore]);

    useEffect(() => {
        if (storeData) {
            if (storeData.store_plan) {
                setStatusplan(storeData.store_plan.store_plan_status_id)
                setDaysToPay(storeData.store_plan.days_to_pay)
                setPaymentStatus(storeData.store_plan.store_plan_payment_status_id)
            }
        }
    }, [storeData])

    useEffect(() => {
        if (!progress && storeId) {
            getProcess(storeId)
                .then(({ data }) => setProgress(data.progress))
                .catch(err => { });
        }
    }, [progress, storeData, storeId]);

    return (
        <Content isActive={storeData ? storeData.active ? false : true : false}>
            <Grid item container xs={12} >
                {/* <PlanInfoBar
                    paymentStatus={paymentStatus}
                    statusPlan={statusPlan}
                    daysToPay={daysToPay}
                    storeId={storeId}
                /> */}
                <Grid item xs={10}>

                    <Typography variant="h4">
                        {t('HomePage.title')}
                    </Typography>
                    <ContentSubTitle>
                        <Typography variant="body2">{t('HomePage.subtitle')}</Typography>
                        <p> <span>{progress}%</span> Completado</p>
                        <LinearProgress variant="determinate" value={progress} style={{
                            height: 15,
                            borderRadius: 4
                        }} />
                    </ContentSubTitle>
                </Grid>
                <Grid item xs={2}>
                    <img style={{ height: 220, float: 'right', padding: 25, borderRadius: 8 }} src={ImageDefaultHome}></img>
                </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 10 }}>
                <CardItemHome
                    title={t('HomePage.customTitle')}
                    subtitle={t('HomePage.customDescription')}
                    image={ImageDefaultCustom}
                    action={routes.store}
                    btnTitle={t('HomePage.customBtn')}
                >
                </CardItemHome>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 10 }}>
                <CardItemHome
                    title={t('HomePage.categoriesTitle')}
                    subtitle={t('HomePage.categoriesDescription')}
                    image={ImageDefaultCategories}
                    action={routes.createCollection}
                    btnTitle={t('Collection.createCollections')}
                >
                </CardItemHome>
            </Grid>


            <Grid item xs={12}>
                <CardItemHome
                    title={t('HomePage.productsTitle')}
                    subtitle={t('HomePage.productsDescription')}
                    image={ImageDefaultProducts}
                    action={routes.createProduct}
                    btnTitle={t('HomePage.productsBtn')}
                >
                </CardItemHome>
            </Grid>

            <WizardSelectPlan
                open={cancelDialogOpen}
                currentPlan={infoStore}
                storeId={storeId}
                onClose={() => setCancelDialogOpen(false)}
            />

            <FrillWidget />
        </Content>
    );
}
const mapStateToProps = ({ auth, common }) => ({
    storeId: auth.storeId,
    storeData: common.storeData
});

export default connect(
    mapStateToProps
)(HomePage);