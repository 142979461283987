// React dependencies
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// External dependencies
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';

// Internal dependencies
import { errorToast, successToast, infoToast, routes } from '../../constants.js/misc';
import { CircularProgress } from '@material-ui/core';
import { savePlanPaymentService } from '../../services/billing';

export const PaymentPage = ({storeId, userId}) => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { t } = useTranslation();

    const [saving, setSaving] = useState(true);
    let { planId, paymentTypeId } = useParams();

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const collectionStatus = query.get('collection_status');
        if(collectionStatus) {
            switch(collectionStatus) {
                case 'approved':
                        enqueueSnackbar(t('BillPaymentPage.approved'), successToast);
                    break;
                case 'pending':
                        enqueueSnackbar(t('BillPaymentPage.pending'), infoToast);
                    break;
                case 'in_process':
                        enqueueSnackbar(t('BillPaymentPage.in_process'), infoToast);
                    break;
                case 'rejected':
                        enqueueSnackbar(t('BillPaymentPage.rejected'), infoToast);
                    break;
                case 'null':
                        enqueueSnackbar(t('BillPaymentPage.null'), errorToast);
                    break;
                default:
                        enqueueSnackbar(t('BillPaymentPage.default'), infoToast);
                    break;
            }

            if(collectionStatus === 'pending' || collectionStatus === 'in_process' || collectionStatus === 'approved'){
                // save payment data and redirect to the billing page
                savePlanPaymentService(storeId, {
                    preference_id: query.get('preference_id'),
                    external_reference: query.get('external_reference'),
                    back_url: '',
                    payment_id: query.get('collection_id'),
                    payment_status: query.get('collection_status'),
                    payment_status_detail: '',
                    merchant_order_id: query.get('merchant_order_id'),
                    processing_mode: query.get('processing_mode'),
                    plan_id: planId,
                    plan_payment_type_id: paymentTypeId,
                })
                .then(res => {
                    history.replace(routes.billing)
                })
                .catch(err => {
                    enqueueSnackbar('Imposible guardar el pago en la base de datos, contacta con el administrador', errorToast);
                    history.replace(routes.billing)
                })
            } else {
                history.replace(routes.billing)
            }

        }
    }, [])

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {saving && <CircularProgress />}
        </div>
    );
}

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId,
    userId: auth.userId
});

export default connect(
    mapStateToProps
)(PaymentPage);