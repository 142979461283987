import styled from '@emotion/styled';

export const ImgPreviewProduct = styled.img`
  box-shadow: 0px 0px 1px rgba(52, 58, 67, 0.75);
  border-radius: 8px;
  width: 32px;
  height: 32px;
  margin-left: 6px;
`;

export const IconDragCategories = styled.img`
  height: 18px;
`;
export const LenghtProduct = styled.span`
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(52, 58, 67, 0.75);
  width: 32px;
  height: 32px;
  margin-left: 6px;
  padding: 6px;
  display: inline-block;
  position: relative;
  top: -10px;
`;
export const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 14px 0 0;
`;

