import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const OrderComments = ({ comments }) => {
    const { t } = useTranslation();

    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            <CardHeader
                titleTypographyProps={{ variant: 'h6' }}
                title={t('OrderDetail.commentsTitle')}
                style={{ padding: '20px 16px 13px 16px' }}
            />
            <CardContent style={{ padding: '0 16px 25px 16px' }}>
                <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ padding: '16px 14px', backgroundColor: '#FCF3EA', borderRadius: '8px' }}>
                        <Typography gutterBottom variant="body1">
                            {comments ? comments : t('OrderDetail.comments.message')}
                        </Typography>
                    </div>
                </Grid>
            </CardContent>
        </Card>
    );
};
export default connect()(OrderComments);