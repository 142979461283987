// react dependencies
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// material ui dependencies
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// internal dependencies
import { routes } from '../../constants.js/misc';

// internal components
import Bill from '../../components/Bill/Bill';

const BillDetailPage = ({ storeId }) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={3}>
            <Grid item container xs={12}>
                <Grid item xs={6}>
                    <Typography
                        variant="h4"
                        style={{ marginBottom: '10px' }}>
                        {t('Billing.title')}
                    </Typography>
                    <Link
                        component={RouterLink}
                        to={routes.billing}>
                        ‹ {t('Config.title')}
                    </Link>
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Bill />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(BillDetailPage);
