import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';

import {
    ActionItem, SubmitButtonContainer
} from '../SignUpForm/SignUpForm.style';

import { emailRegex, errorToast } from '../../constants.js/misc';

/*SERVICES */
import {
    generateRecoveryPasswordService
} from '../../services/auth';

const RecoveryPasswordForm = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loadingChange, setloadingChange] = useState(false);

    const { handleSubmit, control, formState, errors, setValue } = useForm({
        mode: "onChange"
    });


    const onSubmit = (data) => {
        setloadingChange(true);
        generateRecoveryPasswordService(data)
            .then(({ data }) => {
                setloadingChange(false);
                setValue('email', '');
                enqueueSnackbar(t('RecoveryPassWord.sendEmail'), {
                    variant: 'success',
                    autoHideDuration: 2500
                });
            }).catch(err => {
                setloadingChange(false);
                enqueueSnackbar(t('RecoveryPassWord.sendEmailError'), errorToast);
            });
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth
                >
                    <FormLabel error={errors?.email ? true : false}>{t('LogIn.email')}</FormLabel>
                    <Controller
                        render={({ field }) => (
                            <OutlinedInput
                                {...field}
                                defaultValue=""
                                error={errors?.email ? true : false}
                                placeholder={t('LogIn.emailPlaceholder')}
                            />
                        )}
                        control={control}
                        defaultValue=""
                        name="email"
                        rules={{ required: true, pattern: emailRegex }}
                    />
                    <FormHelperText error={errors?.email ? true : false}>{errors?.email ? t('LogIn.emailError') : ''}</FormHelperText>
                </FormControl>
                <SubmitButtonContainer>
                    <Button
                        color="primary"
                        disabled={!formState.isValid || loadingChange}
                        type="submit"
                        size="large"
                        variant="contained"
                        fullWidth>
                        {t('RecoveryPassWord.btnTitle')}
                    </Button>
                </SubmitButtonContainer>
            </form>
            <ActionItem>
                <Typography variant="body1" gutterBottom>
                    {t('RecoveryPassWord.linkBack')}
                    <Link component={RouterLink} to="/">{t('LogIn.titlePassword')}</Link>
                </Typography>
            </ActionItem>
        </>
    );
};

export default RecoveryPasswordForm;
