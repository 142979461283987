import { b64toBlob } from '../constants.js/orders';
import { axiosInstance } from './instance';

/**
 * Gets the products list
 * @param {number} storeId store id
 * @param {number} limit how many items to show per page
 * @param {number} page page to show
 */
const getProductsService = (storeId, limit = 10, page = 0, filterName = '', filterStatus = null, order = '', collectionId) => {
    if (collectionId) {
        return axiosInstance.get(`/stores/${storeId}/products?limit=${limit}&page=${page + 1}&sort=name&name.contains=${filterName}&sku.contains=${filterName}&product_collection.collection_id.equals=${collectionId}`);
    } else {
        return axiosInstance.get(`/stores/${storeId}/products?limit=${limit}&page=${page + 1}&sort=name&name.contains=${filterName}&sku.contains=${filterName}`);
    }
};

const getProductsServiceMobile = (storeId, limit = 999) => {
    return axiosInstance.get(`/stores/${storeId}/products?limit=${limit}&sort=name`);
};

const getProductsServiceForSortName = (storeId, limit = 10, page = 0, filterName = '', filterStatus = 1, order = '', collectionId) => {
    if (collectionId) {
        return axiosInstance.get(`/stores/${storeId}/products?limit=${limit}&page=${page + 1}&sort=name&name.contains=${filterName}&sku.contains=${filterName}&sort=name ${order}&product_collection.collection_id.equals=${collectionId}`);
    } else {
        return axiosInstance.get(`/stores/${storeId}/products?limit=${limit}&page=${page + 1}&sort=name&name.contains=${filterName}&sku.contains=${filterName}&sort=name ${order}`);
    }
};

const getProductsServiceForStatus = (storeId, limit = 10, page = 0, filterName = '', filterStatus = 1, order = '', collectionId) => {
    if (collectionId) {
        return axiosInstance.get(`/stores/${storeId}/products?limit=${limit}&page=${page + 1}&sort=name&active.equals=${filterStatus}&name.contains=${filterName}&sku.contains=${filterName}&product_collection.collection_id.equals=${collectionId}`);
    } else {
        return axiosInstance.get(`/stores/${storeId}/products?limit=${limit}&page=${page + 1}&sort=name&active.equals=${filterStatus}&name.contains=${filterName}&sku.contains=${filterName}`);
    }
};

const getDownloadProducts = (storeId) => {
    return axiosInstance.get(`/stores/${storeId}/products-download`)
    .then(response => {
        const base64Content = response.data.base64Content

        const blob = b64toBlob(base64Content)

        const url = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url;

        const fileName = 'productos.xlsx';  
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click();

        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
    })
    .catch(error => {
        console.error('Error al descargar el archivo:', error)
    })
}

/**
 * Creates a product
 * @param {number} storeId store id
 * @param {Object} data product data
 */
const createProductService = (storeId, data) => {
    return axiosInstance.post(`/stores/${storeId}/products`, data);
}

const updateProductServiceAdditionalInfo = (storeId, productId, data) => {
    return axiosInstance.put(`/stores/${storeId}/products/${productId}/additional-info/${data.id}`, data);
}

const deleteProductServiceAdditionalInfo = (storeId, productId, data) => {
    return axiosInstance.delete(`/stores/${storeId}/products/${productId}/additional-info/${data.id}`, data);
}

const createProductServiceAdditionalInfo = (storeId, productId, data) => {
    return axiosInstance.post(`/stores/${storeId}/products/${productId}/additional-info`, data);
}

const getAdditionalInfo = (storeId, productId) => {
    return axiosInstance.get(`/stores/${storeId}/products/${productId}/additional-info`);
}

/**
 * Updates images for a products
 * @param {number} storeId store id
 * @param {number} productId product id
 * @param {Array} files array of files to upload
 */
const createProductImagesService = (storeId, productId, files) => {
    const formData = new FormData();
    files.forEach(file => {
        formData.append('files', file);
    });
    return axiosInstance.put(`/stores/${storeId}/products/${productId}/images`, formData);
}

/**
 * Updates images for a product
 * @param {number} storeId store id
 * @param {number} processId process id
 * @param {Array} files array of files to upload
 */
const createBulkProductImagesService = (storeId, processId, sku, files) => {
    const formData = new FormData();
    files.forEach(file => {
        formData.append('files', file);
    });
    return axiosInstance.put(`/stores/${storeId}/products/bulk/${processId}/images/${sku}`, formData);
}

/**
 * Updates images for a products
 * @param {number} storeId store id
 * @param {number} productId product id
 * @param {number} imageId image id
 */
const deleteProductImagesService = (storeId, productId, imageId) => {
    return axiosInstance.delete(`/stores/${storeId}/products/${productId}/images/${imageId}`);
}

/**
 * Updates images for a products
 * @param {number} storeId store id
 * @param {number} process process id
 * @param {number} imageId image id
 */
const deleteBulkProductImagesService = (storeId, processId, imageId) => {
    return axiosInstance.delete(`/stores/${storeId}/products/bulk/${processId}/images/${imageId}`);
}

/**
 * Updates a product
 * @param {number} storeId store id
 * @param {Object} data updated product data
 * @param {number} productId product id
 * @param {boolean} patch use the patch method
 */
const updateProductService = (storeId, data, productId, patch = false) => {
    if (patch) {
        return axiosInstance.patch(`/stores/${storeId}/products/${productId}`, data);
    }
    return axiosInstance.put(`/stores/${storeId}/products/${productId}`, data);
}

/**
 * Gets the information of a product
 * @param {number} storeId store id
 * @param {number} productId product id
 */
const getProductService = (storeId, productId) => {
    return axiosInstance.get(`/stores/${storeId}/products/${productId}`);
}

/**
 * deletes a product
 * @param {number} storeId store id
 * @param {number} productId product id
 */
const deleteProductService = (storeId, productId) => {
    return axiosInstance.delete(`/stores/${storeId}/products/${productId}`);
}

/**
 * Upload File Masive  product
 * @param {number} storeId store id
 * @param {number} productId product id
 */
const uploadBulkProducts = (storeId, value) => {
    return axiosInstance.post(`/stores/${storeId}/products/bulk`, value);
}

/**
 * Saves the products batch
 * @param {number} storeId store id
 * @param {array} items items array
 * @param {number} processId process id
 */
const saveBulkProducts = (storeId, items, processId) => {
    return axiosInstance.put(`/stores/${storeId}/products/bulk/${processId}/process`, items);
}

const saveAttachment = (storeId, id, value) => {
    return axiosInstance.put(`/stores/${storeId}/products/${id}/attachments`, value);
}

const removeAttachmentProduct = (storeId, id, attachment_id) => {
    return axiosInstance.delete(`/stores/${storeId}/products/${id}/attachments/${attachment_id}`, {});
}

/**
 * Gets the products list
 * @param {number} storeId store id
 */
 const getProductsOrder = (storeId, filterName = '') => {
    return axiosInstance.get(`/stores/${storeId}/products?sort=name&name.contains=${filterName}&sku.contains=${filterName}`);
}

/**
 * Gets the products list with variations
 * @param {string} storeId store id
 */
const getProducts = (storeId, filterName /* = 'test' */) => {
    return axiosInstance.get(`/stores/${storeId}/products-search?term=${filterName}`);
}

export {
    createBulkProductImagesService,
    createProductImagesService,
    createProductService,
    createProductServiceAdditionalInfo,
    deleteBulkProductImagesService,
    deleteProductImagesService,
    deleteProductService,
    deleteProductServiceAdditionalInfo,
    getAdditionalInfo,
    getProductService,
    getProducts,
    getProductsOrder,
    getProductsService,
    getProductsServiceForSortName,
    getProductsServiceForStatus,
    getProductsServiceMobile,
    removeAttachmentProduct,
    saveAttachment,
    saveBulkProducts,
    updateProductService,
    updateProductServiceAdditionalInfo,
    uploadBulkProducts,
    getDownloadProducts
}