// React and external dependencies
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

// Material UI dependencies
import { Card, CardHeader, CardContent, FormControl, CardActions, LinearProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Internal dependencies
import { errorToast, successToast } from '../../constants.js/misc';
import { autocompleteUserStoresService, setMainUserStoreService } from '../../services/store';

/**
 * Renders the form for setting the main user store
 * @param {number} storeId store id
 */
export const MainUserStore = ({ storeId, storeData, userMain }) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedStore, setSelectedStore] = useState(false);
    const [value, setValue] = useState(null);

    useEffect(() => {
        setLoading(true);
        autocompleteUserStoresService(storeId)
            .then(res => {
                setLoading(false);
                setOptions(res.data);
            })
            .catch(err => setLoading(false))
    }, [storeId]);

    useEffect(() => {
        if (storeData?.default_user_store){
            if (storeData?.default_user_store?.mobile_phone_number){
                setValue(storeData.default_user_store)
            }else{
                setMainUserStoreService(storeId, options[0]?.id, options[0])
                setValue(options[0])
            }
        }
    }, [storeData])

    useEffect(() => {
        if (storeData.default_user_store)
            setValue(storeData.default_user_store);
    }, [storeData])

    useEffect(() => {
        setValue(userMain)
    }, [userMain])
    
    /**
     * Sets the main user store
     */
    const saveMainUserStore = () => {
        setSaving(true);
        setMainUserStoreService(storeId, selectedStore.id, selectedStore)
            .then(() => {
                setSaving(false);
                enqueueSnackbar(t('UserStores.mainUserStore.success'), successToast);
            })
            .catch(() => {
                setSaving(false);
                enqueueSnackbar(t('UserStores.mainUserStore.error'), errorToast);
            });
    }
    return (
         <Card style={{ margin: '21px 0', }}>
            {saving && <LinearProgress />}
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('UserStores.mainUserStore')} />
            <CardContent>
                <Typography variant="body2">{t('UserStores.mainUserStoreIntro')}</Typography>
                <br />
                <Autocomplete
                    value={value}
                    onChange={(e, value) => { setSelectedStore(value); setValue(value); }}
                    style={{ width: '100%' }}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    getOptionSelected={(option, value) => option?.name === value?.name}
                    getOptionLabel={(option) => option.name}
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                        <FormControl>
                            <TextField
                                {...params}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress color="inherit" size={20} style={{ position: 'absolute', right: '11px' }} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        </FormControl>
                    )}
                />
            </CardContent>
            <CardActions style={isTabletOrMobile ? { display: 'flex', justifyContent: 'center' } : { justifyContent: 'flex-end' }}>
                <Button
                    onClick={saveMainUserStore}
                    disabled={!selectedStore || saving}
                    color="primary"
                    variant="contained"
                >
                    {t('UserStores.SetMainButton')}
                </Button>
            </CardActions>
        </Card>
    );
}

export default connect()(MainUserStore);