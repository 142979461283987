import { axiosInstance, serverInstance } from './instance'

const getProcess = (storeId) => {
    return axiosInstance.get(`stores/${storeId}/progress`)
}

const sendResponsePopup = (storeId, data) => {
    return axiosInstance.post(`stores/${storeId}/surveys`, data)
}

const welcomeMessageService = (data) => {
  return serverInstance.post("/welcome-message", data);
};

export { getProcess, sendResponsePopup, welcomeMessageService };