import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CardHeader, FormControl, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import OrderProductsTable from '../OrderProductsTable/OrderProductsTable';
import { formatNumber } from '../../../constants.js/misc';

// Assets
import calendar from '../../../assets/icons/calendar2.svg';
import { useFieldArray, useForm } from "react-hook-form";
import { parseDateToEdit } from '../../../constants.js/orders';
import OrderProductsTableToEdit from '../OrderProductsTable/OrderProductsTableToEdit';
import { autocompleteUserStoresService } from '../../../services/store';
import { useState } from 'react';
import { DivContainerTable } from '../../../constants.js/containers';

const useStyles = makeStyles({
    total: {
        padding: '16px',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
    }
});

const OrderDetailsTable = ({ orderDetails, order, storeData, editMode, generalData, setGeneralData, submitProductsTable }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const options = { month: "long", day: "numeric" };

    const creationDate = new Date(order?.created_at);
    const dateCreation = creationDate.toLocaleDateString("es-ES", options);
    const yearCreation = creationDate.toLocaleDateString("es-ES", { year: "numeric" });
    const hourCreation = creationDate.toLocaleTimeString();

    const creationDateToEdit = parseDateToEdit(order?.created_at, false);

    const [userStores, setUserStores] = useState([])

    // Usando el costo de envío segun la tienda
    const totalWithShipping = () => {
        return order?.shipping_cost
            ? (isNaN(order?.shipping_cost)
                ? ((isNaN(storeData.delivery_cost_details) || !storeData.delivery_cost_enabled) ? 0 : parseInt(storeData.delivery_cost_details))
                : order?.shipping_cost
            )
            : (isNaN(storeData.delivery_cost_details) || !storeData.delivery_cost_enabled) ? 0 : parseInt(storeData.delivery_cost_details);
    }

    const calcDeliveriCost = () => {
        return order?.shipping_cost !== (0 || null)
            ? parseInt(order?.shipping_cost)
            : storeData?.delivery_cost_enabled
                ? isNaN(storeData?.delivery_cost_details)
                    ? storeData?.delivery_cost_details
                    : parseInt(storeData?.delivery_cost_details)
                : 0
    }

    /* Funciones para cuando se esté usando el costo de envío segun la orden (order?.shipping_cost)
    
    const totalWithShipping = () => {
        return isNaN(order?.shipping_cost)
            ? ((isNaN(storeData.delivery_cost_details) || !storeData.delivery_cost_enabled) ? 0 : parseInt(storeData.delivery_cost_details))
            : order?.shipping_cost;
    } 
    
    const calcDeliveriCost = () => {
        return isNaN(order?.shipping_cost)
            ? (storeData?.delivery_cost_enabled
                ? isNaN(storeData?.delivery_cost_details)
                    ? storeData?.delivery_cost_details
                    : storeData?.delivery_cost_details
                : 0)
            : order?.shipping_cost;
    }
    */

    useEffect(() => {
        editMode &&
            autocompleteUserStoresService(storeData?.id)
                .then(res => {
                    setUserStores(res.data);
                })
                .catch(err => {
                    console.log(err)
                })
    }, [editMode, storeData])

    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: {
            products: orderDetails?.map(products => products),
        },
        mode: 'all',
    });

    // If the product existed in the order
    const updateOptions = (opts, variantsSelected) => {
        let id = []

        const optOpt2 = opts?.map(opt => opt?.options)
        const arrOpts2 = optOpt2?.map(opt => opt?.filter(opt =>
            variantsSelected?.includes(opt?.name)
        ))
        arrOpts2.map(sOpt => id.push(sOpt[0]?.id))

        return id
    }

    const case2WithOpts = (opts, variantsSelected, id) => {
        const optOpt = opts?.map(opt => opt?.options)
        const arrOpts = optOpt?.map(opt => opt?.filter(opt =>
            variantsSelected?.includes(opt?.name)
        ))
        arrOpts.map(sOpt => id.push(sOpt[0]?.id))
    }

    const parseOptions = (opts, method, variantsSelected, oldOptions) => {
        let id = []
        switch (method) {
            case 1:
                oldOptions !== []
                    ? id = oldOptions?.map(optc => optc?.product_option_value_id)
                    : id = updateOptions(opts, variantsSelected)
                break;
            case 2:
                Array.isArray(opts)
                    ? case2WithOpts(opts, variantsSelected, id)
                    : id = []
                break;
            case 3: //Reserva para cuando ya existe el producto en la orden
                id = opts?.map(opt => opt?.product_option_value_id)
                break;
            default:
                break;
        }
        return id
    }

    const parseVariant = (variants, variantsSelected) => {
        const id = (variants?.find(element => JSON.stringify(element?.options) === JSON.stringify(variantsSelected))?.id || 0)
        return id
    }

    const normalizeData = (data) => {
        // To get options selected of products
        const optionsSelected = data?.order_item_option
            ? parseOptions(data?.product?.product_options, 1, data?.variants, data?.order_item_option)
            : parseOptions(data?.optionsProduct, 2, data?.variants)

        return {
            options: optionsSelected,
            product_id: data?.product_id,
            product_variant_id: data?.product_variant_id || parseVariant(data?.variantsProduct, data?.variants),
            quantity: parseInt(data?.quantity),
            price: data?.price
        }
    }

    const saveInfo = (data) => {
        setGeneralData({ ...generalData, CreationDate: watch('newCreationDate'), line: watch('line'), shippingCost: watch('shipping_cost'), dataProducts: data?.products?.map(prod => normalizeData(prod)) })
    }

    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('OrderDetail.detailsTitle')} style={{ paddingBottom: '0' }} />
            <CardContent style={{ padding: 0 }}>
                <Grid container>
                    <div style={{ padding: '10px 16px 16px 16px' }}>
                        {!editMode
                            ? <>
                                <img src={calendar} alt="calendar" style={{ width: '14px' }} /> {`${dateCreation}, ${yearCreation} - ${hourCreation}`}
                                <Typography variant="body1">Canal de venta: <span style={{ color: '#48AC98', fontWeight: 700 }}>{order?.user_store?.mobile_phone_number}</span> </Typography>
                            </>
                            : <>
                                <FormControl
                                    variant="outlined"
                                    margin="normal"
                                    defaultValue={creationDateToEdit}
                                    fullWidth>
                                    <OutlinedInput
                                        defaultValue={creationDateToEdit}
                                        type="datetime-local"
                                        {...register("newCreationDate")}
                                        onMouseOut={editMode && saveInfo}
                                        fullWidth />
                                </FormControl>

                                <Select
                                    defaultValue={order?.user_store?.id}
                                    {...register("line")}
                                    onClick={editMode && saveInfo}
                                    fullWidth
                                    variant="outlined"
                                    disableUnderline
                                >
                                    {
                                        userStores?.map(userStore => (
                                            <MenuItem
                                                key={userStore?.id}
                                                value={userStore?.id}
                                                defaultValue={order?.user_store?.id}
                                            >
                                                {userStore?.mobile_phone_number}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </>
                        }
                    </div>
                    {!editMode
                        ? <>
                            <DivContainerTable>
                                <OrderProductsTable orderDetails={orderDetails} />
                            </DivContainerTable>
                            <div className={classes.total}>
                                <div style={{ marginRight: '1rem' }}>
                                    <Typography gutterBottom variant="body1">Subtotal:</Typography>
                                    <Typography gutterBottom variant="body1">Costos de envio:</Typography>
                                    <Typography gutterBottom variant="body1">Total:</Typography>
                                </div>
                                <div>
                                    <Typography gutterBottom variant="body1">{formatNumber(orderDetails.reduce((acc, detail) => acc + (detail.price * detail.quantity), 0), storeData.currency)} {storeData?.currency}</Typography>
                                    <Typography gutterBottom variant="body1">{formatNumber(calcDeliveriCost(), storeData.currency)}</Typography>
                                    <Typography gutterBottom variant="body1">{formatNumber(orderDetails.reduce((acc, detail) => acc + (detail.price * detail.quantity), 0) + totalWithShipping(), storeData.currency)} {storeData?.currency}</Typography>
                                </div>
                            </div>
                        </>
                        : <DivContainerTable>
                            <OrderProductsTableToEdit
                                displayBlock
                                deliveryCost={calcDeliveriCost()}
                                control={control}
                                register={register}
                                watch={watch}
                                handleSubmit={handleSubmit}
                                setValueShipping={setValue}
                                useFieldArray={useFieldArray}
                                saveInfo={saveInfo}
                                ubmitProductsTable={submitProductsTable}
                            />
                        </DivContainerTable>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
};

const mapStateToProps = ({ common }) => ({
    storeData: common.storeData
});

export default connect(
    mapStateToProps
)(OrderDetailsTable);