// ReactJS
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

// Components
import { Intro } from '../../components/ManageCollection/ManageCollection.styles';

// Services
import {
    getPaymentChannels,
    getPaymentChannelsList,
} from '../../services/paymentProviders';

// Icons
import ConfigIcon from "../../assets/icons/config.svg";

// MUI Components
import { Grid, Typography } from "@material-ui/core";

// Components: PaymentProviders
import NavLink from "./NavLink";
import SectionPayment from "./SectionPayment";
import ListOfPayment from "./ListOfPayment";

const PaymentProviders = ({ storeId }) => {
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [payment, setPayment] = useState(false);
    const [paymentList, setPaymentList] = useState([]);
    const [configList, setConfigList] = useState([]);

    useEffect(() => {
        getPaymentChannels(storeId)
            .then(res => {
                setPaymentList(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }, [storeId]);

    useEffect(() => {
        getPaymentChannelsList(storeId)
            .then(res => {
                setConfigList(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }, [storeId]);

    return (
        <Grid container spacing={isTabletOrMobile ? 0 : 3} style={{ justifyContent: 'center' }}>
            {isTabletOrMobile &&
                <Intro item container xs={12}>
                    <img src={ConfigIcon} alt="" />
                    <Typography variant="caption">
                        {t('Config.paymentProviders')}
                    </Typography>
                </Intro>
            }
            <NavLink isTabletOrMobile={isTabletOrMobile} />
            {payment
                ? <ListOfPayment paymentList={paymentList} />
                : <SectionPayment
                    setPayment={setPayment}
                    configList={configList}
                    setConfigList={setConfigList}
                />
            }
        </Grid>
    );
}

export default PaymentProviders;