import colombiaFlag from "../assets/img/flags/colombia.png";
import ecuadorFlag from "../assets/img/flags/ecuador.png";
import panamaFlag from "../assets/img/flags/panama.png";
import mexicoFlag from "../assets/img/flags/mexico.png";
import spainFlag from "../assets/img/flags/espana.png";
import usaFlag from "../assets/img/flags/estados-unidos.png";

export const FLAGS_LISTS = [
  { name: "colombia", flag: colombiaFlag },
  { name: "ecuador", flag: ecuadorFlag },
  { name: "panama", flag: panamaFlag },
  { name: "mexico", flag: mexicoFlag },
  { name: "espana", flag: spainFlag },
  { name: "usa", flag: usaFlag },
];

export default {};