import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthForm } from '../../components/AuthForm/AuthForm';
import SignUpCodeActivation from '../../components/SignUpForm/SignUpCodeActivation/SignUpCodeActivation';

import signUpImg from '../../assets/img/account_signup_v2.jpeg';

export const SignUpVerificationPage = (props) => {
    const { t } = useTranslation();
    const handleSignUp = () => console.log('signUp succesfull');

    return (
        <AuthForm
            image={signUpImg}
            title={t('SignUp.verificationTitle')}
            subtitle={t('SignUp.verificationSubtitle')}>
            <SignUpCodeActivation onSignUp={handleSignUp}/>
        </AuthForm>
    );
}