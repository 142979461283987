import React from 'react';

import { SortableContainer } from 'react-sortable-hoc';
import List from '@material-ui/core/List';
import SortableItem from '../SortableItem/SortableItem';

const SortableList = SortableContainer(({ items, iconAction, handleClick }) => {
   return (
        <List ContainerComponent="div">
            {items.map((value, index) => (
                <SortableItem
                    key={`item-${index}`}
                    i={index}
                    index={index}
                    id={value.id}
                    iconAction={iconAction}
                    handleItemClick={handleClick}
                    dataImage={value ? value.product_images && value.product_images.length > 0 ? value.product_images[0].picture_url : value.picture_url : ''}
                    value={value ? value.name : ''} >
                </SortableItem>
            ))}
        </List>
    );
});


export default SortableList;