// react dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

// material ui dependencies
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import WizardSelectPlan from '../WizardSelectPlan/WizardSelectPlan';
import { getStoreUsageService } from '../../../services/billing';
import DowngradeModal from '../../DowngradeModal/DowngradeModal';

import {
    PlanAsk, PlanTitle, Title, Detail, ButtonChangePlanContainer, InfoCardContainer, InfoCard
} from './PlanAndUse.styles';

// styles
const useStyles = makeStyles({
    top: {
        color: '#C9CED6',
        position: 'absolute',
        left: 0,
    },
    avatarPlan: {
        color: '#266678',
        float: 'left',
        background: '#F5D6BA',
        fontSize: 18,
        lineHeight: 23,
        textTransform: 'uppercase'
    },
});
/**
 * @param {number} storeId store id from state
 */
const PlanAndUse = ({ storeId, storeData }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [infoStore, setInfoStore] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [prevPlan, setPrevPlan] = useState({});
    const [newPlan, setNewPlan] = useState({});

    const daysToPay = storeData?.store_plan?.days_to_pay
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

    useEffect(() => {
        if (!infoStore) {
            getStoreUsageService(storeId, 'CO')
                .then(({ data }) => {
                    const prevPlan = JSON.parse(localStorage.getItem(storeId + '-prevPlan'));
                    if (!prevPlan) {
                        // save plan lines in localstorage
                        localStorage.setItem(storeId + '-prevPlan', JSON.stringify({
                            included_lines: data.included_lines,
                            name: data.plan_name
                        }));
                    }
                    if (prevPlan && data.whatsapp_lines > data.included_lines) {
                        // plan downgrade, show downgrade modal
                        setModalOpen(true);
                        setPrevPlan(prevPlan);
                    }
                    setNewPlan({
                        included_lines: data.included_lines,
                        name: data.plan_name
                    });
                    setInfoStore(data);

                    if (prevPlan) {
                        if (prevPlan.included_lines < data.included_lines) {
                            // upgrade
                            localStorage.setItem(storeId + '-prevPlan', JSON.stringify({
                                included_lines: data.included_lines,
                                name: data.plan_name
                            }));
                        }
                    }
                })
                .catch(err => { })
        }
    }, [storeId]);

    const formatNumber = n =>
        n
            .toString()
            .replace(/\D/g, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    const handleDowngradeSuccess = () => {
        localStorage.removeItem(storeId + '-prevPlan');
        setTimeout(() => setInfoStore(''), 500);
    }


    const checkDate = (value) => {
        const date = new Date(value)
        return date.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })
    }

    return (
        <>
            <DowngradeModal
                onSuccess={handleDowngradeSuccess}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                prevLines={prevPlan.included_lines}
                maxLines={newPlan.included_lines}
                prevPlanName={prevPlan.name}
                newPlanName={newPlan.name} />
            <Card container spacing={3} style={{ width: '100%' }}>
                <CardHeader
                    titleTypographyProps={{ variant: 'h6' }}
                    title={t('PlanAndUse.title')} />
                <CardContent>
                    <InfoCardContainer>
                        <InfoCard style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar className={classes.avatarPlan}>{infoStore ? infoStore.plan_name.slice(0, 2) : ''}</Avatar>
                            <PlanTitle>{infoStore?.plan_name === 'Membresía mensual' ? 'Membresía mensual' : 'Membresía anual'}</PlanTitle>
                        </InfoCard>
                        <InfoCard >
                        <div style={{ position: 'relative' }}>
                                <CircularProgress className={classes.top} size={40} thickness={11} style={{ float: 'left' }} variant="static" value={100} />
                                <CircularProgress size={40} thickness={11} style={{ float: 'left' }} variant="static" value={infoStore.whatsapp_lines * 100 / infoStore.included_lines > 100 ? 100 : infoStore.whatsapp_lines * 100 / infoStore.included_lines} />
                            </div>
                            <Title>Líneas</Title>
                            <Detail>{infoStore ? infoStore.whatsapp_lines + ' de ' + infoStore.included_lines : ''}</Detail>
                        </InfoCard>
                        {/* {storeData?.store_plan?.plan_id === 1 &&
                            <InfoCard>
                                <div style={{ position: 'relative' }}>
                                    <CircularProgress className={classes.top} size={40} thickness={11} style={{ float: 'left' }} variant="static" value={100} />
                                    <CircularProgress size={40} thickness={11} style={{ float: 'left' }} variant="static" value={ Math.floor(daysToPay * 10 / 7 * 5) } />
                                </div>
                                <Title >Días de prueba</Title>
                                <Detail>{storeData ? daysToPay : ''}</Detail> 
                            </InfoCard>
                        } */}
                        {storeData?.store_plan?.plan_id !== 1 &&
                            <>
                                {/* <InfoCard>
                                    <Title>Precio del plan</Title>
                                    <Detail>{infoStore ? 'COP $' + formatNumber(infoStore.amount_date) : ''}</Detail>
                                </InfoCard> */}
                                <InfoCard>
                                    <Title>Fecha de Corte</Title>
                                    <Detail>{storeData ? checkDate(storeData?.store_plan?.next_cut_date) : ''}</Detail>
                                </InfoCard>
                            </>
                        }
                        
                    </InfoCardContainer>
                    {/* <ButtonChangePlanContainer> // Desactivado porque solo existe un plan
                        <Button style={{ width: '100%' }} color="primary" variant="contained" onClick={() => setCancelDialogOpen(true)}>
                            Cambiar plan
                        </Button>
                    </ButtonChangePlanContainer> */}
                </CardContent>
                <PlanAsk>
                    <Typography variant="body1" gutterBottom>
                        {t('PlanAndUse.question')}
                        <Link target="_blank" href="https://wa.me/573003154556?text=Hola, me gustaría obtener más información sobre los planes y precios">{t('PlanAndUse.knownPlan')}</Link>
                    </Typography>
                </PlanAsk>
            </Card>
            <WizardSelectPlan
                open={cancelDialogOpen}
                currentPlan={infoStore}
                storeId={storeId}
                onClose={() => setCancelDialogOpen(false)}
            />
        </>
    );
};


const mapStateToProps = ({ auth, common }) => ({
    storeId: auth.storeId,
    storeData: common.storeData
});

export default connect(
    mapStateToProps
)(PlanAndUse);

