// react dependencies
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

// material ui dependencies
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// internal dependencies
import { routes } from '../../constants.js/misc';

// internal components
import PendingBills from '../../components/BillingPage/PendingBills/PendingBills';
import { Intro, ButtonContainer } from '../../components/ManageCollection/ManageCollection.styles';
import ConfigIcon from "../../assets/icons/config.svg";
// import PaymentMethod from '../../components/BillingPage/PaymentMethod/PaymentMethod';
import BillingDetails from '../../components/BillingPage/BillingDetails/BillingDetails';
import PlanAndUse from '../../components/BillingPage/PlanAndUse/PlanAndUse';
import BillsTable from '../../components/BillingPage/BillsTable/BillsTable';
// import HelpBilling from '../../components/BillingPage/HelpBilling/HelpBilling';

// styles
const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    }
});

const BillingPage = ({ storeId }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <Grid container spacing={isTabletOrMobile ? 0 : 3} style={{ justifyContent: 'center' }}>
            <Grid item container xs={12} md={10} xl={8} className={classes.container} style={{
                justifyContent: 'flex-start',
                padding: isTabletOrMobile ? 20 : '0 0 20px'
            }}>
                <Grid item xs={6}>
                    <Typography
                        variant={isTabletOrMobile ? 'h5' : 'h4'}
                        style={{ marginBottom: '10px' }}>
                        {t('Billing.title')}
                    </Typography>
                    <Link
                        component={RouterLink}
                        to={routes.config}>
                        ‹ {t('Config.title')}
                    </Link>
                </Grid>
            </Grid>
            <Grid item container xs={12} md={10} xl={8} className={classes.container} style={{ marginBottom: isTabletOrMobile ? 40 : 0 }}>
                <PlanAndUse />
            </Grid>
            {/* <Grid item container xs={12} md={10} xl={8} className={classes.container} style={{ marginBottom: isTabletOrMobile ? 40 : 0 }}>
                <PendingBills />
            </Grid> */}

            {/* <Grid item container xs={12} md={10} xl={8} className={classes.container}>
                <PaymentMethod />
            </Grid> */}

            <Grid item container xs={12} md={10} xl={8} className={classes.container} style={{ marginBottom: isTabletOrMobile ? 40 : 0 }}>
                <BillingDetails />
            </Grid>
            {/* <Grid item container xs={12} md={10} xl={8} className={classes.container} style={{ marginBottom: isTabletOrMobile ? 40 : 0 }}>
                <BillsTable />
            </Grid> */}
            {/* <Grid item container xs={12} md={10} xl={8} className={classes.container} style={{ marginBottom: isTabletOrMobile ? 100 : 0 }}>
                <HelpBilling />
            </Grid> */}
        </Grid>
    );
};

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(BillingPage);
