// ReactJS
import React from "react";

// Images [PNG]
import AddField from '../../../../assets/img/addField.png';
import AddText from '../../../../assets/img/addText.png';

// MUI Components
import { Card, Grid, Typography, makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '1rem'
    },
    wrapperInput: {
        alignItems: 'center',
        backgroundColor: '#F8F9FB',
        border: '1px dashed #48AC98',
        borderRadius: 8,
        color: '#48AC98',
        cursor: 'pointer',
        display: 'flex',
        fontSize: 16,
        fontWeight: '700',
        placeContent: 'center',
        height: 90,
    },
});

const ActionButtons = ({ handleAppend, fieldIndex }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid item container xs={12} md={10} xl={8} className={classes.header}>
                <Card
                    elevation={1}
                    style={{ padding: 25, width: '100%' }}
                >
                    <Typography
                        onClick={() => handleAppend({ isSection: false, index: fieldIndex })}
                        variant="body1"
                        className={classes.wrapperInput}
                        style={{ marginBottom: '1rem' }}
                    >
                        <img src={AddField} alt="add field" style={{ marginRight: '5px' }} />
                        Agregar un campo personalizado
                    </Typography>
                    <Typography
                        onClick={() => handleAppend({ isSection: true, index: fieldIndex })}
                        variant="body1"
                        className={classes.wrapperInput}
                    >
                        <img src={AddText} alt="add text" style={{ marginRight: '5px' }} />
                        Agregar un título y descripción
                    </Typography>
                </Card>
            </Grid>
            <Grid item container xs={12} md={10} xl={8} className={classes.header} style={{ justifyContent: 'flex-end' }}>
                <Button
                    className="btnLink"
                    color="primary"
                    variant="contained"
                    type="submit"
                >
                    Guardar cambios
                </Button>
            </Grid>
        </React.Fragment>
    );
}

export default ActionButtons;