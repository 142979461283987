
import styled from '@emotion/styled';



export const PlanAsk = styled.div`
    background: #F8F9FB;
    border-radius: 0px 0px 8px 8px;
    padding: 15px;
`;

export const PlanTitle = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #53627C;
    padding-left: 10px;
    display: flex;
`;

export const Title = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #53627C;
    padding-left: 10px;
    display: flex;
`;
export const InfoCardContainer = styled.div`
    display: flex;
    justify-content: space-around;
    @media(max-width: 710px) {
        flex-direction: column;
        gap: 20px;
    }
`
export const InfoCard = styled.div`
    width: 30%;
    @media(max-width: 710px) {
        width: 95%;
        margin: 0 auto;
    }
`

export const Detail = styled.span`
   margin-left: 10px;
`
export const ButtonChangePlanContainer = styled.div`
    width: 40%;
    margin: 31px auto 0
`