import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import { CardHeader } from '@material-ui/core';

import { getCollectionTree } from '../../../modules/collections';

const useStyles = makeStyles({
    collectionImage: {
        width: '20px',
        height: '20px',
        objectFit: 'cover',
        borderRadius: '3px',
        marginRight: '10px',
    },
    collectionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
});

const Collection = ({ collection, selected }) => {
    const classes = useStyles();
    const checkDefault = collection && collection.default ? true : false;
    return (
        <div className={classes.collectionContainer}>
            <FormControlLabel
                control={<Checkbox color="primary" checked={checkDefault ? true : selected} disabled={checkDefault} onChange={collection.handleChange} name="checkedA" />}
                label={collection.name} />
            {collection.picture_url && <img className={classes.collectionImage} src={process.env.REACT_APP_IMAGES_API_URL + collection.picture_url} alt={collection.name} />}
            {/* <div style={{ marginLeft: '20px' }}>
                {collection.subcollections && collection.subcollections.map(subcollection =>
                    <Collection
                        selected={subcollection.selected}
                        key={subcollection.id}
                        collection={subcollection} />
                )}
            </div> */}
        </div>
    )
}

const ProductCollections = ({
    isTabletOrMobile,
    control,
    setValue,
    storeId,
    collectionTree,
    gettingCollectionTree,
    getCollectionTree,
    productData
}) => {
    const { t } = useTranslation();
    const [collectionTreeState, setCollectionTreeState] = useState([]);

    useEffect(() => {
        getCollectionTree(storeId);
    }, [getCollectionTree, storeId]);

    useEffect(() => {
        const handleCollectionChange = (collectionId, checked) => {

            const getCollectionData = (collection, parent = false) => {
                // if(parent) parent.selected = checked;

                return {
                    ...collection,
                    subcollections: collection.subcollections ?
                        collection.subcollections.map(subcollection => getCollectionData(subcollection, collection)) :
                        null,
                    selected: collection.id === collectionId ? checked : collection.selected,
                }
            }

            setCollectionTreeState(tree => tree.map(collection => getCollectionData(collection)));
        }

        const setCollectionCheckboxes = (collection) => {
            collection.selected = false;
            collection.handleChange = ({ target }) => handleCollectionChange(collection.id, target.checked)
            if (collection.subcollections)
                collection.subcollections.forEach(subcollection => setCollectionCheckboxes(subcollection));
            return collection;
        }

        const newCollectionTree = [];
        collectionTree.forEach(collection => newCollectionTree.push(setCollectionCheckboxes(collection)));

        if (productData) {
            const productCollectionTree = [];
            const setSelectedCollection = (collection) => {
                const selectedValue = productData.collections ?
                    productData.collections.map(c => c.collection_id).indexOf(collection.id) !== -1 :
                    false;
                collection.selected = selectedValue;
                if (collection.subcollections)
                    collection.subcollections.forEach(subcollection => setSelectedCollection(subcollection));
                return collection;
            }
            collectionTree.forEach(collection => productCollectionTree.push(setSelectedCollection(collection)));
            setCollectionTreeState(productCollectionTree);
        } else {
            setCollectionTreeState(newCollectionTree);
        }
    }, [collectionTree, productData]);

    useEffect(() => {
        const idsArray = [];

        const getCheckedIds = (collection) => {
            if (collection.selected)
                idsArray.push(collection.id);
            if (collection.subcollections)
                collection.subcollections.forEach(subcollection => getCheckedIds(subcollection));
        }

        collectionTreeState.forEach(collection => getCheckedIds(collection));
        setValue('collections', idsArray);
    }, [collectionTreeState, setValue]);


    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            {gettingCollectionTree && <LinearProgress />}
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('CollectionsTitle')} style={{ paddingTop: 10 }} />
            <CardContent >
                <Grid container spacing={isTabletOrMobile ? 0 : 3}>
                    <Grid item xs={12}>
                        {collectionTreeState.map(collection =>
                            <Collection
                                onChange={collection.handleChange}
                                selected={collection.selected}
                                collection={collection}
                                key={collection.id}
                            />
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const mapStateToProps = ({ auth, collections }) => ({
    storeId: auth.storeId,
    collectionTree: collections.collectionTree,
    gettingCollectionTree: collections.gettingCollectionTree
});

const mapDispatchToProps = dispatch => bindActionCreators({ getCollectionTree }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductCollections);