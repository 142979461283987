import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthForm } from '../../components/AuthForm/AuthForm';
import DefultImg from '../../assets/img/account_reset_v2.jpg';
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm';




const ResetPasswordPage = () => {
    const { t } = useTranslation();

    return (
        <AuthForm
            isLogin={true}
            image={DefultImg}
            title={t('RecoveryPassWord.newPassword')}>
            <ResetPasswordForm></ResetPasswordForm>
        </AuthForm>
    );
}
export default ResetPasswordPage;