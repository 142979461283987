import { b64toBlob } from '../constants.js/orders';
import { axiosInstance } from './instance';

/**
 * Gets the inventory list
 * @param {number} storeId store id
 * @param {number} limit how many items to show per page
 * @param {number} page page to show
 */
const getInventory = (
    storeId,
    limit = 10,
    page = 0,
    search = ''
) => {
    return axiosInstance.get(`/stores/${storeId}/inventory?limit=${limit}&page=${page + 1}&search=${search}`);
};

/**
 * Update / Add inventory to a product.
 * @param {string} storeId store id
 * @param {string} id product id
 * @param {string} data quantity available
 */
const addProductInventory = (storeId, id, data) => {
    return axiosInstance.put(`/stores/${storeId}/products/${id}/inventory-movement`, data);
};

/**
 * Update / Add inventory to a product variant.
 * @param {string} storeId store id
 * @param {string} id product id
 * @param {string} variantId qvariant id
 * @param {string} data quantity available
 */
const addProductInventoryWithVariant = (storeId, id, variantId, data) => {
    return axiosInstance.put(`/stores/${storeId}/products/${id}/variant/${variantId}/inventory-movement`, data);
};

/**
 * Update inventory witout variants
 * @param {string} storeId store id
 * @param {string} id product id
 * @param {string} data quantity available
 */
const updateProductInventory = (storeId, id, data) => {
    return axiosInstance.put(`/stores/${storeId}/products/${id}`, data);
};

/**
 * Upload File Masive  product
 * @param {number} storeId store id
 * @param {number} value value to add
 */
const importInventoryReview = (storeId, value) => {
    return axiosInstance.post(`/stores/${storeId}/inventory/bulk`, value);
}

/**
 * Upload File Masive  product
 * @param {number} storeId store id
 * @param {number} value value to add
 * @param {number} processId processId
 */
const importInventoryProcess = (storeId, processId, value) => {
    return axiosInstance.put(`/stores/${storeId}/inventory/bulk/${processId}/process`, value);
}

/**
 * Upload File Masive  product
 * @param {number} storeId store id
 * @param {number} search search
 */
const importInventoryDownload = (storeId, search = '') => {
    return axiosInstance.get(`/stores/${storeId}/inventory-download${search !== '' ? `?search=${search}` : ''}`);
}

/**
 * Upload File Masive  product
 * @param {number} storeId store id
 * @param {number} search search
 */
const downloadStock = storeId => {
    return axiosInstance.get(`/stores/${storeId}/inventory-download-excel`)
    .then(response => {
        const base64Content = response.data.base64Content

        const blob = b64toBlob(base64Content)

        const url = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url;

        const fileName = 'inventario.xlsx';  
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click();

        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
    })
    .catch(error => {
        console.error('Error al descargar el archivo:', error)
    })
}

export {
    getInventory,
    addProductInventory,
    addProductInventoryWithVariant,
    updateProductInventory,
    importInventoryReview,
    importInventoryDownload,
    importInventoryProcess,
    downloadStock,
}
