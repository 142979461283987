import styled from '@emotion/styled';

export const IconCustom = styled.img`
   height: 70px;
   float: left;
   margin-right: 16px;
   border-radius: 8px;
`
export const CardContainer = styled.div`
   display: flex;
   align-items: center;
`
export const TextContainer = styled.div`
   display: flex;
   flex-direction: column;
`