// react dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

// material ui dependencies
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// internal dependencies
import { getStoreCountries } from '../../services/billing';
import { errorToast } from '../../constants.js/misc';
import { setCountries } from '../../modules/common';

// ISO 3166-1 alpha-2  ⚠️ No support for IE 11
const countryToFlag = isoCode =>
    typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode;

// styles
const useStyles = makeStyles({
    formControl: {
        margin: '0 auto',
        display: 'flex'
    },
    flag: {
        marginRight: '1rem'
    }
});

const CountrySelectorAutocomplete = ({
    name,
    countries,
    handleCountryChange,
    defaultCountryValue,
    label
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [gettingCountries, setGettingCountries] = useState(false);

    useEffect(() => {
        if (countries.length === 0) {
            setGettingCountries(true);
            getStoreCountries()
                .then(({ data }) => {
                    setCountries(data);
                    setGettingCountries(false);
                })
                .catch(err => {
                    enqueueSnackbar(t('CountrySelector.error'), errorToast);
                    setGettingCountries(false);
                });
        }
    }, [countries]);

    return (
        <FormControl margin="normal" className={classes.formControl}>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
                name={name}
                defaultValue={defaultCountryValue}
                options={countries}
                loading={gettingCountries}
                getOptionLabel={option => (`${countryToFlag(option.code)}  ${option.name}`)}
                onChange={(e, v) => handleCountryChange(v)}
                getOptionSelected={(o, v) => o.name === v.name}
                renderOption={option => (
                    <>
                        <span style={{ marginRight: '1rem' }}>
                            {countryToFlag(option.code)}&nbsp;&nbsp;{option.name}
                        </span>
                    </>
                )}
                style={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
        </FormControl >
    );
};

const mapStateToProps = ({ common }) => ({
    countries: common.countries
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setCountries
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CountrySelectorAutocomplete);

