import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { CardHeader, Box, FormControl, FormLabel, OutlinedInput } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';

const ProductAvailability = ({ isTabletOrMobile, control, watch, productData, setValue, hasVariants }) => {
    const { t } = useTranslation();
    const active = watch('active');
    let { id } = useParams();
    const [isCheck, setIsCheck] = useState(false)

    useEffect(() => {
        if (productData) {
            setValue('active', productData.active === 1 ? true : false);
            setValue('quantity_available', productData?.quantity_available);
        }
        setIsCheck(Boolean(productData.active))
    }, [productData, setValue]);

    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('Product.availability')} />
            <CardContent>
                <Grid
                    container
                    spacing={isTabletOrMobile ? 0 : 3}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Controller
                            render={({ field: { value, ref, ...field } }) => (
                                <Switch
                                    {...field}
                                    inputRef={ref}
                                    checked={!!value}
                                    disableRipple
                                    onClick={() => setIsCheck(!isCheck)}
                                />
                            )}
                            control={control}
                            defaultValue={true}
                            name="active" />
                        {id
                            ? <Typography variant="body2">
                                {isCheck ? t('Product.availability.switchActive') : t('Product.availability.switchInactive')}
                            </Typography>
                            : <Typography variant="body2">
                                {(!isCheck) ? t('Product.availability.switchActive') : t('Product.availability.switchInactive')}
                            </Typography>
                        }
                    </Box>
                    {!hasVariants && <FormControl style={{ padding: 12 }}>
                        <FormLabel>{t('Product.availability.quantity_available')}</FormLabel>
                        <Controller
                            render={({ field: { value, ref, ...field } }) => (
                                <OutlinedInput
                                    {...field}
                                    value={value}
                                    margin="normal"
                                    style={{ width: '80%' }}
                                    type="number"
                                    defaultValue={null}
                                    inputProps={{ min: 0 }}
                                />
                            )}
                            defaultValue={null}
                            control={control}
                            name="quantity_available"
                        />
                    </FormControl>}
                </Grid>
            </CardContent>
        </Card>
    );
};
export default connect()(ProductAvailability);