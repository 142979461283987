import React from 'react';
import { Route } from 'react-router-dom'

import { LoginPage } from '../../pages/LogInPage/LogInPage';
import { SignUpPage } from '../../pages/SignUpPage/SignUpPage';
import { SignUpUserInfoPage } from '../../pages/SignUpUserInfoPage/SignUpUserInfoPage';
//import { SignUpDetailsPage } from '../../pages/SignUpDetailsPage/SignUpDetailsPage';
import { SignUpVerificationPage } from '../../pages/SignUpVerificationPage/SignUpVerificationPage';
import RecoveryPasswordPage from '../../pages/RecoveryPasswordPage/RecoveryPasswordPage';
import ResetPasswordPage from '../../pages/ResetPasswordPage/ResetPasswordPage';
import AuthCodePage from '../../pages/AuthCodePage/AuthCodePage';

/**
 * Page container for the auth forms
 */
const AuthContainer = () => {
    return (
        <main>
            <Route exact path="/" component={LoginPage} />
            <Route exact path="/sign-up/discount" component={SignUpPage} />
            <Route exact path="/sign-up/verify" component={SignUpVerificationPage} />
            {/*<Route exact path="/sign-up/details" component={SignUpDetailsPage} />*/}
            <Route exact path="/sign-up" component={SignUpUserInfoPage} />
            <Route exact path="/code" component={AuthCodePage} />
            <Route exact path="/password-recovery" component={RecoveryPasswordPage} />
            <Route exact path="/reset" component={ResetPasswordPage} />
        </main>
    );
};

export default AuthContainer;