// react dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Link as RouterLink } from 'react-router-dom';

// material ui dependencies
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// internal dependencies
import { routes, successToast, errorToast, numberRegex } from '../../constants.js/misc';

// internal components
import CardItemActionFooter from '../../components/CardItemActionFooter/CardItemActionFooter';
import { Intro, ButtonContainer } from '../../components/ManageCollection/ManageCollection.styles';

//Icons
import ConfigIcon from "../../assets/icons/config.svg";

import { editShippingStore, getStoreService } from '../../services/store';
import { FormHelperText } from '@material-ui/core';
import { Helper } from '../../components/ClientInfoPage/EditClientDataModal/ClientForm';


const ShippingConfigPage = ({ storeId }) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [checkValueDelivery, setCheckValueDelivery] = useState(false);
    const [checkValueTime, setCheckValueTime] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const { handleSubmit, control, formState, errors, setValue } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        getStoreService(storeId)
            .then(({ data }) => {
                setCheckValueDelivery(data.delivery_cost_enabled);
                setCheckValueTime(data.shipping_time_enabled);
                setValue('delivery_cost_enabled', data.delivery_cost_enabled);
                setValue('delivery_cost_details', data.delivery_cost_details);
                setValue('shipping_time_enabled', data.shipping_time_enabled);
                setValue('shipping_details', data.shipping_details);
            })
            .catch(err => { });
    }, [storeId, setValue]);

    const onSubmit = (data) => {
        setSaving(true);
        editShippingStore(storeId, data)
            .then(({ data }) => {
                setSaving(false);
                enqueueSnackbar(t('Config.shippingMessageSuccess'), successToast);
            })
            .catch(error => {
                setSaving(false);
                enqueueSnackbar(t('GeneralPage.errorMessage'), errorToast);
            });
    };

    return (
        <Grid container spacing={isTabletOrMobile ? 0 : 3}>
            {isTabletOrMobile &&
                <Intro item container xs={12}>
                    <img src={ConfigIcon} alt="" />
                    <Typography variant="caption">
                        {t('Config.shippingRoute')}
                    </Typography>

                </Intro>
            }
            <Grid item xs={12} style={{ margin: isTabletOrMobile ? 17 : 0 }}>
                <Grid item xs={12} lg={6}>
                    <Typography
                        variant={isTabletOrMobile ? 'h5' : 'h4'}
                        style={{ marginBottom: '10px' }}
                    >
                        {t('Config.shipping')}
                    </Typography>
                    <Link
                        component={RouterLink}
                        to={routes.config}
                    >
                        {t('Config.title')}
                    </Link>
                </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <Grid item xs={12} style={{ marginBottom: 18 }}>
                    <CardItemActionFooter
                        title={t('Config.shippingTitle')}
                        children={
                            <React.Fragment>
                                <Controller
                                    name="delivery_cost_enabled"
                                    /* checked={true} */
                                    control={control}
                                    render={({ field: { value, ref, ...field } }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    inputRef={ref}
                                                    checked={!!value}
                                                    disableRipple
                                                />
                                            }
                                            label={t('Config.shippingOptionViewCheck')}
                                        />
                                    )}
                                />
                                <FormControl
                                    variant="outlined"
                                    margin="normal"
                                >
                                    <FormLabel error={errors?.delivery_cost_details ? true : false}>
                                        {t('Config.shippingOptionViewPrice')}
                                    </FormLabel>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="delivery_cost_details"
                                        rules={{ pattern: numberRegex }}
                                        render={({ field }) => (
                                            <OutlinedInput
                                                {...field}
                                                defaultValue=""
                                                placeholder={t('Config.shippingPlaceHolder')}
                                                error={errors?.delivery_cost_details ? true : false}
                                            />
                                        )}
                                    />
                                    {formState?.errors?.delivery_cost_details && <Helper><FormHelperText>{t('Config.shippingOptionNumberError')}</FormHelperText></Helper>}
                                    <FormHelperText error={errors?.delivery_cost_details ? true : false}>{errors?.delivery_cost_details ? t('Config.shippingOptionNumberError') : ''}</FormHelperText>
                                </FormControl>
                            </React.Fragment>
                        }
                    />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 18 }}>
                    <CardItemActionFooter
                        title={t('Config.shipping.timeTitle')}
                        children={
                            <React.Fragment>
                                <Controller
                                    name="shipping_time_enabled"
                                    defaultValue=""
                                    control={control}
                                    render={({ field: { value, ref, ...field } }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    inputRef={ref}
                                                    checked={!!value}
                                                    disableRipple
                                                />
                                            }
                                            label={t('Config.shippingOptionTimeCheck')}
                                        />
                                    )}
                                />
                                <FormControl
                                    margin="normal"
                                    fullWidth
                                >
                                    <FormLabel error={errors?.shipping_details ? true : false}>
                                        {t('Config.shippingOptionTimeLabel')}
                                    </FormLabel>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="shipping_details"
                                        render={({ field }) => (
                                            <OutlinedInput
                                                {...field}
                                                defaultValue=""
                                                placeholder={t('Config.shippingOptionTimePlaceHolder')}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </React.Fragment>
                        }
                    />
                </Grid>
                {!isTabletOrMobile
                    ?
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        <Button
                            type="submit"
                            disabled={saving}
                            color="primary"
                            variant="contained">
                            {t('SaveChangesBtn')}
                        </Button>
                    </Grid>
                    :
                    <ButtonContainer
                        item
                        xs={12}
                        lg={4}>
                        <Button
                            style={{ width: '100%' }}
                            type="submit"
                            disabled={saving}
                            color="primary"
                            variant="contained">
                            {t('SaveChangesBtn')}
                        </Button>
                    </ButtonContainer>
                }
            </form>
        </Grid>
    );
};

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(ShippingConfigPage);
