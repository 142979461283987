import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

// external dependencies
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, CircularProgress, Link, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';

import { Autocomplete } from '@material-ui/lab';

import {
    ActionItem,
    SubmitButtonContainer
} from '../SignUpForm.style';

import { emailRegex, phoneRegex, passWordRegex, routes } from '../../../constants.js/misc';
import { countries } from '../../../constants.js/countries';
import { signUp, logIn, saveSignUpData } from '../../../modules/auth';
import { setShowWelcomePopup } from "../../../modules/common";

import visibility from '../../../assets/icons/visibility.svg';
import visibilityOff from '../../../assets/icons/visibilityOff.svg';

//MIXPANEL
import { Mixpanel } from '../../../constants.js/mixpanel';
import ReactPixel from 'react-facebook-pixel'
import { welcomeMessageService } from '../../../services/home';
import InsightsSingUp from './InsightsSingUp/InsightsSingUp';


// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode
}

const fieldsSchema = yup.object({
  first_name: yup.string().required("Se requiere el nombre"),
  last_name: yup.string().required("Se requiere el apellido"),
  email: yup.string().required("Se requiere un email"),
  password: yup.string().required("Se requiere una contraseña"),
  store: yup.string().required("Se requiere el nombre de la tienda"),
  store_phone: yup
    .string()
    .matches(phoneRegex, "Se requiere un número de teléfono válido")
    .required("Se requiere un número de teléfono"),
  user_phone: yup
    .string()
    .matches(phoneRegex, "Se requiere un número de teléfono válido")
    .required("Se requiere un número de teléfono"),
});

const ONBOARDING = gql`
    {
        onboarding{
            id,
            question,
            onboardingOptions{
                id,
                order,
                option
            }
        }
    }
`;

const SignUpUserInfoForm = ({
  signUp,
  isSigningUp,
  signUpData,
  logIn,
  saveSignUpData,
  selectedPlan,
  setShowWelcomePopup,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, error, data } = useQuery(ONBOARDING);
  const [saving, setSaving] = useState(false);
  const [password, setPassword] = useState(false);
  const [prefix, setPrefix] = useState();
  const [valueNameMarket, setValueNameMarket] = useState('')

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    register,
    watch,
    setValue,
  } = useForm({
    yupResolver: yupResolver(fieldsSchema),
    mode: "all",
  });

  const handleSignUpError = (error) => {
    setSaving(false);
    enqueueSnackbar(t(error), {
      variant: "error",
      autoHideDuration: 2500,
    });
  };

  const handleRedirectionWelcome = () => {
    setShowWelcomePopup(true);
  };

  const showPassword = () => {
    setPassword((prevState) => !prevState);
  };

  const handleKeyDown = (e) => {
    var event = e || window.event;
    var key = event.keyCode || event.which;

    if (key === 110 || key === 190 || key === 188) {
      event.preventDefault();
    }
  };

  const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === "") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const filterCountries = (options, state) => {
    return options.filter((option) => {
      return (
        option.phone.toLowerCase().includes(state.inputValue.toLowerCase()) ||
        option.label.toLowerCase().includes(state.inputValue.toLowerCase())
      );
    });
  };

  const onSubmit = (formData) => {
    setSaving(true);

    // createHubSpotContact({
    //     "properties": {
    //         "firstname": signUpData.first_name,
    //         "lastname": signUpData.last_name,
    //         "phone": formData.storeCountryNumber + formData.store_phone,
    //         "hs_whatsapp_phone_number": formData.storeCountryNumber + formData.store_phone,
    //         "utm_campaign": signUpData.utm_campaign,
    //         "utm_medium": signUpData.utm_medium,
    //         "utm_source": signUpData.utm_source,
    //         "source": 'registro_web',
    //         "email": formData.email,
    //         "company": formData.store,
    //         "website": formData.business_url || '',
    //         "industry": data.onboarding[0]?.onboardingOptions.find(x => x.id === formData.onboarding_1).option,
    //         "sales_channel": data.onboarding[1]?.onboardingOptions.find(x => x.id === formData.onboarding_2).option
    //     }
    // });

    Mixpanel.track("Registro", {
      Canal: getCookie("source"),
      "Fuente de trafico": document.referrer,
      Correo: formData.email,
      "Nombre de la tienda": formData.store,
      "No whatsapp": signUpData.countryNumber + signUpData.phone,
    });

    const handleRegisterOk = () => {
      // Send welcome whatsapp
      const userPhoneNumber =
        formData.storeCountryNumber + formData.store_phone;
      const userName = formData.first_name;
      // sendWelcomeMessage(userPhoneNumber, userName, API_URL, API_KEY);
      const data = { userPhoneNumber, userName };
      welcomeMessageService(data)
        .then((response) => console.log("Welcome mensaje", response))
        .catch((error) =>
          console.log("Imposible enviar mensaje de bienvenida", error)
        );
    }

    signUp(
      {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email?.trim(),
        password: formData.password,
        store: formData.store,
        phone: formData.storeCountryNumber + formData.store_phone,
        contact_phone_number: formData.countryNumber + formData.user_phone,
        source: getCookie("source"),
        verified: 1,
        user_type_id: 1,
        user_status_id: 1,
        country: signUpData.country,
        country_code: formData.storeCountry,
        on_boarding_options: [+formData.onboarding_1, +formData.onboarding_2],
        user_information: {
          first_name: signUpData.first_name,
          last_name: signUpData.last_name,
          phone_number: signUpData.countryNumber + signUpData.phone,
          business_url: formData.business_url || "",
          country_code: signUpData.country,
        },
      },
      logIn,
      handleSignUpError,
      handleRedirectionWelcome,
      handleRegisterOk
    );

    
    // const sendInfoWA = (msg) => {
    //   const sirenaNumber = '573003154556'
    //   const message = `
    //       ${msg}
    //       *Correo*: ${formData.email?.trim()},
    //       *Teléfono*: ${formData.storeCountryNumber + formData.store_phone},
    //       *Nombre de la tienda*: ${formData.store}
    //   `
    //   window.open(`https://wa.me/${sirenaNumber}?text=${encodeURIComponent(message)}`)
    // }
    if (location.pathname === "/sign-up/discount") {
      localStorage.setItem("campaing", "true");
      localStorage.setItem("selectedPlan", selectedPlan);
      // if (selectedPlan === 'annual') {
      //     sendInfoWA('Hola, estos son los datos de mi tienda, quiero información sobre el plan anual.')
      // }
      ReactPixel.track("AddToCart", {
        content_name: "/sign-up/discount",
      });
    } else {
      localStorage.setItem("campaing", "false");
      // sendInfoWA('Hola, estos son los datos de mi tienda para una asesoría personalizada.')
      ReactPixel.track("CompleteRegistration", {
        content_name: "Terminando formulario",
      });
    }
  };

  const PopperMy = function (props) {
    return (
      <Popper {...props} style={{ width: 250 }} placement="bottom-start" />
    );
  };

  const handleFilterInput = (e) => {
    const actValue = e.target.value;
    const filValue = actValue.replace(/[^a-zA-Z0-9\s]/g, "");
    setValueNameMarket(filValue);
  }

  useEffect(() => {
    ReactPixel.init("678045126245695");
  }, []);

  return (
    <Box>
      <InsightsSingUp />
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} lg={6}>
            <FormControl variant="outlined" fullWidth>
              <FormLabel error={errors?.store ? true : false}>
                {t("SignUp.firstName")}
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    {...register("first_name")}
                    onKeyDown={handleKeyDown}
                    error={errors?.store ? true : false}
                  />
                )}
                name="first_name"
                control={control}
                rules={{ required: true, minLength: 4 }}
              />
              <FormHelperText error={errors?.store ? true : false}>
                {errors?.store ? t("SignUp.storeError") : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl variant="outlined" fullWidth>
              <FormLabel error={errors?.store ? true : false}>
                {t("SignUp.lastName")}
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    {...register("last_name")}
                    onKeyDown={handleKeyDown}
                    error={errors?.store ? true : false}
                  />
                )}
                name="last_name"
                control={control}
                rules={{ required: true, minLength: 4 }}
              />
              <FormHelperText error={errors?.store ? true : false}>
                {errors?.store ? t("SignUp.storeError") : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl variant="outlined" fullWidth>
              <FormLabel error={errors?.store ? true : false}>
                {t("SignUp.nameMarket")}
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    {...register("store")}
                    onKeyDown={handleKeyDown}
                    error={errors?.store ? true : false}
                    onChange={handleFilterInput}
                    value={valueNameMarket}
                  />
                )}
                name="store"
                control={control}
                rules={{ required: true, minLength: 4 }}
              />
              <FormHelperText error={errors?.store ? true : false}>
                {errors?.store ? t("SignUp.storeError") : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl variant="outlined" fullWidth>
              <FormLabel error={errors?.email ? true : false}>
                {t("LogIn.email")}
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    {...register("email")}
                    defaultValue={signUpData.email}
                    error={errors?.email ? true : false}
                  ></OutlinedInput>
                )}
                control={control}
                name="email"
                rules={{ required: true, pattern: emailRegex }}
              />
              <FormHelperText error={errors?.email ? true : false}>
                {errors?.email ? t("LogIn.emailError") : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl variant="outlined" fullWidth>
              <FormLabel error={errors?.password ? true : false}>
                {t("LogIn.password")}
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <OutlinedInput
                    type={password ? "text" : "password"}
                    {...field}
                    {...register("password")}
                    error={errors?.password ? true : false}
                  />
                )}
                control={control}
                name="password"
                rules={{ required: true, pattern: passWordRegex }}
              />
              <img
                style={{
                  width: "25px",
                  height: "25px",
                  position: "absolute",
                  right: "15px",
                  top: "33px",
                  cursor: "pointer",
                }}
                src={password ? visibilityOff : visibility}
                onClick={showPassword}
              />
              <FormHelperText error={errors?.password ? true : false}>
                {errors?.password ? t("LogIn.passwordErrorMinLength") : ""}
              </FormHelperText>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} lg={6}>
              <FormControl variant="outlined" fullWidth>
                <FormLabel error={errors?.business_url ? true : false}>
                  {t("SignUp.website")}{" "}
                  <sup style={{ fontSize: "10px" }}>
                    {t("SignUp.websiteOptional")}
                  </sup>
                </FormLabel>
                <Controller
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      {...register("business_url")}
                      error={errors?.business_url ? true : false}
                      placeholder={t("SignUp.websitePlaceholder")}
                    />
                  )}
                  control={control}
                  name="business_url"
                />
                <FormHelperText error={errors?.business_url ? true : false}>
                  {errors?.business_url ? t("SignUp.website") : ""}
                </FormHelperText>
              </FormControl>
            </Grid> */}
          {/* {get(data, 'onboarding', Array(2).fill({onboardingOptions: []})).map(onboardingItem => (
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" key={onboardingItem?.id}>
                                <InputLabel id={'onboarding_' + onboardingItem?.id}>{t(onboardingItem?.question)}</InputLabel>
                                <Controller
                                    control={control}
                                    name={'onboarding_' + onboardingItem?.id}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select labelId={'onboarding_' + onboardingItem?.id} {...field}>
                                            {onboardingItem?.onboardingOptions?.map(option => (
                                                <MenuItem key={option?.id} value={option?.id}>{option?.option}</MenuItem>
                                            ))}
                                        </Select>
                                    )}>
                                </Controller>
                                {loading && <LinearProgress />}
                            </FormControl>
                        </Grid>
                    ))} */}
          <Grid item xs={12} lg={6}>
            <InputLabel htmlFor="storeWhatsapp">
              {t("SignUp.whatsapp")}
            </InputLabel>
            <Grid container spacing={2} style={{ alignItems: "center" }}>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name="storeCountry"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      variant="outlined"
                      PopperComponent={PopperMy}
                      id="storeWhatsappCountry"
                      style={{ width: "100%" }}
                      options={countries}
                      size="small"
                      getOptionSelected={(o, v) => o?.phone === v?.phone}
                      onChange={(event, value) => {
                        setValue("storeCountry", value?.code);
                        setValue("storeCountryNumber", value?.phone);
                      }}
                      disableClearable
                      disableListWrap
                      noOptionsText="No hay resultados"
                      filterOptions={filterCountries}
                      getOptionLabel={(option) => option?.phone || ""}
                      renderOption={(option) => (
                        <>
                          <span style={{ marginRight: "1rem" }}>
                            {countryToFlag(option.code)}
                          </span>
                          {`${option.label} +${option.phone}`}
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          placeholder="(+00)"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: false,
                            autoComplete: prefix, // disable browser autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={9}>
                <Controller
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      error={errors?.store_phone ? true : false}
                      fullWidth
                      helperText={
                        errors?.store_phone ? t("SignUp.phoneError") : ""
                      }
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      type="tel"
                      placeholder={t("SignUp.phonePlaceholder")}
                    />
                  )}
                  control={control}
                  name="store_phone"
                  rules={{ required: true, pattern: phoneRegex }}
                />
              </Grid>
            </Grid>
            <FormHelperText error={errors?.store_phone ? true : false}>
              {errors?.store_phone ? t("SignUp.phoneError") : ""}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel htmlFor="storeWhatsapp">
              {t("SignUp.phoneUser")}
            </InputLabel>
            <Grid container spacing={2} style={{ alignItems: "center" }}>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name="country"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      variant="outlined"
                      PopperComponent={PopperMy}
                      id="storeWhatsappCountry"
                      style={{ width: "100%" }}
                      options={countries}
                      size="small"
                      getOptionSelected={(o, v) => o?.phone === v?.phone}
                      onChange={(event, value) => {
                        setValue("country", value?.code);
                        setValue("countryNumber", value?.phone);
                      }}
                      disableClearable
                      disableListWrap
                      noOptionsText="No hay resultados"
                      filterOptions={filterCountries}
                      getOptionLabel={(option) => option?.phone || ""}
                      renderOption={(option) => (
                        <>
                          <span style={{ marginRight: "1rem" }}>
                            {countryToFlag(option.code)}
                          </span>
                          {`${option.label} +${option.phone}`}
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          placeholder="(+00)"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: false,
                            autoComplete: prefix, // disable browser autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={9}>
                <Controller
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      error={errors?.user_phone ? true : false}
                      fullWidth
                      helperText={
                        errors?.user_phone ? t("SignUp.phoneError") : ""
                      }
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      type="tel"
                      placeholder={t("SignUp.phonePlaceholder")}
                    />
                  )}
                  control={control}
                  name="user_phone"
                  rules={{ required: true, pattern: phoneRegex }}
                />
              </Grid>
            </Grid>
            <FormHelperText error={errors?.user_phone ? true : false}>
              {errors?.user_phone ? t("SignUp.phoneError") : ""}
            </FormHelperText>
          </Grid>
        </Grid>
        <SubmitButtonContainer lg={6}>
          <Button
            color="primary"
            disabled={!isValid || saving}
            type="submit"
            size="large"
            variant="contained"
            fullWidth
          >
            {saving ? (
              <CircularProgress size={30} color="secondary" />
            ) : (
              t("SignUp.userFormCta")
            )}
          </Button>
        </SubmitButtonContainer>
        <ActionItem mt={2} style={{ position: "relative" }}>
          <Typography variant="body1" gutterBottom>
            {t("SignUp.alreadyIn")}
            <Link component={RouterLink} to="/">
              {t("LogIn")}
            </Link>
          </Typography>
        </ActionItem>
      </form>
    </Box>
  );
};

const mapStateToProps = ({ auth }) => ({
  isSigningUp: auth.isSigningUp,
  signUpData: auth.signUpData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { signUp, logIn, saveSignUpData, setShowWelcomePopup },
    dispatch
  );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpUserInfoForm);

