
import styled from '@emotion/styled';



export const Price = styled.p`
    color: #1d293f;
    font-size: 1.5rem;
    line-height: 1.75;
`;

export const DetailsPrice = styled.p`
    font-style: normal;
    color: #53627c;
    font-size: 16px;
    margin-bottom: 2rem;
`;

export const Options = styled.li`
    list-style: none;
    margin-bottom: 14px;
`;

export const CenterAlign = styled.div`
    text-align: center;
    button{
        margin-bottom: 2rem;
    }
`;



export const IconCheck = styled.img`
    height: 21px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
`;
export const Detail = styled.p`
   margin-bottom: 1.25rem;
`;
export const Op = styled.span`
position: relative;
display: inline-block;

&::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #3a8a7a;
  transform: rotate(10deg);
}
`