import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  Link,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';

//Internal Dependencies
import { routes } from '../../../constants.js/misc';

import { HeaderClassStyle, AppCover, AppCoverEmpty } from './Header.styles';
import CheckStoreBag from './CheckStoreBag';
import WsIcon from './WsIcon';

const Header = ({ shopTitle, inactiveForPay, shopLogo, shopDescription, shopCover, verified, cartUrl, cartItems, shopType, loading }) => {
  const shopLogoImageSrc = shopLogo
    ? (`${process.env.REACT_APP_IMAGES_API_URL}fit-in/90x90/${shopLogo}`)
    : (`https://ui-avatars.com/api/?name=${shopTitle || `10X&length=3`}&size=90&background=266678&color=fff`);
  const shopLogoImageSrcSet = shopLogo
    ? (`${process.env.REACT_APP_IMAGES_API_URL}fit-in/90x90/${shopLogo}`)
    : (`https://ui-avatars.com/api/?name=${shopTitle || `10X&length=3`}&size=90&background=266678&color=fff 1x,
                                 https://ui-avatars.com/api/?name=${shopTitle || `10X&length=3`}&size=180&background=266678&color=fff 2x,
                                 https://ui-avatars.com/api/?name=${shopTitle || `10X&length=3`}&size=270&background=266678&color=fff 3x`);

  const shopCoverImage = shopCover ? `${process.env.REACT_APP_IMAGES_API_URL + shopCover}` : ''
  const HeaderStyles = HeaderClassStyle();
  const { t } = useTranslation();


  return (
    <header className={HeaderStyles.header}>
      {shopCoverImage ? (
        <AppCover image={shopCoverImage} />
      ) : (
        <AppCoverEmpty>
          <Link
            component={RouterLink}
            to={routes.store}
            underline="none"
            color="secondary"
          >
            <i class="ri-image-add-line" style={{ fontSize: "20px" }}></i>
          </Link>
          <small style={{ textAlign: "center", margin: "0" }}>
            Actualiza tu banner <br /> en la sección de personalización
          </small>
        </AppCoverEmpty>
      )}
      <div className={HeaderStyles.content}>
        <div className={HeaderStyles.shopBranding}>
          <div className={HeaderStyles.avatar}>
            {!loading && (
              <img
                className={HeaderStyles.logo}
                srcSet={shopLogoImageSrcSet}
                src={shopLogoImageSrc}
                alt={shopTitle}
              />
            )}
          </div>
          <div className={HeaderStyles.titleContainer}>
            <Typography variant="h5">
              {shopTitle}
              {!loading && verified && (
                <VerifiedTooltip
                  title="Tienda verificada"
                  aria-label="Tienda verificada"
                  placement="right"
                  enterTouchDelay={0}
                  arrow
                >
                  <div className={HeaderStyles.verifiedBadge}>
                    <CheckStoreBag
                      className={HeaderStyles.verifiedBadgeIcon}
                      color="secondary"
                      fontSize="small"
                    />
                  </div>
                </VerifiedTooltip>
              )}
            </Typography>
            <div className={HeaderStyles.whatsappBadge}>
              <WsIcon className={HeaderStyles.whatsappBadgeIcon} />{" "}
              <span className={HeaderStyles.whatsappBadgeCaption}>
                {!shopType ? `Tienda WhatsApp` : `Tienda WhatsApp Mayorista`}
              </span>
            </div>
          </div>
        </div>
        <Typography variant="body1" gutterBottom>
          {shopDescription}
        </Typography>
      </div>
    </header>
  );
}

// Customize the VerifiedTooltip Component
const VerifiedTooltip = (props) => {
  const classes = verifiedTooltipStyles()
  return (
    <Tooltip arrow classes={classes} {...props} />
  )
}

// Material-UI Styles
const verifiedTooltipStyles = makeStyles(theme => ({
  popper: {
    marginLeft: '-20px',
  },
}));

export default Header
