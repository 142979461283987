// react dependencies
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';


// external dependencies
import { useSnackbar } from 'notistack';

// material ui dependencies
import { FormHelperText, OutlinedInput, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Autocomplete from '@material-ui/lab/Autocomplete';



//MIXPANEL
import { Mixpanel } from '../../../constants.js/mixpanel';

import { setCountries } from '../../../modules/common';

// Internal dependencies
import { commaSeparatedEmailsRegex, errorToast } from '../../../constants.js/misc';
import { setBillingInfoService, getStoreCountries } from '../../../services/billing';
import { getCitiesService, getDocumentTypesService } from '../../../services/misc';

const ShippingInfoForm = ({
    storeId,
    dataToEdit,
    setData,
    handleSuccess,
    formObject,
    setSaving,
    submit,
    setSubmit,
    countries,
    selectedCountryBilling,
    changeCountry
}) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [cities, setCities] = useState([]);
    const [countryChange, setCountryChange] = useState();
    const [documentTypes, setDocumentTypes] = useState([]);
    const formRef = useRef(null);

    const countryToFlag = (isoCode) => {
        return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode
    }

    const {
        control,
        handleSubmit,
        errors,
        setValue
    } = formObject;

    const getCities = () => {
        getCitiesService()
            .then(res => setCities(res.data))
            .catch(err => enqueueSnackbar(t('BilligDetailsModal.citiesError'), errorToast))
    }

    const getDocumenTypes = () => {
        getDocumentTypesService()
            .then(res => setDocumentTypes(res.data))
            .catch(err => enqueueSnackbar(t('BilligDetailsModal.documentTypesError'), errorToast))
    }

    const normalizeData = data => ({
        ...data,
        country: countryChange,
        document_type_id: +data.document_type_id,
        city_id: data.city_id ? +data.city_id : null,
        country_code: countryChange ? countryChange?.code : (selectedCountryBilling ? selectedCountryBilling?.code : (dataToEdit?.country_code ? dataToEdit?.country_code : data?.country?.code)),
    });

    const onSubmit = data => {
        /* setSaving(true);
        setBillingInfoService(storeId, normalizeData(data), dataToEdit)
            .then(res => {
                setSubmit(false);
                setSaving(false);
                handleSuccess();
            })
            .catch(err => {
                setSubmit(false);
                setSaving(false);
                enqueueSnackbar(t('BilligDetailsModal.submitError'), errorToast);
            }); */
        setData(data)
    }

    useEffect(() => {
        getCities();
        getDocumenTypes();
    }, []);

    useEffect(() => {
        if (countries.length === 0) {
            getStoreCountries()
                .then(({ data }) => {
                    setCountries(data);
                })
                .catch(err => { })
        }
    }, [countries]);

    useEffect(() => {
        if (submit) {
            handleSubmit(onSubmit)();
        }
    }, [submit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <Grid container spacing={isTabletOrMobile ? 2 : 3}>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel error={errors?.country_code ? true : false}>{t('OrderDetail.shippingModal.country')}</FormLabel>
                        <Controller
                            name="country_code"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={countries?.find(country => country.code === dataToEdit?.country_code)}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    defaultValue={countries?.find(country => country.code === dataToEdit?.country_code)}
                                    value={countryChange?.code}
                                    error={errors?.country ? true : false}
                                    options={countries}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, v) => { setCountryChange(v); setValue('country_code', v?.code); }}
                                    getOptionSelected={(o, v) => o.name === v.name}
                                    renderOption={option => (
                                        <>
                                            <span style={{ marginRight: '1rem' }}>
                                                {countryToFlag(option.code)}&nbsp;&nbsp;{option.name}
                                            </span>
                                        </>
                                    )}
                                    id="combo-box-demo"
                                    style={{ width: '100%' }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} >
                    <FormControl>
                        <FormLabel error={errors?.city_id ? true : false}>{t('OrderDetail.shippingModal.city')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    native
                                    fullWidth
                                    variant="outlined"
                                    error={errors?.city_id ? true : false}
                                    margin="normal"
                                    defaultValue={dataToEdit?.city_id}
                                //disabled={(countryChange ? (countryChange?.code !== 'CO' || false) : (selectedCountryBilling?.code === 'CO' ? false : (dataToEdit?.country !== 'CO' || false)))}
                                >
                                    <option value=''>...</option>
                                    {cities.map(city => <option value={city.id}>{city.name}</option>)}
                                </Select>
                            )}
                            control={control}
                            rules={{ required: (countryChange?.code === 'CO' ? true : false) }}
                            name="city_id"
                            defaultValue={dataToEdit?.city_id}
                        //defaultValue={(countryChange ? (countryChange?.code === 'CO' ? dataToEdit?.city_id : null) : (dataToEdit?.country_code === 'CO' ? dataToEdit?.city_id : null))}
                        >
                        </Controller>
                    </FormControl>
                </Grid>

                <Grid item xs={12} >
                    <FormControl>
                        <FormLabel error={errors?.address ? true : false}>{t('OrderDetail.shippingModal.address')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.address ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="address"
                            control={control}
                            defaultValue={dataToEdit?.address ? dataToEdit?.address : null}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}  >
                    <FormControl>
                        <FormLabel error={errors?.address_detail ? true : false}>{t('OrderDetail.shippingModal.addressDetail')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.address_detail ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="address_detail"
                            control={control}
                            defaultValue={dataToEdit?.address_detail ? dataToEdit?.address_detail : null}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}  >
                    <FormControl>
                        <FormLabel error={errors?.comments ? true : false}>{t('OrderDetail.shippingModal.comments')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.comments ? true : false}
                                    margin="normal"
                                    multiline
                                    rows={3}
                                />
                            )}
                            name="comments"
                            control={control}
                            defaultValue={dataToEdit?.comments ? dataToEdit?.comments : null}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </form >
    );
};

const mapStateToProps = ({ auth, common }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId,
    countries: common.countries
});

export default connect(
    mapStateToProps
)(ShippingInfoForm);
