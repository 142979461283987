import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import ws from '../../../assets/img/ws.jpg';
import { DOCUMENTS_TYPES } from '../../../constants.js/generals';
import EditClientDataModal from '../EditClientDataModal/EditClientDataModal';
import { useSnackbar } from 'notistack';
import { successToast } from '../../../constants.js/misc';

const ClientInfo = ({ clientId, fullname, first_name, last_name, phone_number, email, dni, document_type, address, address_detail, city_id, city, handleClickContact }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogClose = () => setDialogOpen(false);
    const handleDataUpdate = () => {
        enqueueSnackbar(t('OrderDetail.submitSuccess'), successToast)
        setTimeout(() => window.location.reload(), 1000)
        handleDialogClose();
    }

    const openModal = () => setDialogOpen(true);

    const documentTypeClient = DOCUMENTS_TYPES[document_type]?.name || '';

    return (
        <>
            <Card elevation={2} style={{ marginBottom: '21px' }}>
                <Grid container
                    alignItems="center"
                    justifyContent="center">
                    <Grid item xs={10}>
                        <CardHeader
                            titleTypographyProps={{ variant: 'h6' }}
                            /* avatar={<img src={clientIcon} alt='clientIcon' />} */
                            title={t('ClientDetail.details.title')}
                            style={{ padding: '17px 20px 13px 20px' }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Link
                            style={{ fontSize: '16px', cursor: 'pointer' }}
                            target="_blank"
                            onClick={openModal}
                        >
                            {t('ClientDetail.details.edit')}
                        </Link>
                    </Grid>
                </Grid>
                <CardContent style={{ padding: '0 16px 25px 16px' }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography gutterBottom variant="body1">
                            {t('ClientDetail.details.name')} {fullname}
                        </Typography>
                        <Typography gutterBottom variant="body1" style={{
                            margin: '10px 0 0 0 0', display: 'flex', columnGap: '7px'
                        }}>
                            {t('ClientDetail.details.whatsapp')} <Link
                                style={{ display: 'flex', alignItems: 'center' }}
                                target="_blank"
                                onClick={handleClickContact}>
                                <>
                                    <img src={ws} alt={phone_number} style={{ marginRight: '5px' }} />
                                    +{phone_number}
                                </>
                            </Link>
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {email}
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {t('ClientDetail.details.id')} {documentTypeClient} {dni}
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {t('ClientDetail.details.address')} {address}
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {t('ClientDetail.details.addressDetail')} {address_detail}
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {t('ClientDetail.details.city')} {city?.name}
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {t('ClientDetail.details.department')} {city?.state}
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {t('ClientDetail.details.country')} {city?.country_code}
                        </Typography>
                    </Grid>
                </CardContent>
            </Card>
            <EditClientDataModal
                dataToEdit={{ clientId, first_name, last_name, phone_number, email, dni, document_type, address, address_detail, city_id }}
                //setData={}
                open={dialogOpen}
                isNoClose={false}
                handleClose={handleDialogClose}
                handleSuccess={handleDataUpdate}
                type={1}
            />
        </>
    );
};
export default connect()(ClientInfo);