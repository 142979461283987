import styled from '@emotion/styled';
import TableCell from '@material-ui/core/TableCell';

export const NameCell = styled(TableCell)`
  display: flex;
  align-items: center;

  img {
      margin-right: 1rem;
  }
`;

export const AddNewProduct = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px;
  justify-content: space-between;
`;
export const ProductImage = styled.img`
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
  position: relative; 
  transform: scale(1); 
  cursor: zoom-in;
  &:hover {
    z-index: 1; 
    transform: scale(4) translateX(15px) translateY(14px); 
    border-radius: 4px;
  }
  ${({ isLast }) => isLast && `
    &:hover {
      transform: scale(4) translateX(15px) translateY(-14px); 
      border-radius: 4px;
    }
  `}    
`