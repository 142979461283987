import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthForm } from '../../components/AuthForm/AuthForm';
import SignInForm from '../../components/SignInForm/SignInForm';

import signInImg from '../../assets/img/account_login_v2.jpg';

export const LoginPage = () => {
    const { t } = useTranslation();
    const handleLogin = () => console.log('Login succesfull');
    useEffect(() => {
        if (window.location.search.split('source=')[1]) {
            let d = new Date();
            d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = 'source=' + window.location.search.split('source=')[1] + ";" + expires;
        }
    }, []);

    return (
        <AuthForm
            isLogin={true}
            image={signInImg}
            title={t('LogIn.title')}
            subtitle={t('LogIn.subtitle')}>
            <SignInForm onLogin={handleLogin} />
        </AuthForm>
    );
}