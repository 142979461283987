import { setData } from './utils';

export const SET_COUNTRIES = 'common/SET_COUNTRIES';
export const SET_STORE_DATA = 'common/SET_STORE_DATA';
export const SET_CLEAN_CONTAINER = "common/SET_CLEAN_CONTAINER";
export const SET_SHOW_WELCOME_POPUP = "common/SET_SHOW_WELCOME_POPUP";

const initialState = {
  countries: [],
  storeData: {},
  cleanContainer: false,
  showWelcomePopup: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_STORE_DATA:
      return {
        ...state,
        storeData: action.payload,
      };
    case SET_CLEAN_CONTAINER:
      return {
        ...state,
        cleanContainer: action.payload,
      };
    case SET_SHOW_WELCOME_POPUP:
      return {
        ...state,
        showWelcomePopup: action.payload,
      };
    default:
      return state;
  }
}

export const setCountries = (countries) => setData(SET_COUNTRIES, countries);
export const setStoreData = (store) => setData(SET_STORE_DATA, store);
export const setCleanContainer = (store) => setData(SET_CLEAN_CONTAINER, store);
export const setShowWelcomePopup = (store) => setData(SET_SHOW_WELCOME_POPUP, store);

