import styled from '@emotion/styled';

export const ContentError = styled.div`
    background: #F7EDEE;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
`;

export const IconError = styled.img`
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
`;
