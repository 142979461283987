import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import SignUpUserInfoForm from '../../components/SignUpForm/SignUpUserInfoForm/SignUpUserInfoForm'

//Asets
import { IconCheck } from '../../assets/icons/funcCheckIcon'
import { IconCheckCircleFill } from '../../assets/icons/funcCheckCircleIcon';
import { IconChevronDown } from '../../assets/icons/funcChevronDown';
import { IconChevronUp } from '../../assets/icons/funcChevronUp';
import image from '../../assets/img/discount_image.png'
import logo from '../../assets/img/10x_logo.svg'

//MIXPANEL
import { Mixpanel } from '../../constants.js/mixpanel'

import { 
    Cards,
    CardsContainer,
    CardsTitle,
    Container,
    FormContainer,
    Header,
    ImageContainer,
    IncludesTitle,
    IncludesTitleContainer,
    ListFeatures,
    ListIncludes,
    ListIncludesItem,
    Logo,
    MainContainer,
    Membership,
    PlanInfo,
    PlansInfoContainer,
    Price,
    PriceContainer,
    SectionLeftContainer,
    SectionLeftMobile,
    SectionRightContainer,
    SingUpPageContainer, 
    SubTitle, 
    Title, 
    TitleContainer, 
} from './styles.singUpPage'

const planIncludes = [
    {
        id: 1,
        title: 'Hasta 5 líneas WhatsApp'
    },
    {
        id: 2,
        title: 'Órdenes ilimitadas'
    },
    {
        id: 3,
        title: 'Productos ilimitados'
    },
    {
        id: 4,
        title: 'Categorías ilimitadas'
    },
    {
        id: 5,
        title: 'URL personalizada'
    },
    {
        id: 6,
        title: 'Panel de administración 10x'
    },
    {
        id: 7,
        title: 'Agrega información de envío'
    },
    {
        id: 8,
        title: 'Administra tu base de datos'
    },
    {
        id: 9,
        title: 'Administra tu base de datos'
    },
]

const features = [
    {
        id: 1,
        title: '5 vendedores / Líneas de WhatsApp'
    },
    {
        id: 2,
        title: 'Ordenes ilimitadas - Sin comisiones'
    },
    {
        id: 3,
        title: 'Pasarela de pagos - Sin comisiones'
    }
]

export const SignUpPage = () => {
    const { t } = useTranslation()
    const location = useLocation()
    
    const getParams = new URLSearchParams(location.search)
    const urlParams = getParams.get('utm_campaign')?.includes('COL')
    const priceWithNoDis = urlParams ? '1.018.000' : '540 USD'
    const discountPriceMont = urlParams ? '89.900' : '25 USD'
    const discountPriceYear = urlParams ? '550.000' : '200 USD'
    
    const [openList, setOpenList] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState('monthly')

    useEffect(() => {
        Mixpanel.track('Visita el formulario de registro', {
            "View": 1,
            "Fuente de trafico": document.referrer
        });
        
        if (window.location.search.split('source=')[1]) {
            let d = new Date();
            d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = 'source=' + window.location.search.split('source=')[1] + ";" + expires;
        }
    }, [])

    return (
        <SingUpPageContainer>
            <Container>
            <Header>
                <Logo src={logo} alt="10x logo" />
            </Header>
            <MainContainer>
                <SectionLeftContainer>
                    <TitleContainer>
                        <Title>{t('SignUp.singUpPage.title')}</Title>
                        <SubTitle>{t('SignUp.singUpPage.subTitle')}</SubTitle>
                    </TitleContainer>
                    <ImageContainer>
                        <img src={image} style={{ height:'100%', width: '100%' }} alt='ref image'/>
                    </ImageContainer>
                    <ListFeatures>
                        { features.map( item => (
                            <ListIncludesItem key={item.id}>
                                <IconCheckCircleFill style={{ width: '22px', height: '22px', color: '#266678' }}/>
                                <p>{item.title}</p>
                            </ListIncludesItem>
                        ))}
                    </ListFeatures>
                </SectionLeftContainer>
                <SectionRightContainer>
                        <CardsTitle>{t('SignUp.singUpPage.select')}</CardsTitle>
                        <CardsContainer>
                            <Cards 
                                one={true}
                                selectedPlan={selectedPlan}
                                onClick={() => setSelectedPlan('monthly')} 
                                style={{ 
                                    boxShadow : selectedPlan === 'monthly' && '#62ba9f91 5px 5px 10px', 
                                    backgroundColor: selectedPlan === 'monthly' && '#e0eae9' 
                                }}
                            >
                                <Membership>{t('SignUp.singUpPage.badge')}</Membership>
                                <PlanInfo><p>{t('SignUp.singUpPage.plan')} 1&nbsp;</p> &#8226; {t('SignUp.singUpPage.monthly')}</PlanInfo>
                                <PriceContainer>
                                    <Price>{discountPriceMont}</Price>
                                    <p>/mes</p>
                                </PriceContainer>
                            </Cards>
                            <Cards 
                                onClick={() => setSelectedPlan('annual')} 
                                style={{ 
                                    boxShadow : selectedPlan === 'annual' && '#62ba9f91 5px 5px 10px', 
                                    backgroundColor: selectedPlan === 'annual' && '#e0eae9' 
                                }}
                            >
                                <PlanInfo><p>{t('SignUp.singUpPage.plan')} 2&nbsp;</p> &#8226; {t('SignUp.singUpPage.annual')}</PlanInfo>
                                <PriceContainer>
                                    <Price>{discountPriceYear}</Price>
                                    <p>/año</p>
                                    <del>{priceWithNoDis}</del>
                                </PriceContainer>
                            </Cards>
                        </CardsContainer>
                    <PlansInfoContainer onClick={ () => setOpenList(!openList) }>
                        <IncludesTitleContainer>
                            <IncludesTitle >{t('SignUp.singUpPage.howIncludes')}</IncludesTitle>
                            {   openList 
                                ? <IconChevronUp style={{ width: '25px', height: '25px' }} /> 
                                : <IconChevronDown style={{ width: '25px', height: '25px' }} />
                            }
                        </IncludesTitleContainer>
                        <hr style={{ color: '#266678' }}/>
                        { openList &&
                            <ListIncludes>
                                { planIncludes.map( item => (
                                    <ListIncludesItem key={item.id}>
                                        <IconCheck style={{ width: '22px', height: '22px' }}/>
                                        <p>{item.title}</p>
                                    </ListIncludesItem>
                                ))}
                            </ListIncludes>
                        }
                    </PlansInfoContainer>
                    <FormContainer>
                        <SignUpUserInfoForm selectedPlan={selectedPlan}/>
                    </FormContainer>
                </SectionRightContainer>

                <SectionLeftMobile>
                    <TitleContainer>
                        <Title>{t('SignUp.singUpPage.title')}</Title>
                        <SubTitle>{t('SignUp.singUpPage.subTitle')}</SubTitle>
                    </TitleContainer>
                    <div>
                        <CardsTitle>{t('SignUp.singUpPage.select')}</CardsTitle>
                        <CardsContainer>
                            <Cards 
                                onClick={() => setSelectedPlan('monthly')} 
                                style={{ 
                                    boxShadow : selectedPlan === 'monthly' && '#62ba9f91 5px 5px 10px', 
                                    backgroundColor: selectedPlan === 'monthly' && '#e0eae9' 
                                }}
                            >
                                <Membership>{t('SignUp.singUpPage.badge')}</Membership>
                                <PlanInfo><p>{t('SignUp.singUpPage.plan')} 1&nbsp;</p> &#8226; {t('SignUp.singUpPage.monthly')}</PlanInfo>
                                <PriceContainer>
                                    <Price>{discountPriceMont}</Price>
                                    <p>/mes</p>
                                </PriceContainer>
                            </Cards>
                            <Cards 
                            onClick={() => setSelectedPlan('annual')} 
                            style={{ 
                                boxShadow : selectedPlan === 'annual' && '#62ba9f91 5px 5px 10px', 
                                backgroundColor: selectedPlan === 'annual' && '#e0eae9' 
                            }}
                            >
                                <PlanInfo><p>{t('SignUp.singUpPage.plan')} 2&nbsp;</p> &#8226; {t('SignUp.singUpPage.annual')}</PlanInfo>
                                <PriceContainer>
                                    <Price>{discountPriceYear}</Price>
                                    <p>/año</p>
                                    <del>{priceWithNoDis}</del>
                                </PriceContainer>
                            </Cards>
                        </CardsContainer>
                    </div>
                    <FormContainer style={{ margin: '20px 0 0' }}>
                        <SignUpUserInfoForm selectedPlan={selectedPlan}/>
                    </FormContainer>
                    <ListFeatures style={{ margin: '20px 0 0' }}>
                        { features.map( item => (
                            <ListIncludesItem key={item.id}>
                                <IconCheckCircleFill style={{ width: '22px', height: '22px', color: '#266678' }}/>
                                <p>{item.title}</p>
                            </ListIncludesItem>
                        ))}
                    </ListFeatures>
                    <ImageContainer>
                        <img src={image} style={{ height:'100%', width: '100%' }} alt='ref image'/>
                    </ImageContainer>
                    <PlansInfoContainer onClick={ () => setOpenList(!openList) }>
                        <IncludesTitleContainer>
                            <IncludesTitle >{t('SignUp.singUpPage.howIncludes')}</IncludesTitle>
                            {   openList 
                                ? <IconChevronUp style={{ width: '25px', height: '25px' }} /> 
                                : <IconChevronDown style={{ width: '25px', height: '25px' }} />
                            }
                        </IncludesTitleContainer>
                        <hr style={{ color: '#266678' }}/>
                        { openList &&
                            <ListIncludes>
                                { planIncludes.map( item => (
                                    <ListIncludesItem key={item.id}>
                                        <IconCheck style={{ width: '22px', height: '22px' }}/>
                                        <p>{item.title}</p>
                                    </ListIncludesItem>
                                ))}
                            </ListIncludes>
                        }
                    </PlansInfoContainer>
                </SectionLeftMobile>
            </MainContainer>
            </Container>
        </SingUpPageContainer> 
    )
}