// ReactJS
import React from "react";

// MUI Components
import { Card, Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '1rem'
    },
});

const InfoGrid = ({ message }) => {
    const classes = useStyles();

    return (
        <Grid container xs={12} md={10} xl={8} className={classes.header}>
            <Card elevation={1} style={{ padding: 25, width: '100%' }}>
                <Typography
                    variant="body1"
                    style={{ color: '#53627C', fontSize: 16 }}
                >
                    {message}
                </Typography>
            </Card>
        </Grid>
    );
}

export default InfoGrid;