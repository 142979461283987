import React from 'react';
import { Grid } from "@material-ui/core";
import { InsightContainer } from './InsightsSingUp.style';

const InsightsSingUp = () => {
  return (
    <Grid container style={{ marginBottom: "30px", rowGap: '10px' }}>
      <InsightContainer item xs={12} lg={4}>
        <i class="ri-wallet-3-line icon-insight"></i>
        <div className="container-texts">
          <p>0% de comisiones por venta</p>
          <small>Todas las ventas te pertenecen</small>
        </div>
      </InsightContainer>
      <InsightContainer item xs={12} lg={4}>
        <i class="ri-coins-line icon-insight"></i>
        <div className="container-texts">
          <p>Sin costos adicionales</p>
          <small>
            No necesitas configurar Hosting o dominio. tienda lista.
          </small>
        </div>
      </InsightContainer>
      <InsightContainer item xs={12} lg={4}>
        <i class="ri-archive-stack-line icon-insight"></i>
        <div className="container-texts">
          <p>Sube productos Ilimitados</p>
          <small>El límite solo está en tu imaginación.</small>
        </div>
      </InsightContainer>
    </Grid>
  );
}

export default InsightsSingUp