import { getCollectionTreeService } from "../services/collections";
import { setData } from './utils';

export const SET_COLLECTION_TREE = 'collections/SET_COLLECTION_TREE';
export const GETTING_COLLECTION_TREE_TOGGLE = 'collections/GETTING_COLLECTION_TREE_TOGGLE';
export const SET_ACTIVE = 'collections/SET_ACTIVE';

const initialState = {
  collectionTree: [],
  gettingCollectionTree: false,
  activeCollection: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING_COLLECTION_TREE_TOGGLE:
      return {
        ...state,
        ...action.payload,
        gettingCollectionTree: !state.gettingCollectionTree,
      }

    case SET_COLLECTION_TREE:
      return {
        ...state,
        collectionTree: action.payload,
      }

    case SET_ACTIVE:
      return {
        ...state,
        activeCollection: action.payload,
      }

    default:
      return state
  }
}

export const getCollectionTree = (storeId) => {
  return dispatch => {
    dispatch({ type: GETTING_COLLECTION_TREE_TOGGLE });

    getCollectionTreeService(storeId)
      .then(({ data }) => {
        dispatch({ 
            type: SET_COLLECTION_TREE, 
            payload: data
        });
        dispatch({ type: GETTING_COLLECTION_TREE_TOGGLE });
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: GETTING_COLLECTION_TREE_TOGGLE });
      });
  }
}

export const setActiveCollection = (collectionId) => setData(SET_ACTIVE, collectionId);