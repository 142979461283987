// ReactJS
import React from "react";
import { connect } from 'react-redux';

// Components
import ConfigProvider from "../../components/PaymentProviders/ConfigProvider";

const AddPaymentProvidersPage = ({ storeId }) => {
    return (
        <ConfigProvider storeId={storeId} />
    );
}

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId,
});

export default connect(mapStateToProps)(AddPaymentProvidersPage);
