import styled from '@emotion/styled';
import { colors } from '../../constants.js/colors';

export const UserStoreLink = styled.a`
    text-decoration: none;
    color: ${colors.brandColor};
`
export const QuickActions = styled.article`
    display: flex;
    gap: 5px;
`