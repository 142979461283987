import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { CardHeader, FormHelperText, OutlinedInput } from '@material-ui/core';

const InfoCollection = ({ control, errors, dataCollection, setValue }) => {
    const { t } = useTranslation();

    useEffect(() => {
        setValue('name', dataCollection.name || '');
        setValue('description', dataCollection.description || '');
    }, [dataCollection, setValue]);

    return (
        <Card elevation={0}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('Collection.addTitle')} />
            <CardContent>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel error={errors?.name ? true : false}>{t('Collection.nameCollection')}*</FormLabel>
                            <Controller
                                render={({ field }) => (
                                    <OutlinedInput
                                        {...field}
                                        fullWidth
                                        disabled={dataCollection ? dataCollection.default ? true : false : false}
                                        error={errors.name ? true : false}
                                        defaultValue="Holaaaaaaaaaaa"
                                        margin="normal"
                                    />
                                )}
                                control={control}
                                rules={{ required: true }}
                                name="name"
                            />
                            <FormHelperText error={errors?.name ? true : false}>{errors?.name ? t('Product.errors?.name') : ''}</FormHelperText>

                        </FormControl>
                    </Grid>

                    {/*<Grid item xs={12}>
                        <FormControl>
                            <FormLabel>{t('Collection.descriptionCollection')}</FormLabel>
                            <Controller
                                variant="outlined"
                                render = {({ field})=> (
                                <TextField/>
                            )}
                                fullWidth
                                control={control}
                                defaultValue=""
                                margin="normal"
                                name="description"
                                rows={5}
                                rowsMax={4}
                            />
                        </FormControl>
    </Grid>*/}
                </Grid>

            </CardContent>
        </Card>
    );
};



export default connect()(InfoCollection);