import React from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

/*SERVICES */
import { filterNameCollectionsService } from '../../../services/collections';


const AutoCompleteSubColletion = ({ storeId, setEventAdd, label }) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const setFilterValue = (value) => {
        setLoading(true);
        if (value) {
            filterNameCollectionsService(storeId, value)
                .then(({ data }) => {
                    setOptions(data);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }

    const addChip = (value) => {
        setEventAdd(value);
    }

    return (
        <Autocomplete
            style={{ flex: 1 }}
            id="asynchronous-product"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={loading}
            onChange={(event, value) => addChip(value)}
            onInputChange={(event, newInputValue) => {
                setFilterValue(newInputValue);
            }}
            renderInput={(params) => (
                <FormControl>
                    {label && <FormLabel>{label}</FormLabel>}
                    <TextField
                        variant="outlined"
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} style={{ position: 'absolute', right: '11px' }}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                </FormControl>
            )}
        />
    );
};


const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId
});


export default connect(
    mapStateToProps
)(AutoCompleteSubColletion);
