// react dependencies
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// material ui dependencies
import { Card, CardContent, CardHeader, TableHead, Box, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

// assets
import Check from '../../../assets/icons/check.png';
// import Visa from '../../../assets/icons/visa.png';

// internal dependencies
import { getStoreInvoicesService } from '../../../services/billing';
import { routes, formatNumber, formatDate } from '../../../constants.js/misc';

const useStyles = makeStyles({
    noBills: {
        backgroundColor: '#F8F9FB',
        padding: '47px 0',
        fontSize: '16px',
        margin: '0 16px 16px 16px',
        borderRadius: '8px'
    },
});

const BillsTable = ({ storeId }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [hasBills, setHasBills] = useState(false);
    const [billingStore, setBillingStore] = useState([]);
    const [gettingData, setGettingData] = useState(true);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

    useEffect(() => {
        getStoreInvoicesService(storeId)
            .then(({ data }) => {
                setGettingData(false);
                setHasBills(data.length > 0 ? true : false);
                setBillingStore(data);
            })
            .catch(err => setGettingData(false))
    }, []);

    return (
        <Card container spacing={3} style={{ width: '100%' }}>
            <CardHeader
                titleTypographyProps={{ variant: 'h6' }}
                title={t('BillsTable.title')} />
            {gettingData && <LinearProgress />}
            {!gettingData &&
                <CardContent style={{ padding: '16px 0 0 0' }}>
                    {hasBills
                        ?
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('BillsTable.headers.status')}</TableCell>
                                        <TableCell>{t('BillsTable.headers.date')}</TableCell>
                                        {!isTabletOrMobile && <TableCell>{t('BillsTable.headers.amount')}</TableCell>}
                                        {/* <TableCell>{t('BillsTable.headers.method')}</TableCell> */}
                                        {!isTabletOrMobile && <TableCell></TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {billingStore.map(item =>
                                        <TableRow>
                                            <TableCell>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    {item.invoice_status_id === 2 && <img src={Check} alt="" style={{ marginRight: '5px' }} />}
                                                    {t('BillState' + item.invoice_status_id)}
                                                </Box>
                                            </TableCell>
                                            <TableCell>{formatDate(item.created_at)}</TableCell>
                                            {!isTabletOrMobile && <TableCell>{formatNumber(item.amount)}</TableCell>}
                                            {/*<TableCell>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={Visa} alt="" style={{ marginRight: '5px' }} />
                                                    <b>Visa</b> 3294
                                                </Box>
                                            </TableCell> */}
                                            {!isTabletOrMobile &&
                                                <TableCell>
                                                    <Button component={RouterLink} to={`${routes.bill}/${item.id}`} variant="contained">{t('BillsTable.viewBill')}</Button>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <Box
                            className={classes.noBills}
                            container
                            spacing={2}
                            justifyContent="center"
                            display="flex">
                            {t('PendingBills.noBills')}
                        </Box>
                    }
                </CardContent>
            }
        </Card>
    );
};

const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(BillsTable);
