import { setData } from './utils';

export const SET_PRODUCT_TO_EDIT = 'collections/SET_PRODUCT_TO_EDIT';
export const SET_ROWS_PER_PAGE = 'collections/SET_ROWS_PER_PAGE';
export const SET_PAGE = 'collections/SET_PAGE';
export const SET_PRODUCTS_FILE = 'collections/SET_PRODUCTS_FILE';
export const SET_PROCESS_ID = 'collections/SET_PROCESS_ID';

const initialState = {
  productToEdit: null,
  rowsPerPage: 10,
  page: 0,
  productsFile: [],
  processId: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_TO_EDIT:
      return { ...state, productToEdit: action.payload }
    case SET_ROWS_PER_PAGE:
        return { ...state, rowsPerPage: action.payload }
    case SET_PAGE:
        return { ...state, page: action.payload }
    case SET_PRODUCTS_FILE:
        return { ...state, productsFile: action.payload }
    case SET_PROCESS_ID:
          return { ...state, processId: action.payload }

    default:
      return state
  }
}

export const setProductToEdit = (product) => setData(SET_PRODUCT_TO_EDIT, product);
export const setRowsPerPageState = (rowsPerPage) => setData(SET_ROWS_PER_PAGE, rowsPerPage);
export const setPageState = (page) => setData(SET_PAGE, page);
export const setProductsFile = (productsArray) => setData(SET_PRODUCTS_FILE, productsArray);
export const setProcessId = (processId) => setData(SET_PROCESS_ID, processId);
