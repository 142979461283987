// react dependencies
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';


// external dependencies
import { useSnackbar } from 'notistack';

// material ui dependencies
import { FormHelperText, OutlinedInput, Select, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';

// Internal dependencies
import { commaSeparatedEmailsRegex, errorToast, numberRegex, successToast } from '../../../constants.js/misc';
import { getCitiesService, getDocumentTypesService } from '../../../services/misc';
import { Autocomplete } from '@material-ui/lab';
import { getStoreCountries } from '../../../services/billing';
import { setCountries } from '../../../modules/common';
import { updateClientInfo } from '../../../services/clients';
import styled from '@emotion/styled';

export const Helper = styled.div`
    p {
        color: red;
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    &:hover {
        p {
            font-size: 1rem;
            transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
    };
`;

const ClientForm = ({
    storeId,
    dataToEdit,
    countries,
    handleSuccess,
    formObject,
    setSaving,
    submit,
    setSubmit,
    selectedCountryBilling,
    changeCountry
}) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [cities, setCities] = useState([]);
    const [countryChange, setCountryChange] = useState();
    const [documentTypes, setDocumentTypes] = useState([]);

    const formRef = useRef(null);

    const countryToFlag = (isoCode) => {
        return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode
    }

    const {
        control,
        handleSubmit,
        errors,
        formState,
        setValue,
    } = formObject;

    const getCities = () => {
        getCitiesService()
            .then(res => setCities(res.data))
            .catch(err => enqueueSnackbar(t('BilligDetailsModal.citiesError'), errorToast))
    }

    const getDocumenTypes = () => {
        getDocumentTypesService()
            .then(res => setDocumentTypes(res.data))
            .catch(err => enqueueSnackbar(t('BilligDetailsModal.documentTypesError'), errorToast))
    }

    const normalizeData = data => ({
        first_name: data?.first_name,
        last_name: data?.last_name,
        phone_number: data?.phone_number,
        email: data?.email,
        document_type: data?.document_type,
        dni: data?.dni,
        city_id: parseInt(data?.city_id),
        address: data?.address,
        address_line: data?.address_detail,
    });

    const onSubmit = data => {
        setSaving(true);
        updateClientInfo(storeId, dataToEdit?.clientId, normalizeData(data))
            .then(res => {
                setSubmit(false);
                setSaving(false);
                handleSuccess();
            })
            .catch(err => {
                console.log(err)
                setSubmit(false);
                setSaving(false);
                enqueueSnackbar(t('ClientDetail.submitError'), errorToast);
            });
    }

    useEffect(() => {
        getCities();
        getDocumenTypes();
    }, []);

    useEffect(() => {
        if (countries.length === 0) {
            getStoreCountries()
                .then(({ data }) => {
                    setCountries(data);
                })
                .catch(err => { })
        }
    }, [countries]);

    useEffect(() => {
        if (submit) {
            handleSubmit(onSubmit)();
        }
    }, [submit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <Grid container spacing={isTabletOrMobile ? 2 : 3}>
                <Grid item xs={12} lg={6}>
                    <FormControl>
                        <FormLabel error={errors?.first_name ? true : false}>{t('OrderDetail.clientModal.firstName')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.first_name ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="first_name"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={dataToEdit?.first_name}
                        />
                        {formState?.errors?.first_name && <Helper><FormHelperText> {t('OrderDetail.clientModal.firstNameError')}</FormHelperText></Helper>}
                        <FormHelperText error={errors?.first_name ? true : false}>{errors?.first_name ? t('OrderDetail.clientModal.firstNameError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <FormControl>
                        <FormLabel error={errors?.last_name ? true : false}>{t('OrderDetail.clientModal.lastName')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.last_name ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="last_name"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={dataToEdit?.last_name}
                        />
                        {formState?.errors?.last_name && <Helper><FormHelperText>{t('OrderDetail.clientModal.lastNameError')}</FormHelperText></Helper>}
                        <FormHelperText error={errors?.last_name ? true : false}>{errors?.last_name ? t('OrderDetail.clientModal.lastNameError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel error={errors?.phone_number ? true : false}>{t('OrderDetail.clientModal.phone')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.phone_number ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="phone_number"
                            control={control}
                            rules={{ required: true, pattern: numberRegex }}
                            defaultValue={dataToEdit?.phone_number}
                        />
                        {formState?.errors?.phone_number && <Helper><FormHelperText>{t('OrderDetail.clientModal.phoneError')}</FormHelperText></Helper>}
                        <FormHelperText error={errors?.phone_number ? true : false}>{errors?.phone_number ? t('OrderDetail.clientModal.phoneError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel error={errors?.email ? true : false}>{t('OrderDetail.clientModal.email')}</FormLabel>
                        <Controller
                            variant="outlined"
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.email ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="email"
                            control={control}
                            rules={{ required: true, pattern: commaSeparatedEmailsRegex }}
                            defaultValue={dataToEdit.email ? dataToEdit.email : null}
                        />
                        {formState?.errors?.email && <Helper><FormHelperText>{t('OrderDetail.clientModal.emailError')}</FormHelperText></Helper>}
                        <FormHelperText error={errors?.email ? true : false}>{errors?.email ? t('OrderDetail.clientModal.emailError') : ''}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid container style={{ padding: '12px' }}>
                    <Grid item xs={12} lg={2}>
                        <FormControl>
                            <FormLabel error={errors?.document_type ? true : false}>{t('OrderDetail.clientModal.idType')}</FormLabel>
                            <Controller
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        native
                                        variant="outlined"
                                        fullWidth
                                        error={errors?.document_type ? true : false}
                                        margin="normal"
                                    >
                                        <option value="CC">
                                            CC
                                        </option>
                                        <option value="TI">
                                            TI
                                        </option>
                                        <option value="Pasaporte">
                                            Pasaporte
                                        </option>
                                    </Select>
                                )}
                                control={control}
                                /* rules={{ required: true }} */
                                name="document_type"
                                defaultValue={dataToEdit.document_type ? dataToEdit.document_type : 'CC'}
                            >
                            </Controller>
                            {formState?.errors?.document_type && <Helper><FormHelperText>{t('OrderDetail.clientModal.idTypeError')}</FormHelperText></Helper>}
                            <FormHelperText error={errors?.document_type ? true : false}>{errors?.document_type ? t('OrderDetail.clientModal.idTypeError') : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={10} style={{ paddingLeft: '8px' }}>
                        <FormControl>
                            <FormLabel error={errors?.dni ? true : false}>{t('OrderDetail.clientModal.dni')}</FormLabel>
                            <Controller
                                render={({ field }) => (
                                    <OutlinedInput
                                        {...field}
                                        fullWidth
                                        error={errors?.dni ? true : false}
                                        margin="normal"
                                    />
                                )}
                                control={control}
                                /* rules={{ required: true }} */
                                name="dni"
                                defaultValue={dataToEdit.dni ? dataToEdit.dni : null}
                            />
                            {formState?.errors?.dni && <Helper><FormHelperText>{t('OrderDetail.clientModal.dniError')}</FormHelperText></Helper>}
                            <FormHelperText error={errors?.dni ? true : false}>{errors?.dni ? t('OrderDetail.clientModal.dniError') : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>

                {/* <Grid item xs={12}>
                    <FormControl>
                        <FormLabel error={errors?.country_code ? true : false}>{t('OrderDetail.shippingModal.country')}</FormLabel>
                        <Controller
                            name="country_code"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={countries?.find(country => country.code === dataToEdit?.country_code)}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    defaultValue={countries?.find(country => country.code === dataToEdit?.country_code)}
                                    value={countryChange?.code}
                                    error={errors?.country ? true : false}
                                    options={countries}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, v) => { setCountryChange(v); setValue('country_code', v?.code); }}
                                    getOptionSelected={(o, v) => o.name === v.name}
                                    renderOption={option => (
                                        <>
                                            <span style={{ marginRight: '1rem' }}>
                                                {countryToFlag(option.code)}&nbsp;&nbsp;{option.name}
                                            </span>
                                        </>
                                    )}
                                    id="combo-box-demo"
                                    style={{ width: '100%' }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                            )}
                        />
                    </FormControl>
                </Grid> */}

                <Grid item xs={12} >
                    <FormControl>
                        <FormLabel error={errors?.city_id ? true : false}>{t('OrderDetail.shippingModal.city')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    native
                                    fullWidth
                                    variant="outlined"
                                    error={errors?.city_id ? true : false}
                                    margin="normal"
                                    defaultValue={dataToEdit?.city_id}
                                //disabled={(countryChange ? (countryChange?.code !== 'CO' || false) : (selectedCountryBilling?.code === 'CO' ? false : (dataToEdit?.country !== 'CO' || false)))}
                                >
                                    <option value=''>...</option>
                                    {cities.map(city => <option value={city.id}>{city.name}</option>)}
                                </Select>
                            )}
                            control={control}
                            rules={{ required: (countryChange?.code === 'CO' ? true : false) }}
                            name="city_id"
                            defaultValue={dataToEdit?.city_id}
                        //defaultValue={(countryChange ? (countryChange?.code === 'CO' ? dataToEdit?.city_id : null) : (dataToEdit?.country_code === 'CO' ? dataToEdit?.city_id : null))}
                        >
                        </Controller>
                    </FormControl>
                </Grid>

                <Grid item xs={12} >
                    <FormControl>
                        <FormLabel error={errors?.address ? true : false}>{t('OrderDetail.shippingModal.address')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.address ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="address"
                            control={control}
                            defaultValue={dataToEdit?.address ? dataToEdit?.address : null}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}  >
                    <FormControl>
                        <FormLabel error={errors?.address_detail ? true : false}>{t('OrderDetail.shippingModal.addressDetail')}</FormLabel>
                        <Controller
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    fullWidth
                                    error={errors?.address_detail ? true : false}
                                    margin="normal"
                                />
                            )}
                            name="address_detail"
                            control={control}
                            defaultValue={dataToEdit?.address_detail ? dataToEdit?.address_detail : null}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </form >
    );
};

const mapStateToProps = ({ auth, common }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId,
    countries: common.countries
});

export default connect(
    mapStateToProps
)(ClientForm);
