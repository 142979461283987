
// ReactJS
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { routes } from "../../../constants.js/misc";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
});

const NavLink = (isTabletOrMobile) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid
            item
            container
            xs={12} md={10} xl={8}
            className={classes.container}
            style={{
                justifyContent: 'flex-start',
                padding: isTabletOrMobile ? 20 : '0 0 20px'
            }}>
            <Grid item xs={6}>
                <Typography
                    variant={isTabletOrMobile ? 'h5' : 'h4'}
                    style={{ marginBottom: '10px' }}
                >
                    {t('Config.domain')}
                </Typography>
                <Link
                    component={RouterLink}
                    to={routes.config}
                >
                    {t('Config.title')}
                </Link>
            </Grid>
        </Grid>
    );
}

export default NavLink;