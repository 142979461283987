import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthForm } from '../../components/AuthForm/AuthForm';
import SignUpUserInfoForm from '../../components/SignUpForm/SignUpUserInfoForm/SignUpUserInfoForm';

import signUpImg from '../../assets/img/account_signup_v2.jpeg';

export const SignUpUserInfoPage = (props) => {
    const { t } = useTranslation();
    const handleSignUp = () => console.log('signUp succesfull');

    return (
        <AuthForm
            image={signUpImg}
            title={t('SignUp.userTitle')}
            subtitle={t('SignUp.userSubtitle')}>
            <SignUpUserInfoForm onSignUp={handleSignUp}/>
        </AuthForm>
    );
}