import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useMediaQuery } from 'react-responsive';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Card, CardContent, CardHeader, Grid, LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { Intro } from '../../components/ManageCollection/ManageCollection.styles';
import OrderIcon from '../../assets/icons/orders.svg';

//MIXPANEL
import { Mixpanel } from '../../constants.js/mixpanel';

import {
    errorToast,
    infoToast,
    successToast
} from '../../constants.js/misc';

import OrderStatus from '../../components/OrderInfoPage/OrderStatus/OrderStatus';
import OrderDetailsTable from '../../components/OrderInfoPage/OrderDetailsTable/OrderDetailsTable';
import OrderClientInfo from '../../components/OrderInfoPage/OrderClientInfo/OrderClientInfo';

import { getDynamicFormInfo, getOrderService, updateCustomerInfo, updateOrder, updateProductsOrder, updateShippingInfo } from '../../services/orders';
import { routes } from '../../constants.js/misc';
import { setOrderToEdit } from '../../modules/orders';
import OrderShippingInfo from '../../components/OrderInfoPage/OrderShippingInfo/OrderShippingInfo';
import OrderComments from '../../components/OrderInfoPage/OrderComments/OrderComments';
import OrderPaymentInfo from '../../components/OrderInfoPage/OrderPaymentInfo/OrderPaymentInfo';
import { parseDateToEdit, statusShipping } from '../../constants.js/orders';
import StatePill from '../../components/StatePill';

/**
 * Renders the order info page
 * @param {number} storeId main store id
 */
const OrderInfoPage = ({ storeId, orderToEdit, setOrderToEdit, storeData }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();
    let { id, orderId } = useParams();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    // State variables
    const [gettingOrder, setGettingOrder] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [orderData, setOrderData] = useState(null)
    const [formInfo, setFormInfo] = useState([])

    //  State Data Edit Mode
    const [generalData, setGeneralData] = useState({})
    const [clientData, setClientData] = useState()
    const [shippingData, setShippingData] = useState()
    const [error, setError] = useState(false)

    const handleSuccess = () => {
        enqueueSnackbar(t('OrderDetail.submitSuccess'), successToast)
        setTimeout(() => setEditMode(false), 1000)
        window.location.reload()
    }

    // const for button to updateTableProducts
    const submitProductsTable = React.useRef(null);

    /**
     * Get the product data if an id is provided in the url
     */
    useEffect(() => {
        if (id) {
            /**
             * If the orderToEdit variable is set in the state use it to
             * show the info
             */
            if (orderToEdit) {
                Mixpanel.track('Visitar resumen del pedido', {
                    "Canal de venta": orderToEdit.user_store ? orderToEdit.user_store.name : '',
                    "productos": orderToEdit.order_details,
                    "pedido": orderToEdit.sequence,
                    "ID pedido": id
                });
                setOrderData(orderToEdit);
            } else {
                /**
                 * If the variable is not set it means the user got to the page using
                 * only the url, get the store data from the api to show the info
                 */
                setGettingOrder(true);
                getOrderService(storeId, id)
                    .then(res => {
                        setGettingOrder(false);
                        setOrderData(res.data);
                        Mixpanel.track('Visitar resumen del pedido', {
                            "Canal de venta": res.data.user_store ? res.data.user_store.name : '',
                            "productos": res.data.order_details,
                            "pedido": res.data.sequence,
                            "ID pedido": id
                        });
                    })
                    .catch(err => {
                        setGettingOrder(false);
                        enqueueSnackbar(t('OrderDetail.dataError'), errorToast);
                        history.push(routes.ordersPage)
                    });
            }
            getDynamicFormInfo( storeId, id )
            .then( res => setFormInfo(res.data) )
            .catch( err => console.log(err) )
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    
    /**
     * Clean the saved product data after unmount
     */
    useEffect(() => () => {
        setOrderToEdit(null)
    }, [])

    const EditModeOn = () => {
        setEditMode(!editMode)
        enqueueSnackbar(t('OrderDetail.info'), infoToast);
        setGeneralData({
            line: orderData?.user_store?.mobile_phone_number,
            OrderState: orderData?.order_status.id,
            CreationDate: parseDateToEdit(orderData?.created_at),
            ShippingState: orderData?.shipping_order_status_id
        })
    }

    const normalizeGeneralData = (data, moreData) => ({
        user_store_id: parseInt(data?.line) || orderData?.user_store_id,
        date: parseDateToEdit(data?.CreationDate, true),
        comments: moreData?.comments || orderData?.comments,
        order_status_id: data?.OrderState,
        shipping_order_status_id: data?.ShippingState,
        shipping_cost: data?.shippingCost
    });

    const normalizeShippingData = (data) => ({
        // organizar el country code
        country_code: data?.country_code,
        city_id: parseInt(data?.city_id),
        address: data?.address,
        address_detail: data?.address_detail,
    });

    const update = () => (
        submitProductsTable.current?.click()
    )

    const submitEdit = () => {
        update();

        clientData && updateCustomerInfo(storeId, id, orderData?.customer?.id, clientData)
            .then()
            .catch(err => {
                console.log(err)
                setError(true);
            })
        shippingData && updateShippingInfo(storeId, id, normalizeShippingData(shippingData))
            .then()
            .catch(err => {
                console.log(err)
                setError(true);
            })
        generalData && updateProductsOrder(storeId, id, generalData?.dataProducts)
            .then()
            .catch(err => {
                console.log(err)
                setError(true);
            })
        generalData && updateOrder(storeId, id, normalizeGeneralData(generalData, shippingData))
            .then(handleSuccess)
            .catch(err => {
                console.log(err)
                setError(true);
            })

        //submitUpdate();
        //error ? enqueueSnackbar(t('OrderDetail.submitError'), errorToast) : handleSuccess();
    }

    return (
        <Grid container spacing={isTabletOrMobile ? 0 : 3}>
            {isTabletOrMobile &&
                <Intro item container xs={12}>
                    <img src={OrderIcon} alt='OrderIcon' />
                    <Typography variant="caption">
                        {t('OrdersTitle')}
                    </Typography>
                </Intro>
            }

            <Grid item xs={12} style={{ margin: isTabletOrMobile ? 10 : 0 }}>
                <Grid item xs={12}>
                    <Grid container
                        alignItems="center"
                        justifyContent="center">
                        <Grid item xs={10}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <Typography variant="h4" style={{ marginRight: '6px' }}>
                                    {t('OrderDetail.title')} {orderData ? `#${orderData.sequence}` : ''}
                                </Typography>
                                {orderData?.order_status?.name && <StatePill state={orderData?.order_status?.name} type={1} />}
                                {orderData?.shipping_order_status_id && <StatePill state={statusShipping[orderData?.shipping_order_status_id]} type={2} />}
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            {!editMode
                                ? <Button
                                    fullWidth
                                    type='button'
                                    variant="contained"
                                    onClick={EditModeOn}
                                    color="primary">
                                    {t('OrderDetail.editBtn')}
                                </Button>
                                : <Button
                                    fullWidth
                                    type='button'
                                    variant="contained"
                                    onClick={submitEdit}
                                    color="primary">
                                    {t('OrderDetail.saveBtn')}
                                </Button>
                            }
                        </Grid>
                    </Grid>
                    <Link
                        component={RouterLink}
                        to={routes.ordersPage}>
                        ‹ {t('OrdersTablePage.title')}
                    </Link>
                    {gettingOrder && <LinearProgress />}
                </Grid>
            </Grid>
            {(!gettingOrder && orderData) &&
                <>
                    <Grid item xs={12} lg={7}>
                        <OrderStatus status={orderData?.order_status.id} storeId={storeId} orderId={id} editMode={editMode} generalData={generalData} setGeneralData={setGeneralData} />
                        {!editMode && <OrderPaymentInfo
                            info={orderData?.order_payment}
                            orderShippingCost={orderData?.shipping_cost}
                            clientName={`${orderData?.customer?.first_name} ${orderData?.customer?.last_name}`}
                            clientPhone={orderData?.customer?.phone_number}
                            client={orderData?.customer}
                            orderDetails={orderData?.order_details}
                            store={storeData}
                            idOrder={id}
                        />}
                        <OrderDetailsTable orderDetails={orderData?.order_details} order={orderData} editMode={editMode} generalData={generalData} setGeneralData={setGeneralData} submitProductsTable={submitProductsTable} />
                    </Grid>
                    <Grid item xs={12} lg={5} style={{ marginBottom: isTabletOrMobile ? 100 : 0 }}>
                        <OrderClientInfo client={orderData?.customer} editMode={editMode} setClientData={setClientData} />
                        <OrderShippingInfo
                            client={orderData?.customer}
                            shipping={orderData?.shipping_information}
                            shippingStatus={orderData?.shipping_order_status_id}
                            comments={orderData?.comments}
                            storeId={storeId}
                            orderId={id}
                            editMode={editMode}
                            generalData={generalData}
                            setGeneralData={setGeneralData}
                            setShippingData={setShippingData}
                        />
                        <OrderComments comments={orderData?.comments} />
                        { formInfo.length > 0 &&
                            <Card>
                                <CardHeader
                                    titleTypographyProps={{ variant: 'h6' }}
                                    title='Respuestas del formulario personalizado'
                                    style={{ padding: '20px 16px 13px 16px' }}
                                />
                                <CardContent style={{ padding: '0 16px 25px 16px' }}>
                                    { formInfo.map( item => (
                                        <div key={item.id} style={{ display: 'flex', gap: 10}}>
                                            <Typography variant="body1" style={{ fontWeight: 600}}>{item.title}:</Typography>
                                            <Typography variant="body1">{item.type === 'range' ? item.response.replace(/[{()}"]/g, '') : item.response}</Typography>
                                        </div>
                                    ) ) }
                                </CardContent>
                            </Card>
                        }
                    </Grid>
                </>
            }
        </Grid >
    );
}

const mapStateToProps = ({ auth, orders, common }) => ({
    storeId: auth.storeId,
    orderToEdit: orders.orderToEdit,
    storeData: common.storeData
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setOrderToEdit
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderInfoPage);