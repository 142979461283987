import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useMediaQuery } from 'react-responsive';


import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardHeader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';


import { ContentUpload } from '../../ManageImportProducts/ManageImportProducts.styles';


import UploadImage from '../../../assets/icons/uploadnube.svg';
import BtnUpload from '../../../assets/icons/btnupload.svg';


const DataSheetProduct = ({ fileUpload, handleChangeDataSheet, deleteTagFile }) => {
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: '.pdf',
        onDrop: handleChangeDataSheet
    });


    return (
        <Card elevation={0} style={{ marginBottom: isTabletOrMobile ? 100 : 21 }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('Product.dataSheetProduct.title')} subheader={
                <Typography variant="body1">
                    {t('Product.dataSheetProduct.description')}
                </Typography>
            } />
            <CardContent>
            {!fileUpload 
                ?<ContentUpload {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <>
                            <img src={UploadImage} ></img>
                            <img src={BtnUpload} />
                        </>
                    ) : (
                            <>
                                <img src={UploadImage}></img>
                                <img src={BtnUpload} />
                                <Typography variant="p">
                                    {t('Product.dragTitle')}
                                </Typography>
                            </>
                        )}

                </ContentUpload>
                :<Typography variant="h6" color='primary' style={{ width: 'fit-content', margin: '0 auto 5px' }}>{t('Product.dataSheetProduct.success')}</Typography>
            }
                {fileUpload &&
                    <li style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        padding: 5,
                        margin: 0 
                    }}>
                        <Chip
                            label={fileUpload.name || fileUpload.title}
                            onDelete={deleteTagFile}
                        />
                    </li>
                }
            </CardContent>
        </Card>
    );
};



export default connect()(DataSheetProduct);