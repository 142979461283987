import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

import { makeStyles } from '@material-ui/core/styles';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import CollectionPlaceholder from '../../assets/img/10x-category.jpg';
import NewIcon from '../../assets/icons/plus.svg';

import { routes } from '../../constants.js/misc';
import { getCollectionsService } from '../../services/collections';
import { setActiveCollection } from '../../modules/collections';

// edit-collection.svg

const CollectionStyles = makeStyles({
    image: {
        width: 80,
        height: 80,
        objectFit: 'cover',
        borderRadius: '50%'
    },
    plus: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid #A6AEBC',
        borderRadius: '50%',
        width: 80,
        height: 80,
        position: 'relative'
    },
    editBtn: {
        width: 32,
        height: 32,
        position: 'absolute',
        top: -5,
        right: -5,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    name: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        width: '100%',
        paddingTop: '.25rem'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: 80,
        marginRight: '1rem'
    }
});

const WrapperStyles = makeStyles({
    container: {
        display: 'flex',
        overflow: 'scroll',
        overflowY: 'hidden',
        maxWidth: '100vw',
        padding: 15,
        position: 'relative'
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: 104
    },
    icon: {
        position: 'absolute',
        right: 10,
        top: 46,
        background: 'white !important',
        height: 20,
        width: 20
    }
});

const Collection = ({ collection, createNew, active, setActive }) => {
    const classes = CollectionStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            {createNew ?
                <RouterLink
                    to={routes.createCollection}
                    className={classes.plus}>
                    <img
                        alt={t('MobileCategories.addNew')}
                        src={NewIcon} />
                </RouterLink> :
                <div className={classes.plus} onClick={() => setActive(collection.id)}>

                    {active &&
                        <RouterLink
                            className={classes.editBtn}
                            to={`${routes.updateCollection}/${collection.id}`}>
                        </RouterLink>
                    }

                    <img
                        style={{ border: `2px solid ${active ? '#48AC98' : '#F0F0F3'}` }}
                        className={classes.image}
                        alt={collection.name}
                        src={collection.picture_url ? process.env.REACT_APP_IMAGES_API_URL + collection.picture_url : CollectionPlaceholder} />
                </div>
            }
            <span className={classes.name}>{createNew ? t('MobileCategories.addNew') : collection.name}</span>
        </div>
    )
}

const MobileCategories = ({ storeId, setActiveCollection, activeCollection }) => {
    const classes = WrapperStyles();
    const [loading, setLoading] = useState(true);
    const [collections, setCollections] = useState([]);
    const [hideArrow, setHideArrow] = useState(false);

    const collectionListRef = useRef(null);

    const handleScroll = ({ target }) => setHideArrow(target.scrollLeft > 128);
    const scrollListToRight = () => {
        collectionListRef.current.scrollTo({
            left: window.innerWidth - 32,
            behavior: 'smooth',
        });
    }

    useEffect(() => {
        setLoading(true);
        getCollectionsService(storeId).then(({ data }) => {
            setLoading(false);
            setCollections(data.sort((a, b) => Number(b.default) - Number(a.default)));
            const defaultCollection = data.find(collection => collection.default);
            if (defaultCollection) setActiveCollection(defaultCollection.id);
        })
            .catch(err => setLoading(false));
    }, [storeId]);

    useEffect(() => {
        return () => setActiveCollection(false)
    }, [])

    return (
        <div style={{ position: 'relative' }}>
            <div
            className={classes.container}
            ref={collectionListRef}
            onScroll={handleScroll}>
                {loading &&
                    <div className={classes.loader}>
                        <CircularProgress />
                    </div>
                }
                {!loading && <Collection createNew={true} collection={{}} />}
                {!loading && collections.map(collection =>
                    <Collection
                        collection={collection}
                        active={activeCollection === collection.id}
                        setActive={setActiveCollection} />
                )}
            </div>
            {(!loading && !hideArrow) &&
                <IconButton
                    className={classes.icon}
                    onClick={scrollListToRight}
                    color="default"
                    aria-label="Mostrar más categorías">
                    <ChevronRight fontSize="small" />
                </IconButton>
            }
        </div>
    );
}

const mapStateToProps = ({ auth, common, collections }) => ({
    storeId: auth.storeId,
    storeData: common.storeData,
    activeCollection: collections.activeCollection
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setActiveCollection
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileCategories);