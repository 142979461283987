import styled from '@emotion/styled';

export const ContentUpload = styled.div`
    background: #F8F9FB;
    outline: 0;
    height: ${props => props.height || 'auto'};
    border: 2px dashed #98A1B1;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 10px auto;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`;

export const TextUpload = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #53627C;
`;



export const PreviewImg = styled.div`
  width: 100%;
  height: 210px;
`;

export const SelectedImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover + div {
    display: flex;
  }
`;



export const RemoveImg = styled.div`
  position: absolute;
  width: 100%;
  height: 180px;
  background: rgba(0, 0, 0, 0.5);

  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  border-radius: 5px;
  display: none;
  transition: all 0.3s ease;
  :hover {
    display: flex;
  }
`;

export const RemoveImgWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
