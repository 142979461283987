import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import arrayMove from 'array-move';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import useInput from '../../hook/useInput';

import InfoCollection from './InfoCollection/InfoCollection';
import ProductosCollection from './ProductsCollection/ProductosCollection';
import ImageCollection from './ImageCollection/ImageCollection';
import SubCollection from './SubCollection/SubCollection';
import CollectionAvailability from './CollectionAvailability/CollectionAvailability';
import DeleteDialog from '../DeleteConfirmDialog/DeleteConfirmDialog';

import { Container, ButtonContainer, Intro } from './ManageCollection.styles';
import CollectionIcon from "../../assets/icons/collections.svg";
import { routes, errorToast } from '../../constants.js/misc';
//MIXPANEL
import { Mixpanel } from '../../constants.js/mixpanel';

/*SERVICES */
import {
    createCollectionsService, updateCollectionsService,
    collectionsxIdService, updateCollectionsServiceImage,
    deleteCollectionsServiceImage, deleteCollectionsService,
    createSubCollection, deleteSubCollection, orderProductsCollections
} from '../../services/collections';

import { getProductsService } from '../../services/products';

const ManageCollection = ({ storeId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    let { id } = useParams();

    const [chipDataSubCollection, setChipDataSubCollection] = useState([]);
    const [loadingCollection, setLoadingCollection] = useState(false);
    const [dataCollection, setDataCollection] = useState({});
    const [listDataProduct, setListDataProduct] = useState([]);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [cancelOptions, setCancelOptions] = useState(false);
    const [checkProductExists, setCheckProductExists] = useState(false);
    const [colleImg, setColleImg] = useState({});
    const [selectedImg, setSelectedImg] = useState([]);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const nameSubCollection = useInput('');
    const isEmptyFiles = obj => Object.keys(obj).length === 0;

    useEffect(() => {
        if (id) {
            setLoadingCollection(true);
            collectionsxIdService(storeId, id)
                .then(({ data }) => {
                    setLoadingCollection(false);
                    setDataCollection(data);
                    if (data.picture_url) {
                        setColleImg({
                            preview: process.env.REACT_APP_IMAGES_API_URL + data.picture_url,
                            id: data.id
                        });
                    }
                    setChipDataSubCollection(data.sub_collections ? data.sub_collections : []);
                    setListDataProduct(data.products ? data.products : []);
                })
                .catch(err => {
                    setLoadingCollection(false);
                    enqueueSnackbar(t('Product.dataError'), {
                        variant: 'error',
                        autoHideDuration: 2500
                    });
                });
        }

    }, [id]);
    
    useEffect(() => {
        if (!checkProductExists) {
            getProductsService(storeId, 10, 0)
                .then(res => {
                    setCheckProductExists(res.data.length > 0 ? true : false);
                })
                .catch(err => {
                    enqueueSnackbar(t('ProductsTable.error'), {
                        variant: 'error',
                        autoHideDuration: 2500
                    })
                });
        }
    }, [storeId, checkProductExists]);

    const {
        control,
        formState,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm({
        mode: 'all'
    });

    const isValid = Object.keys(errors)?.length === 0;

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setListDataProduct(arrayMove(listDataProduct, oldIndex, newIndex));
        orderProductsCollections(storeId, id, arrayMove(listDataProduct, oldIndex, newIndex).map(x => x.id))
            .then(({ data }) => { })
            .catch(err => {
                console.log('Error collectionPage', err);
            });
    };

    /**FUNCION PARA CONFIRMAR EL ELMINAR */
    const removeConfirmFunction = (value) => {
        setCancelOptions(value);
        setCancelDialogOpen(true);
    }

    /**FUNCION PARA ELMINAR UNA COLECCION */
    const removeCollection = () => {

        deleteCollectionsService(storeId, id, dataCollection)
            .then(({ data }) => {
                setCancelDialogOpen(false);
                history.push(routes.collectionsPage);
                enqueueSnackbar(`Colección eliminada exitosamente`, {
                    variant: 'success',
                    autoHideDuration: 2500
                });
            }).catch(err => {
                setCancelDialogOpen(false);
                enqueueSnackbar(t('Collection.deleteError'), errorToast);
            });
    }
    
    /**FUNCION PARA ELMINAR LA IMAGEN O ELIMINAR UNA COLECCION */
    const removeFunction = () => {
        if (cancelOptions.image) {
            deleteCollectionsServiceImage(storeId, id, dataCollection)
                .then(({ data }) => {
                    setCancelDialogOpen(false);
                    enqueueSnackbar(`Imagen de colección eliminada exitosamente`, {
                        variant: 'success',
                        autoHideDuration: 2500
                    });
                });
        } else {
            removeCollection();
        }
    }

    const handleDelete = (chipToDelete) => () => {
        setChipDataSubCollection((chips) => chips.filter((chip) => chip !== chipToDelete));
        deleteSubCollection(storeId, id, chipToDelete)
            .then(({ data }) => {
                enqueueSnackbar(`SubColección eliminada exitosamente`, {
                    variant: 'success',
                    autoHideDuration: 2500
                });
            }).catch(err => {
                enqueueSnackbar(t('Collection.deleteError'), errorToast);
            });
    };

    const addHandlerSubCollection = () => {
        if (nameSubCollection.value) {
            createSubCollection(storeId, id, nameSubCollection.value)
                .then(({ data }) => {
                    nameSubCollection.clearValue();
                    setChipDataSubCollection([...chipDataSubCollection, nameSubCollection.value])
                    enqueueSnackbar(`Categoría creada exitosamente`, {
                        variant: 'success',
                        autoHideDuration: 2500
                    });
                }).catch(err => {
                    enqueueSnackbar(t('Collection.deleteError'), errorToast);
                });
        } else {
            enqueueSnackbar('Por favor añadir un nombre de SubCategoría', errorToast);
        }
    }

    /**FUNCION PARA EDITAR O CREAR UNA COLECCION */
    const CallSubmit = (data) => {
        const functionToCall = id ? updateCollectionsService : createCollectionsService;
        setLoadingCollection(true);
        functionToCall(storeId, {
            name: data.name,
            id: id ? id : null,
            description: data.description,
            public: data.active ? 1 : 0,
            sub_collections: chipDataSubCollection.map(x => x.id),
            products: listDataProduct.map(x => x.id)
        }).then(({ data }) => {
            if (!id) {
                Mixpanel.track('Agregar categoria', {
                    "Nombre": data.name,
                    "descripcion": data.description,
                    "imagen": selectedImg.length
                });
            }
            if (!isEmptyFiles(selectedImg)) {
                const imgData = new FormData();
                imgData.append('files', selectedImg[0]);
                updateCollectionsServiceImage(storeId, data.id, imgData)
                    .then(res => {
                        setLoadingCollection(false);
                        enqueueSnackbar(`Categoría ${id ? 'actualizada' : 'creada'} exitosamente`, {
                            variant: 'success',
                            autoHideDuration: 2500
                        });
                        history.push(routes.collectionsPage);
                    })
                    .catch(err => { });
            } else {
                setLoadingCollection(false);
                enqueueSnackbar(`Categoría ${id ? 'actualizada' : 'creada'} exitosamente`, {
                    variant: 'success',
                    autoHideDuration: 2500
                });
                isTabletOrMobile ? history.push(routes.mobileManageCategories) : history.push(routes.collectionsPage);
            }
        }).catch(err => console.log('error', err));
    }

    return (
        <>
            <form onSubmit={handleSubmit(CallSubmit)} style={{ paddingBottom: isTabletOrMobile ? 100 : 0 }}>
                <Container container spacing={isTabletOrMobile ? 0 : 3}>
                    {isTabletOrMobile &&
                        <Intro item container xs={12}>
                            <img src={CollectionIcon} alt="" />
                            <Typography variant="caption">
                                {t('CollectionsTitle')}
                            </Typography>
                        </Intro>
                    }
                    <Grid item container xs={12} style={{ padding: isTabletOrMobile ? 20 : '0 0 20px' }}>
                        <Grid item xs={6}>
                            <Typography
                                variant={isTabletOrMobile ? 'h5' : 'h4'}
                                style={{ marginBottom: '10px' }}
                            >
                                {id ? t('Collection.updateBtn') : t('Collection.createCollection')}
                            </Typography>
                            <Link
                                component={RouterLink}
                                to={isTabletOrMobile ? routes.mobileManageCategories : routes.collectionsPage}
                            >
                                {isTabletOrMobile ? t('ManageCollection.goHome') : t('Collection.title')}
                            </Link>
                        </Grid>

                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                                {(id && !dataCollection.default) &&
                                    <Button
                                        style={{ color: '#CB7C7A' }}
                                        click={removeCollection}
                                        onClick={() => removeConfirmFunction({ image: false })}
                                        size="small"
                                        color="inherit">
                                        {t('Collection.deleteTitle')}
                                    </Button>
                                }
                                {(!id && !isTabletOrMobile) &&
                                    <Button
                                        size="small"
                                        component={RouterLink}
                                        to={routes.collectionsPage}>
                                        {t('Product.cancelButton')}
                                    </Button>
                                }

                                {!isTabletOrMobile &&
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{ marginLeft: '16px' }}
                                        disabled={(id ? !isValid : !formState.isValid) || loadingCollection}
                                        type="submit">
                                        {id ? t('Collection.saveChanges') : t('Collection.createCollection')}
                                    </Button>
                                }
                            </div>
                        </Grid>

                    </Grid>


                    {loadingCollection && <Grid item xs={7}><LinearProgress /></Grid>}
                    <Grid container spacing={isTabletOrMobile ? 0 : 3}>
                        <Grid item xs={12} lg={8}>
                            <InfoCollection
                                errors={errors}
                                dataCollection={dataCollection}
                                control={control}
                                setValue={setValue}
                            >
                            </InfoCollection>
                            {checkProductExists && <ProductosCollection
                                onSortEnd={onSortEnd}
                                collectionData={dataCollection}
                                listDataProduct={listDataProduct}
                                setListDataProduct={setListDataProduct}
                            ></ProductosCollection>
                            }
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <ImageCollection
                                removeImgFunction={() => removeConfirmFunction({ image: true })}
                                setColleImg={setColleImg}
                                setSelectedImg={setSelectedImg}
                                colleImg={colleImg}>
                            </ImageCollection>
                            <CollectionAvailability
                                collectionData={dataCollection}
                                control={control}
                                setValue={setValue}
                                watch={watch}></CollectionAvailability>
                            {/**  {id && <SubCollection
                                handleDelete={handleDelete}
                                nameSubCollection={nameSubCollection}
                                addHandlerSubCollection={addHandlerSubCollection}
                                chipDataSubCollection={chipDataSubCollection}>
                            </SubCollection>
                            }*/}


                        </Grid>

                        {isTabletOrMobile &&
                            <ButtonContainer
                                item
                                xs={12}
                                lg={4}>
                                <Button
                                    style={{ width: '100%' }}
                                    color="primary"
                                    variant="contained"
                                    disabled={(id ? !isValid : !formState.isValid) || loadingCollection}
                                    type="submit">
                                    {id ? t('Collection.saveChanges') : t('Collection.createCollection')}
                                </Button>
                            </ButtonContainer>
                        }
                    </Grid>
                </Container>

                {id &&
                    <DeleteDialog
                        open={cancelDialogOpen}
                        onConfirm={removeFunction}
                        onClose={() => setCancelDialogOpen(false)}
                        title={cancelOptions.image ? t('Collection.deleteImageTitle') : t('Collection.deleteTitle')}
                        message={cancelOptions.image ? t('Product.deleteImageMessage') : t('Collection.deleteMessage')}
                        cancelText={t('Product.deleteCancel')}
                        confirmText={t('Product.deleteConfirm')}
                    />
                }

            </form>

        </>
    );
};

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});


export default connect(
    mapStateToProps
)(ManageCollection);