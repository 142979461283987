import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { CardHeader } from '@material-ui/core';

import AutoCompleteSubCollection from '../AutoCompleteSubCollection/AutoCompleteSubCollection';

const SubCollection = ({ chipDataSubCollection, addHandlerSubCollection, handleDelete, nameSubCollection }) => {
    const { t } = useTranslation();

    return (
        <Card style={{ marginTop: '15px' }} elevation={0}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('SubCollection.title')} />

            <CardContent>

                <Grid container alignItems="flex-end">

                    <Grid item xs={8}>
                        {/**
                    * 
                    * <AutoCompleteSubCollection setEventAdd={setEventAdd}></AutoCompleteSubCollection>
                    */}

                        <TextField
                            fullWidth
                            variant="outlined"
                            value={nameSubCollection.value}
                            onChange={nameSubCollection.onChange}
                            InputLabelProps={{ shrink: true }}
                            name="subcollection"
                        />


                    </Grid>

                    <Grid item xs={4}>
                        <Button
                            style={{ width: '100%' }}
                            onClick={addHandlerSubCollection}
                            color="inherit"
                            type="button"
                            variant="contained">
                            {t('btn.addNewTitle')}
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <ul style={{
                            padding: 0,
                            marginTop: 20
                        }} >
                            {chipDataSubCollection.map((data, index) => {
                                return (
                                    <li key={index} style={{
                                        listStyle: 'none',
                                        display: 'inline'
                                    }}>
                                        <Chip
                                            label={data}
                                            onDelete={handleDelete(data)}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </Grid>


                </Grid>

            </CardContent>
        </Card >
    );
};
export default connect()(SubCollection);