// ReactJS
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

// Components
import { Intro } from '../../components/ManageCollection/ManageCollection.styles';

// Icons
import ConfigIcon from "../../assets/icons/config.svg";

// MUI Components
import { Grid, Typography } from "@material-ui/core";

// Components: DynamicForm
import NavLink from "./NavLink";
import FormContent from "./FormContent";
import InfoGrid from "../InfoGrid";

// Constants
const MESSAGE_FORM = "Agrega campos adicionales al formulario de “Ordenar por WhatsApp”. Verás esta información en los pedidos de tus clientes en WhatsApp y en el detalle de los pedidos."

const DynamicForm = ({ data }) => {
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <Grid container spacing={isTabletOrMobile ? 0 : 3} style={{ justifyContent: 'center' }}>
            {isTabletOrMobile &&
                <Intro item container xs={12}>
                    <img src={ConfigIcon} alt="" />
                    <Typography variant="caption">
                        {t('Config.formRoute')}
                    </Typography>
                </Intro>
            }
            <NavLink isTabletOrMobile={isTabletOrMobile} />
            <InfoGrid message={MESSAGE_FORM} />
            <FormContent data={data} />
        </Grid>
    );
}

export default DynamicForm;