import styled from "@emotion/styled"

export const SingUpPageContainer = styled.main `
    padding: 0 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0eff2;    
`
export const Container = styled.div `
    max-width: 1200px;
`
export const Header = styled.header`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`
export const Logo = styled.img`
    height: 40px;
    width: 40px;
    margin: 10px 0 0;
`
export const MainContainer = styled.section `
    display: flex;
    justify-content: space-between;
    @media(max-width: 1000px) {
        flex-direction: column;
        align-items: center;
    }
`
export const SectionLeftContainer = styled.aside `
    width: 32%;
    display: flex;
    flex-direction: column;
    @media(max-width: 1200px) {
        display: none;
    }
    
`
export const TitleContainer = styled.article `
    @media(max-width: 800px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`
export const Title = styled.h1 `
    font-size: 30px;
    font-weight: 500;
    font-family: 'DM Sans';
    line-height: 40px;
    margin: 30px 0 10px;
    color: #266678;
    @media(max-width: 999px) {
        font-size: 33px;
    }
`
export const SubTitle = styled.p `
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    @media(max-width: 999px) {
        font-size: 19px;
    }
`
export const ImageContainer = styled.article `
    width: 460px;
    height: 400px;
    @media(max-width: 800px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media(max-width: 799px) {
        width: 300px;
        height: 300px;
        margin: 0 auto;
    }
`
export const SectionRightContainer = styled.aside `
    width: 58%;
    display: flex;
    flex-direction: column;
    @media(max-width: 1200px) {
        display: none;
    }
    
`
export const CardsContainer = styled.div `
    display: flex;
    justify-content: space-between;
    @media(max-width: 1200px) {
        flex-direction: column;
        gap: 25px;
    }
    `
export const CardsTitle = styled.h1 `
    margin: 0 0 15px 0;
    font-size: 20px;
    color: #266678;
    font-weight: 600;
    @media(max-width: 799px) {
        font-size: 19px;
        
    }
`
export const Cards = styled.div `
        display: flex;
        padding: 0 20px 10px;
        position: relative;
        width: 48%;
        flex-direction: column;
        border: 2px solid #62ba9f;
        border-radius: 10px;
        cursor: pointer;
        @media(max-width: 1200px) {
            width: 100%;
        }
        @media(max-width: 800px) {
            width: 100%;
        }
    `
export const Membership = styled.span `
    background-color: #266678;
    padding: 3px 0;
    position: absolute;
    top: -10px;
    left: 45%;
    border-radius: 12px;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    width: 150px;
    text-align: center;
    @media(max-width: 1200px) {
        left: 33%;
    }
    @media(max-width: 800px) {
        left: 40%;
    }
`
export const PlanInfo = styled.div `
    display: flex;
    align-items: center;
    color: #266678;
    font-size: 14px;
    font-weight: 600;
    p {
        text-transform: uppercase;
    }
    @media(max-width: 799px) {
        font-size: 19px;
    }
`
export const PriceContainer = styled.span`
    display: flex;
    align-items: center;
    gap: 10px;
    p {
        font-weight: 600;
        font-size: 15px;
        margin: 0;
    }
    del {
        font-size: 16px;
        line-height: 23px;
        @media(max-width: 1200px) {
            font-size: 14px;
        }
    }
    @media(max-width: 1200px) {
        gap: 8px;
    }
`
export const Price = styled.h1`
    margin: 0;
    font-size: 27px;
    @media(max-width: 1200px) {
        font-size: 25px;
    }
`
export const FormContainer = styled.aside `
    width: 100%;
    border: 1px solid #dce3e8;
    padding: 20px;
    border-radius: 8px;
    margin: 0 auto;
    background-color: #fff;
    @media(max-width: 1200px) {
        width: 70%;
    }
    @media(max-width: 800px) {
        width: 100%;
    }
`
export const PlansInfoContainer = styled.article `
    padding: 10px 0;
    margin: 0;
    color: #266678;
    cursor: pointer;
    hr {
        border: 1px solid #bdbdbd8a;
      }
`
export const IncludesTitleContainer = styled.div `
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0 0;
`
export const IncludesTitle = styled.h3 `
    margin: 0;
    color: #266678;
    @media(max-width: 799px) {
        font-size: 19px;
        
    }
`
export const ListIncludes = styled.ul `
    list-style-type: none;
`
export const ListIncludesItem = styled.li `
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    p {
        color: #000;
        @media(max-width: 799px) {
            font-size: 19px;
        }
    }
`
export const ListFeatures = styled.article `
    padding: 20px;
    @media(max-width: 799px) {
        padding: 70px 0 20px;
    }
`


export const SectionLeftMobile = styled.aside `
    display: none;
    @media(max-width: 1200px) {
        width: 100%;
        margin: 0 0 50px;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    
`