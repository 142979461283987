// ReactJS
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

// MUI Components
import { Button, Card, Divider, Grid, makeStyles, Typography } from "@material-ui/core";

// Constants
//import { paymentsMethods } from "../../../constants.js/paymentsMethods";
import { routes } from "../../../constants.js/misc";

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '1rem'
    },
});

const ListOfPayment = ({ paymentList }) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Grid container xs={12} md={10} xl={8} className={classes.header}>
            <Card elevation={1} style={{ width: '100%', marginBottom: '80px' }}>
                {paymentList.filter(m => m?.default === false).map(method => (
                    <Fragment key={method.id}>
                        <Card
                            elevation={0}
                            style={{
                                margin: 16,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <img src={method.icon} alt="iconPayment" />
                            <Typography
                                variant="body1"
                                style={{ color: '#000000', fontSize: 16, width: '75%', margin: 'auto 16px' }}
                            >
                                {method.description}
                            </Typography>
                            <Button
                                className="btnLink"
                                color="primary"
                                variant="contained"
                                onClick={() => history.push(`${routes.addPaymentProviders}`, {
                                    methodId: method.id,
                                    methodName: method.name,
                                    methodLabelAccountId: method.client_id_label,
                                    methodLabelApikey: method.api_key_secret_label,
                                })}
                            >
                                Conectar
                            </Button>
                        </Card>
                        <Divider />
                    </Fragment>
                ))}
            </Card>
        </Grid >
    );
}

export default ListOfPayment;