// react dependencies
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// material ui dependencies
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, TableHead, Box, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownload from '@material-ui/icons/CloudDownload';
import BillingDetailsModal from '../BillingPage/BillingDetailsModal/BillingDetailsModal';

//MIXPANEL
import { Mixpanel } from '../../constants.js/mixpanel';
// Assets
import Check from '../../assets/icons/check.png';
import logo from '../../assets/img/10x_logo.svg';

/*SERVICES */
import {
    getDetailInvoice, createPreferenceService
} from '../../services/billing';
import { routes, errorToast, successToast, formatNumber, formatDate } from '../../constants.js/misc';

// styles
const useStyles = makeStyles({
    info: {
        background: '#EFF3EF',
        padding: '25px',
        display: 'flex',
        alignItems: 'center'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    bill: {
        border: '1px solid #C9CED6',
        padding: '50px',
        marginTop: '15px'
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '30px',
        marginTop: '200px',
        borderTop: '1px solid #C9CED6'
    }
});

const Bill = ({ storeId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    let { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [exitsDataBill, setIsExitsDataBill] = useState(false);

    const [loadingBill, setLoadingBill] = useState(false);
    const [dataBill, setDataBill] = useState({});
    const [creatingPreference, setCreatingPreference] = useState(false);
    const [initPoint, setInitPoint] = useState('');



    useEffect(() => {
        if (id || exitsDataBill) {
            setLoadingBill(true);
            getDetailInvoice(storeId, id)
                .then(({ data }) => {
                    if (Object.keys(data.billing_information).length === 0 || data.billing_information == null) {
                        setDialogOpen(true)
                        return;
                    }
                    setLoadingBill(false);
                    setDataBill(data);
                    setCreatingPreference(false);
                    Mixpanel.track('Visualizar fact', {
                        "Visualizar fact": data.id
                    });

                    // Create mercadopago preference
                    createPreferenceService({
                        notification_url: process.env.REACT_APP_API_URL + '/payments/webhooks/mercadopago',
                        external_reference: btoa(JSON.stringify({
                            store_id: storeId,
                            invoice_id: id,
                            amount: data.amount,
                            type: 'INVOICE'
                        })),
                        items: [
                            {
                                id: id,
                                title: 'Factura 10X',
                                unit_price: data.amount,
                                quantity: 1,
                                currency_id: 'COP',
                            }
                        ],
                        marketplace: '10X',
                        additional_info: "Factura 10X",
                        back_urls: {
                            success: window.location.origin + `${routes.payBill}/${id}`,
                            failure: window.location.origin + `${routes.payBill}/${id}`,
                            pending: window.location.origin + `${routes.payBill}/${id}`
                        },
                        auto_return: "all",
                    })
                        .then(res => setInitPoint(res.data.init_point))
                        .catch(err => {
                            setCreatingPreference(false);
                            enqueueSnackbar('Imposible crear la preferencia', errorToast)
                        });
                })
                .catch(err => {
                });
        }
    }, [id, exitsDataBill]);


    const handleDialogClose = () => setDialogOpen(false);
    const handleDataUpdate = () => {
        handleDialogClose();
        enqueueSnackbar(t('BilligDetailsModal.success'), successToast);
        setIsExitsDataBill(true);
    }

    return (
        <>
            <Card container spacing={3} style={{ width: '100%' }}>
                <CardHeader
                    titleTypographyProps={{ variant: 'h6' }}
                    title={t('Bill.title')} />
                <CardContent>
                    {!loadingBill && Object.keys(dataBill).length > 0 &&
                        <>
                            <Box className={classes.info}>
                                <img src={Check} alt="" style={{ marginRight: '5px' }} />
                                {t('Bill.info')}: {dataBill.billing_information.emails}
                            </Box>
                            <br />
                            <Grid container className={classes.buttons}>
                                {/* <Button style={{ marginRight: '5px' }} startIcon={<CloudDownload />}>{t('Bill.downloadPdf')}</Button> */}
                                {(dataBill.invoice_status_id === 1 || dataBill.invoice_status_id === 3) &&
                                    <Button href={initPoint} disabled={creatingPreference} variant="contained" color="primary">{t('Bill.pay')}</Button>
                                }
                            </Grid>
                            <Grid container className={classes.bill}>
                                <Grid item xs={6}>
                                    <img src={logo} alt="10x" style={{ width: '80px' }} />
                                </Grid>

                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <Typography variant="h6">Factura de venta #{dataBill.number}</Typography>
                                    <Typography variant="body2">{formatDate(dataBill.created_at)}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <br />
                                    <Typography variant="body2"><b>Cobrar A:</b></Typography>
                                    <Typography variant="body2">{dataBill.billing_information.contact_name}{dataBill.billing_information.contact_last_name}</Typography>
                                    <Typography variant="body2"> {dataBill.billing_information.emails.split(',')[0]}</Typography>
                                    <Typography variant="body2">{dataBill.billing_information.document_type.name + ':' || ''} {dataBill.billing_information.dni} </Typography>
                                    <Typography variant="body2">{dataBill.billing_information.legal_name}</Typography>
                                    <Typography variant="body2">{dataBill.billing_information.address}</Typography>
                                    <Typography variant="body2">{dataBill.billing_information.city.name} {dataBill.billing_information.city.state}</Typography>
                                    <Typography variant="body2">{dataBill.billing_information.country.name}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <br />
                                    <Typography variant="body2"><b>Periodo de facturación {formatDate(dataBill.due_date)} - {formatDate(dataBill.due_date)}</b></Typography>
                                    <Typography variant="body2">{formatDate(dataBill.due_date)} - {formatDate(dataBill.due_date)}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '15px' }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Descripcion</TableCell>
                                                    <TableCell>Cantidad</TableCell>
                                                    <TableCell>Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {dataBill.invoice_details.map(item =>
                                                    <TableRow>
                                                        <TableCell>{item.description}</TableCell>
                                                        <TableCell><b>{item.quantity}</b></TableCell>
                                                        <TableCell><b>{formatNumber(item.price)}</b></TableCell>
                                                    </TableRow>
                                                )}

                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell><b>Subtotal</b></TableCell>
                                                    <TableCell><b>{formatNumber(dataBill.amount - dataBill.discount)}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell><b>Total a pagar</b></TableCell>
                                                    <TableCell><b>{formatNumber(dataBill.amount)}</b></TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>


                                <Grid item xs={12} className={classes.footer}>
                                    <Typography><b>¿Tienes preguntas?</b> Contáctanos a soporte@get10x.co o llámanos al +57 3000000000</Typography>
                                    <img src={logo} alt="10x" style={{ width: '50px' }} />
                                </Grid>
                            </Grid>
                        </>
                    }
                </CardContent>
            </Card>

            <BillingDetailsModal
                billingData={{}}
                open={dialogOpen}
                handleClose={() => setDialogOpen(false)}
                handleSuccess={handleDataUpdate} />

        </>
    );
};

const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(Bill);
