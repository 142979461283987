export const SET_USER_STORE_TO_EDIT = 'user-stores/SET_USER_STORE_TO_EDIT';
export const SET_ROWS_PER_PAGE = 'user-stores/SET_ROWS_PER_PAGE';
export const SET_PAGE = 'user-stores/SET_PAGE';

const initialState = {
  userStoreToEdit: null,
  rowsPerPage: 10,
  page: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_STORE_TO_EDIT:
      return {
        ...state,
        userStoreToEdit: action.payload,
      }
    case SET_ROWS_PER_PAGE:
        return {
            ...state,
            rowsPerPage: action.payload,
        }
    case SET_PAGE:
        return {
            ...state,
            page: action.payload,
        }

    default:
      return state
  }
}

const setData = (actionName, payload) => {
    console.log(actionName, payload);
    return dispatch => {
        dispatch({ 
            type: actionName, 
            payload: payload
        });
    }
}

export const setUserStoreToEdit = (userStore) => setData(SET_USER_STORE_TO_EDIT, userStore);
export const setRowsPerPageState = (rowsPerPage) => setData(SET_ROWS_PER_PAGE, rowsPerPage);
export const setPageState = (page) => setData(SET_PAGE, page);


