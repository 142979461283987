import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button, Dialog } from "@material-ui/core";
import { CloseContainer, Links, MainContainer } from "./styles.popup";

import bell from "../../assets/img/bell.png";
import { IconClose } from "../../assets/icons/close-icon";

import { routes } from "../../constants.js/misc";

const NotificationPopup = ({ openNotification, onClose }) => {
  const history = useHistory();
  const goTo = () => {
    onClose();
    history.push(`${routes.ordersPage}`);
  };
  const PayPalUrl =
    "https://www.loom.com/share/8ad57f280d46492e84f61ddd5bf9aabc?sid=62d4381a-c6f0-42bd-899c-17611c9e8e34";
  const wompiUrl =
    "https://www.loom.com/share/2c12c11e852c4805b37291dd970077c8?sid=2dbed171-4eea-418b-95af-3a3ca87b8ae2";
  const goPayPal = () => window.open(PayPalUrl);
  const goWompi = () => window.open(wompiUrl);

  useEffect(() => {
    localStorage.setItem("showPopupLinks", "true");
    localStorage.removeItem("showPopupOrders");
    localStorage.removeItem("showPopupExcuse");
  }, []);
  return (
    <Dialog open={openNotification}>
      <MainContainer>
        <CloseContainer onClick={onClose}>
          <IconClose />
        </CloseContainer>
        <img src={bell} alt="notification image" />
        <h1>¡Hola comunidad!</h1>
        <p>
          Queremos asegurarnos de que todos tengan la información necesaria para
          sacar el máximo provecho de nuestra plataforma. Por eso, hemos creado
          una serie de tutoriales sobre cómo conectar tu tienda a diferentes
          pasarelas de pago. Estos tutoriales detallados te guiarán paso a paso
          a través del proceso, desde la configuración hasta la implementación.
        </p>

        <div style={{display: "flex", flexDirection: "column", width: "100%", gap: "1rem"}}>
          <p>Aquí tienes los enlaces a los videos:</p>

          <p>
            <a href="http://surl.li/shqsg">PAYU</a>
            <br />
            <a href="http://surl.li/shqsj">Mercado Pago</a>
            <br />
            <a href="http://surl.li/shqsm">WOMPI</a>
            <br />
            <a href="http://surl.li/shqsp">PayPal</a>
            <br />
          </p>
        </div>
        <p>
          Recuerda que también puedes encontrar el paso a paso detallado en la
          descripción de cada video. ¡No dudes en ponerte en contacto si tienes
          alguna pregunta o necesitas ayuda adicional!
        </p>

        <p>
          ¡Gracias por ser parte de nuestra comunidad y por confiar en nosotros
          para hacer crecer tu negocio!
        </p>

        {/* <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={goTo}
                >
                    Ir a Pedidos
                </Button> */}
        <Button fullWidth variant="contained" color="primary" onClick={onClose}>
          Cerrar
        </Button>
      </MainContainer>
    </Dialog>
  );
};

export default NotificationPopup;
