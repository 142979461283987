// ReactJS
import React from "react";
import { Button, Table, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core";
import { formatDateLarge } from "../../../constants.js/misc";

const MobileTableDomain = ({ storeData, setIsOpen }) => {
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableRow>
                    <TableCell align="left">Nombre de dominio</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                        <Typography
                            variant="h5"
                            style={{ color: '#0F2930', fontSize: 16 }}
                        >
                            {storeData?.domain}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell align="left">Estado</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                        <Typography
                            variant="h5"
                            style={{
                                float: "right",
                                alignItems: 'center',
                                backgroundColor: '#48AC98',
                                borderRadius: 12,
                                color: '#FFFFFF',
                                display: 'flex',
                                fontSize: 16,
                                height: 26,
                                justifyContent: 'center',
                                margin: 'auto',
                                width: 86,
                            }}
                        >
                            Conectado
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell align="left">Fecha en que se agregó</TableCell>
                    <TableCell style={{ textAlign: "right" }}>{formatDateLarge(storeData?.custom_domain_created_at)}</TableCell>
                </TableRow>

                {/* <TableRow>
                    <TableCell align="left">Proveedor</TableCell>
                    <TableCell style={{textAlign: "right"}}>GoDaddy.com, LLC</TableCell>
                </TableRow> */}

                <TableRow>
                    <TableCell align="center" colSpan={2}>
                        <Button
                            style={{
                                backgroundColor: '#EEEEEE',
                                color: '#53627C',
                                height: 33,
                                idth: 94,
                                fontSize: 16
                            }}
                            type="button"
                            onClick={() => setIsOpen(true)}
                            variant="contained"
                        >
                            Cambiar
                        </Button>
                    </TableCell>
                </TableRow>
            </Table>
        </TableContainer>
    );
}

export default MobileTableDomain;