import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';


import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from '../../assets/img/10x_logo.svg';
import ImageDefault from '../../assets/img/default/wa10x_desktop_login.png';
import { ContainerApp, Description, CodeGenerate, ContainerCode } from './CodeMobileGeneratePage.styles';


/*SERVICES */
import {
    generateCodeAuthWeb
} from '../../services/auth';
import { logOut } from '../../modules/auth';



const CodeMobileGeneratePage = ({ logOut }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [codeView, setCodeView] = useState('');
    const [progress, setProgress] = useState(0);


    const generateCode = () => {
        generateCodeAuthWeb()
            .then(({ data }) => {
                setProgress(10);
                setCodeView(data.code);
            });
    }

    const logoutAndGoHome = () => {
        logOut();
        history.push('/');
    }


    useEffect(() => {
        if (codeView) {
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
            }, 6000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [codeView]);


    useEffect(() => {
        generateCode();
        const timer = setInterval(() => {
            generateCode();
        }, 60000);
        return () => {
            clearInterval(timer);
        };
    }, []);


    return (
        <>
            <ContainerApp>
                <img style={{ height: '40px' }} src={logo} alt="logo"></img>
                <Description>
                    Para usar 10X ingresa a get10x.co/code en tu computadora
                   
                </Description>
                <img style={{ height: '150px', display: 'block', margin: '0 auto' }} src={ImageDefault} ></img>
                <Description>
                <p>Para ingresar al panel de control de 10X debes iniciar sesion a traves de un computador.
                    Ve a <span style={{ fontWeight: 'bold' }}>get10x.co/code</span> e ingresar este codigo de seguridad
                   </p>
                </Description>


                <ContainerCode>
                    <CircularProgress variant="static" value={progress} />
                    <CodeGenerate>
                        {codeView}
                    </CodeGenerate>
                </ContainerCode>


                <Button
                    fullWidth
                    onClick={logoutAndGoHome}
                    color="primary"
                    variant="contained">
                    {t('Logout')}
                </Button>

            </ContainerApp>
        </>
    );
}

const mapStateToProps = ({ auth }) => ({
    isUserAuthenticated: auth.isUserAuthenticated
});

const mapDispatchToProps = dispatch => bindActionCreators({
    logOut
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CodeMobileGeneratePage);